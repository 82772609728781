import { faArrowLeft, faBed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  Popover,
  Drawer,
  Spin,
  InputRef,
  notification,
  Modal,
} from "antd";
import format from "date-fns/format";
import { DateRange } from "react-date-range";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SearchContext } from "../../../Context/context";
import ILocationSuggestion, {
  IGeoLocale,
} from "../../../Models/SearchBoxModel";
import { ISearch, ISuggestValue } from "../../../Models/Search";
import ChildrenBox from "../ChildrenBox";
import DebounceSelect, { fetchSuggestList } from "./AutoComplete";
import FloatLabel from "../../FloatLabel";
import { RoutesPath } from "../../../Routes";
import { useViewport } from "../../../Context/useViewport";
import { getRaiusKM } from "../../../helpers/requestSetting";
import {
  totalAdults,
  childrenAges,
  totalChildrens,
} from "../../../helpers/occupanciesCalc";

moment.locale("en");

interface SearchBoxFormProps {
  onChangeUbication(value: string): void | null;
  onUpdate?(): void;
  isWpWidget?: boolean;
  setErr: React.Dispatch<React.SetStateAction<boolean>>;
  setErrmsg: React.Dispatch<React.SetStateAction<string>>;
  Err: boolean;
  isWidget?: boolean;
}

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

interface optionSelect {
  coordenates: IGeoLocale;
  type?: string;
  id?: number;
}

const SearchBoxForm = ({
  onChangeUbication,
  onUpdate,
  isWpWidget,
  setErr,
  setErrmsg,
  Err,
  isWidget,
}: SearchBoxFormProps): ReactElement => {
  const navigate = useNavigate();
  const [loadingBox, setLoadingBox] = useState(false);
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;
  const [searchParams] = useSearchParams();
  const [optionSelected, setOptionSelected] = useState<optionSelect>();
  const [locationName, setLocationName] = useState("");
  const [valueSuggest, setValueSuggest] = React.useState<ISuggestValue[]>([]);
  const [range, setRange] = useState([
    {
      startDate:
        searchContent.checkIn === "Invalid date"
          ? moment(moment().add(1, "days")).toDate()
          : moment(moment(searchContent.checkIn)).toDate(),
      endDate:
        searchContent.checkOut === "Invalid date"
          ? moment(moment().add(1, "days")).toDate()
          : moment(moment(searchContent.checkOut)).toDate(),
      key: "selection",
    },
  ]);
  const [dateRangeopen, setDateRangeopen] = useState(false);
  const [dateSelectionPos, setdateSelectionPos] = useState(1);
  const refOne = useRef<HTMLDivElement | null>(null);
  const locationParam = searchParams.get("countryname") || "";
  const latParam = searchParams.get("lat");
  const longParam = searchParams.get("long");
  const hotelId = searchParams.get("hotelId");
  const today = new Date();

  const futureDate = new Date();
  const { width } = useViewport();
  futureDate.setDate(futureDate.getDate() + 3);
  const [open, setOpen] = useState<boolean>(false);
  const [isRequired, setRequired] = useState<boolean>(false);
  const isRequiredFocus = useRef<InputRef>(null);

  const correlationId = uuidv4();

  const hideOnEscape = (e: { key: string }) => {
    if (e.key === "Escape") {
      setDateRangeopen(false);
    }
  };

  const hideOnClickOutside = (e: { target: any }) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setDateRangeopen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  useEffect(() => {
    if (locationParam && locationParam != "null") {
      if (valueSuggest && valueSuggest.length === 0) {
        setRequired(false);
        setValueSuggest([{ label: locationParam, value: locationParam }]);
        setLocationName(locationParam);
        if (latParam && longParam)
          setOptionSelected({
            coordenates: {
              lat: parseFloat(latParam),
              long: parseFloat(longParam),
            },
          });
      }
      setValueSuggest([
        {
          label: locationParam.replaceAll("%", " "),
          value: locationParam.replaceAll("%", " "),
        },
      ]);
    } else {
      setValueSuggest([]);
    }
  }, [locationParam]);

  const handleDateChange = (dateString: {
    startDate: Date;
    endDate: Date;
    key: string;
  }) => {
    setdateSelectionPos(dateSelectionPos + 1);
    if (dateSelectionPos % 2 == 0) {
      setDateRangeopen(false);
      setdateSelectionPos(1);
    }
    if (
      moment(moment(dateString.endDate), "YYYY-MM-DD").diff(
        moment(moment(dateString.startDate), "YYYY-MM-DD"),
        "days"
      ) > 31
    ) {
      notification.error({
        message: "Please Select a shorter stay",
      });
      return;
    }
    setRange([dateString]);
    setSearchContent({
      ...searchContent,
      checkIn: moment(dateString.startDate).format("YYYY-MM-DD"),
      checkOut: moment(dateString.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleClick = async () => {
    if (searchContent.filters) {
      const { filters, ...newObject } = searchContent;
      setSearchContent(newObject);
    }
    if (searchContent.sortOption) {
      setSearchContent({
        ...searchContent,
        sortOption: "Lowest price",
      });
    }
    if (
      moment(searchContent.checkIn).format("YYYY-MM-DD") ===
      moment(searchContent.checkOut).format("YYYY-MM-DD")
    ) {
      notification.error({
        message: "At least one night required",
      });
      return;
    }

    setLoadingBox(true);

    if (hotelId && !optionSelected) {
      const device = searchParams.get("device");
      const price = searchParams.get("price");
      const tax = searchParams.get("tax");
      const fee = searchParams.get("fee");
      const name = searchParams.get("name");
      const roomIdentifierKey = searchParams.get("roomIdentifierKey");
      const otherRateComponent = searchParams.get("otherRateComponent");
      const checkout = searchParams.get("checkout");
      const checkin = searchParams.get("checkin");
      const adults = parseInt(searchParams.get("adults") || "0");
      const rooms = parseInt(searchParams.get("rooms") || "0");
      const children = searchParams.get("children")?.split(",");

      let extraParams = "";

      const validateParam = (paramValue: string | null) => {
        return (
          paramValue !== undefined &&
          paramValue !== null &&
          paramValue !== "" &&
          paramValue !== "null" &&
          paramValue !== "undefined"
        );
      };

      const validateOcupp = (): boolean => {
        if (
          rooms != searchContent.occupancies.length ||
          adults != totalAdults(searchContent.occupancies) ||
          (children != undefined &&
            children.length != totalChildrens(searchContent.occupancies))
        ) {
          return false;
        }
        return true;
      };

      if (
        validateParam(device) &&
        validateParam(name) &&
        checkout == searchContent.checkOut &&
        checkin == searchContent.checkIn &&
        validateParam(price) &&
        validateParam(tax) &&
        validateParam(fee) &&
        validateParam(roomIdentifierKey) &&
        validateParam(otherRateComponent) &&
        validateOcupp()
      ) {
        extraParams = `&device=${device}&price=${price}&tax=${tax}&fee=${fee}&name=${name}&roomIdentifierKey=${roomIdentifierKey}&otherRateComponent=${otherRateComponent}&context=google`;
      } else {
        extraParams = "&context=DirectHotel";
      }
      const url = `${
        RoutesPath.hotelDetail
      }?hotelId=${hotelId}&countryname=${locationName}&checkin=${
        searchContent.checkIn
      }&checkout=${searchContent.checkOut}&adults=${totalAdults(
        searchContent.occupancies
      )}&children=${childrenAges(searchContent.occupancies)}&rooms=${
        searchContent.occupancies.length
      }&nationality=US&countryOfResidence=US&currency=${
        searchContent.currency
      }&culture=en-US${extraParams}`;
      if (url.includes("context=DirectHotel")) {
        navigate(url, { state: { fromwithinapp: true } });
      } else {
        navigate(url);
      }

      navigate(url);
      setLoadingBox(false);
    }
    if (onUpdate !== undefined) {
      onUpdate();
    }
    if (optionSelected && optionSelected.type === "Hotel") {
      navigate(
        `${RoutesPath.hotelDetail}?hotelId=${
          optionSelected.id
        }&countryname=${locationName}&checkin=${
          searchContent.checkIn
        }&checkout=${searchContent.checkOut}&adults=${totalAdults(
          searchContent.occupancies
        )}&children=${childrenAges(searchContent.occupancies)}&rooms=${
          searchContent.occupancies.length
        }&nationality=US&countryOfResidence=US&currency=${
          searchContent.currency
        }&culture=en-US&context=DirectHotel`,
        { state: { fromwithinapp: true } }
      );
      setLoadingBox(false);
    } else if (optionSelected) {
      if (valueSuggest.length === 0) {
        setLoadingBox(false);
        notification.error({
          message: "Please Select a destination",
        });
        setRequired(true);
        isRequiredFocus.current!.focus({
          cursor: "start",
        });
        return;
      }
      navigate(
        `${
          RoutesPath.root
        }hotel/results?correlationId=${correlationId}&checkin=${
          searchContent.checkIn
        }&checkout=${searchContent.checkOut}&lat=${
          optionSelected.coordenates.lat
        }&long=${
          optionSelected.coordenates.long
        }&radius=${await getRaiusKM()}&rooms=${
          searchContent.occupancies.length
        }&adults=${totalAdults(
          searchContent.occupancies
        )}&children=${childrenAges(
          searchContent.occupancies
        )}&countryname=${locationName}&currency=${searchContent.currency}`
      );
      setLoadingBox(false);
    } else if (!hotelId && !optionSelected) {
      notification.error({
        message: "Please Select a destination",
      });
      setLoadingBox(false);
      return;
    }
  };

  const handlerFromWP = async () => {
    if (!optionSelected) return;
    if (optionSelected.type === "Hotel") {
      window.parent.location.href = `${localStorage.getItem(
        "REACT_APP_BASE_URL"
      )}/${RoutesPath.hotelDetail}?hotelId=${optionSelected.id}&checkin=${
        searchContent.checkIn
      }&checkout=${
        searchContent.checkOut
      }&correlationId=${correlationId}&adults=${totalAdults(
        searchContent.occupancies
      )}&children=${childrenAges(searchContent.occupancies)}&currency=${
        searchContent.currency
      }`;
    } else {
      window.parent.location.href = `${localStorage.getItem(
        "REACT_APP_BASE_URL"
      )}/hotel/results?correlationId=${correlationId}&checkin=${
        searchContent.checkIn
      }&checkout=${searchContent.checkOut}&lat=${
        optionSelected.coordenates.lat
      }&long=${
        optionSelected.coordenates.long
      }&radius=${await getRaiusKM()}&rooms=${
        searchContent.occupancies.length
      }&adults=${totalAdults(
        searchContent.occupancies
      )}&children=${childrenAges(
        searchContent.occupancies
      )}&countryname=${locationName}&currency=${searchContent.currency}`;
    }
  };

  const searchButton = (
    <button
      type="button"
      className={
        isWidget
          ? `btn btn-search-box-filter-widget btn-block`
          : `btn btn-search-box-filter btn-block`
      }
      onClick={isWpWidget ? handlerFromWP : handleClick}
    >
      <div className="button-search_box">
        {loadingBox && (
          <div className="spin">
            <Spin />
          </div>
        )}
        <div className="texto">
          <span>{isWidget ? "FIND DEALS" : "Update Search"}</span>
        </div>
      </div>
    </button>
  );
  const transformCheckIn = moment(searchContent.checkIn, "YYYY-MM-DD");
  const checkIn = moment(transformCheckIn);
  const transformCheckOut = moment(searchContent.checkOut, "YYYY-MM-DD");
  const checkOut = moment(transformCheckOut);
  const numberNigths = Number.isNaN(Math.abs(checkIn.diff(checkOut, "days")))
    ? 0
    : Math.abs(checkIn.diff(checkOut, "days"));
  const labelCheck = `(${numberNigths} ${
    numberNigths > 1 ? "nights" : "night"
  })`;

  return (
    <div className={isWidget ? `result-container-widget` : `result-container`}>
      <Form layout="vertical" className={isWidget ? "" : "row"}>
        {isWidget ? (
          <>
            <h2 id="searchbox-title">BOOK A RIDICULOUS DEAL:</h2>
            <div className="row">
              <div
                className={"col-lg-10 input-search-box-col-widget"}
                id="select-ubication"
              >
                <FloatLabel
                  label={
                    isWpWidget
                      ? "Were to?"
                      : "City, address, landmark, or airport"
                  }
                  value={
                    isWpWidget && valueSuggest && valueSuggest.length === 0
                      ? undefined
                      : valueSuggest
                  }
                  isRequired={isRequired}
                >
                  <DebounceSelect
                    value={valueSuggest}
                    fetchOptions={fetchSuggestList}
                    allowClear
                    isRequiredFocus={isRequiredFocus}
                    onChange={(newValue, option) => {
                      try {
                        const selected =
                          option as unknown as ILocationSuggestion;
                        if (selected) {
                          setOptionSelected({
                            coordenates: selected?.coordinates,
                            type:
                              selected?.type === undefined ? "" : selected.type,
                            id: selected?.id,
                          });
                          setValueSuggest(newValue);
                          setLocationName(selected?.label);
                          onChangeUbication(selected?.label);
                          setRequired(false);
                        }
                      } catch {
                        setErr(true);
                        setErrmsg(
                          "Please type and select another hotel from the list "
                        );
                        setTimeout(() => {
                          setErrmsg("");
                          setErr(false);
                        }, 5000);
                      }
                    }}
                    style={{ width: "100%" }}
                    onClear={() => {
                      setErr(false);
                      setValueSuggest([]);
                      setOptionSelected(undefined);
                    }}
                  />
                </FloatLabel>
              </div>
              <div className="col-2">
                <div className="search-box-button-container pt-5">
                  {searchButton}
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-lg-5 ps-4"}>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ paddingLeft: 0, paddingRight: "2%" }}
                  >
                    {width >= 480 ? (
                      <Popover
                        content={<ChildrenBox setOpen={setOpen} open={open} />}
                        trigger="click"
                        placement="bottomRight"
                        open={open}
                        onOpenChange={() => setOpen(!open)}
                        className="pophover-wrapper"
                      >
                        <FloatLabel label="Rooms and Guest" value={1}>
                          <Form.Item>
                            <div className="children-buttons">
                              <div
                                className="children-buttons_box"
                                onClick={() => setOpen(!open)}
                              >
                                <FontAwesomeIcon
                                  icon={faBed as IconProp}
                                  className="mx-2"
                                  color="#0068EF"
                                />
                                <span>
                                  {searchContent.occupancies.length} Room,{" "}
                                  {totalAdults(searchContent.occupancies)}{" "}
                                  Adults,{" "}
                                  {totalChildrens(searchContent.occupancies)}{" "}
                                  {totalChildrens(searchContent.occupancies) ===
                                  1
                                    ? "Child"
                                    : "Children"}
                                </span>
                              </div>
                            </div>
                          </Form.Item>
                        </FloatLabel>
                      </Popover>
                    ) : (
                      <>
                        <Form.Item>
                          <div
                            className="children-buttons"
                            onClick={() => setOpen(true)}
                            style={{ width: "97%", marginLeft: "2%" }}
                          >
                            <div className="children-buttons_box">
                              <FontAwesomeIcon
                                icon={faBed as IconProp}
                                className="mx-2"
                                color="#0068EF"
                              />
                              <span>
                                {searchContent.occupancies.length} Room,{" "}
                                {totalAdults(searchContent.occupancies)} Adults,{" "}
                                {totalChildrens(searchContent.occupancies)}{" "}
                                {totalChildrens(searchContent.occupancies) === 1
                                  ? "Child"
                                  : "Children"}
                              </span>
                            </div>
                          </div>
                        </Form.Item>
                        <Drawer
                          title="Rooms and Guest"
                          placement="top"
                          closable
                          open={open}
                          onClose={() => setOpen(false)}
                          height="750"
                          closeIcon={
                            <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                          }
                        >
                          <FloatLabel label="Rooms and Guest" value={1}>
                            <Form.Item>
                              <div className="children-buttons">
                                <div className="children-buttons_box">
                                  <FontAwesomeIcon
                                    icon={faBed as IconProp}
                                    className="mx-2"
                                    color="#0068EF"
                                  />
                                  <span>
                                    {searchContent.occupancies.length} Room,{" "}
                                    {totalAdults(searchContent.occupancies)}{" "}
                                    Adults,{" "}
                                    {totalChildrens(searchContent.occupancies)}{" "}
                                    {totalChildrens(
                                      searchContent.occupancies
                                    ) === 1
                                      ? "Child"
                                      : "Children"}
                                  </span>
                                </div>
                              </div>
                            </Form.Item>
                          </FloatLabel>
                          <ChildrenBox setOpen={setOpen} open={open} />
                        </Drawer>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={"col-lg-5"}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                id="range-date-filter"
              >
                <Form.Item className="row date-box-test">
                  <FloatLabel label="Check-In - Check-Out" value={1}>
                    <div className="col calendarWrap">
                      <input
                        value={`${format(
                          range[0].startDate,
                          "MM/dd/yyyy"
                        )} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
                        readOnly
                        className={"inputBox ant-select-selector"}
                        onClick={() => setDateRangeopen((isOpen) => !isOpen)}
                      />
                      <Modal open={dateRangeopen}>
                        <div ref={refOne}>
                          {dateRangeopen && (
                            <DateRange
                              onChange={(item: any) =>
                                handleDateChange(item.selection)
                              }
                              moveRangeOnFirstSelection={false}
                              ranges={range}
                              months={2}
                              direction={
                                width <= 992 ? "vertical" : "horizontal"
                              }
                              className="calendar-element-widget"
                              minDate={
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate() + 1
                                )
                              }
                              preventSnapRefocus
                            />
                          )}
                        </div>
                      </Modal>
                    </div>
                  </FloatLabel>
                  <div
                    className={`col nights-total ${
                      isWpWidget ? "wp-widget" : ""
                    }`}
                  >
                    <span>{labelCheck}</span>
                  </div>
                </Form.Item>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div
              className={"col-lg-3 col-xs-12 input-search-box-col"}
              id="select-ubication"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <FloatLabel
                label={
                  isWpWidget
                    ? "Were to?"
                    : "City, address, landmark, or airport"
                }
                value={
                  isWpWidget && valueSuggest && valueSuggest.length === 0
                    ? undefined
                    : valueSuggest
                }
                isRequired={isRequired}
              >
                <DebounceSelect
                  value={valueSuggest}
                  fetchOptions={fetchSuggestList}
                  allowClear
                  isRequiredFocus={isRequiredFocus}
                  onChange={(newValue, option) => {
                    try {
                      const selected = option as unknown as ILocationSuggestion;
                      if (selected) {
                        setOptionSelected({
                          coordenates: selected?.coordinates,
                          type:
                            selected?.type === undefined ? "" : selected.type,
                          id: selected?.id,
                        });
                        setValueSuggest(newValue);
                        setLocationName(selected?.label);
                        onChangeUbication(selected?.label);
                        setRequired(false);
                      }
                    } catch {
                      setErr(true);
                      setErrmsg(
                        "Please type and select another hotel from the list "
                      );
                      setTimeout(() => {
                        setErrmsg("");
                        setErr(false);
                      }, 5000);
                    }
                  }}
                  style={{ width: "100%" }}
                  onClear={() => {
                    setErr(false);
                    setValueSuggest([]);
                    setOptionSelected(undefined);
                  }}
                />
              </FloatLabel>
            </div>
            <div
              className="col-lg-3 col-xs-12 input-search-box-col"
              style={{ paddingLeft: 0, paddingRight: 0 }}
              id="range-date-filter"
            >
              <Form.Item className="row date-box-test">
                <FloatLabel label="Check-In - Check-Out" value={1}>
                  <div className="col calendarWrap">
                    <input
                      value={`${format(
                        range[0].startDate,
                        "MM/dd/yyyy"
                      )} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
                      readOnly
                      className={"inputBox ant-select-selector"}
                      onClick={() => setDateRangeopen((isOpen) => !isOpen)}
                    />
                    <div ref={refOne}>
                      {dateRangeopen && (
                        <DateRange
                          onChange={(item: any) =>
                            handleDateChange(item.selection)
                          }
                          moveRangeOnFirstSelection={false}
                          ranges={range}
                          months={2}
                          direction={width <= 1220 ? "vertical" : "horizontal"}
                          className="calendarElement"
                          minDate={
                            new Date(
                              today.getFullYear(),
                              today.getMonth(),
                              today.getDate() + 1
                            )
                          }
                          preventSnapRefocus
                        />
                      )}
                    </div>
                  </div>
                </FloatLabel>
                <div
                  className={`col nights-total ${
                    isWpWidget ? "wp-widget" : ""
                  }`}
                >
                  <span>{labelCheck}</span>
                </div>
              </Form.Item>
            </div>
            <div
              className="col-lg-3 col-xs-12 input-search-box-col"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className="row">
                <div
                  className="col-12"
                  style={{ paddingLeft: 0, paddingRight: "2%" }}
                >
                  {width >= 480 ? (
                    <Popover
                      content={<ChildrenBox setOpen={setOpen} open={open} />}
                      trigger="click"
                      placement="bottomRight"
                      open={open}
                      onOpenChange={() => setOpen(!open)}
                      className="pophover-wrapper"
                    >
                      <FloatLabel label="Rooms and Guest" value={1}>
                        <Form.Item>
                          <div className="children-buttons">
                            <div
                              className="children-buttons_box"
                              onClick={() => setOpen(!open)}
                            >
                              <FontAwesomeIcon
                                icon={faBed as IconProp}
                                className="mx-2"
                                color="#0068EF"
                              />
                              <span>
                                {searchContent.occupancies.length} Room,{" "}
                                {totalAdults(searchContent.occupancies)} Adults,{" "}
                                {totalChildrens(searchContent.occupancies)}{" "}
                                {totalChildrens(searchContent.occupancies) === 1
                                  ? "Child"
                                  : "Children"}
                              </span>
                            </div>
                          </div>
                        </Form.Item>
                      </FloatLabel>
                    </Popover>
                  ) : (
                    <>
                      <Form.Item>
                        <div
                          className="children-buttons"
                          onClick={() => setOpen(true)}
                          style={{ width: "97%", marginLeft: "2%" }}
                        >
                          <div className="children-buttons_box">
                            <FontAwesomeIcon
                              icon={faBed as IconProp}
                              className="mx-2"
                              color="#0068EF"
                            />
                            <span>
                              {searchContent.occupancies.length} Room,{" "}
                              {totalAdults(searchContent.occupancies)} Adults,{" "}
                              {totalChildrens(searchContent.occupancies)}{" "}
                              {totalChildrens(searchContent.occupancies) === 1
                                ? "Child"
                                : "Children"}
                            </span>
                          </div>
                        </div>
                      </Form.Item>
                      <Drawer
                        title="Rooms and Guest"
                        placement="top"
                        closable
                        open={open}
                        onClose={() => setOpen(false)}
                        height="750"
                        closeIcon={
                          <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                        }
                      >
                        <FloatLabel label="Rooms and Guest" value={1}>
                          <Form.Item>
                            <div className="children-buttons">
                              <div className="children-buttons_box">
                                <FontAwesomeIcon
                                  icon={faBed as IconProp}
                                  className="mx-2"
                                  color="#0068EF"
                                />
                                <span>
                                  {searchContent.occupancies.length} Room,{" "}
                                  {totalAdults(searchContent.occupancies)}{" "}
                                  Adults,{" "}
                                  {totalChildrens(searchContent.occupancies)}{" "}
                                  {totalChildrens(searchContent.occupancies) ===
                                  1
                                    ? "Child"
                                    : "Children"}
                                </span>
                              </div>
                            </div>
                          </Form.Item>
                        </FloatLabel>
                        <ChildrenBox setOpen={setOpen} open={open} />
                      </Drawer>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xs-12">
              <div className="search-box-button-container p-2">
                {searchButton}
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
export default SearchBoxForm;
