import { Drawer, Modal } from "antd";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useViewport } from "../../../Context/useViewport";
import { RoutesPath } from "../../../Routes";

interface ModalAuthManagementProps {
  clickOnClose(): void;
  modalOpen: boolean;
  childrenModal: React.ReactElement | null;
  title: string;
  subtitle?: string;
  linkModal?: string;
}

const ModalAuthManagement = ({
  clickOnClose,
  modalOpen,
  childrenModal,
  title,
  subtitle,
  linkModal,
}: ModalAuthManagementProps): ReactElement => {
  const { width } = useViewport();
  const navigate = useNavigate();

  return width > 480 ? (
    <>
      <li className="link-right nav-link">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => navigate(RoutesPath.SignIn)}
        >
          Sign In
        </button>
      </li>
      <Modal
        open={modalOpen}
        title={title}
        bodyStyle={{ padding: "3rem 5rem" }}
        footer={null}
        closeIcon={<small>close</small>}
        closable
        keyboard
        onCancel={clickOnClose}
        destroyOnClose
      >
        <div className="text-center mb-3">
          <small>{subtitle}</small>
        </div>
        {childrenModal}
        <button
          type="button"
          className="btn btn-link btn-block"
          onClick={clickOnClose}
        >
          <small> {linkModal}</small>
        </button>
      </Modal>
    </>
  ) : (
    <Drawer
      title={title}
      placement="top"
      closable
      open={modalOpen}
      onClose={clickOnClose}
      bodyStyle={{ padding: "1rem 2.5rem" }}
      height={450}
    >
      <div className="text-center mb-3">
        <small>{subtitle}</small>
      </div>
      {childrenModal}
      <button
        type="button"
        className="btn btn-link btn-block pb-3"
        onClick={clickOnClose}
      >
        <span> {linkModal}</span>
      </button>
    </Drawer>
  );
};
export default ModalAuthManagement;
