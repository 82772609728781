import React from "react";
import moment from "moment";
import { Button, Result, Modal, Table, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import LoaderComponent from "../../../Components/LoaderComponent";
import { IBookDetailResponse } from "../../../Models/BookDetailRequest";
import { EBookingSatus } from "../../../Common/Enums";
import { cancelBookingFirstStep } from "./cancelBookingFirstStep";
import { DataType } from ".";
const { TextArea } = Input;

export const ChildrenCancelBooking = (
  BookDetail: IBookDetailResponse | undefined,
  OpenModal: boolean,
  handleCloseModal: () => void,
  CurrentStep: number,
  handleOkModal: () => void,
  SelectedReason: string | undefined,
  OtherReason: string,
  columns: ColumnsType<DataType>,
  data: DataType[],
  rowSelection: {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => void;
  },
  handleTextAreaChange: (e: { target: { value: any } }) => void,
  loading: boolean,
  navigate: any,
  step: number,
  width: number,
  providerNumber: string,
  setProviderNumber: React.Dispatch<React.SetStateAction<string>>,
  error: boolean,
  handleConfirm: () => Promise<void>,
  errorMessage: string
) => {
  return (
    <React.Fragment>
      <Modal
        title={`We are sorry you are cancelling, ${BookDetail?.hotelBooking.billingContact?.firstName}`}
        open={OpenModal}
        width={1000}
        onCancel={handleCloseModal}
        footer={
          CurrentStep === 1
            ? [
                <Button key="back" onClick={handleCloseModal}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleOkModal}
                  disabled={
                    !SelectedReason
                      ? true
                      : SelectedReason === "Other"
                      ? !(OtherReason.length > 0)
                      : false
                  }
                >
                  Next
                </Button>,
              ]
            : [
                <Button key="back" onClick={handleCloseModal}>
                  I want to keep my booking
                </Button>,
                <Button key="submit" type="primary" onClick={handleOkModal}>
                  I understand the cancellation policy and i wish to cancel
                </Button>,
              ]
        }
      >
        {CurrentStep === 1 ? (
          <>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
            />
            {SelectedReason === "Other" && (
              <TextArea
                rows={4}
                placeholder="Please state your reason"
                className="mt-2"
                onChange={handleTextAreaChange}
              />
            )}
          </>
        ) : (
          <div className="mt-3">
            <span className="small-titles">Cancellation Policy</span>
            <ul>
              {BookDetail?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                (item) => (
                  <li>
                    Cancellation Penalty between{" "}
                    {moment(item.start).format("MM/DD/YYYY")} to{" "}
                    {moment(item.end).format("MM/DD/YYYY")} is{" "}
                    {BookDetail.hotelBooking.rates[0].currency}
                    {BookDetail.hotelBooking.rates[0].currency === "USD" &&
                      "$"}{" "}
                    {item.estimatedValue}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </Modal>
      {loading && <LoaderComponent title="Loading Cancellation..." />}
      {BookDetail &&
        BookDetail.hotelBooking.bookingStatus === EBookingSatus.Cancelled &&
        navigate(
          `/bookings/detail?bookId=${BookDetail.hotelBooking.bookingId}`
        )}

      {!loading &&
        step === 1 &&
        cancelBookingFirstStep(
          width,
          providerNumber,
          setProviderNumber,
          error,
          handleConfirm,
          BookDetail
        )}
      {!loading && step === 2 && (
        <Result
          status="success"
          title="Your booking has been succesfully cancelled"
          extra={[
            <Button type="primary" key="console" onClick={() => navigate("/")}>
              Go Home
            </Button>,
          ]}
        />
      )}
      {!loading && step === 3 && (
        <Result
          status="error"
          title="Booking Cancellation Error"
          subTitle={errorMessage}
          extra={[
            <Button type="primary" key="console" onClick={() => navigate("/")}>
              Go Home
            </Button>,
          ]}
        />
      )}
    </React.Fragment>
  );
};
