import { Divider } from "antd";
import React, { ReactElement } from "react";
import TitleFilter from "../TitleFilter";

const FilterItem = (props: {
  title: string;
  children: any;
  className?: string;
}): ReactElement => {
  const { title, children, className = "my-2" } = props;
  return (
    <div className={className}>
      <TitleFilter title={title} />
      {children}
    </div>
  );
};

export default FilterItem;
