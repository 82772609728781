import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faExpand,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel } from "antd";
import React, { ReactElement, useState, useEffect } from "react";
import { useViewport } from "../../../../Context/useViewport";
import { IImage } from "../../../../Models/HotelContent";

interface HeroBannerProps {
  images?: IImage[];
  arrayHeroBanner?: IImage[];
  nameHotel?: string;

  onClickLightBox(index: number): void;
}

const HeroBanner = ({
  images,
  arrayHeroBanner,
  nameHotel,
  onClickLightBox,
}: HeroBannerProps): ReactElement => {
  const { width } = useViewport();
  const [totalSlides, setTotalslides] = useState<number>(0);

  useEffect(() => {
    if (width <= 480) {
      setTotalslides(1);
    } else if (width > 480 && width <= 1024) {
      setTotalslides(3);
    } else if (width > 1024) {
      setTotalslides(5);
    }
  }, [width]);
  return (
    <Carousel
      slidesToScroll={1}
      slidesPerRow={totalSlides}
      draggable
      infinite={false}
      dots={false}
      nextArrow={<FontAwesomeIcon icon={faAngleRight as IconProp} />}
      prevArrow={<FontAwesomeIcon icon={faAngleLeft as IconProp} />}
      arrows
    >
      {arrayHeroBanner?.map((img, index) => (
        <div className="card-hero-banner mx-2" key={index}>
          <img
            src={img.links[0]?.url}
            alt={nameHotel}
            className="hero-banner-image"
          />
          <div className={`card-overlay ${index === 5 ? "" : "d-none"}`}>
            <div className="photos-information">
              <FontAwesomeIcon
                icon={faImage as IconProp}
                color="white"
                className="me-2"
              />
              <span className="label-card-overlay">
                {images?.length} Photos
              </span>
            </div>
            <button
              className="btn btn-primary rounded-pill"
              type="button"
              onClick={() => onClickLightBox(index)}
            >
              View all
            </button>
          </div>
          {index === 0 && (
            <span className="badge-total-images">
              {index + 1}/{images?.length}
            </span>
          )}
          <button
            className={`btn btn-light btn-view-lightbox ${
              index === 5 ? "d-none" : ""
            }`}
            type="button"
            onClick={() => onClickLightBox(index)}
          >
            <FontAwesomeIcon icon={faExpand as IconProp} color="#0068EF" />
          </button>
          {index === 0 && (
            <button
              onClick={() => onClickLightBox(index)}
              className="btn btn-primary rounded-pill btn-view-lightbox-start"
              type="button"
            >
              <FontAwesomeIcon
                icon={faImage as IconProp}
                color="white"
                className="me-2"
              />
              See all {images?.length} photos
            </button>
          )}
        </div>
      ))}
    </Carousel>
  );
};
export default HeroBanner;
