import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Divider, Modal, Table, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { IFacility } from "../../../../../../Models/CommonType";
import {
  IDailyRate,
  IRate,
  IRecommendation,
  IRoom,
} from "../../../../../../Models/RoomDetail";
import FacilityRoom from "./FacilityRoom";
import { IFakePrice } from "../../../../../../Models/GooglePrice";
import SupplierLabel from "../../../../../../Components/HotelCard/InformationHotelSection/SupplierLabel";
import { PriceContext } from "../../../../../../Context/RoomContext";
import { IPolicy } from "../../../../../../Models/HotelContent";
import { IPriceResponse } from "../../../../../../Models/Checkout";
import RowRoomCard from "./RowRoomCard";
import GroupRoomCard from "./GroupRoomCard";
import { camelCaseToNormalString } from "../../../../../../helpers/validationsInputs";
import DOMPurify from "dompurify";
import { useViewport } from "../../../../../../Context/useViewport";
import { fetchCountry } from "../../../../../../helpers/fetchLocation";
import { ISearch } from "../../../../../../Models/Search";
import { SearchContext } from "../../../../../../Context/context";
import { getIp } from "../../../../../../helpers/configHelper";
import { isMobile } from "react-device-detect";
import { isDeviceExempt } from "../../../../../../helpers/validateExemptDevice";
import { validateDevice } from "../../../../../../helpers/twoFactor";
const { Paragraph } = Typography;
const userAgent: string = window.navigator.userAgent;

interface ContentRoomCardProp {
  room: IRoom;
  rates?: IRate[];
  recommendations?: IRecommendation[];
  correlationId: string;
  deeplink: string | undefined;
  description: string;
  name: string;
  facilities?: Array<IFacility>;
  id: string; // roomid
  numOfAdults: number;
  numOfChildren: number;
  fakePrice: IFakePrice | undefined;
  provider: string;
  hotelDetailPolicy?: IPolicy[];
  rawRates: IRate[];
  closeOtherOffers?: () => void;
  isMultiRoom: boolean;
  setOpenDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  setcheckoutUrl?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedRate?: React.Dispatch<React.SetStateAction<IRate | undefined>>;
  showNightlyRate: boolean;
  sFakePrice: IFakePrice | undefined;
  isIpExcempt: boolean;
}

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

interface ISearchParams {
  hotelId: string | null;
  adults: string | null;
  children: string | null;
  rooms: number;
  context: string | null;
}

interface transformPrices {
  date: string;
  amount: string;
}

const ContentRoomCard = ({
  correlationId,
  deeplink,
  name,
  description,
  facilities,
  id,
  numOfAdults,
  numOfChildren,
  fakePrice,
  provider,
  room,
  rates,
  recommendations,
  hotelDetailPolicy,
  rawRates,
  closeOtherOffers,
  isMultiRoom,
  setOpenDrawer,
  setcheckoutUrl,
  setSelectedRate,
  showNightlyRate,
  sFakePrice,
  isIpExcempt,
}: ContentRoomCardProp): ReactElement => {
  const [searchParams] = useSearchParams();
  const { searchContent } = useContext(SearchContext) as ContextType;
  const navigate = useNavigate();
  const { width } = useViewport();
  const [viewPolicies, setViewPolicies] = useState<boolean>(false);
  const [isDeviceExempted, setDeviceExempt] = useState<boolean>(false);
  const [modalObject, setModalObject] = useState({
    policyObject: [],
    refundable: false,
  });
  const [dataPrices, setdataPrices] = useState<transformPrices[]>([]);
  const [myRates, setMyRates] = useState<IRate[] | undefined>([]);
  const [currentPolicy, setCurrentPolicy] =
    useState<{ text: string; type: string }[]>();
  const checkIn = searchParams.get("checkin");
  const transformCheckIn = moment(checkIn, "YYYY-MM-DD");
  const checkinAsDate = moment(transformCheckIn);
  const checkOut = searchParams.get("checkout");
  const transformCheckOut = moment(checkOut, "YYYY-MM-DD");
  const checkoutAsDate = moment(transformCheckOut);
  const [facilitiesArray, setFacilitiesArray] = useState<
    IFacility[] | undefined
  >([]);
  const { setPriceContext } = useContext<IPriceResponse | any>(PriceContext);
  function getParams(): ISearchParams {
    const hotelId = searchParams.get("hotelId");
    const adults = searchParams.get("adults");
    const children = searchParams.get("children");

    const rooms = parseInt(searchParams.get("rooms") || "1", 10);
    const context = searchParams.get("context");

    return {
      hotelId,
      adults,
      children,
      rooms,
      context,
    };
  }

  const returnRecomIDByRateID = (rate: IRate) => {
    const recomArr = recommendations?.filter(
      (recomendation) => recomendation.rates[0] === rate.id
    );
    if (!recomArr) return "Not found";
    return recomArr[0].id;
  };
  const urlMaker = (
    hotelId: string | null,
    rooms: number | null,
    rate: IRate | IRate[],
    rateId: string | string[],
    roomId: string,
    adults: string | null,
    children: string | null,
    context: string | null,
    recomId?: string
  ) => {
    let extraParams = "";
    const trv_reference = searchParams.get("trv_reference") ?? "";
    const advertiser_id =
      searchParams.get("adv_id") ?? searchParams.get("advertiser_id") ?? "";
    const locale = searchParams.get("locale") ?? "";

    extraParams += `&trv_reference=${trv_reference}&advertiser_id=${advertiser_id}&locale=${locale}`;

    const currentBaseRate = Array.isArray(rate)
      ? rate[0].baseRate
      : rate.baseRate;
    if (
      fakePrice &&
      (fakePrice.roomId === roomId || fakePrice.roomIdentifierKey === roomId) &&
      fakePrice.price === currentBaseRate
    ) {
      if (fakePrice.roomName) extraParams += `&name=${fakePrice.roomName}`;

      if (fakePrice.device) extraParams += `&device=${fakePrice.device}`;

      if (fakePrice.price > 0) {
        setPriceContext({
          currency: Array.isArray(rate) ? rate[0].currency : rate.currency,
          token: sessionStorage.getItem("token"),
          hotel: { id: hotelId, rooms: [room], rates: [rate] },
        });
        extraParams += `&price=${fakePrice.price}&tax=${fakePrice.tax}&fee=${fakePrice.fee}&otherRateComponent=${fakePrice.otherRateComponent}&roomIdentifierKey=${fakePrice.roomIdentifierKey}`;
      }
    }

    if (
      sFakePrice &&
      (sFakePrice.roomId === roomId ||
        sFakePrice.roomIdentifierKey === roomId) &&
      sFakePrice.price === currentBaseRate
    ) {
      if (sFakePrice.roomName) extraParams += `&name=${sFakePrice.roomName}`;

      if (sFakePrice.device) extraParams += `&device=${sFakePrice.device}`;

      if (sFakePrice.price) {
        setPriceContext({
          currency: Array.isArray(rate) ? rate[0].currency : rate.currency,
          token: sessionStorage.getItem("token"),
          hotel: { id: hotelId, rooms: [room], rates: [rate] },
        });
        extraParams += `&price=${sFakePrice.price}&tax=${sFakePrice.tax}&fee=${sFakePrice.fee}&otherRateComponent=${sFakePrice.otherRateComponent}&roomIdentifierKey=${sFakePrice.roomIdentifierKey}`;
      }
    }

    if (context) extraParams += `&context=${context}`;
    const locationParam = searchParams.get("countryname");
    const url = `/checkout?hotelId=${hotelId}&recommendationId=${
      recomId
        ? recomId
        : returnRecomIDByRateID(Array.isArray(rate) ? rate[0] : rate)
    }&rateid=${rateId}&basePrice=${
      Array.isArray(rate) ? rate[0].baseRate : rate.baseRate
    }&name=${name}&roomid=${roomId}&countryname=${locationParam}&checkin=${checkIn}&checkout=${checkOut}&correlationId=${correlationId}&adults=${adults}&children=${
      children
        ?.split(",")
        .filter(Number)
        .map((item) => parseInt(item, 10)) || []
    }&rooms=${rooms}&deeplink=${deeplink}${extraParams}`;
    return encodeURI(url);
  };
  const handleBook = async (
    rateId: string[] | string,
    rate: IRate | IRate[],
    recomId?: string
  ) => {
    let alreadyAuthString = localStorage.getItem("UserAuth");
    let { providerId } = Array.isArray(rate) ? rate[0] : rate;
    const validatedDevice = !(await validateDevice(isMobile, providerId));
    const isPreApproved = validatedDevice || isDeviceExempted;

    let alreadyAuth = alreadyAuthString ? JSON.parse(alreadyAuthString) : false;
    if (typeof closeOtherOffers === "function") {
      closeOtherOffers();
    }
    const countryLoc = await fetchCountry(
      searchContent.userIp ?? (await getIp())
    );

    const params = getParams();
    const url = urlMaker(
      params.hotelId,
      params.rooms,
      rate,
      rateId,
      id,
      params.adults,
      params.children,
      params.context,
      recomId
    );
    setSelectedRate && setSelectedRate(Array.isArray(rate) ? rate[0] : rate);

    if (
      isPreApproved ||
      alreadyAuth ||
      (countryLoc.toLowerCase() !== "us" && countryLoc.toLowerCase() !== "ni")
    ) {
      navigate(url);
    } else {
      setcheckoutUrl && setcheckoutUrl(url);
      setOpenDrawer && setOpenDrawer(true);
      !setOpenDrawer && navigate(url);
    }
  };

  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  const info = () => {
    Modal.info({
      width: width - 10,
      title: "Price per day",
      content: (
        <div className="policy-modal">
          <Divider />
          <Table columns={columns} dataSource={dataPrices} pagination={false} />
          <Divider />
          {currentPolicy &&
            currentPolicy.length > 0 &&
            currentPolicy.map(
              (policy: { text: string; type: string }, index: number) => (
                <div key={index} className="mt-2">
                  <strong>{camelCaseToNormalString(policy.type)}</strong>
                  <br />
                  <small
                    dangerouslySetInnerHTML={{
                      __html: `${DOMPurify.sanitize(policy.text || "")}`,
                    }}
                  />
                </div>
              )
            )}
          <Divider />
        </div>
      ),
      onOk() {
        setdataPrices([]);
        setCurrentPolicy([]);
      },
    });
  };

  const showDailyRates = (
    dailyRates: IDailyRate[],
    currency: string,
    policies: { text: string; type: string }[]
  ) => {
    setCurrentPolicy(policies);
    const transform = dailyRates.map((item) => {
      const dateTra = moment(item.date, "YYYY-MM-DD");
      const finalDate = moment(dateTra);
      const obj = {
        date: `${finalDate.format("dddd").slice(0, 3)}, ${finalDate.format(
          "MMMM"
        )} ${finalDate.format("DD")}`,
        amount: `${currency === "USD" ? "$" : `${currency}`}${Math.trunc(
          item.amount
        )}`,
      };

      return obj;
    });
    setdataPrices(transform);
  };

  const baseRatePerDay = (rate: IRate) => {
    const diffDays = Math.abs(checkinAsDate.diff(checkoutAsDate, "days"));
    if (diffDays > 0) {
      return Math.trunc(rate.baseRate / diffDays);
    }
    return Math.trunc(rate.baseRate / 1);
  };

  useEffect(() => {
    if (dataPrices.length > 0) {
      info();
    }
  }, [dataPrices]);

  useEffect(() => {
    const init = async (): Promise<void> => {
      const isDeviceExempted = await isDeviceExempt(userAgent);
      setDeviceExempt(isDeviceExempted);
      if(!isDeviceExempted){
        setDeviceExempt(isIpExcempt);
      }
    };
    init();
  }, [userAgent]);

  useEffect(() => {
    setMyRates(rates);
  }, [fakePrice, id, rates]);

  useEffect(() => {
    if (facilities && facilities?.length > 4) {
      setFacilitiesArray(facilities?.splice(0, 4));
    } else {
      setFacilitiesArray(facilities);
    }
  }, [facilities]);

  const ratesData = myRates?.map((a) =>
    Object.assign(
      {
        key: a.id,
      },
      ...[a]
    )
  );

  const checkPolicies = (policy: any, refundable: boolean) => {
    setModalObject({
      policyObject: policy,
      refundable,
    });
    setViewPolicies(!viewPolicies);
  };

  const removeFacilitiesDuplicated = (arr: IFacility[]) => {
    const withoutDuplicates = arr.filter(
      (v, i, a) => a.findIndex((v2) => v2.name === v.name) === i
    );
    return withoutDuplicates;
  };

  const transformDate = (checkDate: Date) => moment(checkDate, "YYYY-MM-DD");

  const removeDuplicatesById = (arr: IRecommendation[]) => {
    const uniqueMap = new Map();

    for (const obj of arr) {
      const id = obj.id;
      if (!uniqueMap.has(id)) {
        uniqueMap.set(id, obj);
      }
    }
    return Array.from(uniqueMap.values());
  };

  const getRecommendationOfRoom = () => {
    let recomOfRoom: IRecommendation[] = [];
    recommendations?.map((recommendation) => {
      recommendation.rates.map((rateID) => {
        rawRates.map((rate) => {
          if (rate.id === rateID) {
            if (rate.occupancies[0].roomId === id) {
              recomOfRoom.push(recommendation);
            }
          }
        });
      });
    });
    return removeDuplicatesById(recomOfRoom);
  };

  const makeReadableString = (rateText: string) => {
    switch (rateText) {
      case "nomeal":
        return "No meal";
      default:
        return rateText;
    }
  };

  return (
    <>
      <div
        className=".col-12 .col-sm-12 col-md-4 col-lg-4.col-xl-4 pt-3 p-2"
        style={
          width <= 768
            ? {
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              }
            : {}
        }
      >
        <div className="row">
          <div className="col-lg-9 col-xs-12">
            <h5>{name}</h5>
            <div className="d-flex">
              <span className="max-guest-total">Adults: {numOfAdults}</span>
              <span className="max-guest-total">
                &nbsp; Children: {numOfChildren}
              </span>
            </div>
          </div>
          {description && (
            <div className="col-12 ">
              <div className="room-description">
                <Paragraph
                  ellipsis={{
                    rows: 4,
                    expandable: true,
                    suffix: "",
                  }}
                  title={`${description?.replace(/<\/?[^>]+(>|$)/g, "")}`}
                >
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Paragraph>
              </div>
            </div>
          )}
          {facilitiesArray && (
            <div className=".col-12 .col-sm-12 col-md-4 col-lg-4 .col-xl-4 room-description-facility">
              {removeFacilitiesDuplicated(facilitiesArray).map(
                (facility, index) => (
                  <FacilityRoom nameFacility={facility.name} key={index} />
                )
              )}
            </div>
          )}
          <SupplierLabel supplier={provider} className="room-detail-supplier" />
        </div>
      </div>
      {!isMultiRoom ? (
        <RowRoomCard
          ratesData={ratesData}
          checkPolicies={checkPolicies}
          transformDate={transformDate}
          modalObject={modalObject}
          hotelDetailPolicy={hotelDetailPolicy}
          setViewPolicies={setViewPolicies}
          viewPolicies={viewPolicies}
          fakePrice={fakePrice}
          id={id}
          checkinAsDate={checkinAsDate}
          checkoutAsDate={checkoutAsDate}
          baseRatePerDay={baseRatePerDay}
          showDailyRates={showDailyRates}
          handleBook={handleBook}
          showNightlyRate={showNightlyRate}
          sFakePrice={sFakePrice}
        />
      ) : (
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 no-padding">
          {getRecommendationOfRoom().map((recomendation) => (
            <GroupRoomCard
              ratesData={rates || []}
              recommendation={recomendation}
              makeReadableString={makeReadableString}
              checkPolicies={checkPolicies}
              transformDate={transformDate}
              modalObject={modalObject}
              hotelDetailPolicy={hotelDetailPolicy}
              setViewPolicies={setViewPolicies}
              viewPolicies={viewPolicies}
              fakePrice={fakePrice}
              id={id}
              checkinAsDate={checkinAsDate}
              checkoutAsDate={checkoutAsDate}
              baseRatePerDay={baseRatePerDay}
              showDailyRates={showDailyRates}
              handleBook={handleBook}
              rawRates={rawRates}
              showNightlyRate={showNightlyRate}
              sFakePrice={sFakePrice}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ContentRoomCard;
