import React from "react";
import { Divider } from "antd";
import DOMPurify from "dompurify";
import { IDescription } from "../../../../Models/HotelContent";

interface InformationSectionProps {
  descriptionText?: IDescription[];
}

const InformationSection = ({ descriptionText }: InformationSectionProps) => (
  <div className="text-description-wrapper">
    <h4>About the Hotel</h4>
    {descriptionText?.map((item, index) => (
      <div key={index} className="p-5">
        <h6>{item.type}</h6>
        <div
          className="text-description"
          dangerouslySetInnerHTML={{
            __html: `${DOMPurify.sanitize(item.text)}`,
          }}
        />
        {descriptionText?.length > 1 && <Divider />}
      </div>
    ))}
    <Divider />
  </div>
);
export default InformationSection;
