import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import LightBoxComponent from "../../../../../../Components/LightBoxComponent";
import { ILink } from "../../../../../../Models/RoomDetail";
import { useViewport } from "../../../../../../Context/useViewport";

interface ImageRoomCardProps {
  images: ILink[];
  className?: string;
  altText?: string;
}

const ImageRoomCard = ({
  images,
  altText,
  className,
}: ImageRoomCardProps): ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);
  const [arrayImages, setArrayImages] = useState<ILink[]>([]);
  const carousel = useRef<CarouselRef>(null);
  const { width } = useViewport();
  const handleNextClick = () => carousel?.current?.next();
  const handlePrevClick = () => carousel?.current?.prev();

  const handleVisibleImage = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (images.length > 0 && images.length > 4) {
      setArrayImages(images.slice(0, 4));
    } else {
      setArrayImages(images);
    }
  }, [images]);

  return (
    <div
      className={`${className} .col-12 .col-sm-12 col-md-4 col-lg-4 .col-xl-4 .image-room-card-wrapper `}
      style={width < 900 ? { paddingLeft: 0, paddingRight: 0 } : {}}
    >
      {arrayImages !== undefined && arrayImages.length > 0 ? (
        arrayImages[0] && (
          <>
            <Carousel ref={carousel}>
              {arrayImages.map((image, index) => (
                <div key={index} className="img-wrapper">
                  <div className="indicator-last" onClick={handlePrevClick}>
                    <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                  </div>
                  <div className="indicator-next" onClick={handleNextClick}>
                    <FontAwesomeIcon icon={faAngleRight as IconProp} />
                  </div>
                  <div className="action-section-carousel" />
                  <img src={image.url} alt={altText} loading="lazy" />
                </div>
              ))}
            </Carousel>
            <LightBoxComponent
              images={images}
              visible={visible}
              onClickVisible={handleVisibleImage}
            />
          </>
        )
      ) : (
        <div className="image-room-card-wrapper">
          <img src={images[0].url} alt={altText} />
        </div>
      )}
    </div>
  );
};
export default ImageRoomCard;
