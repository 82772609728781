import React, { ReactElement, useEffect } from "react";
import { Result } from "antd";
import { Link, useNavigate } from "react-router-dom";

interface NotFoundProp {
  openMenu?: string;
}
const NotFound = ({ openMenu }: NotFoundProp): ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/bookings") {
      navigate("/sign");
    }
  }, []);

  return (
    <Result
      status="404"
      title={openMenu ? openMenu : "404"}
      subTitle={
        openMenu
          ? ""
          : "Sorry, the page's that you are trying to access doesn't exist."
      }
      extra={
        <Link to="/" className="btn btn-success-dp  my-4 ">
          Back Home
        </Link>
      }
    />
  );
};

export default NotFound;
