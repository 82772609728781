import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { IPayment, IPriceResponse } from "../../../Models/Checkout";
import { IAdditionalCharges, ITax, IRate } from "../../../Models/RoomDetail";
import { Tooltip } from "antd";
import { ICardPolicy } from "../../../Models/SettingsModel";

interface props {
  price: IPriceResponse;
  membershipMandatory: boolean;
  paymentInfo: IPayment;
  cardFee: ICardPolicy | undefined;
}

const SidebarForm = ({
  price,
  membershipMandatory,
  paymentInfo,
  cardFee,
}: props) => {
  const [searchParams] = useSearchParams();
  const { currency, hotel } = price;
  const checkin = searchParams.get("checkin");
  const transformCheckIn = moment(checkin, "YYYY-MM-DD");
  const checkIn = moment(transformCheckIn);
  const checkout = searchParams.get("checkout");
  const baserate = searchParams.get("price");
  const isContext =
    (searchParams.get("context")?.toUpperCase() === "GOOGLE" ||
      searchParams.get("context")?.toUpperCase() === "TRIVAGO") &&
    parseFloat(baserate || "0") > 0;

  const otherRateComponent = parseFloat(
    searchParams.get("otherRateComponent") || "0"
  );
  const tax = parseFloat(searchParams.get("tax") || "0");
  const fee = parseFloat(searchParams.get("fee") || "0");
  const transformCheckOut = moment(checkout, "YYYY-MM-DD");
  const checkOut = moment(transformCheckOut);
  const rooms = parseInt(searchParams.get("rooms") || "1", 10);
  const [cardFeeApplies, setcardFeeApplies] = useState(false);

  const totalAddCharges = (
    additionalCharges: IAdditionalCharges[] | undefined
  ) => {
    if (!additionalCharges) return 0;
    const resp = additionalCharges.reduce((a, b) => a + b.charge.amount, 0);
    return resp;
  };
  const totalTaxes = (taxesArr: ITax[] | undefined) => {
    if (!taxesArr) return 0;
    const resp = taxesArr.reduce((a, b) => a + b.amount, 0);
    return resp;
  };

  const sumBaseRate = (hotelArray: IRate[]) =>
    hotelArray.reduce((totals, hotels) => totals + hotels.baseRate, 0);

  const sumTaxes = (hotelArray: IRate[]) =>
    hotelArray.reduce((totals, hotels) => totals + totalTaxes(hotels.taxes), 0);

  const sumAddCharges = (hotelArray: IRate[]) =>
    hotelArray.reduce(
      (totals, hotels) => totals + totalAddCharges(hotels.additionalCharges),
      0
    );

  const CalculateTotalTaxes = (rates: IRate[]) => {
    let totalTaxesComplete = 0;
    for (let i = 0; i < rates.length; i++) {
      const taxArray = rates[i].taxes;
      for (let j = 0; j < taxArray.length; j++) {
        totalTaxesComplete += taxArray[j].amount;
      }
    }

    return totalTaxesComplete;
  };

  const sumAddRates = (rates: IRate[]) => {
    let TotalRate = 0;

    rates.map(
      (item, index) =>
        item.otherRateComponents &&
        item.otherRateComponents.length > 0 &&
        item.otherRateComponents[index] &&
        item.otherRateComponents[index].amount > 0 &&
        (TotalRate = TotalRate + item.otherRateComponents[index].amount)
    );

    return TotalRate;
  };

  const returnTotalAddRates = (rates: IRate[]) => {
    let TotalRate = 0;
    {
      rates.map(
        (item, index) =>
          item.otherRateComponents &&
          item.otherRateComponents.length > 0 &&
          item.otherRateComponents[index] &&
          item.otherRateComponents[index].amount > 0 &&
          (TotalRate = TotalRate + item.otherRateComponents[index].amount)
      );
    }
    return (
      <div className="first">
        <Tooltip
          title={
            rates[0].otherRateComponents &&
            rates[0].otherRateComponents.length > 0 &&
            rates[0].otherRateComponents[0].description
          }
          placement="right"
        >
          <span>Service Fee</span>
        </Tooltip>
        <span>
          {currency} {currency === "USD" ? "$" : " "}
          {TotalRate}
        </span>
      </div>
    );
  };

  const returnOtherRateAmount = (otherRateAmount: number) => {
    return (
      <div className="first">
        <Tooltip placement="right">
          <span>Service Fee</span>
        </Tooltip>
        <span>
          {currency} {currency === "USD" ? "$" : " "}
          {otherRateAmount.toFixed(2)}
        </span>
      </div>
    );
  };

  const calculateTotalAmount = (rates: IRate[]) => {
    let totalAmount = 0;
    for (let i = 0; i < rates.length; i++) {
      totalAmount += rates[i].totalRate;
    }
    totalAmount += sumAddRates(hotel?.rates);
    if (cardFee && cardFeeApplies) {
      totalAmount += cardFee.amount;
    }

    return totalAmount;
  };

  const plainTaxes = (rates: ITax) => {
    return <span>{rates.amount.toFixed(2)}</span>;
  };

  const promediateTaxes = () => {
    if (!hotel?.rates[0].taxes) return 0;
    if (
      hotel?.rates[0].taxes &&
      hotel?.rates[0].taxes.length > 1 &&
      hotel.rates.length > 1
    ) {
      return CalculateTotalTaxes(hotel.rates) / hotel.rates.length;
    }
    return CalculateTotalTaxes(hotel.rates);
  };

  const calculateTotalAdditionalCharges = (array: IRate[]) => {
    let total = 0;

    for (const obj of array) {
      if (obj.additionalCharges) {
        for (const charge of obj.additionalCharges) {
          if (charge.charge && charge.charge.amount) {
            total += charge.charge.amount;
          }
        }
      }
    }

    return total;
  };

  const readableTaxes = (myString: string) => {
    if (myString === "TAXESANDFEES" || !myString) return "Taxes and Fees";

    myString = myString.replace(/[\W_]+/g, " ");

    myString = myString.replace(/([a-z])([A-Z])/g, "$1 $2");

    myString = myString.charAt(0).toUpperCase() + myString.slice(1);
    return myString;
  };

  const sum = (...args: number[]) =>
    args.reduce((partialSum, a) => partialSum + a, 0).toFixed(2);

  const creditCardFeeDisplay = () => {
    const shouldApplyCardFee =
      cardFee &&
      cardFee.active === true &&
      cardFee.provider.includes(price.hotel.rates[0].providerId) &&
      cardFee.issuer.includes(paymentInfo.issuer);

    setcardFeeApplies(shouldApplyCardFee || false);
  };

  useEffect(creditCardFeeDisplay, [
    cardFee,
    price.hotel.rates,
    paymentInfo.issuer,
  ]);

  return (
    <div className="sidebar_info">
      <div className="first_info">
        <div className="first_info_box">
          <div className="first">
            <span>Base rate</span>
            <span>
              {currency} {currency === "USD" ? "$" : " "}
              {isContext && !price.checked
                ? parseFloat(baserate || "0").toFixed(2)
                : sumBaseRate(hotel?.rates).toFixed(2)}
            </span>
          </div>
          <div className="room_line">
            <span>
              {rooms} {rooms > 1 ? "rooms" : "room"}
            </span>
          </div>
        </div>
        <div className="first mt-2">
          <span>Number of nights</span>
          <span>{Math.abs(checkIn.diff(checkOut, "days"))}</span>
        </div>
        {isContext && !price.checked && tax > 0 && (
          <div>
            <div>
              <div className="first mt-1">
                <span>Taxes and fees</span>
                <span>
                  {currency} {currency === "USD" ? "$" : " "}
                  {tax}
                </span>
              </div>
            </div>
          </div>
        )}
        {isContext && !price.checked && otherRateComponent > 0 ? (
          returnOtherRateAmount(otherRateComponent)
        ) : (
          <></>
        )}
        {isContext && price.checked && hotel?.rates[0].otherRateComponents ? (
          returnTotalAddRates(hotel.rates)
        ) : (
          <></>
        )}
        {!isContext && hotel?.rates[0].otherRateComponents ? (
          returnTotalAddRates(hotel.rates)
        ) : (
          <></>
        )}
        {isContext && price.checked && hotel?.rates[0].taxes && (
          <div>
            {hotel?.rates[0].taxes?.map((item, index) => (
              <div key={index}>
                <div className="first mt-1" style={{ whiteSpace: "pre-line" }}>
                  <span>
                    {index === 0
                      ? "Taxes and Fees"
                      : readableTaxes(item.description)}
                  </span>
                  <span>
                    {currency} {currency === "USD" ? "$" : " "}
                    {item.amount.toFixed(2)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
        {!isContext && hotel?.rates[0].taxes && (
          <div>
            {hotel?.rates[0].taxes?.map((item, indext) => (
              <div key={indext}>
                <div className="first mt-1" style={{ whiteSpace: "pre-line" }}>
                  <span>
                    {indext === 0
                      ? "Taxes and Fees"
                      : readableTaxes(item.description)}
                  </span>
                  {hotel?.rates[0].taxes?.length && (
                    <span>
                      {currency} {currency === "USD" ? "$" : " "}
                      {hotel.rates[0].providerName === "GetARoom"
                        ? plainTaxes(item)
                        : promediateTaxes().toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {membershipMandatory && (
        <>
          <hr />
          <div className="second_info_gold">
            <div>
              <span>DealPeakGold Membership</span>
              <span>$0.00</span>
            </div>
          </div>
        </>
      )}
      <hr />
      {cardFee && cardFeeApplies && (
        <>
          <div className="second_info_gold">
            <div>
              <span>Credit Card Processing Fee</span>
              <span>${cardFee.amount}</span>
            </div>
          </div>
          <hr />
        </>
      )}
      <div className="second_info">
        <div>
          <span className="green">You pay today</span>
          <span className="green">
            {currency} {currency === "USD" ? "$" : " "}
            {isContext && !price.checked
              ? sum(parseFloat(baserate || "0"), tax, otherRateComponent)
              : calculateTotalAmount(hotel.rates).toFixed(2)}
          </span>
        </div>
      </div>
      <hr />
      {fee > 0 && !price.checked && (
        <>
          <hr />
          <div className="first_info">
            <div className="first mb-1">
              <span>Resort Fee*</span>
              <span>
                {currency === "USD" ? "$" : " "}
                {fee.toFixed(2)}
              </span>
            </div>
          </div>
          <hr />
          <div className="second_info">
            <div>
              <span>Total Cost</span>
              <span>
                {currency} {currency === "USD" ? "$" : " "}
                {sum(
                  isContext
                    ? parseFloat(baserate || "0")
                    : hotel?.rates[0].baseRate,
                  fee,
                  tax,
                  otherRateComponent,
                  cardFee && cardFeeApplies ? cardFee.amount : 0
                )}
              </span>
            </div>
          </div>
        </>
      )}
      {price.checked &&
        hotel?.rates[0].additionalCharges &&
        hotel?.rates[0].additionalCharges.length > 0 && (
          <>
            <hr />
            <div className="first_info">
              <div>
                <div className="first mb-1">
                  <span>Resort Fee</span>
                  <span>
                    {hotel?.rates[0].additionalCharges[0].charge.currency}{" "}
                    {currency === "USD" ? "$" : " "}
                    {calculateTotalAdditionalCharges(hotel?.rates).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div className="second_info">
              <div>
                <span>Total Cost</span>
                <span>
                  {currency} {currency === "USD" ? "$" : " "}
                  {sum(
                    !price.checked
                      ? parseFloat(baserate || "0")
                      : sumBaseRate(hotel?.rates),
                    hotel?.rates[0].additionalCharges &&
                      hotel?.rates[0].additionalCharges.length > 0
                      ? sumAddCharges(hotel?.rates)
                      : 0,
                    price.checked && tax > 0 && !hotel?.rates[0].taxes
                      ? tax
                      : hotel?.rates[0].taxes &&
                        hotel?.rates[0].taxes.length > 0
                      ? sumTaxes(hotel?.rates)
                      : 0,
                    sumAddRates(hotel?.rates),
                    cardFee && cardFeeApplies ? cardFee.amount : 0
                  )}
                </span>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default SidebarForm;
