import React from "react";
import { useViewport } from "../../../Context/useViewport";

const HeroPerksSection = () => {
  const { width } = useViewport();
  return (
    <div className="hero-perk-section">
      <div className="hero-perk-header-section d-flex align-items-center justify-content-center">
        <div>
          <div className="row">
            <div className="col-lg-8 col-md-8 offset-md-4 hero-perk-header-text">
              <span id="top">YOUR EXCLUSIVE MEMBERSHIP PERKS</span>
              <br />
              <div id="bottom">
                <span>Your next vacation just got a whole lot cheaper.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPerksSection;
