import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, notification, Image } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
  Auth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { logInWithEmailAndPassword } from "../../../Auth/firebase";
import { SearchContext } from "../../../Context/context";
import { ISearch } from "../../../Models/Search";
import fbicon from "../../../img/facebook.png";
import googleicon from "../../../img/google.png";
import { IUsers } from "../../../Models/UsersModel";
import { getUsers, postUser } from "../../../helpers/requestUserAndRole";
import { RoutesPath } from "../../../Routes";

export interface formProps {
  onChangeForm(type: string): void;
  auth: Auth;
}

const LoginForm = ({ onChangeForm, auth }: formProps) => {
  const [user, loading, error] = useAuthState(auth);
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const userCredential = await logInWithEmailAndPassword(
      values.username,
      values.password,
      auth
    );
    if (userCredential?.user) {
      notification.success({
        message: "success",
        description: `Welcome ${userCredential.user.email}`,
      });
      navigate(RoutesPath.bookings);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.error({
      message: "Error",
      description: `Error: ${errorInfo}`,
    });
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          navigate(RoutesPath.root);
        } catch (err) {
          console.error(err);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          navigate(RoutesPath.root);
        } catch (err) {
          console.error(err);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading === true) {
      setLoadingButton(true);
    } else {
      setLoadingButton(false);
    }

    if (user) {
      notification.success({
        message: "Sign in success!",
        description: `Welcome ${user.email}`,
      });
      navigate(RoutesPath.bookings);
    }

    if (error) {
      onFinishFailed(error);
    }
  }, [error, loading, navigate, user]);

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: "Please input your Username!" }]}
      >
        <Input placeholder="Email Address" allowClear />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          htmlType="button"
          className="login-form-forgot"
          onClick={() => onChangeForm("reset")}
        >
          Forgot password
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          loading={loadingButton}
          className="btn-success-dp btn-block btn-login"
          htmlType="submit"
        >
          Sign In
        </Button>
        <div className="mt-2 or-text">
          <span>Or</span>
        </div>

        <Button
          htmlType="button"
          className="btn-facebook"
          onClick={signInWithFacebook}
        >
          <div className="socialLogin">
            <Image
              src={fbicon}
              preview={false}
              width={20}
              height={20}
              className="icon"
            />
            <div className="text">
              <span>Sign In with Facebook</span>
            </div>
          </div>
        </Button>
        <Button
          htmlType="button"
          className="btn-google"
          onClick={signInWithGoogle}
        >
          <div className="socialLogin">
            <Image
              src={googleicon}
              preview={false}
              width={20}
              height={20}
              className="icon"
            />
            <div className="text">
              <span>Sign In with Google</span>
            </div>
          </div>
        </Button>
        <div className="d-flex py-3">
          <small className="mt-2">Do not have an account?</small>
          <Button
            type="link"
            htmlType="button"
            onClick={() => onChangeForm("register")}
          >
            Create account!
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
