export const updateThreeDsForm = (value: string, inputId: string) => {
  const inputElement = document.getElementById(inputId) as HTMLInputElement;
  try {
    if (inputId === "x_exp_year") {
      inputElement.value = value.slice(-2);
      return;
    }
    if (inputId === "x_exp_month") {
      inputElement.value = value.padStart(2, "0");
      return;
    }
    if (inputElement) {
      inputElement.value = value;
    }
  } catch (error) {
    console.error(error);
  }
};
