import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faSearch,
  faPlus,
  faReply,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Table,
  Menu,
  Switch,
} from "antd";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "../../../Auth/firebase";
import AccountCard from "../../../Components/AccountManagment/AccountCard";
import LoaderComponent from "../../../Components/LoaderComponent";
import { SearchContext } from "../../../Context/context";
import { useViewport } from "../../../Context/useViewport";
import { sendEmailUserCreated } from "../../../helpers/requestBookings";
import { getWebConfig } from "../../../helpers/requestSetting";
import {
  getRolesFetch,
  getUserRol,
  getUsers,
  postUser,
  putUser,
  deleteUser,
} from "../../../helpers/requestUserAndRole";
import { ISearch } from "../../../Models/Search";
import { IUsers, IUsersTransform, IRole } from "../../../Models/UsersModel";

const UserList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [usersTransform, setUsersTransform] = useState<IUsersTransform[]>([]);
  const [usersList, setUsersList] = useState<IUsersTransform[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const [roleSubmit, setRoleSubmit] = useState(0);
  const [formEdit, setFormEdit] = useState({
    userId: 0,
    firstName: "",
    lastName: "",
    userName: "",
    roleId: 0,
    password: undefined,
    status: true,
    active: false,
  });
  const { width } = useViewport();
  const { SubMenu } = Menu;

  function getObjectWithIdAndEdit(array: any[], targetId: number) {
    const filteredArray = array.filter((obj) => obj.edit === targetId);
    return filteredArray;
  }

  const editAccount = async (id: number) => {
    const result = getObjectWithIdAndEdit(usersTransform, id);
    const userInfo = await getUserRol(result[0].userId);

    setFormEdit({
      ...formEdit,
      userId: result[0].key,
      firstName: result[0].firstName,
      lastName: result[0].lastName,
      userName: result[0].userId,
      roleId: result[0].rolId,
      active: userInfo.active,
    });

    setIsModalVisible(true);
  };

  const switchUser = (active: boolean) => {
    setFormEdit({ ...formEdit, active: active });
  };

  const resetPassword = async (email: string) => {
    await sendPasswordReset(email, searchContent.auth);
  };

  const filterIconSearch = () => (
    <FontAwesomeIcon icon={faSearch as IconProp} />
  );
  const [filterObject, setfilterObject] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    role: "",
    id: "",
  });
  const returnSearchID = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by ID"
        autoFocus
        value={filterObject.id}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            id: e.target.value,
          })
        }
      />
    </div>
  );
  const returnSearchName = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by First Name"
        autoFocus
        value={filterObject.firstName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            firstName: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchLastName = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Last Name"
        autoFocus
        value={filterObject.lastName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            lastName: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchRole = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Role"
        autoFocus
        value={filterObject.role}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            role: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchUserid = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Email"
        autoFocus
        value={filterObject.userName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            userName: e.target.value,
          })
        }
      />
    </div>
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 70,
      filterDropdown: () => returnSearchID(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) => (a.id < b.id ? -1 : 1),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      filterDropdown: () => returnSearchName(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      filterDropdown: () => returnSearchLastName(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.lastName.toLowerCase() < b.lastName.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "User Email",
      dataIndex: "userId",
      key: "userId",
      width: 150,
      filterDropdown: () => returnSearchUserid(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.userId.toLowerCase() < b.userId.toLowerCase() ? -1 : 1,
    },
    {
      title: "Rol",
      dataIndex: "rol",
      key: "rol",
      filterDropdown: () => returnSearchRole(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.rol.toLowerCase() < b.rol.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      filterDropdown: () => returnSearchRole(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) => a.active - b.active,
      render: (active: boolean) => <Switch checked={active} />,
    },
    {
      title: "Bookings",
      dataIndex: "bookings",
      key: "bookings",
      width: 120,
      render: (id: number) => (
        <button
          type="button"
          className="btn btn-primary-dp-xs"
          onClick={() =>
            navigate(`/accounts/bookings?user=${usersTransform[id].userId}`)
          }
        >
          <FontAwesomeIcon icon={faSearch as IconProp} className="me-2" />
          Bookings
        </button>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      width: 90,
      render: (id: number) => (
        <button
          type="button"
          onClick={() => editAccount(id)}
          className="btn btn-success-dp-xs"
        >
          <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
          Edit Account
        </button>
      ),
    },
    {
      title: "Reset account",
      dataIndex: "userId",
      key: "userId",
      width: 150,
      render: (email: string) => (
        <button
          type="button"
          onClick={() => resetPassword(email)}
          className="btn btn-success-dp-xs"
        >
          <FontAwesomeIcon icon={faReply as IconProp} className="me-2" />
          Send reset password email
        </button>
      ),
    },
  ];

  const getRoles = async () => {
    try {
      const response = await getRolesFetch();
      setRoles(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const setInfo = (response: IUsers[]) => {
    const newInfo = response.map((item, index) => {
      const obj = {
        key: item.userId,
        id: item.userId,
        bookings: index,
        userId: item.userName,
        firstName: item.firstName,
        lastName: item.lastName,
        rol: item.role.roleName,
        rolId: item.role.roleId,
        edit: index,
        active: item.active,
      };
      return obj;
    });
    setUsersList(newInfo);
    setUsersTransform(newInfo);
    if (roles.length === 0) {
      getRoles();
    } else {
      setLoading(false);
    }
  };

  const renderInfo = async () => {
    try {
      const response = await getUsers();
      setInfo(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const copyArr = usersList;
    setUsersTransform(
      copyArr.filter(
        (item) =>
          item.key.toString().includes(filterObject.id) &&
          item.userId
            .toLowerCase()
            .includes(filterObject.userName.toLowerCase()) &&
          item.firstName
            .toLowerCase()
            .includes(filterObject.firstName.toLowerCase()) &&
          item.lastName
            .toLowerCase()
            .includes(filterObject.lastName.toLowerCase()) &&
          item.rol.toLowerCase().includes(filterObject.role.toLowerCase())
      )
    );
  }, [filterObject]);

  const handleDelete = async () => {
    await deleteUser(formEdit.userName);
    setIsModalVisible(false);
    notification.success({ message: "Success", description: "User deleted" });
    renderInfo();
  };

  const onFinishForm = async (values: any) => {
    setLoading(true);
    setModalCreateVisible(false);
    const webConfigResp = await getWebConfig();
    const app = initializeApp(webConfigResp.firebaseConfig, "secondary");
    const auth = getAuth(app);
    try {
      const userCredential = await registerWithEmailAndPassword(
        values.userName,
        values.password,
        auth
      );
      if (userCredential) {
        const obj = {
          userName: values.userName,
          firstName: values.firstName,
          lastName: values.lastName || "",
          password: values.password,
          roleId: roleSubmit,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            sendEmailUserCreated(values.userName, "NewUser", values.password);
          notification.success({
            message: "Succces Registred",
            description: "User registerd!",
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      } else setLoading(false);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Error",
      });
    }
  };

  const onFinishFormUpdate = async () => {
    setLoading(true);
    setIsModalVisible(false);
    try {
      await putUser(formEdit);
      renderInfo();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    renderInfo();
  }, []);

  return (
    <div className="container mt-5">
      {loading && <LoaderComponent title="Loading Accounts..." />}
      {!loading && (
        <>
          <div className="book-title mt-3">
            <h3>Users</h3>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => setModalCreateVisible(true)}
            >
              <FontAwesomeIcon icon={faPlus as IconProp} className="me-2" />
              Create New User
            </button>
          </div>

          {width < 1200 ? (
            <>
              <Menu mode="inline" className="pb-3">
                <SubMenu
                  key="filters"
                  title={
                    <span className="filter-menu-title">
                      <FontAwesomeIcon icon={faSliders as IconProp} />
                      <span>Filters</span>
                    </span>
                  }
                >
                  <Menu.Item className="filter-menu-item" key="filterId">
                    {returnSearchID()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterName">
                    {returnSearchName()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterLastName">
                    {returnSearchLastName()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterUserId">
                    {returnSearchUserid()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterRol">
                    {returnSearchRole()}
                  </Menu.Item>
                </SubMenu>
              </Menu>
              {usersTransform.map((user: IUsersTransform, index) => (
                <AccountCard
                  user={user}
                  editAccount={editAccount}
                  userIndex={index}
                  key={index}
                />
              ))}
            </>
          ) : (
            <>
              <Divider />
              <div className="card rounded-dp shadow-lg">
                <div className="card-body">
                  <Table
                    columns={columns}
                    dataSource={usersTransform}
                    scroll={{ x: 240 }}
                  />
                </div>
              </div>
            </>
          )}
          <Modal
            title="Create Account"
            open={modalCreateVisible}
            onCancel={() => setModalCreateVisible(false)}
            footer={null}
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinishForm}
                autoComplete="off"
                layout="vertical"
                className="create-account-form"
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user first name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, firstName: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user last name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, lastName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="userName"
                  label="User Name"
                  rules={[
                    { required: true, message: "Please input the user email!" },
                  ]}
                >
                  <Input
                    type="email"
                    value={formEdit.userName}
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, userName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="User Role"
                  rules={[
                    { required: true, message: "Please select the user role!" },
                  ]}
                >
                  <Select
                    placeholder="Select the role"
                    onChange={(value) => setRoleSubmit(value)}
                  >
                    {roles.map((item) => (
                      <Select.Option value={item.roleId} key={item.roleId}>
                        {item.roleName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password type="password" />
                </Form.Item>
                <div className="buttons-modal">
                  <button
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Edit Account"
            footer={null}
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            closeIcon={<small>Close</small>}
            destroyOnClose
          >
            <div>
              <Form
                name="basic"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinishFormUpdate}
              >
                <div>
                  <Form.Item label="First Name">
                    <Input
                      value={formEdit.firstName}
                      disabled
                      onChange={(e) =>
                        setFormEdit({
                          ...formEdit,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="Last Name">
                    <Input
                      value={formEdit.lastName}
                      disabled
                      onChange={(e) =>
                        setFormEdit({ ...formEdit, lastName: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="User Email">
                    <Input
                      disabled
                      value={formEdit.userName}
                      onChange={(e) =>
                        setFormEdit({ ...formEdit, lastName: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="Rol">
                    <Select
                      value={formEdit.roleId}
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        setFormEdit({ ...formEdit, roleId: value })
                      }
                    >
                      {roles.map((item) => (
                        <Select.Option value={item.roleId} key={item.roleId}>
                          {item.roleName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Item label="Active">
                      <div>
                        <Switch
                          checked={formEdit.active}
                          onChange={switchUser}
                        />
                        <span>{formEdit.active ? "True" : "False"}</span>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="col">
                    <Form.Item label="Delete User">
                      <div>
                        <button
                          type="button"
                          onClick={handleDelete}
                          className="btn btn-danger-dp btn-block"
                        >
                          Delete
                        </button>
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="buttons-modal">
                  <button
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default UserList;
