import React from "react";
import { Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBed,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import APIClient from "../../Services/APIClient";
import ILocationSuggestion from "../../../Models/SearchBoxModel";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  label?: string;
  isRequiredFocus?: React.Ref<any> | undefined;
}

const DebounceSelect = <
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    type: string;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 300,
  isRequiredFocus,
  ...props
}: DebounceSelectProps) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select<ValueType>
      suffixIcon={
        <FontAwesomeIcon icon={faSearch as IconProp} color="#0068EF" />
      }
      className="ant-select-selection-search select-ubication ant-select-selection"
      showSearch
      showArrow
      labelInValue
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      ref={isRequiredFocus}
    >
      {options.map((item: any, index) => (
        <Select.Option
          key={index}
          value={item.value}
          label={item.label}
          coordinates={item.coordinates}
          type={item.type}
          id={item.id}
        >
          {item.type === "Hotel" ? (
            <FontAwesomeIcon
              icon={faBed as IconProp}
              className="icon-auto-complete"
              color="#0068EF"
            />
          ) : (
            <FontAwesomeIcon
              icon={faLocationDot as IconProp}
              className="icon-auto-complete"
              color="#0068EF"
            />
          )}

          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
};

// Usage of DebounceSelect
export interface ISuggestValue {
  label: string;
  value: string;
}

export const fetchSuggestList = async (
  locationName: string
): Promise<ISuggestValue[]> => {
  if (locationName && locationName.length > 1) {
    const client = new APIClient();
    const result = await client.client.get(
      `Suggest?locationName=${locationName}`
    );
    return result.data.locationSuggestions.map(
      (location: ILocationSuggestion) => ({
        label: location.fullName,
        value: location.id,
        coordinates: location.coordinates,
        type: location.type || "",
        id: location.referenceId,
      })
    );
  }
  return [];
};

export default DebounceSelect;
