import React, { ReactElement } from "react";

interface DateReviewProps {
  date: string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

const DateReview = ({ date }: DateReviewProps): ReactElement => (
  <div className="date-review-element">
    <span style={{ fontSize: 12 }} className="date-label">
      {formatDate(date)}
    </span>
  </div>
);
export default DateReview;
