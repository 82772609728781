import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { IFakePrice } from "../../../../../../../Models/GooglePrice";
import { IPolicy } from "../../../../../../../Models/HotelContent";
import RoomPolicy from "../../../RoomPolicy";
import {
  IDailyRate,
  IOccupancy,
  IRate,
  IRecommendation,
  IRule,
  ITax,
} from "../../../../../../../Models/RoomDetail";
import { getMinimalRestrictionDate } from "../../../../../../../helpers/validationsInputs";
import { sumAmounts } from "../../../../../../../helpers/ratesHandler";
import { useSearchParams } from "react-router-dom";

interface GroupRoomCardProps {
  ratesData: IRate[];
  recommendation: IRecommendation;
  makeReadableString: (rateText: string) => string;
  checkPolicies: (policy: any, refundable: boolean) => void;
  transformDate: (checkDate: Date) => moment.Moment;
  modalObject: { policyObject: never[]; refundable: boolean };
  hotelDetailPolicy: IPolicy[] | undefined;
  setViewPolicies: React.Dispatch<React.SetStateAction<boolean>>;
  viewPolicies: boolean;
  fakePrice: IFakePrice | undefined;
  id: string;
  checkinAsDate: moment.Moment;
  checkoutAsDate: moment.Moment;
  baseRatePerDay: (rate: IRate) => number;
  showDailyRates: (
    dailyRates: IDailyRate[],
    currency: string,
    policies: { text: string; type: string }[]
  ) => void;
  handleBook: (
    rateId: string[] | string,
    rate: IRate | IRate[],
    recomId: string
  ) => void;
  rawRates: IRate[];
  showNightlyRate: boolean;
  sFakePrice: IFakePrice | undefined;
}

const GroupRoomCard = ({
  ratesData,
  recommendation,
  makeReadableString,
  checkPolicies,
  transformDate,
  modalObject,
  hotelDetailPolicy,
  setViewPolicies,
  viewPolicies,
  fakePrice,
  id,
  checkinAsDate,
  checkoutAsDate,
  baseRatePerDay,
  showDailyRates,
  handleBook,
  rawRates,
  showNightlyRate,
  sFakePrice,
}: GroupRoomCardProps): ReactElement => {
  const [searchParams] = useSearchParams();
  const removeDuplicatesById = (arr: IRate[]) => {
    const uniqueMap = new Map();

    for (const obj of arr) {
      const id = obj.id;
      if (!uniqueMap.has(id)) {
        uniqueMap.set(id, obj);
      }
    }

    return Array.from(uniqueMap.values());
  };

  const returnRateByRecomID = () => {
    let newArr: IRate[] = [];
    let ratesNoDuplicate = removeDuplicatesById(ratesData);
    recommendation?.rates.map((item) => {
      ratesNoDuplicate.map((rate) => {
        if (rate.id === item) {
          newArr.push(rate);
        }
      });
    });
    return newArr;
  };

  const cancellationPenaltyStart = (cancellationRules: IRule[]) => {
    const firstObjectWithValueAboveOne = cancellationRules.find(
      (rule) => rule.estimatedValue > 1
    );

    if (firstObjectWithValueAboveOne) {
      return (
        <div className="before_date-style">
          <span>
            before{" "}
            {moment(transformDate(firstObjectWithValueAboveOne.start))
              .format("MMMM")
              .slice(0, 3)}
            {moment(transformDate(firstObjectWithValueAboveOne.start)).format(
              "DD"
            )}
            ,
            {moment(transformDate(firstObjectWithValueAboveOne.start)).format(
              "YYYY"
            )}
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <div className="shadow_prices no-padding">
        {recommendation &&
          recommendation.rates.map((rateId, index) => {
            const rate = rawRates.find((rate) => rate.id === rateId);
            if (rate) {
              return (
                <div key={index} className="body px-3 pb-3 pt-2">
                  <div className="room-policies-wrapper">
                    <span className="policy-description">
                      {makeReadableString(
                        (rate &&
                          rate.boardBasis &&
                          rate.boardBasis.description) ||
                          ""
                      )}
                    </span>
                    {rate.additionalInformation &&
                      rate.additionalInformation[0].text ==
                        searchParams.get("roomIdentifierKey") &&
                      Math.trunc(rate.baseRate) ==
                        Math.trunc(
                          parseFloat(searchParams.get("price") || "0")
                        ) &&
                      (searchParams.get("context")?.toLowerCase() ?? "") ===
                        "google" && (
                        <div className="google-mark">
                          <img
                            src="https://storage.googleapis.com/imagebucketdealpeak/google-icon-2048x2048-pks9lbdv.png"
                            alt="Google Logo"
                            className="google-mark-icon me-2"
                          />
                          <p className="centered-text">As seen on Google</p>
                        </div>
                      )}
                    {rate.additionalInformation &&
                      rate.additionalInformation[0].text ==
                        searchParams.get("roomIdentifierKey") &&
                      Math.trunc(rate.baseRate) ==
                        Math.trunc(
                          parseFloat(searchParams.get("price") || "0")
                        ) &&
                      (searchParams.get("context")?.toLowerCase() ?? "") ===
                        "trivago" && (
                        <div className="google-mark">
                          <img
                            src="https://storage.googleapis.com/imagebucketdealpeak/trivago-logo.png"
                            alt="Google Logo"
                            className="google-mark-icon me-2"
                          />
                          <p className="centered-text">As seen on Trivago</p>
                        </div>
                      )}
                    <button
                      className="btn btn-link fw-bold"
                      type="button"
                      onClick={() =>
                        checkPolicies(
                          rate.cancellationPolicies,
                          rate.refundable
                        )
                      }
                    >
                      {rate.refundable ? "Free Cancellation" : "Non Refundable"}
                    </button>
                    <small className="nightly-rate">
                      {showNightlyRate ? (
                        <>
                          Nightly Rate: $
                          {sumAmounts(
                            rate,
                            Math.abs(
                              checkinAsDate.diff(checkoutAsDate, "days")
                            ) ?? 1
                          ).toFixed(2)}{" "}
                          incl. taxes and fees
                        </>
                      ) : (
                        <></>
                      )}
                    </small>
                    {rate.additionalInformation &&
                      rate.additionalInformation[0].text ==
                        searchParams.get("roomIdentifierKey") &&
                      Math.round(rate.baseRate) ==
                        Math.round(
                          parseFloat(searchParams.get("price") || "0")
                        ) &&
                      (searchParams.get("context")?.toLowerCase() ?? "") ===
                        "google" && (
                        <div className="google-mark">
                          <img
                            src="https://storage.googleapis.com/imagebucketdealpeak/google-icon-2048x2048-pks9lbdv.png"
                            alt="Google Logo"
                            className="google-mark-icon me-2"
                          />
                          <p className="centered-text">As seen on Google</p>
                        </div>
                      )}
                    {rate.additionalInformation &&
                      rate.additionalInformation[0].text ==
                        searchParams.get("roomIdentifierKey") &&
                      Math.trunc(rate.baseRate) ==
                        Math.trunc(
                          parseFloat(searchParams.get("price") || "0")
                        ) &&
                      (searchParams.get("context")?.toLowerCase() ?? "") ===
                        "trivago" && (
                        <div className="google-mark">
                          <img
                            src="https://storage.googleapis.com/imagebucketdealpeak/trivago-logo.png"
                            alt="Google Logo"
                            className="google-mark-icon me-2"
                          />
                          <p className="centered-text">As seen on Trivago</p>
                        </div>
                      )}
                    {rate.refundable &&
                      rate.cancellationPolicies &&
                      rate.cancellationPolicies.length > 0 &&
                      rate.cancellationPolicies[0].rules &&
                      rate.cancellationPolicies[0].rules.length > 0 &&
                      cancellationPenaltyStart(
                        rate.cancellationPolicies[0].rules
                      )}
                  </div>

                  <div>
                    <span>
                      <RoomPolicy
                        policyObject={modalObject.policyObject}
                        refundable={modalObject.refundable}
                        hotelDetailPolicy={hotelDetailPolicy}
                        setViewPolicies={setViewPolicies}
                        viewPolicies={viewPolicies}
                        minimalRestrictDate={getMinimalRestrictionDate(
                          rate.policies
                        )}
                      />
                    </span>
                  </div>
                  <div className="room_price-per_night">
                    <span className="price-label">
                      <small className="currency-label">
                        {rate.currency === "USD" ? "$" : `${rate.currency}`}
                      </small>{" "}
                      {fakePrice !== undefined &&
                      fakePrice.price > 0 &&
                      fakePrice.roomId === id
                        ? Math.trunc(
                            fakePrice.price /
                              Math.abs(
                                checkinAsDate.diff(checkoutAsDate, "days")
                              )
                          )
                        : baseRatePerDay(rate)}
                    </span>
                    {rate.dailyRates && rate.dailyRates.length > 0 ? (
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            showDailyRates(
                              rate.dailyRates,
                              rate.currency,
                              rate.policies
                            )
                          }
                          className="btn btn-link other_details"
                        >
                          View Details
                        </button>
                      </>
                    ) : (
                      <span className="other_info">price per night</span>
                    )}
                  </div>
                </div>
              );
            }
            return null;
          })}
        <div className="body px-3 pb-3 pt-2">
          <button
            type="button"
            className="btn btn-success-dp rounded-pill "
            onClick={() =>
              handleBook(
                recommendation?.rates,
                returnRateByRecomID(),
                recommendation.id
              )
            }
            aria-hidden="true"
          >
            <span>Book Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupRoomCard;
