import APIClient from "../Components/Services/APIClient";
import { IRateFilter } from "../Models/CommonType";

export const  getAllRateFilter = async () : Promise<IRateFilter[]> => {
    const client = new APIClient();
    return await client.client.get<IRateFilter[]>("ratefilter").then((r)=> r.data);
}

export const addRateFilter = async (rateFilter: IRateFilter): Promise<IRateFilter> => {
    const client = new APIClient();
    const response = await client.client.post("ratefilter", rateFilter);
    return response.data;
}

export const updateRateFilter = async (rateFilter: IRateFilter): Promise<IRateFilter> => {
    const client = new APIClient();
    const response = await client.client.put("ratefilter", rateFilter);
    return response.data;
}

export const deleteRateFilter = async (rateFilter: IRateFilter): Promise<void> => {
    const client = new APIClient();
    await client.client.delete("ratefilter", { data: rateFilter });
}