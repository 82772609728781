import { DatePicker, Select } from "antd";
import { ICity, ICountry, IState } from "country-state-city";
import { isArray } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";

interface inputProps {
  id: number;
  name: string;
  type?: string;
  value: string | number;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: () => void;
  disabled?: boolean;
  extraClass?: string;
  inputType: string;
  label: string;
  errorMessage: string;
  errorArr: number[];
  required: boolean;
  maxLength?: number;
  minLength?: number;
  handleChangeSelects?: (code: string, name: string) => void;
  optionsSelect?: any;
  handleChangeDate?: (value: moment.Moment | null) => void;
  setErrorsArr: React.Dispatch<React.SetStateAction<number[]>>;
}

const FormInput = ({
  type,
  name,
  value,
  id,
  handleChange,
  disabled,
  inputType,
  handleFocus,
  extraClass,
  label,
  required,
  errorMessage,
  errorArr,
  handleChangeSelects,
  maxLength,
  optionsSelect,
  handleChangeDate,
  setErrorsArr,
  minLength,
}: inputProps) => {
  const [focus, setFocus] = useState(false);
  const disabledDate = (current: any) =>
    current && current < moment().endOf("day");

  useEffect(() => {
    if (errorArr.find((item) => item === id) && value !== "") {
      const errorsNew = [...errorArr];
      const idArr = errorArr.findIndex((item) => item === id);
      errorsNew.splice(idArr, 1);
      setErrorsArr(errorsNew);
    }
  }, [value]);

  return (
    <div
      className={`formInput-box ${extraClass}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {inputType === "inputBasic" && (
        <input
          type={type || "text"}
          name={name}
          maxLength={maxLength}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          className={`form-input-checkout ${
            errorArr.find((item) => item === id) && "form-input-checkout_error"
          }  ${disabled && "form-input-checkout_disabled"}`}
          onFocus={handleFocus}
          autoComplete="new-password"
        />
      )}
      {inputType === "date" && handleChangeDate && (
        <DatePicker
          defaultValue={value !== "" ? moment(value, "YYYY/MM") : undefined}
          picker="month"
          className={`${
            errorArr.find((item) => item === id) &&
            "form-input-checkout_error-select"
          }`}
          mode="month"
          getPopupContainer={(trigger) => trigger.parentElement!}
          placeholder=""
          disabledDate={disabledDate}
          onChange={(valueDate) => handleChangeDate(valueDate)}
        />
      )}
      {inputType === "select" && handleChangeSelects && (
        <Select
          className={`form-input-check-width ${
            errorArr.find((item) => item === id) &&
            "form-input-checkout_error-select"
          }`}
          showSearch
          value={value}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(valueCountry, option) => {
            handleChangeSelects(
              valueCountry.toString(),
              isArray(option)
                ? (option[0].children as unknown as string)
                : (option.children as unknown as string)
            );
          }}
        >
          {name === "city" && (
            <>
              {optionsSelect?.map((opt: ICity) => (
                <Select.Option key={opt.name}>{opt.name}</Select.Option>
              ))}
            </>
          )}
          {name !== "city" && (
            <>
              {optionsSelect?.map((opt: ICountry | IState) => (
                <Select.Option key={opt.isoCode}>{opt.name}</Select.Option>
              ))}
            </>
          )}
        </Select>
      )}
      <label
        className={focus || value !== "" ? "label-dp label-float" : "label-dp"}
      >
        {required ? <strong className="text-danger me-2">*</strong> : null}
        {label}
      </label>
      {errorArr.find((item) => item === id) && (
        <span className="errorMessages">{errorMessage}</span>
      )}
    </div>
  );
};

export default FormInput;
