import IUserAgentInformation from "../Models/UserAgentInformation";
import { getByName } from "./requestSetting";
import * as Bowser from "bowser";
import { isMobile } from "react-device-detect";

const configExemptName = "ExemptDevice2fa";

export const isDeviceExempt = async (userAgentInfo: string) => {
  const config = await getByName(configExemptName);
  if (!config) return false;

  const parsedData: IUserAgentInformation[] = JSON.parse(config.value);
  if (parsedData.length <= 0) return false;

  const browser = Bowser.getParser(userAgentInfo);
  let deviceType =
    browser.getPlatformType().toLowerCase() === "tablet"
      ? "mobile"
      : browser.getPlatformType();

  if (deviceType === "") deviceType = isMobile ? "mobile" : "desktop";

  const operationSystem =
    browser.getOSName().toUpperCase() === "CHROME OS"
      ? "linux"
      : browser.getOSName();
  let browserName = browser.getBrowserName();
  if (browserName.toUpperCase() === "FOCUS") browserName = "firefox";

  const userAgent: IUserAgentInformation = {
    browser: browserName,
    deviceType: deviceType,
    operationSystem: operationSystem,
  };

  return isMatch(userAgent, parsedData);
};

function isMatch(
  obj: IUserAgentInformation,
  list: IUserAgentInformation[]
): boolean {
  for (let listItem of list) {
    if (
      listItem.deviceType.toUpperCase() === obj.deviceType.toUpperCase() &&
      (listItem.operationSystem === "*" ||
        listItem.operationSystem.toUpperCase() ===
          obj.operationSystem.toUpperCase()) &&
      (listItem.browser === "*" ||
        listItem.browser.toUpperCase() === obj.browser.toUpperCase())
    ) {
      return true; // Object matches the criteria
    }
  }
  return false; // Object doesn't match any item in the list
}
