import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import LocationRadiusCard from "../../../../Components/AccountManagment/LocationRadiusCard";
import LoaderComponent from "../../../../Components/LoaderComponent";
import DrawMap from "../../../../Components/Map/DrawMap";
import { useViewport } from "../../../../Context/useViewport";
import { WebConfigContext } from "../../../../Context/WebConfigContext";
import {
  createLocationRadius,
  deleteLocationRadius,
  getLocationRadius,
  updateLocationRadius,
} from "../../../../helpers/requestSetting";
import { ILocationRadius } from "../../../../Models/LocationRadius";
import { ISearch } from "../../../../Models/Search";

interface FormEdit {
  id: number;
  name: string;
  geomText: { lat: number; lng: number }[];
  radiusInKm: number;
}

const LocationRadius = () => {
  const { webConfigContext } = useContext<any | ISearch>(WebConfigContext);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [coordinates, setCoordinates] = useState<number[][]>([]);
  const [data, setData] = useState<ILocationRadius[]>([]);
  const [formEditLocation, setFormEditLocation] = useState<FormEdit>({
    id: 0,
    name: "",
    geomText: [],
    radiusInKm: 0,
  });
  const { width } = useViewport();

  const getLocations = async () => {
    setLoading(true);
    try {
      const response = await getLocationRadius();
      setData([...response]);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const transformCoordinates = (coords: string) => {
    const newArr = coords
      .replace("POLYGON", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .trim()
      .split(",")
      .map((item) => item.trim().split(" "));

    const transform = newArr.map((item) => {
      const newObj = [parseFloat(item[0]), parseFloat(item[1])];
      return newObj;
    });
    return transform;
  };

  const transformPath = (coords: string): { lat: number; lng: number }[] => {
    const newArr = coords
      .replace("POLYGON", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .trim()
      .split(",")
      .map((item) => item.trim().split(" "));

    const transform = newArr.map((item) => {
      const newObj = {
        lat: parseFloat(item[0]),
        lng: parseFloat(item[1]),
      };
      return newObj;
    });
    transform.pop();
    return transform;
  };

  const handleModal = (editSetting: ILocationRadius) => {
    setFormEditLocation({
      id: editSetting.id,
      name: editSetting.name,
      geomText: transformPath(editSetting.geomText),
      radiusInKm: editSetting.radiusInKm,
    });
    setCoordinates(transformCoordinates(editSetting.geomText));
    setModalVisible(true);
  };

  const handleDelete = async (record: ILocationRadius) => {
    try {
      await deleteLocationRadius(record.id);
      setCoordinates([]);
      message.success("Location Deleted", 3);
      getLocations();
    } catch (err) {
      setCoordinates([]);
      message.error("Error deleting location", 3);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id", width: 10 },
    { title: "Name", dataIndex: "name", key: "name", width: 180 },
    { title: "Geom", dataIndex: "geomText", key: "geomText" },
    { title: "Radius in KM", dataIndex: "radiusInKm", key: "radiusInKm" },
    {
      title: "Edit",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (_: any, record: ILocationRadius) => (
        <button
          className="btn btn-success-dp-xs"
          type="button"
          aria-hidden="true"
          onClick={() => handleModal(record)}
        >
          <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
          Edit
        </button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (_: any, record: ILocationRadius) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record)}
        >
          <Button
            htmlType="button"
            type="dashed"
            shape="round"
            aria-hidden="true"
            icon={
              <FontAwesomeIcon icon={faClose as IconProp} className="me-2" />
            }
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const onFinishFormUpdate = async () => {
    const obj = {
      id: formEditLocation.id,
      name: formEditLocation.name,
      coordinates: JSON.stringify(coordinates),
      radiusInKm: formEditLocation.radiusInKm,
    };
    try {
      await updateLocationRadius(obj);
      setModalVisible(false);
      message.success("Location Updated", 3);
      setCoordinates([]);
      getLocations();
    } catch (err) {
      setCoordinates([]);
      setModalVisible(false);
      message.error("Error updating location", 3);
    }
  };

  const onFinishForm = async (values: any) => {
    const obj = {
      name: values.name as string,
      coordinates: JSON.stringify(coordinates),
      radiusInKm: values.radiusInKm as number,
    };
    try {
      await createLocationRadius(obj);
      setModalCreateVisible(false);
      message.success("Location Saved", 3);
      setCoordinates([]);
      getLocations();
    } catch (err) {
      setCoordinates([]);
      setModalCreateVisible(false);
      message.error("Error saving location", 3);
    }
  };

  return (
    <div className="container my-5">
      {loading && <LoaderComponent title="Loading location radius table" />}
      {!loading && error && <div>Error loading info</div>}
      {!loading && !error && (
        <>
          <div className="book-title mt-3">
            <h3 className="mb-3">Location Radius</h3>

            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => setModalCreateVisible(!modalCreateVisible)}
            >
              Create custom location
            </button>
          </div>

          <Divider />
          {width < 1200 ? (
            data.map((locRad: ILocationRadius, i) => (
              <LocationRadiusCard
                key={i}
                locationRadius={locRad}
                handleEdit={handleModal}
                handleDelete={handleDelete}
              />
            ))
          ) : (
            <div className="card rounded-dp shadow-lg">
              <div className="card-body">
                <Table
                  columns={columns}
                  dataSource={data}
                  scroll={{ x: 240 }}
                  rowKey="id"
                />
              </div>
            </div>
          )}

          <Modal
            title="Add custom location radius"
            open={modalCreateVisible}
            onCancel={() => {
              setCoordinates([]);
              setModalCreateVisible(false);
            }}
            footer={null}
            cancelText="Cancel"
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form name="basic" onFinish={onFinishForm} layout="vertical">
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "Please input the location name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="radiusInKm"
                  label="Radius in Km"
                  rules={[
                    {
                      required: true,
                      type: "number",
                      message: "Please input the radius in Km!",
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    precision={0}
                    size="large"
                    className="radiusInKm"
                  />
                </Form.Item>
                <Form.Item name="Coordinates" label="Coordinates">
                  <>
                    <span>We will use the last polygon you draw</span>
                    <DrawMap
                      edit={false}
                      setCoordinates={setCoordinates}
                      apiKey={
                        webConfigContext.webConfig.REACT_APP_GOOGLE_MAPS_KEY
                      }
                    />
                  </>
                </Form.Item>

                <div className="buttons-modal">
                  <button
                    type="submit"
                    disabled={!(coordinates.length > 0)}
                    name="create"
                    value="click me"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Edit Location"
            footer={null}
            open={modalVisible}
            onCancel={() => {
              setModalVisible(false);
            }}
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinishFormUpdate}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    label="Name"
                    rules={[
                      {
                        required: true,
                        type: "string",
                        message: "Please input the location name!",
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      value={formEditLocation.name}
                      onChange={(e) =>
                        setFormEditLocation({
                          ...formEditLocation,
                          name: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item
                    label="Radius in Km"
                    rules={[
                      {
                        required: true,
                        type: "number",
                        message: "Please input the radius in Km!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      value={formEditLocation.radiusInKm}
                      precision={0}
                      size="large"
                      className="radiusInKm"
                      onChange={(e) =>
                        setFormEditLocation({
                          ...formEditLocation,
                          radiusInKm: e ?? 0,
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <Form.Item name="Coordinates" label="Coordinates">
                  <>
                    <span>
                      Draw a new location and we will use the last polygon you
                      draw
                    </span>
                    <DrawMap
                      edit
                      coordinatesPolygon={formEditLocation.geomText}
                      setCoordinates={setCoordinates}
                      apiKey={
                        webConfigContext.webConfig.REACT_APP_GOOGLE_MAPS_KEY
                      }
                    />
                  </>
                </Form.Item>
                <div className="buttons-modal mt-3">
                  <button
                    disabled={
                      !!(
                        formEditLocation.name === "" ||
                        !formEditLocation.radiusInKm
                      )
                    }
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default LocationRadius;
