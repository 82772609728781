import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Popover, Row } from "antd";
import { User } from "firebase/auth";
import React, { ReactElement, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Auth/firebase";
import { SearchContext } from "../../../Context/context";
import { ISearch } from "../../../Models/Search";
import { RoutesPath } from "../../../Routes";
import EmailUdpateModal from "../../EmailUpdate";

interface ProfileBoxMenuProps {
  user: User | null | undefined;
}

const ProfileBoxMenu = ({ user }: ProfileBoxMenuProps): ReactElement => {
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const onClickLogout = () => {
    logout(searchContent.auth);
    navigate(`${RoutesPath.root}`);
  };

  return (
    <li className="link-right nav-link profile-box-menu">
      <EmailUdpateModal setVisible={setModalVisible} visible={modalVisible} />
      <Popover
        trigger="click"
        content={
          <div className="container ">
            <Row>
              <Col span={24}>
                <h6>Hi!</h6>
                {user && (
                  <>
                    {user.email && <span>Email: {user.email}</span>}
                    {!user.email && (
                      <span>
                        Email:{" "}
                        <button
                          type="button"
                          onClick={() => setModalVisible(true)}
                          className="btn btn-link"
                        >
                          Add email
                        </button>
                      </span>
                    )}
                  </>
                )}
              </Col>
              <Divider />
              <Col span={24}>
                <button
                  type="button"
                  className="btn btn-link float-end"
                  onClick={onClickLogout}
                >
                  Log out
                </button>
              </Col>
            </Row>
          </div>
        }
      >
        Sign out
        <FontAwesomeIcon icon={faCaretDown as IconProp} className="ms-2" />
      </Popover>
    </li>
  );
};

export default ProfileBoxMenu;
