import { Col } from "antd";
import React, { ReactElement } from "react";
import FacilitiesHotelComponent from "../../../../../Components/HotelCard/FacilitiesHotelComponent";

interface FacilityItemProps {
  facility: string;
}

const FacilityItem = ({ facility }: FacilityItemProps): ReactElement => (
  <Col span={12}>
    <div className="facility-item-container">
      <FacilitiesHotelComponent name={facility} />
      <span className="facility-item-label">{facility}</span>
    </div>
  </Col>
);
export default FacilityItem;
