import React, { ReactElement } from "react";
import { IPriceResponse } from "../../../../Models/Checkout";
import moment from "moment";
import DOMPurify from "dompurify";
import { camelCaseToNormalString } from "../../../../helpers/validationsInputs";
import CancellationPolicyCheckout from "./CancellationPolicies";
import { ICardPolicy } from "../../../../Models/SettingsModel";
import { decodeHtmlEntities } from "../../../../helpers/decoderHelper";

interface props {
  price: IPriceResponse;
  cardFee: ICardPolicy | undefined;
}

const PolicySection = ({ price, cardFee }: props): ReactElement => {

  return (
    <div className="checkout-boxes">
      {cardFee && cardFee.active === true && (
        <b className="mb-5">* {cardFee.politics}</b>
      )}
      <ul className="mt-2">
        <CancellationPolicyCheckout price={price} />
      </ul>
      {price &&
        price.hotel &&
        price?.hotel.rates &&
        price?.hotel.rates.length > 0 &&
        price?.hotel.rates[0].policies &&
        price?.hotel.rates[0].policies.length > 0 &&
        price?.hotel.rates[0].policies[0]?.text && (
          <span className="small-titles">Check In instructions</span>
        )}
      <span>
        <small
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              (price?.hotel.rates[0].policies &&
                decodeHtmlEntities(price?.hotel.rates[0].policies[0]?.text)) ||
                ""
            ),
          }}
        />
      </span>
      {price &&
        price.hotel &&
        price?.hotel.rates &&
        price?.hotel.rates.length > 0 &&
        price?.hotel.rates[0].policies &&
        price?.hotel.rates[0].policies.length > 1 &&
        price.hotel.rates[0].policies
          .slice(1)
          .map((policy: { text: string; type: string }, index: number) => (
            <div key={index} className="mt-2">
              <strong>{camelCaseToNormalString(policy.type)}</strong>
              <br />
              <small
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(policy.text || "")}`,
                }}
              />
            </div>
          ))}
    </div>
  );
};

export default PolicySection;
