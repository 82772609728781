import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Carousel, Col } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import React, { ReactElement, useState, useEffect, useRef } from "react";
import { IImage } from "../../../Models/HotelContent";

export interface ImageHotelProps {
  images: IImage[];
  className?: string;
  altText?: string;
}

const MainHotelImage = ({
  images,
  className,
  altText,
}: ImageHotelProps): ReactElement => {
  const [arrayImages, setArrayImages] = useState<IImage[]>([]);
  const carousel = useRef<CarouselRef>(null);

  const handleNextClick = () => carousel?.current?.next();
  const handlePrevClick = () => carousel?.current?.prev();

  useEffect(() => {
    if (images.length > 4) {
      setArrayImages(images.slice(0, 4));
    }
  }, [images]);
  return (
    <Col xs={10} md={8} lg={8} className={`hotel-img-wrapper ${className}`}>
      <Carousel ref={carousel}>
        {arrayImages.map((image, index) => (
          <div
            key={index}
            className="image-center img-wrapper"
            style={{ height: "480px", overflow: "hidden" }}
          >
            <div className="indicator-last" onClick={handlePrevClick}>
              <FontAwesomeIcon icon={faAngleLeft as IconProp} />
            </div>
            <div className="indicator-next" onClick={handleNextClick}>
              <FontAwesomeIcon icon={faAngleRight as IconProp} />
            </div>
            <LazyLoadImage
              alt={altText}
              className="image-with-lazy"
              src={image.links[0].url}
              effect="blur"
            />
            {/* <img src={image.links[0]?.url} alt={altText} loading="lazy" /> */}
          </div>
        ))}
      </Carousel>
    </Col>
  );
};

export default MainHotelImage;
