import React from "react";
import HeroPerksSection from "./HeroPerksSection";
import PerkCard from "./PerkCards";
import { CardData } from "../../Models/PerksModel";
import {
  bottomCardData,
  bottomCardIcons,
  cardData,
} from "../../Data/MemberPerksData";
import { useViewport } from "../../Context/useViewport";

const MemberPerks = () => {
  const { width } = useViewport();

  return (
    <div className="member-perk-wrapper mb-5">
      <HeroPerksSection />
      <div className={width >= 992 || width <= 780 ? "container mt-4" : "mt-4"}>
        <div className="row">
          {cardData.map((data: CardData, index: number) => (
            <div className="col-md-6 col-sm-12 col-12" key={index}>
              <PerkCard data={data} />
            </div>
          ))}
        </div>
        {width >= 775 ? (
          <div className="container">
            <div
              className="bottom-perk-card ms-4 me-2 mb-5 mt-5"
              style={{ overflow: "hidden" }}
            >
              <div className="row ">
                <div className="col-8 no-padding" style={{ zIndex: 1 }}>
                  <div className="perk-botom-card-img-wrapper">
                    <img
                      src={
                        "https://storage.googleapis.com/imagebucketdealpeak/tropical-resort.jpg"
                      }
                      className="bottom-perk-card-image"
                    />
                  </div>
                </div>
                <div className="col-4 no-padding">
                  <div className="bottom-perk-text">
                    <span>Discounted </span>
                    {width > 1000 ? <br /> : <></>}
                    <span>All-Inclusive Resorts</span>
                  </div>
                  <div className="row">
                    {bottomCardIcons.map((icon: string) => (
                      <img src={icon} className="perk-card-icons-bottom" />
                    ))}
                  </div>
                  <div
                    className="row pt-4 border-top "
                    style={{ maxWidth: "100%" }}
                  >
                    <div className="col-lg-8 mx-auto d-grid">
                      <a
                        href={bottomCardData.url}
                        target="_blank"
                        className="btn btn-info rounded-pill text-white text-uppercase fw-bold"
                      >
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-6 col-sm-12 col-12">
            <PerkCard data={bottomCardData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberPerks;
