import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { debounce } from "lodash";
import { Country, State, City, ICountry } from "country-state-city";
import { useAuthState } from "react-firebase-hooks/auth";
import { Button } from "antd";
import { IBilling } from "../../../../Models/Checkout";
import { SearchContext } from "../../../../Context/context";
import { ISearch } from "../../../../Models/Search";
import { GetFromZip } from "../../../../helpers/requestFromZip";
import FormInput from "../../../../Components/InputsCheckout/FormInput";
import { inputsCheckout, inputValue } from "../../../../Models/InputsCheckout";
import {
  onlyLetters,
  removeWhiteSpaces,
  validEmail,
} from "../../../../helpers/validationsInputs";
import { getUserRol } from "../../../../helpers/requestUserAndRole";
import { IWebConfigContext } from "../../../../Models/SettingsModel";
import { WebConfigContext } from "../../../../Context/WebConfigContext";
import { addNewProspect, IProspect } from "../../../../helpers/requestProspect";
import { getIp } from "../../../../helpers/configHelper";
import { getCampaignSetting } from "../../../../helpers/requestSetting";
import { useLocation } from "react-router-dom";
import { updateThreeDsForm } from "../../../../helpers/domManual";
import { createContactSalesMate } from "../../../../helpers/salesMate";

interface props {
  providerName?: string;
  updateBilling: (objBillingForm: IBilling) => void;
  nationality: string;
  nextStep: boolean;
  billing: IBilling;
  checkAll: boolean;
  updateNext: () => void;
  updateGuestsInfo: (
    firstName: string,
    lastName: string,
    email: string
  ) => void;
  updateSame: (objBillingForm: IBilling) => void;
  updateErrors: React.Dispatch<React.SetStateAction<boolean>>;
  changeNationality: (code: string) => void;
  secondStep: boolean;
  setSecondStep: React.Dispatch<React.SetStateAction<boolean>>;
  membershipMandatory: boolean;
}

interface ILocation {
  long_name: string;
  short_name: string;
  types: string[];
}

const objForm = {
  firstName: "",
  lastName: "",
  age: 0,
  contact: {
    phone: "",
    address: {
      line1: "",
      line2: "",
      city: {
        name: "",
        code: "",
      },
      state: {
        name: "",
        code: "",
      },
      country: {
        name: "",
        code: "",
      },
      postalCode: "",
    },
    email: "",
  },
};

const BillingAddress = ({
  billing,
  providerName,
  nationality,
  changeNationality,
  updateBilling,
  nextStep,
  updateNext,
  updateSame,
  updateErrors,
  checkAll,
  updateGuestsInfo,
  secondStep,
  setSecondStep,
  membershipMandatory,
}: props): ReactElement => {
  const { searchContent, setSearchContent } = useContext<any | ISearch>(
    SearchContext
  );
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const [user] = useAuthState(searchContent.auth);
  const [billingForm, setBillingForm] = useState<IBilling>(billing || objForm);
  const location = useLocation();
  const [countryMemo, setCountryMemo] = useState<ICountry[]>([]);
  const [Email, setEmail] = useState<boolean>(false);
  const [country, setCountry] = useState({
    code: billingForm.contact.address.country.code || "",
    name: billingForm.contact.address.country.name || "",
  });
  const [state, setState] = useState({
    code: billingForm.contact.address.state.code || "",
    name: billingForm.contact.address.state.name || "",
  });

  const statesCountry = useMemo(
    () => State.getStatesOfCountry(country.code),
    [country]
  );

  const citiesState = useMemo(
    () => City.getCitiesOfState(country.code, state.code),
    [country, state]
  );

  const changeCountry = (code: string, name: string) => {
    setCountry({ code, name });

    setState({ code: "", name: "" });
    setBillingForm({
      ...billingForm,
      contact: {
        ...billingForm.contact,
        address: {
          ...billingForm.contact.address,
          country: {
            code,
            name,
          },
          city: { code: "", name: "" },
          state: { code: "", name: "" },
        },
      },
    });
  };

  const changeState = (code: string, name: string) => {
    setState({
      code,
      name,
    });
    setBillingForm({
      ...billingForm,
      contact: {
        ...billingForm.contact,
        address: {
          ...billingForm.contact.address,
          state: {
            code,
            name,
          },
          city: { code: "", name: "" },
        },
      },
    });
  };

  const changeCity = (code: string, name: string) => {
    setBillingForm({
      ...billingForm,
      contact: {
        ...billingForm.contact,
        address: {
          ...billingForm.contact.address,
          city: {
            code,
            name,
          },
        },
      },
    });
  };

  useEffect(() => {
    try {
      if (searchContent.phone) {
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            phone: searchContent.phone,
          },
        });
      }
      if (searchContent.email) {
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            email: searchContent.email,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
    const countries = Country.getAllCountries();
    let countriesPriority = localStorage.getItem("country_priority");
    if (countriesPriority !== undefined && countriesPriority) {
      countriesPriority = countriesPriority.substring(1);
      countriesPriority = countriesPriority.slice(0, -1);
      countriesPriority = countriesPriority.replaceAll("\\", "");
      const countriesPriory: string[] = JSON.parse(countriesPriority);
      for (let i = 0; i < countriesPriory.length; i++) {
        const removeCountry = countries
          .map((itemCountry) => itemCountry.isoCode)
          .indexOf(countriesPriory[i]);
        if (removeCountry !== -1) {
          const temp = countries.splice(removeCountry, 1);
          countries.unshift(...temp);
        }
      }
    }
    setCountryMemo(countries);
  }, []);

  useEffect(() => {
    updateBilling(billingForm);
    updateGuestsInfo(
      billingForm.firstName,
      billingForm.lastName,
      billingForm.contact.email
    );
  }, [
    billingForm.contact.phone,
    billingForm.contact.email,
    billingForm.contact.address.line1,
    billingForm.contact.address.postalCode,
    billingForm.age,
    billingForm.firstName,
    billingForm.lastName,
    billingForm.contact.address,
  ]);

  async function validateZip(zip: string | null) {
    if (zip) {
      const resp = await GetFromZip(
        zip,
        webConfigContext.webConfig.REACT_APP_GOOGLE_MAPS_KEY
      );
      const results = resp.data.results[0];
      if (
        typeof results !== "undefined" &&
        results.address_components[results.address_components.length - 1]
          .short_name === "US"
      ) {
        let address;
        if (resp.data.results.length > 0) {
          address = resp.data.results[0].address_components;
          const countryElement: ILocation | undefined = address.find(
            (item: ILocation) => item.types.includes("country")
          );

          setCountry({ code: "US", name: "United States" });
          if (countryElement) {
            setCountry({
              code: countryElement.short_name,
              name: countryElement.long_name,
            });
          }

          setState({
            code: address[address.length - 2].short_name,
            name: address[address.length - 2].long_name,
          });
        }
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            address: {
              ...billingForm.contact.address,
              country: {
                code: "US",
                name: "United States",
              },
              city: {
                code: address[1].short_name,
                name: address[1].long_name,
              },
              state: {
                code: address[address.length - 2].short_name,
                name: address[address.length - 2].long_name,
              },
              postalCode: zip,
            },
          },
        });
      }
    }
  }

  const debounceZip = debounce(validateZip, 870);

  const handleZip = (value: string) => {
    setBillingForm({
      ...billingForm,
      contact: {
        ...billingForm.contact,
        address: {
          ...billingForm.contact.address,
          postalCode: value,
        },
      },
    });
    debounceZip(value);
  };

  useEffect(() => {
    let isMounted = true;
    const getAccesRoles = async (email: string) => {
      try {
        const response = await getUserRol(email);
        const hasSalesAccess =
          response.role?.access === undefined
            ? false
            : response.role.access.some((item) => item.path === "sales");
        if (isMounted) {
          setEmail(!hasSalesAccess);

          if (hasSalesAccess) {
            setBillingForm({
              ...billingForm,
              contact: {
                ...billingForm.contact,
                email: "",
              },
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (user && user.email) {
      getAccesRoles(user.email);
    }
    return () => {
      isMounted = false;
    };
  }, [user]);

  useEffect(() => {
    const fieldsToUpdate = [
      { value: billingForm.firstName, fieldName: "shippingFirstName" },
      { value: billingForm.lastName, fieldName: "shippingLastName" },
      { value: billingForm.contact.email, fieldName: "email" },
      { value: billingForm.contact.phone, fieldName: "phone" },
      {
        value: billingForm.contact.address.line1,
        fieldName: "shippingAddress1",
      },
      {
        value: billingForm.contact.address.line2,
        fieldName: "shippingAddress2",
      },
      {
        value: billingForm.contact.address.country.code,
        fieldName: "shippingCountry",
      },
      {
        value: billingForm.contact.address.postalCode,
        fieldName: "shippingZip",
      },
      {
        value: billingForm.contact.address.state.code,
        fieldName: "shippingState",
      },
      {
        value: billingForm.contact.address.city.code,
        fieldName: "shippingCity",
      },
      {
        value: billingForm.contact.address.postalCode,
        fieldName: "shippingPostCode",
      },
      {
        value: billingForm.contact.address.line1,
        fieldName: "shippingLine3",
      },      
      {
        value: billingForm.contact.address.line2,
        fieldName: "shippingLine2",
      },      
      {
        value: billingForm.contact.address.line1,
        fieldName: "shippingLine1",
      }
    ];

    fieldsToUpdate.forEach((field) => {
      updateThreeDsForm(field.value, field.fieldName);
    });
  }, [
    billingForm.firstName,
    billingForm.lastName,
    billingForm.contact.email,
    billingForm.contact.phone,
    billingForm.contact.address.line1,
    billingForm.contact.address.line2,
    billingForm.contact.address.country.code,
    billingForm.contact.address.postalCode,
    billingForm.contact.address.state.code,
    billingForm.contact.address.city.code,
  ]);

  const inputs: inputsCheckout[] = [
    {
      id: 1,
      name: "firstName",
      type: "text",
      label: "First Name",
      value: billingForm.firstName,
      handleChange: (e: inputValue) => {
        setBillingForm({
          ...billingForm,
          firstName: e.target.value,
        });
      },
      errorMessage: "Please insert a First Name",
      required: true,
      inputType: "inputBasic",
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      label: "Last Name",
      value: billingForm.lastName,
      handleChange: (e: inputValue) => {
        setBillingForm({
          ...billingForm,
          lastName: onlyLetters(e.target.value),
        });
      },
      errorMessage: "Please insert a Last Name",
      inputType: "inputBasic",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      disabled: Email,
      label: "Email",
      extraClass: "whole-grid",
      value: billingForm.contact.email.trim(),
      handleChange: (e: inputValue) => {
        const value = removeWhiteSpaces(e.target.value);
        setSearchContent({
          ...searchContent,
          email: value,
        });
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            email: value,
          },
        });
      },
      errorMessage: "Please insert an Email",
      required: true,
      inputType: "inputBasic",
    },
    {
      id: 4,
      name: "phone",
      label: "Phone",
      maxLength: 15,
      extraClass: "whole-grid",
      value: billingForm.contact.phone,
      handleChange: (e: inputValue) => {
        const { value } = e.target;
        const numbersOnly = value.replace(/[^0-9]/g, "");
        setSearchContent({
          ...searchContent,
          phone: numbersOnly,
        });
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            phone: numbersOnly,
          },
        });
      },
      errorMessage: "Please insert your phone",
      required: true,
      inputType: "inputBasic",
    },
    {
      id: 5,
      name: "line1",
      label: "Address Line 1",
      value: billingForm.contact.address.line1,
      handleChange: (e: inputValue) => {
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            address: {
              ...billingForm.contact.address,
              line1: e.target.value,
            },
          },
        });
      },
      errorMessage: "Please insert your address",
      required: true,
      inputType: "inputBasic",
    },
    {
      id: 6,
      name: "line2",
      label: "Address Line 2",
      value: billingForm.contact.address.line2,
      handleChange: (e: inputValue) => {
        setBillingForm({
          ...billingForm,
          contact: {
            ...billingForm.contact,
            address: {
              ...billingForm.contact.address,
              line2: e.target.value,
            },
          },
        });
      },
      errorMessage: "",
      required: false,
      inputType: "inputBasic",
    },
    {
      id: 7,
      name: "postalcode",
      label: "Postal Code",
      value: billingForm.contact.address.postalCode,
      handleChange: (e: inputValue) => handleZip(e.target.value.trim()),
      errorMessage: "Please insert your postal code",
      maxLength: 15,
      required: true,
      inputType: "inputBasic",
    },
    {
      id: 8,
      name: "country",
      label: "Country",
      value: billingForm.contact.address.country.code,
      handleChangeSelects: (code, name) => changeCountry(code, name),
      optionsSelect: countryMemo,
      errorMessage: "Please select your country",
      required: true,
      inputType: "select",
    },
    {
      id: 9,
      name: "state",
      label: "State",
      value: billingForm.contact.address.state.code,
      handleChangeSelects: (code, name) => changeState(code, name),
      optionsSelect: statesCountry,
      errorMessage: "Please select your state",
      required: true,
      inputType: "select",
    },
    {
      id: 10,
      name: "city",
      label: "City",
      value: billingForm.contact.address.city.code,
      handleChangeSelects: (code, name) => changeCity(code, name),
      optionsSelect: citiesState,
      errorMessage: "Please select your city",
      required: true,
      inputType: "select",
    },
    {
      id: 11,
      name: "nacionality",
      label: "Nacionality",
      value: nationality,
      handleChangeSelects: (code) => changeNationality(code),
      extraClass: "whole-grid",
      optionsSelect: countryMemo,
      errorMessage: "Please select your nacionality",
      required: providerName === "DOTW",
      hidden: providerName !== "DOTW",
      inputType: "select",
    },
  ];

  const [errorsArr, setErrorsArr] = useState<number[]>([]);

  const handleContinueButton = async () => {
    const invalidForm = validateInputs(inputs.slice(0, 4));
    if (!invalidForm) {
      updateNext();
      await createProspect();
    }
  };

  const validateAll = () => {
    const errors = inputs.filter((item) => item.value === "" && item.required);
    if (errors.length > 0) {
      setErrorsArr([...errors.map((errorItem) => errorItem.id)]);
    } else {
      updateSame(billingForm);
      setErrorsArr([]);
    }
  };
  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const createProspect = async () => {
    const defaultCampaignJSON = await getCampaignSetting();
    const defaultCampaign = JSON.parse(defaultCampaignJSON.value);

    const newProspect: IProspect = {
      campaignId: defaultCampaign[0].campaignId,
      email: billingForm.contact.email,
      ipAddress: await getIp(),
      firstName: billingForm.firstName,
      lastName: billingForm.lastName,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      phone: billingForm.contact.phone,
      AFID: "",
      SID: "",
      AFFID: "",
      C1: "",
      C2: "",
      C3: "",
      AID: "",
      OPT: "",
      click_id: "",
      notes: searchContent.hotelDetailURL,
    };

    try {
      await createContactSalesMate(
        searchContent.hotelDetailURL,
        await getIp(),
        billingForm.contact.phone,
        billingForm.contact.email,
        billingForm.firstName,
        billingForm.lastName
      );
    } catch (error) {
      console.error(error);
    }
    try {
      await addNewProspect(newProspect);
    } catch (error) {
      console.error(error);
    }
  };

  const validateInputs = (inputToValidate: inputsCheckout[]) => {
    const errors = inputToValidate.filter(
      (item) =>
        (item.value === "" && item.required) ||
        (item.type === "email" && !isValidEmail(item.value.toString()))
    );

    if (errors.length > 0) {
      updateErrors(true);
      setSecondStep(false);
      setErrorsArr([...errors.map((errorItem) => errorItem.id)]);
      setTimeout(() => {
        setErrorsArr([]);
      }, 5000);
    } else {
      updateSame(billingForm);
      updateErrors(false);
      setErrorsArr([]);
    }
    return errors.length > 0;
  };

  useEffect(() => {
    if (checkAll) validateAll();
  }, [checkAll]);

  useEffect(() => {
    if (secondStep) {
      handleContinueButton();
    }
  }, [secondStep]);

  return (
    <div className="checkout-boxes">
      <span className="form-titles">Guest Information</span>
      <div className="mt-3 mb-3">
        <div className="form-billing_div-country mt-4">
          {inputs.slice(0, 4).map((input) => (
            <FormInput
              id={input.id}
              type={input.type}
              label={input.label}
              key={input.id}
              inputType={input.inputType}
              required={input.required}
              setErrorsArr={setErrorsArr}
              maxLength={input.maxLength}
              extraClass={input.extraClass}
              name={input.name}
              disabled={input.disabled}
              handleChange={input.handleChange}
              optionsSelect={input.optionsSelect}
              handleChangeSelects={input.handleChangeSelects}
              value={input.value}
              errorArr={errorsArr}
              errorMessage={input.errorMessage}
            />
          ))}
        </div>
        {!nextStep && !membershipMandatory && (
          <div className="next_step_button_no_mandatory mt-4">
            <small>
              By clicking next step I agree to the site{" "}
              <a
                href={`${process.env.REACT_APP_API_BASE_WP_URL}/privacy-policy/`}
                target="_blank"
                rel="noreferrer"
              >
                privacy policy.
              </a>
            </small>
            <Button
              className="btn btn-primary-dp-green"
              onClick={handleContinueButton}
            >
              Next Step
            </Button>
          </div>
        )}
        {nextStep && validEmail(billingForm.contact.email) && (
          <div className="form-billing_div-country mt-4">
            {inputs
              .filter((input) => input.hidden !== true)
              .slice(4)
              .map((input) => (
                <FormInput
                  id={input.id}
                  label={input.label}
                  setErrorsArr={setErrorsArr}
                  key={input.id}
                  inputType={input.inputType}
                  required={input.required}
                  maxLength={input.maxLength}
                  extraClass={input.extraClass}
                  name={input.name}
                  disabled={input.disabled}
                  handleChange={input.handleChange}
                  handleFocus={input.handleFocus}
                  optionsSelect={input.optionsSelect}
                  handleChangeSelects={input.handleChangeSelects}
                  value={input.value}
                  errorArr={errorsArr}
                  errorMessage={input.errorMessage}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingAddress;
