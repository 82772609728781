import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { HashLink } from "react-router-hash-link";
import { IFacility } from "../../../../Models/CommonType";
import { IFacilityGroup } from "../../../../Models/HotelContent";
import { RoutesPath } from "../../../../Routes";

interface AmenitiesSectionProps {
  facilities?: IFacility[] | IFacilityGroup[] | undefined;
  id: string;
  correlationId: string;
  currency: string;
  adults: string | null;
  children?: number[];
  onSave?(): void;
  checkin: string | null;
  checkout: string | null;
}

const AmenitiesSection = ({
  facilities,
  id,
  correlationId,
  adults,
  children,
  checkin,
  checkout,
  currency,
  onSave,
}: AmenitiesSectionProps): ReactElement => (
  <div className="col-xs-12 amenities-section">
    <div className="hotel-facilities">
      <ul className="list-unstyled">
        {facilities?.map((facility, index) => (
          <li className="d-flex" key={facility.id}>
            {index < 4 && (
              <>
                <FontAwesomeIcon icon={faCheck as IconProp} color="#00aa00" />
                &nbsp;<small>{facility.name}</small>
              </>
            )}
          </li>
        ))}
        <li>
          <HashLink
            onClick={onSave}
            to={`${RoutesPath.hotelDetail}?hotelId=${id}&checkin=${checkin}&checkout=${checkout}&correlationId=${correlationId}&adults=${adults}&children=${children}&currency=${currency}#main-header`}
            className="btn btn-link btn-more-amenities"
          >
            <small>See more amenities</small>
          </HashLink>
        </li>
      </ul>
    </div>
  </div>
);
export default AmenitiesSection;
