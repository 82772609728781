import { ReactElement, useEffect, useState } from "react";
import { Button, Checkbox, Divider, Form, Input, Modal, Radio, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IRateFilter } from "../../../../Models/CommonType";
import { getAllRateFilter, addRateFilter, updateRateFilter, deleteRateFilter } from "../../../../helpers/ratesFilterService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";


const RateFilterTable = (): ReactElement => {

    const columns: ColumnsType<IRateFilter> = [
        {
            title: 'Provider',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: 'Distribution Type',
            dataIndex: 'distributionType',
            key: 'distributionType',
        },
        {
            title: 'Distribution Channel',
            dataIndex: 'distributionChannel',
            key: 'distributionChannel',
        },
        {
            title: 'Is Mobile',
            dataIndex: 'isMobile',
            key: 'isMobile',
            render: (isMobile) => (isMobile ? "Mobile" : "Desktop"),
        },
        {
            title: 'Availability',
            dataIndex: 'availability',
            key: 'availability',
            render: (availability) => (availability ? "Available" : "Not Available"),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <button
                        type="button"
                        onClick={() => handleEdit(record)}
                        className="btn btn-success-dp-xs"
                    >
                        <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
                        Edit
                    </button>
                    <button
                        type="button"
                        onClick={() => handleDelete(record)}
                        className="btn btn-cancel-dp-xs"
                    >
                        <FontAwesomeIcon icon={faClose as IconProp} className="me-2" />
                        Delete
                    </button>
                </Space>
            ),
        }];

    const [rateFilterData, setRateFilterData] = useState<IRateFilter[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentRateFilter, setCurrentRateFilter] = useState<IRateFilter | null>(null);
    const [form] = Form.useForm();


    useEffect(() => {
        const init = async () => {
            const data = await getAllRateFilter();
            setRateFilterData(data);
        }
        init();
    }, []);

    const handleAdd = () => {
        form.resetFields();
        setCurrentRateFilter(null);
        setIsEditMode(false);
        setIsModalVisible(true);
    }

    const handleEdit = (record: IRateFilter) => {
        setCurrentRateFilter(record);
        setIsEditMode(true);
        setIsModalVisible(true);
        form.setFieldsValue(record);
    }

    const handleDelete = async (record: IRateFilter) => {
        await deleteRateFilter(record);
        refreshData();
    }

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            if (isEditMode) {
                await updateRateFilter({ ...currentRateFilter, ...values });
            } else {
                await addRateFilter(values);
            }
            setIsModalVisible(false);
            refreshData();
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    }

    const refreshData = async () => {
        const data = await getAllRateFilter();
        setRateFilterData(data);
    }

    return (
        <div className="container mt-5">
            <div className="book-title mt-3">
                <h3>Rate filters</h3>
                <button
                    type="button"
                    className="btn btn-primary-dp"
                    onClick={handleAdd}
                >
                    <FontAwesomeIcon icon={faPlus as IconProp} className="me-2" />
                    Add Rate Filter
                </button>
            </div>
            <Divider />
            <div className="card rounded-dp shadow-lg">
                <div className="card-body">
                    <Table
                        columns={columns}
                        dataSource={rateFilterData}
                        rowKey="id"
                        pagination={{ pageSize: 10, showSizeChanger: false }}
                    />
                </div>
            </div>
            <Modal
                title={isEditMode ? "Edit Rate Filter" : "Add Rate Filter"}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form} layout="vertical" name="rateFilterForm">
                    <Form.Item
                        name="provider"
                        label="Provider"
                        rules={[{ required: true, message: 'Please input the provider!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="distributionType"
                        label="Distribution Type"
                        rules={[{ required: true, message: 'Please input the distribution type!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="distributionChannel"
                        label="Distribution Channel"
                        rules={[{ required: true, message: 'Please input the distribution channel!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="isMobile"
                        label="Device Type"
                        rules={[{ required: true, message: 'Please select a device type!' }]}
                    >
                          <Radio.Group buttonStyle="solid"
                            onChange={(e) => form.setFieldsValue({ isMobile: e.target.value === 'Mobile' })}
                        >
                            <Radio.Button value="Mobile">Mobile</Radio.Button>
                            <Radio.Button value="Desktop">Desktop</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="availability"
                        label="Availability"
                        valuePropName="checked"
                        rules={[{ required: true, message: 'Please select availability!' }]}
                    >
                        <Checkbox />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default RateFilterTable;