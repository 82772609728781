import React from "react";
import { Col } from "antd";
import Map from "../../../../Components/Map";

interface props {
  coordenates?: { lat: string; long: string };
  apiKey: string;
  address?: {
    line1: string;
    line2: string | null;
    city: { code: string | null; name: string };
    state: { code: string | null; name: string };
    country: { code: string | null; name: string };
    postalCode: string;
  };
}

const MapSection = ({ coordenates, address, apiKey }: props) => (
  <div className="row mb-5" id="hotel-map">
    <Col lg={24}>
      <div className="mt-2">
        {coordenates?.lat && coordenates.long && (
          <Map
            address={address}
            type="detail"
            apiKey={apiKey}
            lat={parseFloat(coordenates.lat)}
            long={parseFloat(coordenates.long)}
          />
        )}
      </div>
    </Col>
  </div>
);

export default MapSection;
