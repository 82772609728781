export const trigger3dsverify = async () => {
  try {
    const va = window.threeDSprocessing();
    await new Promise((resolve) => {
      va.verify(
        (response: any) => {
          sessionStorage.removeItem("3ds_verify_response");
          sessionStorage.setItem(
            "3ds_verify_response",
            JSON.stringify(response)
          );
          resolve(response); // Resolve the promise with the response
        },
        (error: any) => {
          sessionStorage.removeItem("3ds_verify_response");
          sessionStorage.setItem("3ds_verify_response", JSON.stringify(error));
          resolve(error);
        },
        { amount: 0 },
        null,
        null,
        (rebill_resolve: any) => {
          sessionStorage.setItem(
            "3ds_rebill_response",
            JSON.stringify(rebill_resolve)
          );
          // No need to resolve here as it's not directly related to verification
        },
        (rebill_reject: any) => {
          sessionStorage.setItem(
            "3ds_rebill_response",
            JSON.stringify(rebill_reject)
          );
          // No need to reject here as it's not directly related to verification
        }
      );
    });
  } catch (error) {
    console.error("Something went wrong", error);
  }
};
