import { User } from "firebase/auth";

export const NewBillingObject = (user: User | null | undefined) => ({
  type: "adult",
  title: "Mr.",
  firstName: "",
  lastName: "",
  age: 30,
  contact: {
    phone: "",
    address: {
      line1: "",
      line2: "",
      city: {
        name: "",
        code: "",
      },
      state: {
        name: "",
        code: "",
      },
      country: {
        name: "",
        code: "",
      },
      postalCode: "",
    },
    email: user && user.email ? user.email : "",
  },
});

const NewChildrenGuestObject = (
  firstName: string,
  lastName: string,
  email: string,
  age: number | string
) => ({
  type: "child",
  firstName,
  lastName,
  email,
  age,
});

const NewGuestObject = (
  firstName: string,
  lastName: string,
  email: string
) => ({
  type: "adult",
  firstName,
  lastName,
  email,
  age: 21,
  title: "Mr.",
});

export const generateGuestChildren = (
  firstName: string,
  lastName: string,
  email: string,
  children: string[] | undefined
) => {
  if (!children) return [];

  const childTempl = [];
  for (let i = 0; i < children.length; i++) {
    childTempl.push(
      NewChildrenGuestObject(firstName, lastName, email, children[i])
    );
  }
  return childTempl;
};
export const generateGuestAdult = (
  firstName: string,
  lastName: string,
  email: string,
  adults: number | undefined
) => {
  if (!adults)
    return [
      {
        type: "adult",
        firstName,
        lastName,
        email,
        age: 21,
        title: "Mr.",
      },
    ];
  const formAdultObj = NewGuestObject(firstName, lastName, email);
  const objTemplate = new Array(Number(adults)).fill(formAdultObj);
  return objTemplate;
};

export const NewPaymentObject = () => ({
  issuer: "",
  number: "",
  cvv: "",
  nameoncard: "",
  expirymonth: "",
  expiryyear: "",
  contact: {
    email: "",
    phone: "",
    billingaddress: {
      line1: "",
      line2: "",
      country: {
        code: "",
        name: "",
      },
      city: {
        name: "",
        code: "",
      },
      state: {
        name: "",
        code: "",
      },
      postalcode: "",
    },
  },
});
