export const renderRate = (rate: number) => {
  const x: number = rate;
  switch (true) {
    case x === 0:
      return "No rating";
    case x < 2:
      return "Normal";
    case x < 3:
      return "Normal";
    case x < 4:
      return "Good";
    case x <= 5:
      return "Wonderful";
  }
};
