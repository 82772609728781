import React, { ReactElement } from "react";
import { Image } from "antd";
import { ILink } from "../../Models/RoomDetail";

interface LightBoxComponentProps {
  images: ILink[] | undefined;
  visible: boolean;

  onClickVisible(current: number | undefined): void;
  current?: number;
}

const LightBoxComponent = ({
  images,
  visible,
  onClickVisible,
  current,
}: LightBoxComponentProps): ReactElement => (
  <div style={{ display: "none" }}>
    <Image.PreviewGroup
      preview={{
        visible,
        onVisibleChange: () => onClickVisible(current),
        current,
      }}
    >
      {images?.map((img, index) => (
        <Image src={img.url} key={index} />
      ))}
    </Image.PreviewGroup>
  </div>
);
export default LightBoxComponent;
