import { ReactElement } from "react";
import { Collapse, Divider, Space } from "antd";
import DataFaqs from "../DataFaqs";
import QuestionItemComponent from "../QuestionItem";

const text = "ejemplo de texto";
const title = "Frequently Asked Questions";
const { Panel } = Collapse;

const AccordionFaqsComponent = (): ReactElement => {
  return (
    <>
      <section className="bg-blue" id="accordion-faq-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h2 className="text-center mt-4 text-white">{title}</h2>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                className="my-5"
              >
                <Collapse defaultActiveKey={["0"]} accordion>
                  {DataFaqs.map((item, index) => {
                    return (
                      <Panel
                        header={item.category}
                        key={index}
                        className="text-uppercase"
                      >
                        <div className="px-3">
                          {item.questions.map(
                            (itemQuestion: any, index: number) => {
                              return (
                                <QuestionItemComponent
                                  {...itemQuestion}
                                  key={index}
                                />
                              );
                            }
                          )}
                        </div>
                      </Panel>
                    );
                  })}
                </Collapse>
              </Space>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AccordionFaqsComponent;
