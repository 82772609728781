import React, { useEffect, useState } from "react";
import { ISetting } from "../../../helpers/requestSetting";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import IUserAgentInformation from "../../../Models/UserAgentInformation";

interface props {
  open: boolean;
  onCancel: () => void;
  setting: ISetting;
  onOk: (values: ISetting) => void;
}

const SettingsFormAuth = ({ open, onCancel, onOk, setting }: props) => {
  const [settingValue, setsettingValue] = useState<IUserAgentInformation[]>();
  const [newSetting, setNewSetting] = useState<IUserAgentInformation>({
    browser: "",
    deviceType: "",
    operationSystem: "",
  });

  const handleOk = async () => {
    if (settingValue) {
      if (
        newSetting.browser === "" &&
        newSetting.deviceType === "" &&
        newSetting.operationSystem === ""
      ) {
        const values = JSON.stringify(settingValue);
        const editedObject = {
          id: setting.id,
          name: setting.name,
          value: values,
        };
        await onOk(editedObject);
      } else if (
        newSetting.browser.length > 0 &&
        newSetting.deviceType.length > 0 &&
        newSetting.operationSystem.length > 0
      ) {
        const copyObj: IUserAgentInformation[] = [...settingValue];
        copyObj.push(newSetting);
        const values = JSON.stringify(copyObj);
        const editedObject = {
          id: setting.id,
          name: setting.name,
          value: values,
        };
        setsettingValue(copyObj);
        setNewSetting({
          browser: "",
          deviceType: "",
          operationSystem: "",
        });
        await onOk(editedObject);
      } else {
        Modal.error({
          title: "Please fill up all the rules information",
        });
      }
    }
  };

  const saveNew = () => {
    if (settingValue) {
      try {
        if (
          newSetting.browser.length > 0 &&
          newSetting.deviceType.length > 0 &&
          newSetting.operationSystem.length > 0
        ) {
          const copyObj: IUserAgentInformation[] = [...settingValue];
          copyObj.unshift(newSetting);
          setsettingValue(copyObj);
        } else {
          Modal.error({
            title: "Please fill up all the rules information",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        clearNewSetting();
      }
    }
  };

  const handleChange = (index: number, prop: string, value: string) => {
    if (settingValue) {
      // Make a copy of the settingValue array
      const copyObj: IUserAgentInformation[] = [...settingValue];
      // Make a copy of the specific object you're modifying
      const updatedObj: IUserAgentInformation = { ...copyObj[index] };
      switch (prop) {
        case "browser":
          updatedObj.browser = value;
          break;
        case "device":
          updatedObj.deviceType = value;
          break;
        case "OpSy":
          updatedObj.operationSystem = value;
          break;
        default:
          break;
      }
      // Update the copy of the array with the modified object
      copyObj[index] = updatedObj;
      // Update the state with the new array
      setsettingValue(copyObj);
    }
  };

  const clearNewSetting = () => {
    setNewSetting({
      browser: "",
      deviceType: "",
      operationSystem: "",
    });
  };

  const closeModal = () => {
    clearNewSetting();
    onCancel();
  };

  const handleNew = (prop: string, value: string) => {
    const updatedObj: IUserAgentInformation = { ...newSetting };
    switch (prop) {
      case "browser":
        updatedObj.browser = value;
        break;
      case "device":
        updatedObj.deviceType = value;
        break;
      case "os":
        updatedObj.operationSystem = value;
        break;
      default:
        break;
    }
    setNewSetting(updatedObj);
  };

  const deleteSetting = (index: number) => {
    if (settingValue) {
      const copyObj: IUserAgentInformation[] = [...settingValue];
      copyObj.splice(index, 1);
      setsettingValue(copyObj);
    }
  };

  useEffect(() => {
    if (setting.name == "ExemptDevice2fa") {
      const comissionValues: IUserAgentInformation[] = JSON.parse(
        setting.value
      );
      setsettingValue(comissionValues);
    }
  }, [setting]);

  return (
    <Modal
      open={open}
      title={setting.name}
      onCancel={closeModal}
      style={{ minWidth: "35%", top: 10 }}
      onOk={handleOk}
      footer={[
        <Tooltip title="Cancel all changes">
          <Button onClick={() => closeModal()}>Cancel</Button>
        </Tooltip>,
        <Tooltip title="Add new rule to the list, changes must be saved">
          <Button onClick={saveNew}>Add rule</Button>
        </Tooltip>,
        <Tooltip title="Save all changes">
          <Button onClick={handleOk} type="primary">
            Save Changes
          </Button>
        </Tooltip>,
      ]}
    >
      <Spin spinning={!settingValue} size="large">
        <>
          <h5>Add new rule</h5>
          <Row
            gutter={16}
            className="d-flex justify-content-center align-items-center mt-3 mb-3"
          >
            <Col xs={16} sm={16} md={6} lg={6} xl={6}>
              <Select<string>
                value={newSetting.browser}
                style={{ width: "100%" }}
                onChange={(e) => handleNew("browser", e)}
                options={[
                  { value: "*", label: "All" },
                  { value: "Chrome", label: "Chrome" },
                  { value: "Firefox", label: "Firefox" },
                  { value: "Safari", label: "Safari" },
                ]}
              />
            </Col>
            <Col xs={16} sm={16} md={6} lg={6} xl={6}>
              <Select<string>
                value={newSetting.deviceType}
                style={{ width: "100%" }}
                onChange={(e) => handleNew("device", e)}
                options={[
                  { value: "mobile", label: "Mobile" },
                  { value: "desktop", label: "Desktop" },
                ]}
              />
            </Col>
            <Col xs={16} sm={16} md={6} lg={6} xl={6}>
              <Select<string>
                value={newSetting.operationSystem}
                style={{ width: "100%" }}
                onChange={(e) => handleNew("os", e)}
                options={[
                  { value: "*", label: "All" },
                  { value: "Linux", label: "Linux" },
                  { value: "macOS", label: "MacOS" },
                  { value: "Windows", label: "Windows" },
                  { value: "iOS", label: "iOS" },
                  { value: "Android", label: "Android" },
                ]}
              />
            </Col>
          </Row>
          <div className="config-container">
            {settingValue &&
              settingValue.map((item: IUserAgentInformation, index: number) => (
                <Row
                  key={index}
                  gutter={16}
                  className="d-flex justify-content-center align-items-center mt-3 mb-3 "
                >
                  <Col xs={16} sm={16} md={2} lg={2} xl={2}>
                    <a onClick={() => deleteSetting(index)}>
                      <FontAwesomeIcon icon={faTrash as IconProp} />
                    </a>
                  </Col>
                  <Col xs={16} sm={16} md={6} lg={6} xl={6}>
                    <Select<string>
                      value={item.browser}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(index, "browser", e)}
                      options={[
                        { value: "*", label: "All" },
                        { value: "Chrome", label: "Chrome" },
                        { value: "Firefox", label: "Firefox" },
                        { value: "Safari", label: "Safari" },
                        { value: "Edge", label: "Edge" },
                        { value: "IE", label: "IE" },
                        { value: "Opera", label: "Opera" },
                      ]}
                    />
                  </Col>
                  <Col xs={16} sm={16} md={6} lg={6} xl={6}>
                    <Select<string>
                      value={item.deviceType}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(index, "device", e)}
                      options={[
                        { value: "Mobile", label: "Mobile" },
                        { value: "Desktop", label: "Desktop" },
                      ]}
                    />
                  </Col>
                  <Col xs={16} sm={16} md={6} lg={6} xl={6}>
                    <Select<string>
                      value={item.operationSystem}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(index, "OpSy", e)}
                      options={[
                        { value: "*", label: "All" },
                        { value: "Linux", label: "Linux" },
                        { value: "macOS", label: "MacOS" },
                        { value: "Windows", label: "Windows" },
                        { value: "iOS", label: "iOS" },
                        { value: "Android", label: "Android" },
                      ]}
                    />
                  </Col>
                  <Divider />
                </Row>
              ))}
          </div>
        </>
      </Spin>
    </Modal>
  );
};

export default SettingsFormAuth;
