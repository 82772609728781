import { AxiosResponse } from "axios";
import APIClient from "../Components/Services/APIClient";
import { NoFraudBody, NoFraudResponse } from "../Models/NoFraud";

export const getNoFraudPost = async (
  obj: NoFraudBody
): Promise<AxiosResponse<NoFraudResponse>> => {
  const client = new APIClient();
  return client.client.post("/NoFraud/ValidateCard", obj, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
};
