import React, { ReactElement, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { IMembership } from "../../../../Models/Checkout";
import image from "../../../../img/membership_header_image.png";
import imageGold from "../../../../img/Gold_word.png";
import one from "../../../../img/Number_1.png";
import two from "../../../../img/Number_2.png";
import three from "../../../../img/Number_3.png";

interface props {
  setMembershipObject: React.Dispatch<
    React.SetStateAction<IMembership | undefined>
  >;
  membershipObject: IMembership | undefined;
  updateMember: (e: boolean | undefined) => void;
  createMembership: boolean | undefined;
  membershipMandatory: boolean;
}

const MembershipSectionSideBar = ({
  setMembershipObject,
  membershipObject,
  updateMember,
  createMembership,
  membershipMandatory,
}: props): ReactElement => {
  const onSelected = (value: boolean | undefined) => {
    setMembershipObject(membershipObject);
    updateMember(value);
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  const [wpBaseUrl] = useState<string>(
    process.env.REACT_APP_API_BASE_WP_URL || ""
  );

  return (
    <div className="card membership-card membership-card-sidebar">
      <img
        className="card-img-top header-membership"
        src={image}
        alt="headerImage"
      />
      <div className="card-body d-flex flex-column ">
        {membershipMandatory ? (
          <>
            <Row className="subtitleMembership subtitleMembershipSideBar">
              <b>Included in today's purchase</b>
            </Row>
            <Row className="h4">
              <b>DealPeak</b>
              <img
                className="gold_word_membership"
                src={imageGold}
                alt="gold_logo"
              />
            </Row>
          </>
        ) : (
          <>
            <Row>
              <img
                className="gold_word_membership"
                src={imageGold}
                alt="gold_logo"
              />
            </Row>
            <Row className=" subtitleMembership subtitleMembershipSideBar">
              <b>Discover DealPeak Gold</b>
            </Row>
          </>
        )}
      </div>
      <div className="offer-section">
        <span className="left-text-membership-sidebar">
          <div className="sideWhen ">
            Never overpay for hotels again with our ridiculously crazy hotel
            deals.
          </div>
        </span>
        <div className="row ">
          <div className="col-lg-12 col-xs-12">
            <ul className="list-benefits-vip-club-sidebar">
              <li>
                <b>70% off Hotels & Resorts</b>
              </li>
              <li>
                <b>Last Minute Unsold Rooms</b>
              </li>
              <li>
                <b>Wholesale Rates</b>
              </li>
              <li>
                <b>Suite Upgrades</b>
              </li>
              <li>
                <b>$100 Hotel Savers Card</b>
              </li>
              <li>
                <b>60 Day Trial</b>
              </li>
            </ul>
          </div>
        </div>
        {!membershipMandatory && (
          <div className="col-lg-12 col-xs-12 text-center">
            <span className="membership-sidebar-subtitle">
              Subscribing To DealPeak Gold is easy:
            </span>
            <Row className="list-benefits-member-club-sidebar">
              <Row>
                <Col>
                  {" "}
                  <img className="list_number_icon" src={one} alt="gold_logo" />
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                  Join Dealpeak
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <img
                    className="list_number_icon_two"
                    src={two}
                    alt="gold_logo"
                  />
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                  Get ridiculously crazy hotel deals
                </Col>
              </Row>
              <Row>
                <Col>
                  <img
                    className="list_number_icon"
                    src={three}
                    alt="gold_logo"
                  />
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                  Go one vacation at a fraction of the cost!
                </Col>
              </Row>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  onChange={(e) => onSelected(e.target.value)}
                  value={true}
                  className="mt-2 mb-2"
                  checked={createMembership}
                >
                  <span className="text-start">
                    <strong className="text-start">YES!</strong> I am a smarty
                    pants and I want lower prices!{" "}
                    <strong style={{ color: "#34ca7f" }}>(recommended)</strong>
                  </span>
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  onChange={(e) => onSelected(e.target.value)}
                  value={false}
                  checked={createMembership === false}
                >
                  <span className="text-start">
                    No I hit my head on a rock and I love paying more!
                  </span>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <span className="smallCaps-sidebar">
                By clicking 'YES', I confirm that I have read and agree to the
                <a
                  href={`${wpBaseUrl}/terms-conditions/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;Membership Terms and Conditions&nbsp;
                </a>
                . Today, I am choosing to join DealPeak Gold and receive a
                60-day complimentary trial subscription. I am aware that at the
                end of the trial period, which is approximately 60 days from
                today, the payment method I have provided will be charged $69
                for the annual DealPeak Gold subscription. This subscription
                will automatically renew each year at the prevailing renewal
                rate. I understand that I have the option to cancel my
                subscription at any time and discontinue future billings by
                calling 1-800-919-2339 or logging into my DealPeak Account
                online. Please note that residents of Iowa and Indiana are not
                eligible for this program.
              </span>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default MembershipSectionSideBar;
