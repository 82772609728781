import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export const errorUserCreation = () => {
  Modal.error({
    title: "Error on user creation",
    content: "There was an error creating your user credentials",
  });
};

export const incompleteMembership = () => {
  Modal.error({
    title: "Please select a yes or no for membership",
  });
};

export const successUserCreation = (mail: string, pass: string) => {
  Modal.success({
    maskClosable: true,
    okButtonProps: { size: "large" },
    closable: true,
    title: "User Credentials created",
    content: `Email: ${mail} Password: ${pass}`,
  });
};

export const error = () => {
  Modal.error({
    title: "Error on form",
    content: "Please fill all of the required fields",
  });
};

export const modalError = (contentMessage: string) => {
  if (contentMessage.includes("correlationId")) {
    Modal.error({
      title: "Error on the Booking",
      content:
        "An error has ocurred, please verify if your requet was successful. If not please try again or contact Support with the url",
    });
  } else {
    Modal.error({
      title: "Error on the Booking",
      content: contentMessage,
    });
  }
};

export const bookingError = (contentMessage: string) => {
  Modal.error({
    title: "Error on the Booking",
    content:
      contentMessage ||
      "An error has ocurred, please verify if your requet was successful. If not please try again or contact Support with the url",
  });
};

export const errorMembership = () => {
  Modal.error({
    title: "Membership",
    content: "You need to select a membership in order to continue",
  });
};

export const noFraudError = () => {
  Modal.error({
    title: "Error on credit card",
    content:
      "There was an error with your credit card, please try again or use a different one",
  });
};

export const showConfirmPrice = async (
  content: string,
  processCheckout: () => Promise<void>
) => {
  Modal.confirm({
    title: "",

    icon: <ExclamationCircleOutlined />,
    okText: "Continue",
    content,
    async onOk() {
      await processCheckout();
    },
    onCancel() {},
  });
};
