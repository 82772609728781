import React, { ReactElement } from "react";
import "./style.css";
import favicon from "../../img/favicon.png";

interface LoaderProps {
  title: string;
  subtitle?: string;
}

const LoaderComponent = (props: LoaderProps): ReactElement => {
  const { title, subtitle } = props;
  return (
    <div className="container-fluid loader-wrapper">
      <div className="row">
        <div className="col">
          <div className="loader-content">
            <span className="loader" />
            <img
              src={favicon}
              alt="Dealpeak"
              width="60"
              height="auto"
              className="dp-logo"
            />
            {subtitle && <h4 className="font-weight-bold">{subtitle}</h4>}
            <h5 className="text-center mt-3">{title}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoaderComponent;
