import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Form, Slider, Checkbox, Select, Divider } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { SliderMarks } from "antd/lib/slider";
import GuestRatingFilter from "../GuestRatingFilter";
import FilterItem from "./FilterItem";
import FacilitiesHotelComponent from "../HotelCard/FacilitiesHotelComponent";
import { getRaiusKM } from "../../helpers/requestSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SearchContext } from "../../Context/context";
import { ISearch } from "../../Models/Search";
import { Input } from "antd";

interface props {
  maxPrice: number;
  minPrice: number;
  locations: string[];
  locationsCant: number[];
  facilitiesGroup: string[];
  facilitiesCant: number[];
  maxDistance: number;

  filter: (fieldsObj: IFields) => void;
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
}

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

export interface IFields {
  name: string;
  price: { min: number; max: number };
  locations: CheckboxValueType[];
  ratings: string[];
  facilities: CheckboxValueType[];
  maxDistance: number;
}

const FormMainFilter = ({
  locations,
  locationsCant,
  facilitiesCant,
  facilitiesGroup,
  maxPrice,
  minPrice,
  maxDistance,
  filter,
  setSortOption,
}: props): ReactElement => {
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;

  const [fields, setFields] = useState<IFields>({
    name: "",
    price: { min: minPrice, max: maxPrice },
    locations: [],
    ratings: [],
    facilities: [],
    maxDistance: 30,
  });

  const marks: SliderMarks = {
    [minPrice]: `$${minPrice.toFixed(2)}`,
    [maxPrice]: `$${maxPrice.toFixed(2)}`,
  };

  const DisntanceMarks: SliderMarks = {
    [maxDistance]: `${maxDistance}`,
  };

  const typeOfHotelOptions = [
    { label: "Lowest price", value: "Lowest price" },
    { label: "Highest price", value: "Highest price" },
    { label: "Lowest Star Rating", value: "Lowest Star Rating" },
    { label: "Highest Star Rating", value: "Highest Star Rating" },
  ];

  const ratingValues = [
    { label: "5+", value: 5 },
    { label: "6+", value: 6 },
    { label: "7+", value: 7 },
    { label: "8+", value: 8 },
    { label: "9+", value: 9 },
  ];

  const { Option } = Select;
  const starFilters = [
    { label: "1+", value: 1 },
    { label: "2+", value: 2 },
    { label: "3+", value: 3 },
    { label: "4+", value: 4 },
    { label: "5+", value: 5 },
  ];

  const changeName = (value: string) => {
    setFields({ ...fields, name: value });
    setSearchContent({
      ...searchContent,
      filters: {
        name: value,
        price:
          searchContent.filters?.price != undefined
            ? searchContent.filters?.price
            : fields.price,
        locations:
          searchContent.filters && searchContent.filters.locations
            ? searchContent.filters.locations
            : fields.locations,
        ratings:
          searchContent.filters && searchContent.filters.ratings.length > 0
            ? searchContent.filters.ratings
            : fields.ratings,
        facilities:
          searchContent.filters && searchContent.filters.facilities
            ? searchContent.filters.facilities
            : fields.facilities,
        maxDistance: 30,
      },
    });
  };

  const changeRatings = (value: string) => {
    if (fields.ratings.indexOf(value) !== -1) {
      setFields({ ...fields, ratings: [] });
      setSearchContent({
        ...searchContent,
        filters: {
          name:
            searchContent.filters && searchContent.filters.name
              ? searchContent.filters.name
              : fields.name,
          price:
            searchContent.filters?.price != undefined
              ? searchContent.filters?.price
              : fields.price,
          locations:
            searchContent.filters && searchContent.filters.locations
              ? searchContent.filters.locations
              : fields.locations,
          ratings: [],
          facilities:
            searchContent.filters && searchContent.filters.facilities
              ? searchContent.filters.facilities
              : fields.facilities,
          maxDistance: 30,
        },
      });
    } else {
      setFields({ ...fields, ratings: [value] });
      setSearchContent({
        ...searchContent,
        filters: {
          name:
            searchContent.filters && searchContent.filters.name
              ? searchContent.filters.name
              : fields.name,
          price:
            searchContent.filters?.price != undefined
              ? searchContent.filters?.price
              : fields.price,
          locations:
            searchContent.filters && searchContent.filters.locations
              ? searchContent.filters.locations
              : fields.locations,
          ratings: [value],
          facilities:
            searchContent.filters && searchContent.filters.facilities
              ? searchContent.filters.facilities
              : fields.facilities,
          maxDistance: 30,
        },
      });
    }
  };

  const filterFunction = (newValue: string, arr: any[]) => {
    let newArr = arr;
    if (arr.includes(newValue)) {
      newArr = arr.filter((item) => item !== newValue);
      return newArr;
    }
    newArr.push(newValue);
    return newArr;
  };

  const selectSort = (value: { label: string; value: string }) => {
    setSortOption(value.value);
  };

  const pricecFilter = (value: number[]) => {
    setFields({ ...fields, price: { min: value[0], max: value[1] } });
    setSearchContent({
      ...searchContent,
      filters: {
        name:
          searchContent.filters && searchContent.filters.name
            ? searchContent.filters.name
            : fields.name,
        price: { min: value[0], max: value[1] },
        locations:
          searchContent.filters && searchContent.filters.locations
            ? searchContent.filters.locations
            : fields.locations,
        ratings:
          searchContent.filters && searchContent.filters.ratings.length > 0
            ? searchContent.filters.ratings
            : fields.ratings,
        facilities:
          searchContent.filters && searchContent.filters.facilities
            ? searchContent.filters.facilities
            : fields.facilities,
        maxDistance: 30,
      },
    });
  };

  const locationFilter = (value: string) => {
    let newLocation = filterFunction(value, fields.locations);
    setFields({
      ...fields,
      locations: newLocation,
    });
    setSearchContent({
      ...searchContent,
      filters: {
        name:
          searchContent.filters && searchContent.filters.name
            ? searchContent.filters.name
            : fields.name,
        price:
          searchContent.filters?.price != undefined
            ? searchContent.filters?.price
            : fields.price,
        locations: newLocation,
        ratings:
          searchContent.filters && searchContent.filters.ratings.length > 0
            ? searchContent.filters.ratings
            : fields.ratings,
        facilities:
          searchContent.filters && searchContent.filters.facilities
            ? searchContent.filters.facilities
            : fields.facilities,
        maxDistance: 30,
      },
    });
  };

  const amenitiesFilter = (value: string) => {
    let newAmenitie = filterFunction(value, fields.facilities);
    setFields({
      ...fields,
      facilities: newAmenitie,
    });
    setSearchContent({
      ...searchContent,
      filters: {
        name:
          searchContent.filters && searchContent.filters.name
            ? searchContent.filters.name
            : fields.name,
        price:
          searchContent.filters?.price != undefined
            ? searchContent.filters?.price
            : fields.price,
        locations:
          searchContent.filters && searchContent.filters.locations
            ? searchContent.filters.locations
            : fields.locations,
        ratings:
          searchContent.filters && searchContent.filters.ratings.length > 0
            ? searchContent.filters.ratings
            : fields.ratings,
        facilities: newAmenitie,
        maxDistance: 30,
      },
    });
  };

  useEffect(() => {
    filter(fields);
  }, [fields]);

  useEffect(() => {
    setSearchContent({
      ...searchContent,
      filters: {
        name:
          searchContent.filters?.name != undefined
            ? searchContent.filters?.name
            : fields.name,
        price:
          searchContent.filters?.price != undefined
            ? searchContent.filters?.price
            : fields.price,
        locations:
          searchContent.filters && searchContent.filters.locations
            ? searchContent.filters.locations
            : fields.locations,
        ratings:
          searchContent.filters && searchContent.filters.ratings.length > 0
            ? searchContent.filters.ratings
            : fields.ratings,
        facilities:
          searchContent.filters && searchContent.filters.facilities
            ? searchContent.filters.facilities
            : fields.facilities,
        maxDistance: 30,
      },
    });
  }, [fields]);

  useEffect(() => {
    let isMounted = true;

    getRaiusKM().then((value) => {
      if (isMounted && searchContent.filters) {
        setFields({
          name: searchContent.filters.name,
          price: searchContent.filters.price,
          locations: searchContent.filters.locations,
          ratings: searchContent.filters.ratings,
          facilities: searchContent.filters.facilities,
          maxDistance: value,
        });
        filter(searchContent.filters);
        if (searchContent.sortOption) setSortOption(searchContent.sortOption);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form id="main-filter-form">
      <FilterItem title="Sort by">
        <Select
          allowClear
          labelInValue
          placeholder="Select type"
          defaultActiveFirstOption
          defaultValue={
            searchContent.sortOption
              ? {
                  label: searchContent.sortOption,
                  value: searchContent.sortOption,
                }
              : { label: "Lowest price", value: "Lowest price" }
          }
          className="dropdown-type"
          onChange={selectSort}
        >
          {typeOfHotelOptions.map((type, index) => (
            <Option
              key={index}
              value={type.value}
              className="type-hotel-option"
            >
              {type.label}
            </Option>
          ))}
        </Select>
      </FilterItem>
      <FilterItem title="Guest rating" className="d-none">
        <GuestRatingFilter
          values={ratingValues}
          type="rating"
          selectedRatings={fields.ratings}
        />
      </FilterItem>
      <FilterItem title="">
        <h6 className="mt-3">Your budget (per night)</h6>
        <Divider />
        <Slider
          marks={marks}
          range={{ draggableTrack: true }}
          value={[fields.price.min, fields.price.max]}
          min={minPrice}
          onChange={(value: number[]) => pricecFilter(value)}
          max={maxPrice}
          defaultValue={[minPrice, maxPrice]}
          step={1}
          id="priceRange"
        />
      </FilterItem>
      <FilterItem title="Search Hotel">
        <Divider />
        <Input
          type={"text"}
          value={fields.name}
          placeholder={"Hotel Name"}
          size="large"
          onChange={(e: { target: { value: string } }) =>
            changeName(e.target.value)
          }
          prefix={<FontAwesomeIcon icon={faSearch as IconProp} />}
          name="hotelName"
        />
      </FilterItem>
      <FilterItem title="Location">
        <Divider />
        <div className="collapse-filter">
          <Form.Item>
            {locations.map((item, index) => (
              <div key={index} className="filter-list">
                <div>
                  <Checkbox
                    value={item}
                    checked={fields.locations.includes(item.toLowerCase())}
                    onChange={(e) => {
                      locationFilter(e.target.value);
                    }}
                  >
                    {item}
                  </Checkbox>
                </div>
                <span>({locationsCant[index]})</span>
              </div>
            ))}
          </Form.Item>
        </div>
      </FilterItem>
      <FilterItem title="Hotel Star Level">
        <Divider />
        <GuestRatingFilter
          values={starFilters}
          type="starRating"
          onChange={changeRatings}
          selectedRatings={fields.ratings}
        />
      </FilterItem>
      <div style={{ display: "none" }}>
        <FilterItem title="Distance">
          <Divider />
          <Slider
            marks={DisntanceMarks}
            range
            min={0}
            onChange={(value: number[]) =>
              setFields({ ...fields, maxDistance: value[1] })
            }
            max={maxDistance}
            defaultValue={[0, maxDistance]}
            step={1}
            id="distanceRange"
          />
        </FilterItem>
      </div>
      <FilterItem title="Amenities">
        <Divider />
        <div className="collapse-filter">
          {facilitiesGroup.map((facility, index) => (
            <div key={index} className="amenities-filter">
              <Checkbox
                value={facility}
                className="box"
                checked={fields.facilities.includes(facility)}
                onChange={(e) => amenitiesFilter(e.target.value)}
              >
                <FacilitiesHotelComponent
                  name={facility}
                  className="filter-view"
                />
                <div className="facility-name">
                  <span>{facility}</span>
                </div>
              </Checkbox>
            </div>
          ))}
        </div>
      </FilterItem>
    </Form>
  );
};
export default FormMainFilter;
