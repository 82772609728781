import { Image } from "antd";
import moment from "moment";
import React, { ReactElement, useState, useEffect } from "react";
import { IBilling, IPayment } from "../../../../Models/Checkout";
import visa from "../../../../img/visa.png";
import mastercard from "../../../../img/mastercard.png";
import discover from "../../../../img/discover.png";
import american from "../../../../img/american.png";
import { inputsCheckout, inputValue } from "../../../../Models/InputsCheckout";
import FormInput from "../../../../Components/InputsCheckout/FormInput";
import { onlyNumbers } from "../../../../helpers/validationsInputs";
import { updateThreeDsForm } from "../../../../helpers/domManual";

interface props {
  paymentInfo: IPayment;
  billingForm: IBilling;
  updatePayment: (objPaymentForm: IPayment) => void;
  checkAll: boolean;
}

const PaymentForm = ({
  paymentInfo,
  updatePayment,
  checkAll,
  billingForm,
}: props): ReactElement => {
  const [payment, setPayment] = useState<IPayment>(paymentInfo);

  const updateCardNumber = (value: string) => {
    if (value === "") {
      setPayment({ ...payment, issuer: "", number: "" });
      return;
    }
    const regexVisa = "^4[0-9]{12}(?:[0-9]{3})?$";
    const regexMaster = "^5[1-5][0-9]{14}$";
    const regexDiscover = "^6(?:011|5[0-9]{2})[0-9]{12}$";
    const regexAmex = "^3[47][0-9]{13}$";

    if (value.match(regexVisa)) {
      setPayment({ ...payment, issuer: "vi", number: value });
    } else if (value.match(regexMaster)) {
      setPayment({
        ...payment,
        issuer: "ca",
        number: value,
      });
    } else if (value.match(regexDiscover)) {
      setPayment({ ...payment, issuer: "ds", number: value });
    } else if (value.match(regexAmex)) {
      setPayment({ ...payment, issuer: "ax", number: value });
    } else {
      setPayment({ ...payment, number: value });
    }
  };

  useEffect(() => {
    updatePayment(payment);
  }, [
    payment.nameoncard,
    payment.issuer,
    payment.cvv,
    payment.expirymonth,
    payment.expiryyear,
    payment.number,
  ]);

  const handleDateChange = (value: moment.Moment | null) => {
    const actualMonth = value?.month() ?? 0;
    setPayment({
      ...payment,
      expirymonth: (actualMonth + 1).toString() || "",
      expiryyear: value?.year().toString() || "",
    });
  };

  const updateStickyForm = () => {
    const fieldsToUpdate = [
      { value: payment.nameoncard, fieldName: "cardHolderName" },
      { value: payment.issuer, fieldName: "creditCardType" },
      { value: payment.number, fieldName: "x_card_num" },
      { value: payment.expirymonth, fieldName: "x_exp_month" },
      { value: payment.expiryyear, fieldName: "x_exp_year" },
      { value: payment.cvv.toString(), fieldName: "x_cvv" },
    ];

    for (const field of fieldsToUpdate) {
      updateThreeDsForm(field.value, field.fieldName);
    }
  };

  useEffect(() => {
    updateStickyForm();
  }, [payment.issuer, payment.expirymonth, payment.expiryyear, payment.cvv]);

  const inputsCreditCard: inputsCheckout[] = [
    {
      id: 1,
      name: "nameOnCard",
      label: "Name on card",
      value: payment.nameoncard,
      handleChange: (e: inputValue) =>
        setPayment({ ...payment, nameoncard: e.target.value }),
      errorMessage: "Please insert the name of the card",
      required: true,
      inputType: "inputBasic",
    },
    {
      id: 2,
      name: "numberOnCard",
      label: "Card number",
      value: payment.number,
      handleChange: (e: inputValue) => {
        if (sessionStorage.getItem("noFraudPass")) {
          sessionStorage.removeItem("noFraudPass");
        }
        updateCardNumber(onlyNumbers(e.target.value));
      },
      errorMessage: "Please insert a valid credit card",
      required: true,
      inputType: "inputBasic",
      maxLength: 22,
    },
    {
      id: 3,
      name: "dateCard",
      label: "YYYY-MM",
      value:
        payment.expirymonth !== ""
          ? `${payment.expiryyear}/${payment.expirymonth}`
          : "",
      handleChangeDate: (value) => handleDateChange(value),
      errorMessage: "Please insert a date",
      required: true,
      inputType: "date",
    },
    {
      id: 4,
      name: "cvv",
      label: "CVV code",
      value: payment.cvv,
      handleChange: (e: inputValue) => {
        setPayment({ ...payment, cvv: onlyNumbers(e.target.value) });
        updateStickyForm();
      },
      errorMessage: "Please insert a valid cvv number",
      required: true,
      inputType: "inputBasic",
      maxLength: 4,
      minLength: 3,
    },
  ];

  const [errorsArr, setErrorsArr] = useState<number[]>([]);

  const validateAll = () => {
    const errors = inputsCreditCard.filter((item) => {
      if (item.name === "numberOnCard") {
        return item.value === "" || payment.issuer === "";
      }
      if (item.minLength && item.value.toString().length < item.minLength) {
        return item;
      }
      return item.value === "" && item.required;
    });
    if (errors.length > 0) {
      setErrorsArr([...errors.map((errorItem) => errorItem.id)]);
    } else {
      setErrorsArr([]);
    }
  };

  useEffect(() => {
    if (checkAll) validateAll();
  }, [checkAll]);

  useEffect(() => {
    setPayment({
      ...payment,
      contact: {
        email: billingForm.contact.email,
        phone: billingForm.contact.phone,
        billingaddress: {
          line1: billingForm.contact.address.line1,
          line2: billingForm.contact.address.line2,
          city: { ...billingForm.contact.address.city },
          country: { ...billingForm.contact.address.country },
          state: { ...billingForm.contact.address.state },
          postalcode: billingForm.contact.address.postalCode,
        },
      },
    });
  }, [billingForm]);

  return (
    <div className="checkout-boxes">
      <span className="form-titles">Payment Method</span>

      <div className="form-billing payment-style mt-2 mb-3">
        <div>
          {payment.issuer === "" && (
            <>
              <span>
                <Image src={visa} width={40} height={45} preview={false} />
              </span>
              <span>
                <Image src={mastercard} width={40} preview={false} />
              </span>
              <span>
                <Image src={discover} width={40} preview={false} />
              </span>

              <span>
                <Image src={american} width={40} preview={false} />
              </span>
            </>
          )}
          <span>
            {payment.issuer === "vi" && (
              <Image src={visa} width={40} height={45} preview={false} />
            )}
            {payment.issuer === "ca" && (
              <Image src={mastercard} width={40} height={45} preview={false} />
            )}
            {payment.issuer === "ds" && (
              <Image src={discover} width={40} height={45} preview={false} />
            )}
            {payment.issuer === "ax" && (
              <Image src={american} width={40} height={45} preview={false} />
            )}
          </span>
        </div>
        {inputsCreditCard.slice(0, 4).map((input, index) => (
          <div className="mt-3" key={index}>
            <FormInput
              id={input.id}
              label={input.label}
              key={input.id}
              inputType={input.inputType}
              required={input.required}
              maxLength={input.maxLength}
              extraClass={input.extraClass}
              name={input.name}
              setErrorsArr={setErrorsArr}
              disabled={input.disabled}
              handleChange={input.handleChange}
              optionsSelect={input.optionsSelect}
              handleChangeSelects={input.handleChangeSelects}
              value={input.value}
              handleChangeDate={input.handleChangeDate}
              errorArr={errorsArr}
              errorMessage={input.errorMessage}
              minLength={input.minLength}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentForm;
