import { Form, Input, Select, Modal } from "antd";
import React, { useState } from "react";
import { IRole } from "../../../Models/UsersModel";

interface CreateAccountProps {
  modalCreateVisible: boolean;
  setModalCreateVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onFinishForm: (values: any) => Promise<void>;
  setRoleSubmit: React.Dispatch<React.SetStateAction<number>>;
  roles: IRole[];
}

const CreateAccountForm = ({
  modalCreateVisible,
  setModalCreateVisible,
  onFinishForm,
  setRoleSubmit,
  roles,
}: CreateAccountProps) => {
  const [formEdit, setFormEdit] = useState({
    userId: 0,
    firstName: "",
    lastName: "",
    userName: "",
    roleId: 0,
    status: true,
  });
  return (
    <Modal
      title="Create Account"
      open={modalCreateVisible}
      onCancel={() => setModalCreateVisible(false)}
      footer={null}
      destroyOnClose
      closeIcon={<small>Close</small>}
    >
      <div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinishForm}
          autoComplete="off"
          layout="vertical"
          className="create-account-form"
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input the user first name!",
              },
            ]}
          >
            <Input
              type="text"
              onChange={(e) =>
                setFormEdit({ ...formEdit, firstName: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input the user last name!",
              },
            ]}
          >
            <Input
              type="text"
              onChange={(e) =>
                setFormEdit({ ...formEdit, lastName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            name="userName"
            label="User Name"
            rules={[
              { required: true, message: "Please input the user email!" },
            ]}
          >
            <Input
              type="email"
              value={formEdit.userName}
              onChange={(e) =>
                setFormEdit({ ...formEdit, userName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="User Role"
            rules={[
              { required: true, message: "Please select the user role!" },
            ]}
          >
            <Select
              placeholder="Select the role"
              onChange={(value) => setRoleSubmit(value)}
            >
              {roles.map((item) => (
                <Select.Option value={item.roleId} key={item.roleId}>
                  {item.roleName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password type="password" />
          </Form.Item>
          <div className="buttons-modal">
            <button type="submit" className="btn btn-success-dp btn-block">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateAccountForm;
