import APIClient from "../Components/Services/APIClient";
import { getByName } from "./requestSetting";

interface IFilterQuery {
  fieldName: string;
  data: string;
}

interface updateContactObject {
  email?: string;
  lastName?: string;
  firstName?: string;
  textCustomField18?: string;
  description?: string;
  mobile?: string;
  billingAddressLine1?: string;
  billingCity?: string;
  billingState?: string;
  billingAddressLine2?: string;
  billingZipCode?: string;
  billingCountry?: string;
}

export const createContactSalesMate = async (
  hoteldetailURL: string,
  ipAddress: string,
  mobile: string = "",
  email: string = "NewContact@fake.com",
  name: string = "New",
  lastname: string = "Contact"
) => {
  const salesMate = await getByName("SalesMate");
  if (!salesMate) return;

  if (JSON.parse(salesMate.value.toLowerCase()) == false) return;

  trimFields();

  const searchContact: any = await searchContactSalesMate(email, mobile);
  if (searchContact.id > 0 && name != "New") {
    await salesMateSmallUpdate(
      searchContact.id,
      mobile,
      hoteldetailURL,
      ipAddress,
      name,
      lastname,
      email
    );
  } else if (!searchContact.id) {
    const client = new APIClient();

    const contactObject = {
      firstName: name,
      lastName: lastname,
      email: email,
      company: 1,
      owner: 1,
      currency: "USD",
      billingCountry: "us",
      type: "Lead",
      mobile: mobile,
      description: hoteldetailURL,
      textCustomField2: "269",
      textCustomField18: ipAddress,
    };

    try {
      await client.client.post<any>(`SalesMate/contact`, contactObject);
    } catch (error) {
      console.error("Something went wrong: ", error);
    }
  }

  function trimFields() {
    mobile = mobile.trim();
    email = email.trim();
    name = name.trim();
    lastname = lastname.trim();
  }
};

const salesMateSmallUpdate = async (
  contactID: string,
  phone: string,
  hoteldetailURL: string,
  ipAddress: string,
  name?: string,
  lastname?: string,
  email?: string
) => {
  const salesMate = await getByName("SalesMate");
  if (!salesMate) return;
  if (JSON.parse(salesMate.value.toLowerCase()) == false) return;
  const client = new APIClient();

  const putObject: updateContactObject = {
    mobile: phone,
    description: hoteldetailURL,
    textCustomField18: ipAddress,
  };

  if (name) {
    putObject.firstName = name;
  }

  if (lastname) {
    putObject.lastName = lastname;
  }

  if (email) {
    putObject.email = email;
  }

  try {
    await client.client.put<any>(`SalesMate/contact/${contactID}`, putObject);
  } catch (error) {
    console.error(error);
  }
};

export const salesMateBigUpdate = async (
  billingAddressLine1: string,
  billingCity: string,
  billingState: string,
  billingAddressLine2: string,
  billingZipCode: string,
  billingCountry: string,
  mobile: string,
  hoteldetailURL: string,
  ipAddress: string,
  name: string,
  lastname: string,
  email: string
) => {
  const salesMate = await getByName("SalesMate");
  if (!salesMate) return;
  if (JSON.parse(salesMate.value.toLowerCase()) == false) return;
  const client = new APIClient();

  const searchContact: any = await searchContactSalesMate(email, mobile);

  if (searchContact.id > 0) {
    const putObject: updateContactObject = {};
    if (mobile) {
      putObject.mobile = mobile;
    }

    if (ipAddress) {
      putObject.textCustomField18 = ipAddress;
    }

    if (hoteldetailURL) {
      putObject.description = hoteldetailURL;
    }

    if (billingCountry) {
      putObject.billingCountry = billingCountry;
    }
    if (billingZipCode) {
      putObject.billingZipCode = billingZipCode;
    }
    if (billingAddressLine2) {
      putObject.billingAddressLine2 = billingAddressLine2;
    }
    if (billingState) {
      putObject.billingState = billingState;
    }
    if (billingCity) {
      putObject.billingCity = billingCity;
    }
    if (billingAddressLine1) {
      putObject.billingAddressLine1 = billingAddressLine1;
    }
    if (name) {
      putObject.firstName = name;
    }

    if (lastname) {
      putObject.lastName = lastname;
    }

    if (email) {
      putObject.email = email;
    }

    try {
      await client.client.put<any>(
        `SalesMate/contact/${searchContact.id}`,
        putObject
      );
    } catch (error) {
      console.error(error);
    }
  } else {
    const client = new APIClient();
    const contactObject = {
      firstName: name,
      lastName: lastname,
      email: email,
      company: 1,
      owner: 1,
      currency: "USD",
      billingCountry: "us",
      type: "Lead",
      mobile: mobile.trim(),
      description: hoteldetailURL,
      textCustomField2: "269",
      textCustomField18: ipAddress,
    };

    try {
      await client.client.post<any>(`SalesMate/contact`, contactObject);
    } catch (error) {
      console.error("Something went wrong: ", error);
    }
  }
};

function buildFilterQuery(email?: string, phone?: string): IFilterQuery[] {
  const filterQueries: IFilterQuery[] = [];

  if (email) {
    filterQueries.push({
      fieldName: "email",
      data: email,
    });
  }
  if (phone) {
    filterQueries.push({
      fieldName: "mobile",
      data: phone,
    });
  }

  return filterQueries;
}

export const searchContactSalesMate = async (
  email?: string,
  mobile?: string
) => {
  if (!email && !mobile) return;
  const salesMate = await getByName("SalesMate");
  if (!salesMate) return;
  if (JSON.parse(salesMate.value.toLowerCase()) == false) return;

  const filterQueries = buildFilterQuery(email, mobile);
  const client = new APIClient();

  try {
    const contact_res = await client.client.post<any>(
      `SalesMate/contact/customSearch`,
      { fieldsQuery: filterQueries }
    );
    if (
      contact_res &&
      contact_res.data &&
      contact_res.data.Data &&
      contact_res.data.Data.data &&
      contact_res.data.Data.data[0] &&
      contact_res.data.Data.data[0].id
    ) {
      return contact_res.data.Data.data[0];
    } else {
      return -1;
    }
  } catch (error) {
    return -2;
  }
};
