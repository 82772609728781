import { Col, Modal, Row, Switch, SelectProps, Checkbox, Button } from "antd";
import React, { useEffect, useState } from "react";
import { ISetting, getByName } from "../../../../helpers/requestSetting";

interface props {
  open: boolean;
  onCancel: () => void;
  onOk: (values: ISetting) => void;
  setting: ISetting;
}

const TwoFactorModal = ({ open, onCancel, onOk, setting }: props) => {
  const [settingObject, setsettingObject] = useState<{
    mobile: boolean;
    desktop: boolean;
    providers: string[];
  }>({
    mobile: true,
    desktop: true,
    providers: [],
  });
  const [providers, setProviders] = useState<string[]>([]);

  const onChange = (checkedValues: any) => {
    setsettingObject({
      ...settingObject,
      providers: checkedValues,
    });
  };

  const onClear = () => {
    setsettingObject({
      ...settingObject,
      providers: [],
    });
  };

  const handleOk = async () => {
    const values = JSON.stringify(settingObject);
    const editedObject = {
      id: setting.id,
      name: setting.name,
      value: values,
    };
    await onOk(editedObject);
  };

  useEffect(() => {
    const init = async () => {
      const setting = await getByName("Providers");
      const providerArray = setting.value.split(",");
      setProviders(providerArray);
    };
    if (setting.name == "SMSTwoFactorActive") {
      const parsedValue = JSON.parse(setting.value);
      setsettingObject(parsedValue);
    }
    init();
  }, [setting]);

  return (
    <Modal
      open={open}
      title="TwoFactorAuth"
      onCancel={onCancel}
      style={{ minWidth: "30%" }}
      onOk={handleOk}
      footer={[
        <Button onClick={onClear}>Clear selection</Button>,
        <Button onClick={onCancel}>Cancel</Button>,
        <Button onClick={handleOk} type="primary">
          Save
        </Button>,
      ]}
    >
      <Row>
        <Col span={12}>
          <Row className="mt-4">
            <Col span={16}>Two Factor Mobile</Col>
            <Col span={4}>
              <Switch
                checked={settingObject?.mobile}
                onChange={() =>
                  setsettingObject({
                    ...settingObject,
                    mobile: !settingObject.mobile,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={16}>Two Factor Desktop</Col>
            <Col span={4}>
              <Switch
                checked={settingObject?.desktop}
                onChange={() =>
                  setsettingObject({
                    ...settingObject,
                    desktop: !settingObject.desktop,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Checkbox.Group
            options={providers}
            defaultValue={settingObject.providers}
            value={settingObject.providers}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default TwoFactorModal;
