import React from "react";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import {
  MembershipMenuItems,
  MembershipMenuLinks,
} from "../../../Common/Enums";

const MenuMembership = () => (
  <section className="mt-5">
    <div className="container-fluid">
      <Row>
        <Col span={24}>
          <h3 className="text-center title-menu-membership">
            Membership Center
          </h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <nav className="navbar navbar-expand-md menu-membership">
            <div className="container-fluid">
              <ul className="navbar-nav mx-auto">
                {MembershipMenuItems.map((menu, index) => (
                  <li className="nav-item dark-link" key={index}>
                    <NavLink
                      className="nav-link "
                      to={MembershipMenuLinks[index]}
                    >
                      {menu}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </Col>
      </Row>
    </div>
  </section>
);
export default MenuMembership;
