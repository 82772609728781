import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "antd";
import React, { ReactElement } from "react";

const AlertCovid = (): ReactElement => (
  <div className="container my-4">
    <div className="row">
      <Col span={24} className="alert-covid">
        <FontAwesomeIcon
          icon={faExclamationCircle as IconProp}
          className="me-2"
        />
        <span>
          <strong>COVID-19</strong> restrictions may impact leisure travel.
          Please review this hotel&apos;s important info.
        </span>
      </Col>
    </div>
  </div>
);
export default AlertCovid;
