import { Alert, Drawer } from "antd";
import React, { ReactElement } from "react";
import SearchBoxForm from "../SearchBoxForm";

interface SearchBoxFilterMobileProps {
  isFilterOpen: boolean;

  onChangeUbication(ubication: string): void;
  onCloseMenu(): void;
  setAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showAlert: boolean;
  setErrmsg: React.Dispatch<React.SetStateAction<string>>;
  errMsg: string;
}

const SearchBoxFilterMobile = ({
  isFilterOpen,
  onChangeUbication,
  onCloseMenu,
  setAlert,
  showAlert,
  setErrmsg,
  errMsg,
}: SearchBoxFilterMobileProps): ReactElement => (
  <Drawer
    title="Filters"
    open={isFilterOpen}
    getContainer={false}
    placement="top"
    closable
    onClose={onCloseMenu}
    height={"90vh"}
    style={{ position: "absolute" }}
  >
    {showAlert && (
      <Alert
        type="error"
        message="Error"
        description={errMsg}
        closable
        className="dateAlert"
      />
    )}
    <SearchBoxForm
      Err={showAlert}
      onChangeUbication={onChangeUbication}
      onUpdate={onCloseMenu}
      setErr={setAlert}
      setErrmsg={setErrmsg}
    />
  </Drawer>
);
export default SearchBoxFilterMobile;
