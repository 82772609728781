import { Table } from "antd";
import React, { useState, useEffect, useContext } from "react";
import LoaderComponent from "../../Components/LoaderComponent";
import { getAll } from "../../helpers/requestSetting";
import { IWebConfigContext } from "../../Models/SettingsModel";
import { WebConfigContext } from "../../Context/WebConfigContext";

const DebugPage = () => {
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<
    { name: string; value: string | undefined }[]
  >([]);
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Value", dataIndex: "value", key: "value" },
  ];

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const appUrl = webConfigContext.webConfig.REACT_APP_DEMO_HOTEL_URL;
        const channelId = webConfigContext.selectedChannel ?? "";
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const envVar = [
          { name: "App Url", value: appUrl },
          { name: "Channel ID", value: channelId },
          { name: "Api URL", value: apiUrl },
        ];
        const settings = await getAll();
        setDataSource([...settings, ...envVar]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    init();
  }, []);
  return (
    <div className="container mt-5">
      {loading && <LoaderComponent title="Loading Configurations" />}
      {!loading && (
        <>
          <h3 className="mb-3">Configuration Values</h3>
          <div className="card rounded-dp shadow-lg">
            <div className="card-body">
              <Table columns={columns} dataSource={dataSource} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DebugPage;
