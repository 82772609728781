import React, { ReactElement, useState } from "react";
import { IHotelDetailFullInformation } from "../../../Models/HotelContent";
import { IRoom } from "../../../Models/RoomDetail";
import {
  IBilling,
  IMembership,
  IPayment,
  IPriceResponse,
} from "../../../Models/Checkout";
import { Product } from "../../../helpers/requestMembership";
import { IBadResponse } from "../../../Models/BadResponse";
import RoomInformation from "../StepForm/RoomInformation";
import BillingAddress from "../StepForm/BillingAddress";
import ErrorResult from "../../../Components/ResultMessage/WarningMessageComponent";
import PaymentForm from "../StepForm/PaymentForm";
import MembershipSection from "../StepForm/Membership";
import ButtonComplete from "../StepForm/ButtonComplete";
import { error, incompleteMembership } from "../Helpers/CheckoutMessage";
import { validEmail } from "../../../helpers/validationsInputs";
import SidebarForm from "../SidebarForm";
import { useViewport } from "../../../Context/useViewport";
import PolicySection from "./PolicySection";
import { Button } from "antd";
import TermsConditionsBox from "./TermsAndConditions";
import { ICardPolicy } from "../../../Models/SettingsModel";

interface props {
  nextStep: boolean;
  nationality: string;
  changeNacionality: (code: string) => void;
  roomDetail: IRoom | undefined;
  hotelDetail: IHotelDetailFullInformation | undefined;
  roomPriceError: IBadResponse | undefined;
  paymentInfo: IPayment;
  billingForm: IBilling;
  updateGuestsInfo: (
    firstName: string,
    lastName: string,
    email: string
  ) => void;
  updateSame: (objBillingForm: IBilling) => void;
  updateStepNext: () => void;
  updateBillingForm: (objBillingForm: IBilling) => void;
  updatePaymentForm: (objPaymentForm: IPayment) => void;
  setMembership: React.Dispatch<React.SetStateAction<IMembership | undefined>>;
  membership: IMembership | undefined;
  products: Product[];
  resetMembershipSelection: () => void;
  createMembership: boolean | undefined;
  updateMember: (create: boolean | undefined) => void;
  price: IPriceResponse | undefined;
  loadingConfirm: boolean;
  confirmCheckout: () => Promise<void>;
  otherOfferStateHandle?: () => void;
  membershipMandatory: boolean;
  cardFee: ICardPolicy | undefined;
}

const FirstStep = ({
  nextStep,
  nationality,
  changeNacionality,
  billingForm,
  updateGuestsInfo,
  updateSame,
  updateStepNext,
  updateBillingForm,
  updatePaymentForm,
  setMembership,
  membership,
  products,
  createMembership,
  updateMember,
  roomDetail,
  hotelDetail,
  roomPriceError,
  paymentInfo,
  price,
  loadingConfirm,
  confirmCheckout,
  resetMembershipSelection,
  otherOfferStateHandle,
  membershipMandatory,
  cardFee,
}: props): ReactElement => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [errorBill, setErrorsBill] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const { images } = roomDetail || {};
  const { name, providerName, contact, starRating, relevanceScore } =
    hotelDetail || {};
  const { width } = useViewport();
  const validateBillForm = (paymentInfo: IPayment): boolean => {
    const { issuer, number, cvv, nameoncard, expirymonth, expiryyear } =
      paymentInfo;

    return (
      issuer !== "" &&
      number !== "" &&
      cvv !== "" &&
      nameoncard !== "" &&
      expirymonth !== "" &&
      expiryyear !== ""
    );
  };

  const secondStepScreen = (): void => {
    setSecondStep(true);
  };

  const validatePaymentForm = (billingValues: IBilling): boolean => {
    const { contact, firstName, lastName } = billingValues;
    const { email, phone, address } = contact;
    const { line1, country, city, state, postalCode } = address;

    return (
      email !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      phone !== "" &&
      line1 !== "" &&
      postalCode !== "" &&
      country.name !== "" &&
      state.name !== "" &&
      city.name !== "" &&
      city.code !== ""
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoadingButton(true);
    setCheckAll(true);

    const isValidatBillForm = validateBillForm(paymentInfo);
    const isValidatPaymentForm = validatePaymentForm(billingForm);
    if (
      isValidatBillForm &&
      isValidatPaymentForm &&
      (createMembership !== undefined || membershipMandatory)
    ) {
      confirmCheckout();
      setLoadingButton(false);
    } else if (!isValidatBillForm || !isValidatPaymentForm) {
      setLoadingButton(false);
      error();
    } else if (createMembership === undefined && !membershipMandatory) {
      resetMembershipSelection();
      incompleteMembership();
      setLoadingButton(false);
    }

    setTimeout(() => {
      setCheckAll(false);
    }, 10);
  };

  return (
    <>
      <span>
        <span className="bold-letters">Almost done! </span>
        Enter your details and complete your booking now.
      </span>
      <RoomInformation
        image={
          roomDetail !== undefined && images && images?.length > 0
            ? images[0].url
            : hotelDetail?.images &&
              hotelDetail?.images.length > 0 &&
              hotelDetail?.images[0].links &&
              hotelDetail?.images[0].links.length > 0
            ? hotelDetail?.images[0].links[0].url
            : undefined
        }
        room={roomDetail}
        name={name}
        address={contact?.address}
        starRating={starRating}
        score={relevanceScore}
      />
      {width <= 1023 && (
        <div className="sidebar-form mt-5">
          <div className="aside_info">
            <div className="sticky_aside">
              {price && (
                <SidebarForm
                  paymentInfo={paymentInfo}
                  price={price}
                  membershipMandatory={membershipMandatory}
                  cardFee={cardFee}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <form name="complete-form" onSubmit={handleSubmit}>
        {roomPriceError === undefined ? (
          <>
            <BillingAddress
              providerName={providerName}
              nationality={nationality}
              changeNationality={changeNacionality}
              updateErrors={setErrorsBill}
              checkAll={checkAll}
              nextStep={nextStep}
              billing={billingForm}
              updateGuestsInfo={updateGuestsInfo}
              updateSame={updateSame}
              updateNext={updateStepNext}
              updateBilling={updateBillingForm}
              secondStep={secondStep}
              setSecondStep={setSecondStep}
              membershipMandatory={membershipMandatory}
            />
            {!nextStep &&
              roomPriceError === undefined &&
              membershipMandatory && (
                <div className="checkout-boxes">
                  <small>
                    By clicking next step I agree to the site{" "}
                    <a
                      href={`${process.env.REACT_APP_API_BASE_WP_URL}/privacy-policy/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy policy.
                    </a>
                  </small>
                  <div>
                    <Button
                      className="btn btn-primary-dp first-step-complete-button"
                      onClick={secondStepScreen}
                    >
                      Next step
                    </Button>
                  </div>
                </div>
              )}
          </>
        ) : (
          <ErrorResult
            message={roomPriceError.message}
            otherOfferStateHandle={otherOfferStateHandle}
          />
        )}
        {roomPriceError === undefined &&
          nextStep &&
          validEmail(billingForm.contact.email) && (
            <>
              <PaymentForm
                checkAll={checkAll}
                paymentInfo={paymentInfo}
                billingForm={billingForm}
                updatePayment={updatePaymentForm}
              />
              <MembershipSection
                setMembership={setMembership}
                membership={membership}
                products={products}
                createMembership={createMembership}
                updateMember={updateMember}
                membershipMandatory={membershipMandatory}
              />
              {price && <PolicySection price={price} cardFee={cardFee} />}
              {membershipMandatory && <TermsConditionsBox />}
              <ButtonComplete
                loading={loadingButton || loadingConfirm}
                membershipMandatory={membershipMandatory}
              />
            </>
          )}
      </form>
    </>
  );
};

export default FirstStep;
