import { Divider, Skeleton } from "antd";
import React, { ReactElement } from "react";

const filtersSkeleton = [1, 2, 3];

const SkeletonLoaderSideFilter = (): ReactElement => (
  <div className="skeleton-side-filter d-sm-none d-md-block">
    <h6 className="text-secondary text-center">Loading filters...</h6>
    {filtersSkeleton.map((ele, index) => (
      <div key={index}>
        <Skeleton active />
        <Divider />
      </div>
    ))}
  </div>
);
export default SkeletonLoaderSideFilter;
