import React, { useEffect, useState } from "react";
import { ISetting } from "../../../helpers/requestSetting";
import { Button, Col, Input, Modal, Row, Select, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface props {
  open: boolean;
  onCancel: () => void;
  setting: ISetting;
  onOk: (values: ISetting) => void;
}

type ProviderCommission = {
  providerId: string;
  commission: string;
  field: string;
};

const SettingsForm = ({ open, onCancel, onOk, setting }: props) => {
  const [settingValue, setsettingValue] = useState<ProviderCommission[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const paginationSize = 5;
  const [newSetting, setNewSetting] = useState<ProviderCommission>({
    providerId: "",
    commission: "",
    field: "",
  });

  const handleOk = async () => {
    const values = JSON.stringify(settingValue);
    const editedObject = {
      id: setting.id,
      name: setting.name,
      value: values,
    };
    await onOk(editedObject);
  };

  const handleChange = (index: number, prop: string, value: string) => {
    if (settingValue) {
      // Make a copy of the settingValue array
      const copyObj: ProviderCommission[] = [...settingValue];
      // Make a copy of the specific object you're modifying
      const updatedObj: ProviderCommission = { ...copyObj[index] };
      switch (prop) {
        case "providerId":
          updatedObj.providerId = value;
          break;
        case "commission":
          updatedObj.commission = value;
          break;
        case "field":
          updatedObj.field = value;
          break;
        default:
          break;
      }
      // Update the copy of the array with the modified object
      copyObj[index] = updatedObj;
      // Update the state with the new array
      setsettingValue(copyObj);
    }
  };

  const handleNew = (prop: string, value: string) => {
    const updatedObj: ProviderCommission = { ...newSetting };
    switch (prop) {
      case "providerId":
        updatedObj.providerId = value;
        break;
      case "commission":
        updatedObj.commission = value;
        break;
      case "field":
        updatedObj.field = value;
        break;
      default:
        break;
    }
    setNewSetting(updatedObj);
  };

  const saveNewRule = () => {
    if (settingValue) {
      const copyObj: ProviderCommission[] = [...settingValue];
      copyObj.push(newSetting);
      setsettingValue(copyObj);
    }
    setNewSetting({
      providerId: "",
      commission: "",
      field: "",
    });
  };

  const deleteSetting = (index: number) => {
    if (settingValue) {
      const copyObj: ProviderCommission[] = [...settingValue];
      copyObj.splice(index, 1);
      setsettingValue(copyObj);
    }
  };

  useEffect(() => {
    if (setting.name == "CommissionPercentages") {
      const comissionValues: ProviderCommission[] = JSON.parse(setting.value);
      setsettingValue(comissionValues);
    }
  }, [setting]);

  const getPageItems = () => {
    if (!settingValue) return [];
    const startIndex = (currentPage - 1) * paginationSize;
    const endIndex = startIndex + paginationSize;
    return settingValue.slice(startIndex, endIndex);
  };

  return (
    <Modal
      open={open}
      title={setting.name}
      onCancel={onCancel}
      style={{ minWidth: "30%" }}
      onOk={handleOk}
      footer={[
        <Button
          type="primary"
          disabled={
            newSetting.commission === "" ||
            newSetting.providerId === "" ||
            newSetting.field === ""
          }
          onClick={saveNewRule}
        >
          Add New Rule
        </Button>,
        <Button onClick={onCancel}>Cancel</Button>,
        <Button onClick={handleOk} type="primary">
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={!settingValue} size="large">
        <>
          {settingValue && settingValue.length > paginationSize && (
            <Row gutter={16} className="mt-3 mb-3 " justify="end">
              <Col span={24}>
                <Button
                  size="small"
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  {"<"}
                </Button>
                {` page ${currentPage}/${Math.ceil(
                  settingValue.length / paginationSize
                )}`}
                <Button
                  size="small"
                  style={{ marginLeft: "10px" }}
                  disabled={
                    currentPage ===
                    Math.ceil(settingValue.length / paginationSize)
                  }
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
          {getPageItems().map((item: ProviderCommission, index: number) => (
            <Row
              key={index}
              gutter={16}
              className="d-flex justify-content-center align-items-center mt-3 mb-3"
            >
              <Col span={2}>
                <a onClick={() => deleteSetting(index)}>
                  <FontAwesomeIcon icon={faTrash as IconProp} />
                </a>
              </Col>
              <Col span={10}>
                <Input
                  value={item.providerId}
                  onChange={(e) =>
                    handleChange(index, "providerId", e.target.value)
                  }
                />
              </Col>
              <Col span={6}>
                <Input
                  value={item.commission}
                  addonAfter="%"
                  onChange={(e) =>
                    handleChange(index, "commission", e.target.value)
                  }
                />
              </Col>
              <Col span={6}>
                <Select<string>
                  value={item.field}
                  style={{ width: "100%" }}
                  onChange={(e) => handleChange(index, "field", e)}
                  options={[
                    { value: "TotalRate", label: "TotalRate" },
                    { value: "BaseRate", label: "BaseRate" },
                  ]}
                />
              </Col>
            </Row>
          ))}

          <Row
            gutter={16}
            className="d-flex justify-content-center align-items-center mt-3 mb-3"
          >
            <Col span={12}>
              <Input
                value={newSetting.providerId}
                onChange={(e) => handleNew("providerId", e.target.value)}
              />
            </Col>
            <Col span={6}>
              <Input
                value={newSetting.commission}
                addonAfter="%"
                onChange={(e) => handleNew("commission", e.target.value)}
              />
            </Col>
            <Col span={6}>
              <Select<string>
                value={newSetting.field}
                style={{ width: "100%" }}
                onChange={(e) => handleNew("field", e)}
                options={[
                  { value: "TotalRate", label: "TotalRate" },
                  { value: "BaseRate", label: "BaseRate" },
                ]}
              />
            </Col>
          </Row>
        </>
      </Spin>
    </Modal>
  );
};

export default SettingsForm;
