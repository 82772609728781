import React from "react";
import { Steps } from "antd";

const steps = [
  {
    title: "Choose Room",
  },
  {
    title: "Guest & Payment Details",
  },
];

interface props {
  current: number;
}

const StepsLine = ({ current }: props) => (
  <Steps
    current={current}
    labelPlacement="vertical"
    size="small"
    items={steps}
  />
);

export default StepsLine;
