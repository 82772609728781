import React, { useContext } from "react";
import { Button, Form, Input, notification, Image } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { registerWithEmailAndPassword } from "../../../Auth/firebase";
import { formProps } from "../LoginForm";
import { getUsers, postUser } from "../../../helpers/requestUserAndRole";
import { SearchContext } from "../../../Context/context";
import { ISearch } from "../../../Models/Search";
import fbicon from "../../../img/facebook.png";
import googleicon from "../../../img/google.png";
import { IUsers } from "../../../Models/UsersModel";
import { RoutesPath } from "../../../Routes";

const validateMessages = {
  required: "Please input your ${label}",
  types: {
    email: "${label} is not a valid email!",
  },
};

const Register = ({ onChangeForm, auth }: formProps) => {
  const navigate = useNavigate();
  const { searchContent } = useContext<any | ISearch>(SearchContext);

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          uid: account.user.uid,
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          navigate(RoutesPath.bookings);
        } catch (err) {
          console.error(err);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          uid: account.user.uid,
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          navigate(RoutesPath.root);
        } catch (err) {
          console.error(err);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = async (values: any) => {
    const userCredential = await registerWithEmailAndPassword(
      values.user.email,
      values.user.password,
      auth
    );
    if (userCredential) {
      const obj = {
        uid: values.user.uid,
        userName: values.user.email,
        firstName: values.user.firstName,
        lastName: values.user.lastName || "",
        password: values.user.password,
        roleId: 0,
      };
      try {
        const response = await postUser(obj);
        if (response !== null)
          notification.success({
            message: "Succces Registred",
            description: "User registerd!",
          });
        navigate(RoutesPath.root);
      } catch (err) {
        console.error(err);
      }
    } else
      notification.error({
        message: "Error",
        description: "Error",
      });
  };

  return (
    <Form
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item name={["user", "firstName"]} rules={[{ required: true }]}>
        <Input placeholder="First Name" allowClear />
      </Form.Item>
      <Form.Item name={["user", "lastName"]}>
        <Input placeholder="Last Name" allowClear />
      </Form.Item>
      <Form.Item
        name={["user", "email"]}
        rules={[{ type: "email", required: true }]}
      >
        <Input placeholder="Email" allowClear />
      </Form.Item>
      <Form.Item
        name={["user", "password"]}
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password type="password" placeholder="Password" />
      </Form.Item>
      <Form.Item
        name={["user", "confirmpassword"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("user").password === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password type="password" placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item>
        <Button
          className="btn-success-dp btn-block btn-login"
          htmlType="submit"
        >
          Create Account
        </Button>
        <Button
          htmlType="button"
          className="btn-facebook"
          onClick={signInWithFacebook}
        >
          <div>
            <Image
              src={fbicon}
              preview={false}
              width={20}
              height={20}
              className="icon"
            />
            <div className="text">
              <span>Sign Up with Facebook</span>
            </div>
          </div>
        </Button>
        <Button
          htmlType="button"
          className="btn-google"
          onClick={signInWithGoogle}
        >
          <div>
            <Image
              src={googleicon}
              preview={false}
              width={20}
              height={20}
              className="icon"
            />
            <div className="text">
              <span>Sign Up with Google</span>
            </div>
          </div>
        </Button>
        <div className="d-flex py-3">
          <small className="pt-2">Already have an account?</small>
          <Button
            type="link"
            htmlType="button"
            onClick={() => onChangeForm("login")}
          >
            Sign in
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default Register;
