import React, { ReactElement } from "react";
import moment from "moment";
import { IFakePrice } from "../../../../../../../Models/GooglePrice";
import { IPolicy } from "../../../../../../../Models/HotelContent";
import RoomPolicy from "../../../RoomPolicy";
import {
  IDailyRate,
  IRate,
  IRule,
} from "../../../../../../../Models/RoomDetail";
import { getMinimalRestrictionDate } from "../../../../../../../helpers/validationsInputs";
import { sumAmounts } from "../../../../../../../helpers/ratesHandler";
import { useSearchParams } from "react-router-dom";

interface RowRoomCardProps {
  ratesData: any[] | undefined;
  checkPolicies: (policy: any, refundable: boolean) => void;
  transformDate: (checkDate: Date) => moment.Moment;
  modalObject: { policyObject: never[]; refundable: boolean };
  hotelDetailPolicy: IPolicy[] | undefined;
  setViewPolicies: React.Dispatch<React.SetStateAction<boolean>>;
  viewPolicies: boolean;
  fakePrice: IFakePrice | undefined;
  id: string;
  checkinAsDate: moment.Moment;
  checkoutAsDate: moment.Moment;
  baseRatePerDay: (rate: IRate) => number;
  showDailyRates: (
    dailyRates: IDailyRate[],
    currency: string,
    policies: { text: string; type: string }[]
  ) => void;
  handleBook: (rateId: string, rate: IRate) => void;
  showNightlyRate: boolean;
  sFakePrice: IFakePrice | undefined;
}

const RowRoomCard = ({
  ratesData,
  checkPolicies,
  transformDate,
  modalObject,
  hotelDetailPolicy,
  setViewPolicies,
  viewPolicies,
  fakePrice,
  id,
  checkinAsDate,
  checkoutAsDate,
  baseRatePerDay,
  showDailyRates,
  handleBook,
  showNightlyRate,
  sFakePrice,
}: RowRoomCardProps): ReactElement => {
  const [searchParams] = useSearchParams();
  const cancellationPenaltyStart = (cancellationRules: IRule[]) => {
    const firstObjectWithValueAboveOne = cancellationRules.find(
      (rule) => rule.estimatedValue > 1
    );
    if (firstObjectWithValueAboveOne) {
      return (
        <div className="before_date-style">
          <span>
            Before{" "}
            {moment(transformDate(firstObjectWithValueAboveOne.start))
              .format("MMMM")
              .slice(0, 3)}{" "}
            {moment(transformDate(firstObjectWithValueAboveOne.start)).format(
              "DD"
            )}
            ,{" "}
            {moment(transformDate(firstObjectWithValueAboveOne.start)).format(
              "YYYY"
            )}
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 no-padding">
      {ratesData &&
        ratesData.length > 0 &&
        ratesData.map((rate: IRate, index) => (
          <div className="shadow_prices no-padding" key={index}>
            <div className={`body p-3 ps-1 pe-1`} style={{}}>
              <div className="room-policies-wrapper">
                {rate.refundable &&
                  rate.cancellationPolicies &&
                  rate.cancellationPolicies.length > 0 &&
                  rate.cancellationPolicies[0].rules &&
                  rate.cancellationPolicies[0].rules.length > 0 &&
                  cancellationPenaltyStart(rate.cancellationPolicies[0].rules)}

                {searchParams.get("s_roomIdentifierKey") != null &&
                rate.additionalInformation &&
                rate.additionalInformation[0].text ==
                  searchParams.get("s_roomIdentifierKey") &&
                (searchParams.get("context")?.toLowerCase() ?? "") ===
                  "google" &&
                rate.baseRate ==
                  parseFloat(searchParams.get("s_price") || "0") ? (
                  <div className="google-mark">
                    <img
                      src="https://storage.googleapis.com/imagebucketdealpeak/google-icon-2048x2048-pks9lbdv.png"
                      alt="Google Logo"
                      className="google-mark-icon me-2"
                    />
                    <p className="centered-text">As seen on Google</p>
                  </div>
                ) : (
                  searchParams.get("s_roomIdentifierKey") == null &&
                  searchParams.get("roomIdentifierKey") != null &&
                  rate.additionalInformation &&
                  rate.additionalInformation[0].text ==
                    searchParams.get("roomIdentifierKey") &&
                  (searchParams.get("context")?.toLowerCase() ?? "") ===
                    "google" &&
                  rate.baseRate ==
                    parseFloat(searchParams.get("price") || "0") && (
                    <div className="google-mark">
                      <img
                        src="https://storage.googleapis.com/imagebucketdealpeak/google-icon-2048x2048-pks9lbdv.png"
                        alt="Google Logo"
                        className="google-mark-icon me-2"
                      />
                      <p className="centered-text">As seen on Google</p>
                    </div>
                  )
                )}
                {searchParams.get("s_roomIdentifierKey") != null &&
                rate.additionalInformation &&
                rate.additionalInformation[0].text ==
                  searchParams.get("s_roomIdentifierKey") &&
                (searchParams.get("context")?.toLowerCase() ?? "") ===
                  "trivago" &&
                rate.baseRate ==
                  parseFloat(searchParams.get("s_price") || "0") ? (
                  <div className="google-mark">
                    <img
                      src="https://storage.googleapis.com/imagebucketdealpeak/trivago-logo.png"
                      alt="Google Logo"
                      className="google-mark-icon me-2"
                    />
                    <p className="centered-text">As seen on Trivago</p>
                  </div>
                ) : (
                  searchParams.get("s_roomIdentifierKey") == null &&
                  searchParams.get("roomIdentifierKey") != null &&
                  rate.additionalInformation &&
                  rate.additionalInformation[0].text ==
                    searchParams.get("roomIdentifierKey") &&
                  (searchParams.get("context")?.toLowerCase() ?? "") ===
                    "trivago" &&
                  rate.baseRate ==
                    parseFloat(searchParams.get("price") || "0") && (
                    <div className="google-mark">
                      <img
                        src="https://storage.googleapis.com/imagebucketdealpeak/trivago-logo.png"
                        alt="Google Logo"
                        className="google-mark-icon me-2"
                      />
                      <p className="centered-text">As seen on Trivago</p>
                    </div>
                  )
                )}
                <button
                  className="btn btn-link fw-bold"
                  type="button"
                  onClick={() =>
                    checkPolicies(rate.cancellationPolicies, rate.refundable)
                  }
                >
                  {rate.refundable ? "Free Cancellation" : "Non Refundable"}
                </button>
                <small className="nightly-rate">
                  {showNightlyRate && (
                    <>
                      {rate.additionalInformation &&
                      rate.additionalInformation[0].text ==
                        searchParams.get("roomIdentifierKey") &&
                      rate.baseRate ==
                        parseFloat(searchParams.get("price") || "0") &&
                      fakePrice !== undefined &&
                      fakePrice.price > 0 &&
                      fakePrice.roomId === id ? (
                        <>
                          Nightly Rate: $
                          {Math.trunc(
                            fakePrice.price +
                              fakePrice.tax +
                              fakePrice.fee /
                                Math.abs(
                                  checkinAsDate.diff(checkoutAsDate, "days")
                                )
                          ) + " incl. taxes and fees"}
                        </>
                      ) : rate.additionalInformation &&
                        rate.additionalInformation[0].text ==
                          searchParams.get("s_roomIdentifierKey") &&
                        rate.baseRate ==
                          parseFloat(searchParams.get("s_price") || "0") &&
                        sFakePrice !== undefined &&
                        sFakePrice.price > 0 &&
                        sFakePrice.roomId === id ? (
                        <>
                          Nightly Rate: $
                          {Math.trunc(
                            sFakePrice.price +
                              sFakePrice.tax +
                              sFakePrice.fee /
                                Math.abs(
                                  checkinAsDate.diff(checkoutAsDate, "days")
                                )
                          ) + " incl. taxes and fees"}
                        </>
                      ) : sumAmounts(
                          rate,
                          Math.abs(
                            checkinAsDate.diff(checkoutAsDate, "days")
                          ) ?? 1
                        ) > 0 ? (
                        `Nightly Rate: $${sumAmounts(
                          rate,
                          Math.abs(
                            checkinAsDate.diff(checkoutAsDate, "days")
                          ) ?? 1
                        ).toFixed(2)} incl. taxes and fees`
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </small>
              </div>
              <div>
                <span>
                  <RoomPolicy
                    policyObject={modalObject.policyObject}
                    refundable={modalObject.refundable}
                    hotelDetailPolicy={hotelDetailPolicy}
                    setViewPolicies={setViewPolicies}
                    viewPolicies={viewPolicies}
                    minimalRestrictDate={getMinimalRestrictionDate(
                      rate.policies
                    )}
                  />
                </span>
              </div>
              <div className="room_price-per_night">
                <span className="price-label">
                  <small className="currency-label">
                    {rate.currency === "USD" ? "$" : `${rate.currency}`}
                  </small>{" "}
                  {baseRatePerDay(rate)}
                </span>
                <small className="currency-label">price per night</small>
                {rate.dailyRates && rate.dailyRates.length > 0 ? (
                  <>
                    <span className="other_info">
                      {searchParams.get("s_roomIdentifierKey") != null &&
                        rate.additionalInformation &&
                        rate.additionalInformation[0].text ==
                          searchParams.get("s_roomIdentifierKey") &&
                        rate.baseRate ==
                          parseFloat(searchParams.get("s_price") || "0") && (
                          <div className="google-mark">
                            <div className="text-container">
                              <h5>
                                {Math.trunc(
                                  parseFloat(searchParams.get("s_price") || "0")
                                ) <
                                  Math.trunc(
                                    parseFloat(searchParams.get("price") || "0")
                                  ) &&
                                  `$ ${Math.trunc(
                                    Math.trunc(
                                      parseFloat(
                                        searchParams.get("price") || "0"
                                      )
                                    ) /
                                      Math.abs(
                                        checkinAsDate.diff(
                                          checkoutAsDate,
                                          "days"
                                        )
                                      )
                                  )}`}
                              </h5>
                              <div className="mask-svg">
                                <svg
                                  width="100%"
                                  height="100%"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                >
                                  <line
                                    x1="0"
                                    y1="0"
                                    x2="100%"
                                    y2="100%"
                                    stroke="black"
                                    stroke-width="4"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}

                      {searchParams.get("roomIdentifierKey") != null &&
                        rate.additionalInformation &&
                        rate.additionalInformation[0].text ==
                          searchParams.get("roomIdentifierKey") &&
                        rate.baseRate ==
                          parseFloat(searchParams.get("price") || "0") && (
                          <div className="google-mark">
                            <div className="text-container">
                              <h5>
                                {Math.trunc(
                                  parseFloat(searchParams.get("price") || "0")
                                ) <
                                  Math.trunc(
                                    parseFloat(
                                      searchParams.get("s_price") || "0"
                                    )
                                  ) &&
                                  `$ ${Math.trunc(
                                    Math.trunc(
                                      parseFloat(
                                        searchParams.get("s_price") || "0"
                                      )
                                    ) /
                                      Math.abs(
                                        checkinAsDate.diff(
                                          checkoutAsDate,
                                          "days"
                                        )
                                      )
                                  )}`}
                              </h5>
                              <div className="mask-svg">
                                <svg
                                  width="100%"
                                  height="100%"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                >
                                  <line
                                    x1="0"
                                    y1="0"
                                    x2="100%"
                                    y2="100%"
                                    stroke="black"
                                    stroke-width="4"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}
                    </span>
                    <button
                      type="button"
                      onClick={() =>
                        showDailyRates(
                          rate.dailyRates,
                          rate.currency,
                          rate.policies
                        )
                      }
                      className="btn btn-link other_details"
                    >
                      View Details
                    </button>
                  </>
                ) : (
                  <span className="other_info">price per night</span>
                )}
              </div>
              <button
                type="button"
                className="btn btn-success-dp px-3 rounded-pill"
                onClick={() => handleBook(rate.id, rate)}
                aria-hidden="true"
              >
                <span>Book Now</span>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default RowRoomCard;
