import React from "react";

export const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.position = "";
    document.body.style.overflow = "";
    document.body.className = "";
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};
