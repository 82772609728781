import { AxiosResponse } from "axios"; // Import the AxiosResponse type or use your preferred HTTP client
import APIClient from "../Components/Services/APIClient";
import {
  IGenericMessage,
  ISendMessage,
  IVerificationRequest,
  IVerificationResponse,
} from "../Models/TwoFactorAuth";
const client = new APIClient();

export const sendCode = async (
  phoneNumber: string
): Promise<ISendMessage | IGenericMessage > => {
  try {
    const response: AxiosResponse<ISendMessage> = await client.client.post(
      "CodeValidator/SendCode",
      {
        to: phoneNumber,
      }
    );
    if (response.data && response.status === 200) {
      return response.data;
    } else {
      return { message: "Failed to send code", status: "error" };
    }
  } catch (error) {
    console.error("Error sending code:", error);
    return { message: "An error occurred", status: "error" };
  }
};

export const verifyCode = async (
  verificationRequest: IVerificationRequest
): Promise<IVerificationResponse> => {
  try {
    const response: AxiosResponse<IVerificationResponse> =
      await client.client.post(
        "CodeValidator/ValidateCode",
        verificationRequest
      );
    if (response.data && response.status === 200) {
      return response.data;
    } else {
      return { message: "Failed to send code", isValid: false };
    }
  } catch (error) {
    console.error("Error sending code:", error);
    return { message: "An error occurred", isValid: false };
  }
};
