import React, { ReactElement } from "react";
import { Col } from "antd";
import { useNavigate } from "react-router-dom";
import {
  IDescription,
  IFacilityGroup,
  IImage,
  INearByAttraction,
  IPolicy,
} from "../../../Models/HotelContent";
import { IRate, IRecommendation, IRoom } from "../../../Models/RoomDetail";
import AboutDetail from "./About";
import Facilities from "./Facilities";
import Reviews from "./Reviews";
import Rooms from "./Rooms";
import CallActionCall from "./CallActionTag";
import AlertCovid from "./AlertCovid";
import AmenitiesSectionRoomDetail from "./Amenities";
import InformationSection from "./InformationSection";
import MapSection from "./MapSection";
import { IWebConfig } from "../../../Models/SettingsModel";
import NearByLocations from "../../../Components/NearByLocations";
import { IFakePrice } from "../../../Models/GooglePrice";
import { IReviews } from "../../../Models/Review";

interface BodycomponentsProps {
  correlationId: string;
  deeplink: string | undefined;
  coordenates?: { lat: string; long: string };
  rooms?: Array<IRoom>;
  images: IImage[] | undefined;
  facilities?: IFacilityGroup[] | undefined;
  address?: {
    line1: string;
    line2: string | null;
    city: { code: string | null; name: string };
    state: { code: string | null; name: string };
    country: { code: string | null; name: string };
    postalCode: string;
  };
  settings: IWebConfig;
  rates: IRate[];
  recommendation: Array<IRecommendation>;
  nameHotel?: string;
  starRating?: string;
  descriptionText?: IDescription[];
  nearByAttractions: INearByAttraction[];
  fakePrice: IFakePrice | undefined;
  sFakePrice: IFakePrice | undefined;
  policy?: IPolicy[];
  reviews: IReviews | undefined;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setcheckoutUrl: React.Dispatch<React.SetStateAction<string>>;
  setSelectedRate: React.Dispatch<React.SetStateAction<IRate | undefined>>;
  showTotalNightly: boolean;
  isIpExcempt:boolean;
}

const BodyComponents = ({
  correlationId,
  deeplink,
  facilities,
  rooms,
  address,
  coordenates,
  images,
  rates,
  recommendation,
  nameHotel,
  starRating,
  settings,
  descriptionText,
  nearByAttractions,
  fakePrice,
  policy,
  reviews,
  setOpenDrawer,
  setcheckoutUrl,
  setSelectedRate,
  showTotalNightly,
  sFakePrice,
  isIpExcempt,
}: BodycomponentsProps): ReactElement => {
  const navigate = useNavigate();
  return (
    <div className="container mt-3" id="gallery-detail-hotel">
      <div className="row">
        {rates?.length < 1 && <CallActionCall />}
        <Col lg={24}>
          <AboutDetail
            titleHotel={nameHotel || undefined}
            starRating={starRating}
            address={address}
          />
          <Facilities facilities={facilities} />
          <AlertCovid />
          <Rooms
            setSelectedRate={setSelectedRate}
            setcheckoutUrl={setcheckoutUrl}
            setOpenDrawer={setOpenDrawer}
            correlationId={correlationId}
            deeplink={deeplink}
            rooms={rooms}
            rates={rates}
            recommendations={recommendation}
            defaultImage={images}
            fakePrice={fakePrice}
            hotelDetailPolicy={policy}
            showTotalNightly={showTotalNightly}
            sFakePrice={sFakePrice}
            isIpExcempt={isIpExcempt}
          />
          <Reviews hidden={!reviews} reviews={reviews} />
          <AmenitiesSectionRoomDetail facilities={facilities} />
          <InformationSection descriptionText={descriptionText} />
          {settings.REACT_APP_GOOGLE_MAPS_KEY && (
            <div
              className={`${
                nearByAttractions && nearByAttractions.length > 0
                  ? "hotel-detail_location"
                  : ""
              }`}
            >
              <MapSection
                coordenates={coordenates}
                address={address}
                apiKey={settings.REACT_APP_GOOGLE_MAPS_KEY}
              />
              {nearByAttractions && nearByAttractions.length > 0 && (
                <div className="mt-4">
                  <NearByLocations nearByAttractions={nearByAttractions} />
                </div>
              )}
            </div>
          )}
          <div className="d-grid">
            <button
              className="btn btn-primary back-button col-4 align-self-center offset-4 "
              type="button"
              onClick={() => navigate(-1)}
            >
              Back to previous screen
            </button>
          </div>
        </Col>
      </div>
    </div>
  );
};
export default BodyComponents;
