import React, { ReactElement } from "react";

interface TitleFilterProps {
  title: string;
}

const TitleFilter = ({ title }: TitleFilterProps): ReactElement => (
  <h6>{title}</h6>
);
export default TitleFilter;
