import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "antd";
import React, { ReactElement } from "react";

const CallActionCall = (): ReactElement => (
  <Col lg={24} className="bg-light p-2 text-center rounded-pill">
    <div className="container">
      <div className="row">
        <Col lg={24}>
          <FontAwesomeIcon icon={faPhone as IconProp} className="me-3" />
          <span>
            <b>Sold Out</b> Update your travel dates call us for special
            discounts at other select hotels
          </span>
        </Col>
      </div>
    </div>
  </Col>
);
export default CallActionCall;
