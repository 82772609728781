import { Checkbox, message } from "antd";
import React, { useState } from "react";
import { priceGuaranteedSubmit } from "../../helpers/requestBookings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

interface PriceMatchComponentProps {
  bookID: string;
  setpriceClaim: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  priceClaim: boolean | undefined;
  setImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
  imageFiles: File[];
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PriceMatchComponent = ({
  bookID,
  setpriceClaim,
  priceClaim,
  setImageFiles,
  imageFiles,
  setOpenModal,
}: PriceMatchComponentProps) => {
  const onChangePriceClaim = (value: boolean) => {
    setpriceClaim(value);
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (Array.from(files).length >= 10) {
        message.error("Max of 10 images reached");
      }
      const newFiles = Array.from(files).slice(0, 10);
      setImageFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      if (imageFiles.length < 1) {
        message.error("Please upload some images before submitting a claim");
        return;
      }

      imageFiles.forEach((file) => {
        formData.append("images", file);
      });

      await priceGuaranteedSubmit(formData, bookID);

      setImageFiles([]);
      setOpenModal(false);
      message.success("Email sent we will contact you shortly");
    } catch (error) {
      message.error(
        "Something went wrong please contact us via sales@dealpek.com"
      );
    }
  };
  return (
    <div>
      <div className="d-flex flex-row justify-content-between ">
        <div>
          <Checkbox
            onChange={() => onChangePriceClaim(true)}
            checked={priceClaim}
          >
            <b> Yes</b>, I want to submit a claim
          </Checkbox>
          <Checkbox
            onChange={() => onChangePriceClaim(false)}
            checked={!priceClaim}
          >
            <b>No</b>, I just want to cancel
          </Checkbox>
        </div>
        {priceClaim && (
          <div>
            <input
              type="file"
              id="file-upload"
              onChange={handleImageChange}
              accept="image/*"
              multiple
              style={{ display: "none" }}
            />
            <label
              htmlFor="file-upload"
              className="btn btn-info  text-white text-uppercase text-sm"
              style={{
                cursor: "pointer",
                fontSize: 12,
                backgroundColor: "#1890ff",
              }}
            >
              <FontAwesomeIcon icon={faUpload as IconProp} /> Upload Images
            </label>
            <p className="text-center">{imageFiles.length} images attached</p>
          </div>
        )}
      </div>
      <p style={{ margin: 0, textAlign: "justify" }}>
        <b>Found a better price?</b> Great! we will match it and you can keep
        your original reservation. You can submit a Price Guarantee claim from
        the moment you make your Price Guarantee Eligible Booking up to 24 hours
        before the local hotel check-in time on the day before your arrival. To
        make a claim, you must capture screen shots of the exact same room type,
        hotel, and bookings dates. This information must be emailed to
        <b>sales@dealpeak.com.</b> The lower price must be available for booking
        when you submit your claim.
      </p>
      <div className="text-end mt-4">
        <div>
          {priceClaim && (
            <button
              className="btn btn-info text-white text-uppercase"
              style={{ fontSize: 12, backgroundColor: "#1890ff" }}
              onClick={handleUpload}
            >
              Submit Claim
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceMatchComponent;
