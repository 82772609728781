import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Divider, Row, Space } from "antd";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { IUsersTransform } from "../../../Models/UsersModel";

interface BookCardProps {
  user: IUsersTransform;
  editAccount: (id: number) => void;
  userIndex: number;
}

const AccountCard = ({
  user,
  editAccount,
  userIndex,
}: BookCardProps): ReactElement => {
  const navigate = useNavigate();
  return (
    <Card className="card rounded-dp shadow-lg booking-card">
      <Row justify="space-between">
        <Col style={{ marginLeft: "10%" }}>
          <h4 style={{ textAlign: "left" }}>{user.firstName}</h4>
        </Col>
        <Col>
          <Space size="small">
            <button
              type="button"
              className="btn btn-primary-dp-xs"
              onClick={() => navigate(`bookings?user=${user.userId}`)}
            >
              <FontAwesomeIcon icon={faSearch as IconProp} className="me-2" />
              Bookings
            </button>

            <button
              type="button"
              onClick={() => editAccount(userIndex)}
              className="btn btn-success-dp-xs"
            >
              <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
              Edit
            </button>
          </Space>
        </Col>
      </Row>
      <Divider orientation="left">ID</Divider>
      <p className="booking-card-info">{user.key}</p>
      <Divider orientation="left">Last Name</Divider>
      <p className="booking-card-info">{user.lastName}</p>
      <Divider orientation="left">User Email</Divider>
      <p className="booking-card-info">{user.userId}</p>
      <Divider orientation="left">Role</Divider>
      <p className="booking-card-info">{user.rol}</p>
    </Card>
  );
};
export default AccountCard;
