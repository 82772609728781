import React, { useEffect } from "react";
import BenefistVipSection from "./BenefitsSection";
import BookYourDiscount from "./BookYourDiscount";
import HeroBannerMembership from "./HeroBanner";
import MenuMembership from "./MenuMembership";

const MembershipPage = () => {
  useEffect(() => {
    window._CTZ = {
      enabled: false,
      verticals: {
        hotel: {
          active: false,
          search: {
            city: undefined,
            province: undefined,
            countryCode: undefined,
            checkInDate: undefined,
            checkOutDate: undefined,
          },
        },
      },
    };
  });
  return (
    <>
      <HeroBannerMembership />
      <MenuMembership />
      <BenefistVipSection />
      <BookYourDiscount />
    </>
  );
};
export default MembershipPage;
