import React, { useEffect, useState } from "react";
import { ISetting } from "../../../helpers/requestSetting";
import { Button, Col, Modal, Row, Input, Divider, Form, Tooltip } from "antd";
import { Rule } from "antd/lib/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface props {
  open: boolean;
  setting: ISetting;
  onCancel: () => void;
  onOk: (values: ISetting) => void;
}

const TwoFaIpListSettingForm = ({
  open,
  onCancel,
  onOk,
  setting,
}: props): React.ReactElement => {
  const [settingValue, setsettingValue] = useState<string[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (setting && setting.value && setting.name === "Exempt2faIpList" ) {
      const ipList: string[] = JSON.parse(setting.value);
      setsettingValue(ipList);
    }
  }, [setting]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSaveChange = async () => {
    form.resetFields();
    const jsonValue = JSON.stringify(settingValue);
    const newSetting: ISetting = {
      id: setting.id,
      name: setting.name,
      value: jsonValue,
    };
    onOk(newSetting);
  };

  const handleDelete = (index: number): void => {
    if (index < 0 && !settingValue) return;

    const copyObj: string[] = [...settingValue];
    copyObj.splice(index, 1);
    setsettingValue(copyObj);
  };

  const handleChange = (index: number, value: string): void => {
    if (index < 0) return;
    const copyObj: string[] = [...settingValue];
    copyObj[index] = value;
    setsettingValue(copyObj);
  };

  const handleAdd = () => {
    const ipValue = form.getFieldValue("ipAddress");
    const isValid = validateIpFormat(ipValue);
    if (!ipValue || !isValid) return;
    const copyObj: string[] = [...settingValue];
    copyObj.unshift(ipValue);
    setsettingValue(copyObj);
    form.resetFields();
  };

  const ipv4Pattern: RegExp = /^(\d{1,3}\.){3}\d{1,3}$/;
  const ipv6Pattern: RegExp =
    /^([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,7}:([0-9a-fA-F]{1,4})?$/;
  const customPattern: RegExp =
    /^(?:\*|[0-9]{1,3})\.(?:\*|[0-9]{1,3})\.(?:\*|[0-9]{1,3})\.(?:\*|[0-9]{1,3})$/;
  const ipWildcardRegex: RegExp =
    /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?|\*)(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?|\*)){3}|(?:[a-fA-F0-9]{1,4}|\*):(?:(?:[a-fA-F0-9]{1,4}|\*):)*(?:[a-fA-F0-9]{1,4}|\*))$/;

  // Custom validator function to check if the input value matches either IPv4 or IPv6 pattern
  const validatorIpAddress = (_: any, value: string): Promise<void> => {
    const isValid = validateIpFormat(value);
    return isValid
      ? Promise.resolve()
      : Promise.reject("Please input a valid IPv4 or IPv6 address");
  };
  const validateIpFormat = (value: string) => {
    if (
      value &&
      !ipv4Pattern.test(value) &&
      !ipv6Pattern.test(value) &&
      !customPattern.test(value) &&
      !ipWildcardRegex.test(value)
    ) {
      return false;
    }
    return true;
  };

  const ipAddressRules: Rule[] = [
    { required: true, message: "Please input the IP address" },
    { validator: validatorIpAddress }, // Add the custom validator
  ];

  return (
    <Modal
      onCancel={handleCancel}
      open={open}
      title={setting.name}
      footer={[
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button onClick={handleSaveChange} type="primary">
          Save Changes
        </Button>,
      ]}
    >
      <>
        <h5>Add new ip</h5>
        <Form form={form} autoComplete="off">
          <Row
            gutter={16}
            className="d-flex justify-content-center align-items-center mt-3 mb-3"
          >
            <Col xs={16} sm={12} md={18} lg={18} xl={18}>
              <Tooltip title="ipv4 or ipv6 format are accepted">
                <Form.Item name="ipAddress" rules={ipAddressRules}>
                  <Input placeholder="192.168.200.10" />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={8} sm={8} md={6} lg={6} xl={6}>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="config-container">
          {settingValue &&
            settingValue.map((item: string, index: number) => (
              <Row
                key={index}
                gutter={16}
                className="d-flex justify-content-center align-items-center mt-3 mb-3 "
              >
                <Col xs={16} sm={16} md={2} lg={2} xl={2}>
                  <a onClick={() => handleDelete(index)}>
                    <FontAwesomeIcon icon={faTrash as IconProp} />
                  </a>
                </Col>
                <Col xs={16} sm={12} md={18} lg={18} xl={18}>
                  <Input
                    value={item}
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(index, e.currentTarget.value)}
                  />
                </Col>
                <Divider />
              </Row>
            ))}
        </div>
      </>
    </Modal>
  );
};

export default TwoFaIpListSettingForm;
