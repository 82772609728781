export const onlyLetters = (value: string) =>
  value.replace(/[0-9\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, "");

export const onlyNumbers = (value: string) =>
  value.replace(/[a-zA-Z\s!@#$¡¿%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, "");

export const validEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
export const removeWhiteSpaces = (str: string) => {
  return str.replace(/\s/g, "");
};

export const formatDateStringWithAMPM = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const date = new Date(dateString);
  return date.toLocaleString(undefined, options);
};

export const camelCaseToNormalString = (
  inputString: string | null | undefined
) => {
  if (!inputString || inputString.length < 1) return "";
  const normalString = inputString.replace(/([a-z])([A-Z0-9])/g, "$1 $2");
  return (
    normalString.charAt(0).toUpperCase() + normalString.slice(1).toLowerCase()
  );
};

export const getMinimalRestrictionDate = (
  policies: { text: string; type: string }[]
) => {
  if (policies && policies.length > 0) {
    const datePattern = /\d{2}\/\d{2}\/\d{4}/;
    const restrictionPolicy = policies.filter(
      (policy: any) => policy.type === "cancellationRestrictionPolicy"
    );
    if (restrictionPolicy && restrictionPolicy.length > 0) {
      const dateStartRestriction = restrictionPolicy[0].text.match(datePattern);
      if (dateStartRestriction && dateStartRestriction.length > 0) {
        return dateStartRestriction[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  return null;
};
