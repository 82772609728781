export const addClickTripz = () => {
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("charset", "UTF-8");
  script.setAttribute("data-publisher", "ba2b9656214045dbba782ef7eb172b06");
  script.setAttribute("src", "https://static.clicktripz.com/tag.js");

  document.body.appendChild(script);
  return script;
};

export const enableTidioChat = () => {
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute(
    "src",
    "//code.tidio.co/xdqamggqmgxsrfbuajapfwowvqnbmhlm.js"
  );
  document.body.appendChild(script);
};

export const addGtag739 = () => {
  const element = document.querySelector("body");
  const script = document.createElement("script");
  script.src = "https://googletagmanager.com/gtag/js?id=AW-739481900";
  script.async = true;
  element?.appendChild(script);
  return script;
};

export const addNoFraud = () => {
  const element = document.querySelector("body");
  const script = document.createElement("script");
  script.src = "https://services.nofraud.com/js/32690/customer_code.js";
  script.async = true;
  element?.appendChild(script);
  return script;
};

export const removeClickTripz = (script: Node) => {
  document.body.removeChild(script);
};

export const addWithinTag = (
  transactionId: string,
  value: string,
  hotelId: string,
  startDate: string,
  endDate: string
) => {
  if (!document.querySelector('script[data-custom-id="purchase-script"]')) {
    const script = document.createElement("script");
    script.dataset.customId = "purchase-script";

    script.innerHTML = `
      gtag('event', 'purchase', {
        'send_to': [
          'G-5GDT3S45MV',
          'HA-1508011347'
        ],
        'transaction_id': '${transactionId}',
        'value': '${value}',
        'currency': 'USD',
        'items': [{
          'id': '${hotelId}',
          'start_date': '${startDate}',
          'end_date': '${endDate}'
        }]
      });
    `;

    document.head.appendChild(script);
  }

  if (
    !document.querySelector(
      'script[data-custom-id="purchase-script-AW-10864491212"]'
    )
  ) {
    const scriptAW = document.createElement("script");
    scriptAW.dataset.customId = "purchase-script-AW-10864491212";
    scriptAW.innerHTML = `
      gtag('event', 'purchase', {
        'send_to': [
        'AW-10864491212/SbWxCPnegIMYEMz1y7wo',
        'HA-1508011347'
      ],
      'transaction_id': '${transactionId}',
      'value': '${value}',
      'currency': 'USD',
      'items': [{
        'id': '${hotelId}',
        'start_date': '${startDate}',
        'end_date': '${endDate}'
      }]
    });`;
    document.head.appendChild(scriptAW);
  }
};

export const refundEventTag = (
  transactionId: string,
  value: string,
  hotelId: string,
  startDate: string,
  endDate: string
) => {
  if (!document.querySelector('script[data-custom-id="refund-script"]')) {
    const script = document.createElement("script");
    script.dataset.customId = "refund-script";

    script.innerHTML = `
      gtag('event', 'refund', {
        'send_to': [
          'G-5GDT3S45MV',
          'HA-1508011347'
        ],
        'transaction_id': '${transactionId}',
        'value': '${value}',
        'currency': 'USD',
        'items': [{
          'id': '${hotelId}',
          'start_date': '${startDate}',
          'end_date': '${endDate}'
        }]
      });
    `;

    document.head.appendChild(script);
  }

  if (
    !document.querySelector(
      'script[data-custom-id="purchase-script-AW-10864491212"]'
    )
  ) {
    const scriptAW = document.createElement("script");
    scriptAW.dataset.customId = "purchase-script-AW-10864491212";
    scriptAW.innerHTML = `
      gtag('event', 'refund', {
        'send_to': [
        'AW-10864491212/SbWxCPnegIMYEMz1y7wo',
        'HA-1508011347'
      ],
      'transaction_id': '${transactionId}',
      'value': '${value}',
      'currency': 'USD',
      'items': [{
        'id': '${hotelId}',
        'start_date': '${startDate}',
        'end_date': '${endDate}'
      }]
    });`;
    document.head.appendChild(scriptAW);
  }
};
