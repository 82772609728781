import { Col, Row } from "antd";
import React from "react";
import CardBookDiscount from "./CardBookDiscount";
import discount1 from "../../../img/discount-1.png";
import discount2 from "../../../img/discount-2.png";
import discount3 from "../../../img/discount-3.png";
import discount4 from "../../../img/discount-4.png";
import discount5 from "../../../img/discount-5.png";
import discount6 from "../../../img/discount-6.png";
import hotelIcon from "../../../img/hotel-icon.png";
import flightIcon from "../../../img/flight-icon.png";
import carIcon from "../../../img/car-icon.png";
import AttractionIcon from "../../../img/attraction-icon.png";
import cruisesIcon from "../../../img/cruises-icon.png";
import vacationIcon from "../../../img/vacation-icon.png";

const discountsData = [
  {
    img: discount1,
    icon: hotelIcon,
    title: "Hotels",
    content: `Want to book a hotel for your next trip? Find cheap hotels in all the top business and leisure destinations! From first-rate luxury hotels and elegant business hotels to mind-range hotels and budgets motels, we have it all. Whether you are a business traveler or a vacationer, find hotel deals with us.`,
  },
  {
    img: discount2,
    icon: flightIcon,
    title: "Flights",
    content: `We offer incredible discounts on flights all over the world, so whether you're looking for a weekend gateway or a once-in-a-lifetime trip around the world, we can get you there for less. Even better, we constantly update our discount airfares, so you can find new deals!`,
  },
  {
    img: discount3,
    icon: carIcon,
    title: "Cars",
    content: `It's never been easier to find low-cost car rentals! When you book with DealPeakPro you can now find and book rental cars to all of your travel destinations with the touch of a button. Whether you want an economy car, a spacious sedan, a luxury car, or an SUV.`,
  },
  {
    img: discount4,
    icon: AttractionIcon,
    title: "Attractions & Activities",
    content: `Top Attractions and activities around the world are at your fingertips. We have done all the footwork securing deals from some of the most popular destinations and attractions around the world. Want to see a Broadway Show? Want to go to a Dinner Show? Theme Parks? Snorkeling off the Keys? We have It! Book Today!`,
  },
  {
    img: discount5,
    icon: cruisesIcon,
    title: "Cruises",
    content: `Cash Back on Cruises \nNet Rates \nVIP Club Exclusive offers \nVIP Upgrades \nGroup Discounts and Perks`,
  },
  {
    img: discount6,
    icon: vacationIcon,
    title: "Resort Vacation Packages from $99",
    content: `Use Code for promotional offers that transport you to luxury properties at a fraction of retail rates.`,
  },
];

const BookYourDiscount = () => (
  <section>
    <div className="container">
      <Row>
        <Col span={24}>
          <h4 className="text-center">Book Your Discount</h4>
        </Col>
      </Row>
      <Row gutter={12}>
        {discountsData.map((discount, index) => (
          <CardBookDiscount
            title={discount.title}
            icon={discount.icon}
            img={discount.img}
            content={discount.content}
            key={index}
          />
        ))}
      </Row>
    </div>
  </section>
);
export default BookYourDiscount;
