import React from "react";
import { Card } from "antd";
import { CardData } from "../../../Models/PerksModel";
import { useViewport } from "../../../Context/useViewport";

const { Meta } = Card;

interface PerkCardProps {
  data: CardData;
}

const PerkCard = ({ data }: PerkCardProps) => {
  const { width } = useViewport();
  return (
    <div className="perk-card-container">
      <Card
        className="card-perk-antd"
        cover={
          <div className="member-perk-card-cover">
            <img src={data.mainImg} className="perk-card-hero-image" />
            <div className="header-perk-text">
              <span>{data.title}</span>
            </div>
          </div>
        }
        bodyStyle={{ padding: 0 }}
      >
        <div className="perk-card-body-conainer">
          <div className="perk-card-meta no-padding justify-content-center align-items-center d-flex">
            <div>
              <div
                className="row pe-2 ps-2 meta-card-icons "
                style={
                  data.title === "Discounted All-Inclusive Resorts"
                    ? { height: "auto" }
                    : {}
                }
              >
                {data.icons.map((icon: string) => (
                  <img
                    src={icon}
                    className={
                      data.icons.length <= 4
                        ? "perk-card-icons-big"
                        : "perk-card-icons"
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="row border-top bottom-perk-card-buton-container">
            <div className="col-6 mx-auto my-3 d-grid">
              <a
                href={data.url}
                target="_blank"
                className="btn btn-info rounded-pill text-white text-uppercase fw-bold"
              >
                Book Now
              </a>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PerkCard;
