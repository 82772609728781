import { getByName } from "./requestSetting";

export const isPreApproved = async (code: string, settingName: string) => {
  try {
    const approvedList = await getByName(settingName);
    if (!approvedList) return false;

    const list = JSON.parse(approvedList.value);
    return list.includes(code);
  } catch (error) {
    // Handle any errors that occur during the process
    console.error("An error occurred:", error);
    // Return a default value in case of an error
    return false;
  }
};
