import React, { useEffect, useState } from "react";
import { getByName } from "../../../helpers/requestSetting";
import SearchBoxFilter from "../../../Components/SearchBoxFilter";
import { useViewport } from "../../../Context/useViewport";

const SearchBarSection = () => {
  const { width } = useViewport();
  const [homePageUrl, setHomePageUrl] = useState(
    "https://storage.googleapis.com/imagebucketdealpeak/bg-01.jpg"
  );

  const init = async (): Promise<void> => {
    try {
      const HomePageHeaderImage = await getByName("HomePageHeaderImage");
      setHomePageUrl(HomePageHeaderImage.value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="landing-page-container">
      <div
        className="landing-page-header-section d-flex align-items-center justify-content-center"
        style={{ backgroundImage: `url(${homePageUrl})` }}
      >
        <div>
          <div className="row search-box-component-wrapper-text ">
            <div className="col-lg-8 col-md-8 hero-text">
              <span id="top">IT'S GO TIME</span> <br />
              <span id="bottom">WHERE ARE YOU GOING NEXT?</span>
            </div>
            {width >= 992 && <div className="col"></div>}
          </div>
          <div
            className={
              width <= 992 ? `d-none` : `row search-box-component-wrapper`
            }
          >
            <SearchBoxFilter onClickOpenMenu={() => {}} isWidget={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBarSection;
