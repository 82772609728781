import { Card, Divider, Skeleton } from "antd";
import React, { ReactElement } from "react";

const SkeletonHotelDetail = (): ReactElement => (
  <div className="container my-4">
    <div className="row">
      <div className="col-12">
        <h2 className="text-secondary">Loading hotel detail information...</h2>
      </div>
      <div className="col-lg-8 col-xs-12">
        <Card>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Card>
      </div>
      <div className="col-lg-4 d-sm-none d-md-block">
        <Card>
          <Skeleton active />
          <Divider />
          <Skeleton active />
          <Divider />
          <Skeleton active />
        </Card>
      </div>
    </div>
  </div>
);
export default SkeletonHotelDetail;
