import { IBadResponse } from "../Models/BadResponse";

export const PRICE_CHECK_RESPONSE_CODES: IBadResponse[] = [
  {
    code: "4001",
    message:
      "Invalid data in request - Please refer to the fields[] for more details.",
  },
  { code: "4004", message: "Room no longer available please contact 1-800-919-2339 for more availability during business hours or try booking another property" },

  { code: "4005", message: "The price for the selected room has changed." },
  {
    code: "4006",
    message:
      "The rate you tried to book has expired. Please try again with a new search.",
  },
  { code: "5000", message: "An error has ocurred." },
  {
    code: "5001",
    message:
      "An error has ocurred due to an unknown error returned by the supplier.",
  },
  {
    code: "custom1",
    message: "Room no longer available please contact 1-800-919-2339 for more availability during business hours or try booking another property",
  },
];
export const GENERIC_ERROR_CODE = "5000";
