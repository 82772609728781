import { Button } from "antd";
import { ReactElement } from "react";

const CallActionFaqPAge = (): ReactElement => {
    const text = "Still can't find an answer to your question? Call us!";
    const tel = "1-800-919-2339"
    return (
        <section id="call-action-faq">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mx-auto col-xs-12 text-center">
                        <span className="h2">{text}</span>
                    </div>
                    <div className="text-center mt-4">
                        <a href="tel: 18009192339" className="btn btn-info text-white btn-lg" title="Call us">{tel}</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CallActionFaqPAge;