import React, { ReactElement, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../Context/context";
import { ISearch } from "../../Models/Search";
import { useAuthState } from "react-firebase-hooks/auth";
import City from "./City.tsx";
import { getRaiusKM } from "../../helpers/requestSetting";
import { RoutesPath } from "../../Routes";
import {
  totalAdults,
  childrenAges,
  totalRooms,
} from "../../helpers/occupanciesCalc";
import SearchBarSection from "./SearchSection";
import DealsSection from "./DealsSection";
import BottomSection from "./BottomSection";
import { getMembershipStatus } from "../../helpers/requestMembership";
import { isMembershipApproved } from "../../Data/MembershiStatusData";
import { useViewport } from "../../Context/useViewport";

interface cities {
  name: string;
  centerLat: number;
  centerLong: number;
}

interface LandingPageProps {
  setIsSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LandingPage = ({
  setIsSearchBoxVisible,
}: LandingPageProps): ReactElement => {
  const { setSearchContent, searchContent } = useContext<any | ISearch>(
    SearchContext
  );
  const { width } = useViewport();
  const [user] = useAuthState(searchContent.auth);
  const navigate = useNavigate();
  const [isMember, setIsMember] = useState(false);

  const city: cities[] = [
    { name: "Las Vegas", centerLat: 36.17497, centerLong: -115.13722 },
    { name: "Dubai", centerLat: 25.07725, centerLong: 55.30927 },
    { name: "New York", centerLat: 40.71427, centerLong: -74.00597 },
    { name: "Los Angeles", centerLat: 34.05223, centerLong: -118.24368 },
    { name: "Miami", centerLat: 25.77427, centerLong: -80.19366 },
    { name: "Barcelona", centerLat: 41.38879, centerLong: 2.15899 },
  ];

  const handleClick = async (id: number): Promise<void> => {
    const correlationId = uuidv4();
    const url = `${
      RoutesPath.results
    }?correlationId=${correlationId}&currency=${
      searchContent.currency
    }&checkin=${searchContent.checkIn}&checkout=${searchContent.checkOut}&lat=${
      city[id].centerLat
    }&long=${city[id].centerLong}&countryname=${
      city[id].name
    }&radius=${await getRaiusKM()}&rooms=${totalRooms(
      searchContent.occupancies
    )}&adults=${totalAdults(searchContent.occupancies)}&children=${childrenAges(
      searchContent.occupancies
    )}&currency=USD`
      .replaceAll(" ", "")
      .replaceAll("\n", "");
    navigate(url);
  };

  const init = async (): Promise<void> => {
    if (user && user.email) {
      const membershipResponse = await getMembershipStatus(user.email);
      if (
        membershipResponse &&
        isMembershipApproved(membershipResponse[0].order_status)
      ) {
        setIsMember(true);
        if (width >= 992) {
          setIsSearchBoxVisible(false);
        }
      } else {
        setIsMember(false);
      }
    } else {
      setIsMember(false);
    }
  };

  useEffect(() => {
    if (searchContent.filters) {
      const { filters, ...newObject } = searchContent;
      setSearchContent(newObject);
    }
    if (searchContent.sortOption) {
      setSearchContent({
        ...searchContent,
        sortOption: "Lowest price",
      });
    }
    init();

    return () => {
      setIsSearchBoxVisible(true);
    };
  }, []);

  useEffect(() => {
    if (isMember && width >= 992) {
      setIsSearchBoxVisible(false);
    } else {
      setIsSearchBoxVisible(true);
    }
  }, [isMember, width]);

  useEffect(() => {
    init();
  }, [user]);

  return (
    <div className="landing-wrapper">
      {isMember ? (
        <>
          <SearchBarSection />
          <DealsSection />
          <BottomSection />
        </>
      ) : (
        <City handleClick={handleClick} />
      )}
    </div>
  );
};

export default LandingPage;
