import moment from "moment";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Button, Divider, PageHeader, Result } from "antd";
import { v4 as uuidv4 } from "uuid";
import { onAuthStateChanged } from "firebase/auth";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  getBookingCancelReason,
  getBookingDetails,
} from "../../../helpers/requestBookings";
import {
  Guest,
  IBookDetailResponse,
  IBookingCancelReason,
  Occupancy,
  Rate,
} from "../../../Models/BookDetailRequest";
import LoaderComponent from "../../../Components/LoaderComponent";
import SideInformationBook from "./sideInformation";
import { ISearch } from "../../../Models/Search";
import { SearchContext } from "../../../Context/context";
import { getUserRol } from "../../../helpers/requestUserAndRole";
import { RoutesPath } from "../../../Routes";
import { IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { useViewport } from "../../../Context/useViewport";
import { decodeHtmlEntities } from "../../../helpers/decoderHelper";

const BookDetail = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const bookId = searchParams.get("bookId");
  const { width } = useViewport();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [bookInformation, setBookInformation] = useState<IBookDetailResponse>();
  const [roomName, setRoomName] = useState<string>();
  const [error, setError] = useState(false);
  const [HDbutton, setHDbutton] = useState(false);
  const [otherRateCharge, setotherRateCharge] = useState(0);
  const [cancelReason, setCancelReason] = useState<
    IBookingCancelReason | undefined
  >();
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );

  const gotoHotelDetail = () => {
    navigate(
      `${RoutesPath.HotelDetailSimple}?hotelId=${bookInformation?.hotelBooking.hotel?.id}&correlationId=${bookInformation?.hotelBooking.correlationId}`
    );
  };

  const formatDate = (isoDate: string) => {
    if (isoDate) {
      let newFormat = isoDate.replace(/\+.*/, "") + "Z";
      const date = new Date(newFormat);
      const options = {
        weekday: "short" as const,
        year: "numeric" as const,
        month: "long" as const,
        day: "numeric" as const,
        timeZone: "America/New_York",
      };
      const formatedEST = date.toLocaleString("en-US", options);
      return formatedEST;
    }
    const date = new Date();
    const options = {
      weekday: "short" as const,
      year: "numeric" as const,
      month: "long" as const,
      day: "numeric" as const,
      timeZone: "America/New_York",
    };
    const formatedEST = date.toLocaleString("en-US", options);
    return formatedEST;
  };

  const calculateTotalGuest = (
    occupancies: Occupancy[],
    countType: "numOfChildren" | "numOfAdults"
  ): number => {
    let total = 0;
    occupancies.forEach((occupancy) => {
      total += parseInt(occupancy[countType]) || 0;
    });
    return total;
  };

  useEffect(() => {
    const getDetail = async () => {
      setLoading(true);
      const listener = onAuthStateChanged(searchContent.auth, async (user) => {
        if (user?.email && bookId) {
          const correlationId = uuidv4();
          const obj = {
            channelId: webConfigContext.selectedChannel ?? "",
            bookingId: bookId,
          };
          try {
            const response = await getBookingDetails(obj, correlationId);
            if (!response.hotelBooking) {
              setError(true);
              setLoading(false);
              return;
            }
            const roles = await getUserRol(user.email);
            if (
              roles.role.roleName === "Admin" ||
              (roles.role.access !== undefined &&
                roles.role.access.some((item) => item.path === "sales"))
            ) {
              setHDbutton(true);
            }

            if (
              response.hotelBooking.billingContact?.contact.email !==
                user.email &&
              roles.role.access !== undefined &&
              roles.role.access.length === 1 &&
              roles.role.access.filter((item) => item.path === "sales")
                .length === 0
            ) {
              setError(true);
              setLoading(false);
              return;
            }

            setBookInformation(response);

            if (
              response &&
              response.hotelBooking &&
              response.hotelBooking.rates
            ) {
              let sum = 0;
              response.hotelBooking.rates.forEach(
                (item: { otherRateComponents: any[] }) => {
                  if (
                    item.otherRateComponents &&
                    Array.isArray(item.otherRateComponents)
                  ) {
                    item.otherRateComponents.forEach((component) => {
                      if (component.amount) {
                        sum += component.amount;
                      }
                    });
                  }
                }
              );

              if (sum > 0) {
                setotherRateCharge(sum);
              }
            }

            if (response.hotelBooking) {
              setRoomName(
                `Room: ${response.hotelBooking.rooms[0].name.toLowerCase()}`
              );
              setLoading(false);
              return;
            }
            setError(true);
            setLoading(false);
          } catch (err) {
            setError(true);
            console.error(err);
          }
        }
      });
      listener();
    };

    getDetail();
  }, [bookId]);

  useEffect(() => {
    const getCancelReason = async () => {
      if (bookId) {
        try {
          const res = await getBookingCancelReason(bookId);
          if (res.status !== 200) setCancelReason(res);
        } catch {
          setCancelReason(undefined);
        }
      }
    };
    getCancelReason();
  }, [bookId]);

  const sumSerFee = (totalRate: number | undefined): number => {
    let totalwServiceFee = totalRate || 0;
    if (
      bookInformation?.hotelBooking.optionalFeeState === "Fee Charged" &&
      bookInformation?.hotelBooking.optionalFeeCharged !== undefined &&
      bookInformation?.hotelBooking.optionalFeeCharged !== null &&
      bookInformation?.hotelBooking.optionalFeeCharged > 0
    ) {
      totalwServiceFee =
        totalwServiceFee + bookInformation.hotelBooking.optionalFeeCharged;
    }

    if (otherRateCharge !== undefined && otherRateCharge > 0) {
      totalwServiceFee = totalwServiceFee + otherRateCharge;
    }

    return totalwServiceFee;
  };

  return (
    <section>
      {loading && <LoaderComponent title="Loading Book Detail..." />}
      {!loading && error && (
        <Result
          status="404"
          title="Booking not found"
          subTitle="Sorry, we did not find this booking for you."
          extra={
            <Link to="/" className="btn btn-success-dp my-2">
              Back Home
            </Link>
          }
        />
      )}
      {!loading && !error && (
        <div className="container my-5">
          <div className="row">
            <div>
              <p className="title-gray">
                Booking #{bookInformation?.hotelBooking.bookingId}:{" "}
                <span>{bookInformation?.hotelBooking.hotel?.name}</span>
              </p>
              <div className="row">
                {HDbutton && (
                  <Button
                    type="primary"
                    className="rounded-pill col-lg-4 col-md-12 col-xs-12"
                    icon={
                      <FontAwesomeIcon
                        icon={faAngleDoubleUp as IconProp}
                        className="me-3"
                      />
                    }
                    onClick={gotoHotelDetail}
                  >
                    Go to this hotel page
                  </Button>
                )}
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-xs-12">
              <div className="checkout-form p-5">
                <div className="book-title row">
                  {width < 1000 ? (
                    <span className="page-header room-text-mobile col-12">
                      {roomName}
                    </span>
                  ) : (
                    <PageHeader
                      className="page-header room-text col-12"
                      title={roomName}
                    />
                  )}
                  <span className="col-12">
                    Booking Status:{" "}
                    <span className="confirmed-book">
                      {bookInformation?.hotelBooking.bookingStatus}
                    </span>
                  </span>
                </div>
                <Divider />
                <div className="form-billing_div">
                  <div>
                    <h5 className="mb-3">Guests for this room</h5>
                    <Divider />
                    <li className="mb-2">
                      {
                        bookInformation?.hotelBooking.roomsAllocations[0]
                          .guests[0].firstName
                      }{" "}
                      {
                        bookInformation?.hotelBooking.roomsAllocations[0]
                          .guests[0].lastName
                      }{" "}
                    </li>
                  </div>
                  <div>
                    <h5 className="mb-3">Billing Information</h5>
                    <Divider />

                    <p>
                      Name:{" "}
                      <span>
                        {bookInformation?.hotelBooking.billingContact?.firstName}{" "}
                        {bookInformation?.hotelBooking.billingContact?.lastName}
                      </span>
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {
                          bookInformation?.hotelBooking.billingContact?.contact
                            .phone
                        }
                      </span>
                    </p>
                    <p>
                      Email:{" "}
                      <span>
                        {
                          bookInformation?.hotelBooking.billingContact?.contact
                            .email
                        }
                      </span>
                    </p>
                  </div>
                </div>
                <Divider />
                <h5 className="mt-3">Rooms</h5>
                <Divider />
                <div className="form-billing_div">
                  {bookInformation &&
                    bookInformation?.hotelBooking &&
                    bookInformation?.hotelBooking.rates &&
                    bookInformation?.hotelBooking.rates.map((item, index) => (
                      <div>
                        {bookInformation?.hotelBooking.rooms.map((room) =>
                          room.id === item.occupancies[0].roomId ? (
                            <h5 className="mb-3">{room.name}</h5>
                          ) : (
                            ""
                          )
                        )}

                        <Divider />

                        <p>
                          Adults :{" "}
                          <span>
                            {calculateTotalGuest(
                              item.occupancies,
                              "numOfAdults"
                            )}
                          </span>
                        </p>

                        <p>
                          Kids :{" "}
                          <span>
                            {calculateTotalGuest(
                              item.occupancies,
                              "numOfChildren"
                            )}
                          </span>
                        </p>
                      </div>
                    ))}
                </div>
                <h5 className="mt-3">Policies</h5>
                <Divider />
                <div>
                  <div className="mb-5">
                    <h5 className="mb-3">Hotel Check-In Instructions</h5>
                    {bookInformation?.hotelBooking.rates[0].policies ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${DOMPurify.sanitize(
                            decodeHtmlEntities(
                              bookInformation.hotelBooking.rates[0].policies[0]
                                ?.text
                            ) || ""
                          )}`,
                        }}
                      />
                    ) : (
                      <p>This room does not have any check in policies</p>
                    )}
                  </div>
                  <div>
                    <h5 className="mb-3">Cancellation Policy</h5>
                    {bookInformation?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                      (item, i) => (
                        <li key={i}>
                          Cancellation penalty between{" "}
                          {moment(item.start).format("MM/DD/YYYY")} to{" "}
                          {moment(item.end).format("MM/DD/YYYY")} is{" "}
                          {bookInformation.hotelBooking.rates[0].currency}
                          {bookInformation.hotelBooking.rates[0].currency ===
                            "USD" && " $"}
                          {item.estimatedValue}
                        </li>
                      )
                    )}
                  </div>
                </div>
                <h5 className="mt-4">Hotel Description</h5>
                <Divider />
                <div>
                  {bookInformation?.hotelBooking.hotel?.descriptions?.map(
                    (description, index) => (
                      <p
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: `${DOMPurify.sanitize(description.text)}`,
                        }}
                      />
                    )
                  )}
                </div>
                <h5 className="mt-4">Facilities</h5>
                <Divider />
                <div className="row">
                  {bookInformation?.hotelBooking.hotel?.facilities.map(
                    (facility, index) => (
                      <div
                        className="col-6 col-lg-4 col-md-6 d-flex my-2"
                        key={index}
                      >
                        <li>{facility.name}</li>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="sidebar-form col-lg-4 col-md-12 col-xs-12 ml-5">
              <h5 className="title-steps_form">Summary</h5>
              <Divider />
              <div>
                <SideInformationBook
                  image={bookInformation?.hotelBooking.hotel?.heroImage}
                  address={bookInformation?.hotelBooking.hotel?.contact.address}
                  name={bookInformation?.hotelBooking.hotel?.name}
                  checkin={bookInformation?.hotelBooking.tripStartDate.toString()}
                  checkout={bookInformation?.hotelBooking.tripEndDate.toString()}
                  currency={bookInformation?.hotelBooking.rates[0].currency}
                  totalrate={sumSerFee(
                    bookInformation?.hotelBooking.rates[0].totalRate
                  )}
                  creationDate={formatDate(
                    bookInformation?.hotelBooking?.creationDate?.toString() ||
                      ""
                  )}
                  bookingCancelReason={cancelReason}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default BookDetail;
