import { Rate } from "antd";
import React, { ReactElement } from "react";

interface RatesProps {
  startRating: number;
  totalReviews?: number;
  score?: number | string;
}

const RatesSection = ({
  startRating,
  totalReviews,
  score,
}: RatesProps): ReactElement => (
  <div className="hotel-card-rate-reviews-wrapper">
    <span className="hotel-card-starts">
      <Rate disabled defaultValue={startRating} />
    </span>
    <span className="mx-2">|</span>
    <span className="hotel-card-hotel-star-review">{startRating} Stars</span>
  </div>
);
export default RatesSection;
