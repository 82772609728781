import React, { ReactElement } from "react";
import HeaderFaqComponent from "./HeaderFaq";
import AccordionFaqsComponent from "./AccordionFaqs/index";
import CallActionFaqPAge from "./CallActionFaq";

const FaqPage = (): ReactElement => {
  return (
    <>
      <HeaderFaqComponent />
      <AccordionFaqsComponent />
      <CallActionFaqPAge />
    </>
  );
};
export default FaqPage;
