import APIClient from "../Components/Services/APIClient";

const client = new APIClient();

export const uploadHotelCenter = async (obj: FormData, listId: string) => {
  const response = await client.client.post(
    `HotelCenter/UploadFile?listId=${listId}`,
    obj
  );
  return response;
};

export const getHotelCenter = async (listId: string) =>
  client.client.get(`HotelCenter/GetHotelCenter?listId=${listId}`);

export const processList = async (listId: string, setLiveOnGoogle: boolean) => {
  const response = await client.client.post(
    `HotelCenter/LiveOnGoogle?listId=${listId}&setLiveOnGoogle=${setLiveOnGoogle}`
  );
  return response;
};
