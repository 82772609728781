import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Divider, Row } from "antd";
import moment from "moment";
import React, { ReactElement } from "react";
import { IBookTransform } from "../../Models/BookingsList";
import { EBookingSatus } from "../../Common/Enums";

interface BookCardProps {
  bookTransform: IBookTransform[];

  confirmCancel: (id: string) => void;

  handleNavigate: (id: string) => void;
  admin?: boolean;
}

const BookingCard = ({
  bookTransform,
  confirmCancel,
  handleNavigate,
  admin,
}: BookCardProps): ReactElement => (
  <>
    {bookTransform.length < 1 && (
      <Card title="There are no bookings" className="booking-card-placeholder">
        <Divider orientation="left">Hotel Name</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Booking ID</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Confirmation Number</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Status</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Hotel ID</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Check In</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Check Out</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">Creation Date</Divider>
        <p className="booking-card-info">No Booking</p>
        <Divider orientation="left">User ID</Divider>
        <p className="booking-card-info">No Booking</p>
      </Card>
    )}
    {bookTransform.map((booking: IBookTransform, index) => (
      <Card className="card rounded-dp shadow-lg booking-card" key={index}>
        <Divider orientation="left">
          <h3>Hotel Name</h3>
        </Divider>
        <Row justify="space-between" style={{ marginLeft: "7%" }}>
          <Col>
            <h6>{booking.hotelname}</h6>
          </Col>
          {admin ? (
            <Col>
              {booking.status === EBookingSatus.Cancelled ? (
                <p>Cancelled</p>
              ) : (
                <Button
                  type="dashed"
                  shape="round"
                  icon={
                    <FontAwesomeIcon
                      icon={faClose as IconProp}
                      className="me-2"
                    />
                  }
                  onClick={() => confirmCancel(booking.bookId.toString())}
                >
                  Manage bookings
                </Button>
              )}
            </Col>
          ) : (
            <Col>
              {booking.status === EBookingSatus.Cancelled ? (
                <p>Cancelled</p>
              ) : (
                <Button
                  danger
                  className="text-Light-Gray"
                  onClick={() => confirmCancel(booking.bookId.toString())}
                >
                  Manage bookings
                </Button>
              )}
            </Col>
          )}
        </Row>

        <Divider orientation="left">Booking ID</Divider>
        <div className="booking-card-info">
          <button
            type="button"
            className="btn btn-primary"
            aria-hidden="true"
            onClick={() => handleNavigate(booking.bookId.toString())}
          >
            #{booking.bookId}
          </button>
        </div>

        <Divider orientation="left">Confirmation Number</Divider>
        <p className="booking-card-info">{booking.providerId}</p>
        <Divider orientation="left">Status</Divider>
        <p className="booking-card-info statusCell">{booking.status}</p>
        <Divider orientation="left">Hotel ID</Divider>
        <p className="booking-card-info">{booking.hotelId}</p>
        <Divider orientation="left">Check In</Divider>
        <p className="booking-card-info">
          {moment(booking.checkIn).format("YYYY-MM-DD")}
        </p>
        <Divider orientation="left">Check Out</Divider>
        <p className="booking-card-info">
          {moment(booking.checkOut).format("YYYY-MM-DD")}
        </p>
        <Divider orientation="left">Creation Date</Divider>
        <p className="booking-card-info">
          {moment(booking.creationDate).format("YYYY-MM-DD")}
        </p>
        {admin && booking.userId && (
          <>
            <Divider orientation="left">User ID</Divider>
            <p className="booking-card-info">{booking.userId}</p>
          </>
        )}
      </Card>
    ))}
  </>
);
export default BookingCard;
