import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useState } from "react";

interface LoadMorebuttonProps {
  disableLoadMore: boolean;
  onClickLoadMore(): void;
}

const LoadMoreButton = ({
  disableLoadMore,
  onClickLoadMore,
}: LoadMorebuttonProps): ReactElement => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handlerClick = () => {
    onClickLoadMore();
    setButtonLoading(true);
    setTimeout(() => {
      setButtonLoading(false);
    }, 2000);
  };
  if (buttonLoading === true) {
    return (
      <button
        type="button"
        className="btn btn-search-box-filter btn-block"
        disabled
      >
        <FontAwesomeIcon
          icon={faSpinner as IconProp}
          className="me-2 fa-spin"
        />
        Loading results...
      </button>
    );
  }
  return (
    <button
      type="button"
      className="btn btn-search-box-filter btn-block"
      disabled={disableLoadMore}
      onClick={handlerClick}
    >
      {disableLoadMore ? "No more results" : "Load More..."}
    </button>
  );
};
export default LoadMoreButton;
