import React, { ReactElement, useContext } from "react";
import { Select } from "antd";
import { ISearch } from "../../../Models/Search";
import { SearchContext } from "../../../Context/context";
import { CaretDownOutlined } from "@ant-design/icons";

const { Option } = Select;

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

const CurrencySelect = (): ReactElement => {
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;
  const currencies = [{ label: "USD", value: 3 }];
  return (
    <div className="currency_margin">
      <Select
        suffixIcon={<CaretDownOutlined />}
        size="large"
        defaultValue="USD"
        onChange={(value) =>
          setSearchContent({
            ...searchContent,
            currency: currencies[parseInt(value, 10) - 1].label,
          })
        }
      >
        {currencies.map((currency, index) => (
          <Option value={currency.value} key={index}>
            {currency.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};
export default CurrencySelect;
