import { FirebaseApp } from "firebase/app";
import { Auth } from "firebase/auth";
import moment from "moment";
import { IOccupancy } from "./CommonType";
import { IFields } from "../Components/FormMainFilter";

export interface ISearch {
  checkIn: string;
  checkOut: string;
  occupancies: IOccupancy[];
  currency: string;
  userIp?: string;
  phone?: string;
  email?: string;
  app?: FirebaseApp;
  auth?: Auth;
  hotelDetailURL?: string;
  sortOption?: string;
  getAuthTest?: (authFirebase: Auth) => void;
  filters?: IFields;
}

export interface ISuggestValue {
  label: string;
  value: string;
}

const getDATE = (dateInOut: string) => {
  const check = moment(dateInOut, "YYYY-MM-DD");
  return moment(check).format("YYYY-MM-DD");
};
export const objectContext = (
  checkin: string | null,
  checkout: string | null,
  occupancies: IOccupancy[],
  currencyParams: string | null
) => {
  const obj = {
    checkIn: checkin
      ? getDATE(checkin)
      : moment().add(1, "days").format("YYYY-MM-DD"),
    checkOut: checkout
      ? getDATE(checkout)
      : moment().add(2, "days").format("YYYY-MM-DD"),
    occupancies,
    currency: currencyParams || "USD",
  };
  return obj;
};
