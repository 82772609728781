import moment from "moment";
import Papa from "papaparse";
import { EBookingSatus } from "../Common/Enums";
import { IBookingsList } from "../Models/BookingsList";
import { getallBookingsAdmin, getBookingsAdmin } from "./requestBookings";

const cancelSetter = (
  checkin: string,
  checkOut: string,
  status: string,
  bookingId: number
) => {
  const checkInDate = moment(checkin);
  const checkOutDate = moment(checkOut);
  const today = moment();
  if (status === EBookingSatus.Cancelled) {
    return "Cancelled";
  }
  if (today.isBetween(checkInDate, checkOutDate)) {
    return "At hotel";
  }
  if (today.isAfter(checkOut)) {
    return "Time Out";
  }
  return "Cancellable";
};

const setInfo = async () => {
  const response: IBookingsList["results"] = await getallBookingsAdmin();

  const newInfo = response.map((item) => {
    const cancelOpt = cancelSetter(
      item.checkIn,
      item.checkOut,
      item.bookingStatus,
      item.bookingId
    );
    const obj = {
      bookId: item.bookingId,
      hotelId: item.hotelId,
      hotelname: item.hotelName,
      status: item.bookingStatus,
      providerId: item.providerConfirmationNumber,
      checkIn: item.checkIn,
      checkOut: item.checkOut,
      cancel: cancelOpt,
      creationDate: item.creationDate,
      isMobile: item.isMobile,
      isMember: item.isMember,
      boughtMembership: item.boughtMembership,
      channelId: item.channelId,
      correlationId: item.correlationId,
      providerName: item.providerName,
      totalRate: item.totalRate,
      baseRate: item.baseRate,
      commission: item.commission,
      feeCharged: item.feeCharged,
      feeState: item.feeState,
    };
    return obj;
  });
  return newInfo;
};

export const exportToCSV = async () => {
  const dataSource = await setInfo();

  const csv = Papa.unparse(dataSource, {
    header: true,
  });

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "data.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
