import { useContext, useEffect, useRef, useState } from "react";
import { getContentAndRooms } from "../../../../helpers/requestRoomsrates";
import {
  IContentRoomBody,
  IContentRooms,
} from "../../../../Models/ContentRooms";
import { SearchContext } from "../../../../Context/context";
import { ISearch } from "../../../../Models/Search";
import { IWebConfigContext } from "../../../../Models/SettingsModel";
import { WebConfigContext } from "../../../../Context/WebConfigContext";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ILink, IRate } from "../../../../Models/RoomDetail";
import { Skeleton } from "antd";
import { useViewport } from "../../../../Context/useViewport";
import RoutesPath from "../../../../Routes";
import { useNavigate } from "react-router";
import { childrenAges, totalAdults } from "../../../../helpers/occupanciesCalc";

interface HotelCardLandingProps {
  id: string;
}

const HotelCardLanding = ({ id }: HotelCardLandingProps) => {
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const navigate = useNavigate();
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const carousel = useRef<CarouselRef>(null);
  const handleNextClick = () => carousel?.current?.next();
  const handlePrevClick = () => carousel?.current?.prev();
  const { width } = useViewport();
  const [HotelInfo, setHotelInfo] = useState<IContentRooms>();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [images, setImages] = useState<ILink[]>();
  const [avgBasRate, setAvgBasRate] = useState("0");
  const [retailPrice, setretailPrice] = useState<number>();
  const [baseRate, setBaseRate] = useState<number>();
  const [discount, setDiscount] = useState<number>();

  const getCityName = (): string => {
    let cityName = "";
    try {
      if (HotelInfo && HotelInfo.contentDetail.contact.address.city) {
        cityName = HotelInfo.contentDetail.contact.address.city.name
          ? HotelInfo.contentDetail.contact.address.city.name
          : HotelInfo.contentDetail.contact.address.city.code || "";

        return cityName.toUpperCase();
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  };
  const getStateName = (): string => {
    let stateName = "";
    try {
      if (HotelInfo && HotelInfo.contentDetail.contact.address.state) {
        stateName = HotelInfo.contentDetail.contact.address.state.name
          ? HotelInfo.contentDetail.contact.address.state.name
          : HotelInfo.contentDetail.contact.address.state.code || "";

        return stateName.length > 2 ? `, ${stateName.toUpperCase()}` : ``;
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  };
  const getLowestRate = (ratesArray: IRate[] | undefined): string => {
    if (!ratesArray) return "0";
    try {
      const lowestBaseRate = ratesArray.reduce((minRate, rate) => {
        if (rate.baseRate > 0 && rate.baseRate < minRate) {
          return rate.baseRate;
        }
        return minRate;
      }, Infinity);
      if (isFinite(lowestBaseRate) && !isNaN(lowestBaseRate)) {
        return lowestBaseRate.toFixed(2);
      } else {
        setIsError(true);
        return "0";
      }
    } catch (error) {
      return "0";
    }
  };

  const getSavingsPerc = (number1: number, number2: number) => {
    const difference = Math.abs(number1 - number2);
    const percentageDifference = (difference / number1) * 100;

    if (isFinite(percentageDifference) && !isNaN(percentageDifference)) {
      return Math.ceil(percentageDifference);
    } else {
      setIsError(true);
      return 0;
    }
  };

  const getSavingsRetail = (ratesArray: IRate[]) => {
    if (!ratesArray) return 0;

    let retailDifference = 0;
    let publishedBaseRate: number | undefined;
    let baseRate: number = 0;

    ratesArray.forEach((rate) => {
      if (
        rate.providerName === "EAN" &&
        rate.baseRate !== 0 &&
        rate.publishedBaseRate !== 0 &&
        rate.publishedBaseRate > rate.baseRate
      ) {
        const difference = Math.abs(rate.baseRate - rate.publishedBaseRate);
        const percentageDifference = (difference / rate.baseRate) * 100;
        if (percentageDifference > retailDifference) {
          retailDifference = percentageDifference;
          publishedBaseRate = rate.publishedBaseRate;
          baseRate = rate.baseRate;
        }
      }
    });
    setDiscount(retailDifference);
    setBaseRate(baseRate);
    setretailPrice(publishedBaseRate);
  };

  const init = async () => {
    setLoading(true);
    const objectRequest: IContentRoomBody = {
      includeAllProviders: true,
      hotelIds: [id],
      contentFields: ["Basic", "Images", "Descriptions"],
      checkIn: searchContent.checkIn,
      checkOut: searchContent.checkOut,
      occupancies: searchContent.occupancies,
      nationality: searchContent.nationality,
      countryOfResidence: "US",
      channelId: webConfigContext.selectedChannel,
      culture: "en-US",
      currency: "USD",
      price: 0,
      tax: 0,
      otherRateComponent: null,
      callId: null,
      isMobile: webConfigContext.mobile,
      roomName: null,
      mail: searchContent.auth?.currentUser?.email,
      context: "DirectHotel"
    };
    try {
      const result = await getContentAndRooms(
        id,
        `landing-page-hotel-${id}`,
        `landing-page-hotel-${id}`,
        objectRequest
      );
      const totalBaseRate = result.data.roomsAndRates.hotel.rates.reduce(
        (accumulator, rate) => {
          if (rate.baseRate > 0) {
            return accumulator + rate.baseRate;
          }
          return accumulator;
        },
        0
      );
      getSavingsRetail(result.data.roomsAndRates.hotel.rates);
      const averageBaseRate =
        totalBaseRate /
        result.data.roomsAndRates.hotel.rates.filter(
          (rate) => rate.baseRate > 0
        ).length;

      const linksArrays = result.data.contentDetail.images.map(
        (item) => item.links
      );
      let combinedLinks: ILink[] = [];
      if (Array.isArray(linksArrays)) {
        combinedLinks = linksArrays.flat();
      }
      setHotelInfo(result.data);
      setImages(combinedLinks.slice(0, combinedLinks.length / 2));
      setAvgBasRate(averageBaseRate.toFixed(2));
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };
  const handleClick = () => {
    const url = `${
      RoutesPath.hotelDetail
    }?hotelId=${id}&countryname=${getCityName()}&checkin=${
      searchContent.checkIn
    }&checkout=${searchContent.checkOut}&adults=${totalAdults(
      searchContent.occupancies
    )}&children=${childrenAges(searchContent.occupancies)}&rooms=${
      searchContent.occupancies.length
    }&nationality=US&countryOfResidence=US&currency=${
      searchContent.currency
    }&culture=en-US&context=DirectHotel`;
    navigate(url, { state: { fromwithinapp: true } });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          {!isError && (
            <div className="hotel-card-container">
              <div className={width < 1000 ? `` : `row`}>
                <div
                  className={
                    width < 1000
                      ? `row image-section-landing`
                      : `col-4 image-section-landing`
                  }
                >
                  <Carousel
                    ref={carousel}
                    draggable
                    infinite={false}
                    dots={false}
                  >
                    {images &&
                      images.length > 0 &&
                      images.map((image, index) => (
                        <div key={index} className="img-wrapper">
                          <div
                            className="indicator-last"
                            onClick={handlePrevClick}
                          >
                            <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                          </div>
                          <div
                            className="indicator-next"
                            onClick={handleNextClick}
                          >
                            <FontAwesomeIcon icon={faAngleRight as IconProp} />
                          </div>
                          <div className="action-section-carousel" />
                          <img
                            src={image.url}
                            alt={"Main HotelImage"}
                            loading="lazy"
                            className="image-hotel"
                          />
                        </div>
                      ))}
                  </Carousel>
                </div>
                {width < 1000 && (
                  <div className="row">
                    <div className="row" onClick={handleClick}>
                      <div className="info-section-landing d-flex align-items-center justify-content-start">
                        <div>
                          <h5 id="hotel-name-landing-hotel-name">
                            {HotelInfo?.contentDetail.name}
                          </h5>

                          <h2 id="hotel-name-landing">
                            {getCityName()} {getStateName()}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="row" onClick={handleClick}>
                      <div className="price-section-landing d-flex align-items-center justify-content-end mb-2">
                        <div className=" text-end">
                          <h1 className="text-dark">
                            {discount && discount > 0 ? (
                              <>
                                <span className="member-price-lading">
                                  Member Price:{" "}
                                </span>{" "}
                                ${baseRate}
                              </>
                            ) : (
                              <>
                                <span className="member-price-lading">
                                  Member Price:{" "}
                                </span>{" "}
                                $
                                {getLowestRate(
                                  HotelInfo?.roomsAndRates.hotel.rates
                                )}
                              </>
                            )}
                          </h1>

                          <div className="text-container">
                            <h5 id="base-rate-landing">
                              $
                              {discount && discount > 0
                                ? retailPrice?.toFixed(2)
                                : avgBasRate}
                            </h5>
                            <div className="mask-svg">
                              <svg
                                width="100%"
                                height="100%"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                              >
                                <line
                                  x1="0"
                                  y1="0"
                                  x2="100%"
                                  y2="100%"
                                  stroke="black"
                                  stroke-width="4"
                                />
                              </svg>
                            </div>
                          </div>
                          <br />
                          {discount && discount > 0 ? (
                            <div className="savings-landing-container">
                              <h4 id="savings-landing">
                                Save {Math.round(discount)}%
                              </h4>
                            </div>
                          ) : (
                            <div className="savings-landing-container">
                              {getSavingsPerc(
                                parseFloat(
                                  getLowestRate(
                                    HotelInfo?.roomsAndRates.hotel.rates
                                  )
                                ),
                                parseFloat(avgBasRate)
                              ) > 0 && (
                                <h4 id="savings-landing">
                                  Save{" "}
                                  {getSavingsPerc(
                                    parseFloat(
                                      getLowestRate(
                                        HotelInfo?.roomsAndRates.hotel.rates
                                      )
                                    ),
                                    parseFloat(avgBasRate)
                                  )}
                                  %
                                </h4>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {width >= 1000 && (
                  <>
                    <div
                      className="col-4 info-section-landing d-flex align-items-center justify-content-start mb-2"
                      onClick={handleClick}
                    >
                      <div>
                        <h5 id="hotel-name-landing-hotel-name">
                          {HotelInfo?.contentDetail.name}
                        </h5>

                        <h2 id="hotel-name-landing">
                          {getCityName()} {getStateName()}
                        </h2>
                      </div>
                    </div>
                    <div
                      className="col-4 price-section-landing d-flex align-items-center justify-content-end "
                      onClick={handleClick}
                    >
                      <div className="pe-4 text-end justify-content-end ">
                        <h1 className="text-dark">
                          {discount && discount > 0 ? (
                            <>
                              <span className="member-price-lading">
                                Member Price:{" "}
                              </span>{" "}
                              ${baseRate}
                            </>
                          ) : (
                            <>
                              <span className="member-price-lading">
                                Member Price:{" "}
                              </span>{" "}
                              $
                              {getLowestRate(
                                HotelInfo?.roomsAndRates.hotel.rates
                              )}
                            </>
                          )}
                        </h1>

                        <div className="text-container">
                          <h5 id="base-rate-landing">
                            $
                            {discount && discount > 0
                              ? retailPrice?.toFixed(2)
                              : avgBasRate}
                          </h5>
                          <div className="mask-svg">
                            <svg
                              width="100%"
                              height="100%"
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                            >
                              <line
                                x1="0"
                                y1="0"
                                x2="100%"
                                y2="100%"
                                stroke="black"
                                stroke-width="4"
                              />
                            </svg>
                          </div>
                        </div>
                        <br />
                        {discount && discount > 0 ? (
                          <div className="savings-landing-container">
                            <h4 id="savings-landing">
                              Save {Math.round(discount)}%
                            </h4>
                          </div>
                        ) : (
                          <div className="savings-landing-container">
                            {getSavingsPerc(
                              parseFloat(
                                getLowestRate(
                                  HotelInfo?.roomsAndRates.hotel.rates
                                )
                              ),
                              parseFloat(avgBasRate)
                            ) > 0 && (
                              <h4 id="savings-landing">
                                Save{" "}
                                {getSavingsPerc(
                                  parseFloat(
                                    getLowestRate(
                                      HotelInfo?.roomsAndRates.hotel.rates
                                    )
                                  ),
                                  parseFloat(avgBasRate)
                                )}
                                %
                              </h4>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="hotel-card-container">
          {width < 1000 ? (
            <>
              <div>
                <div className="row image-section-landing">
                  <Skeleton.Image
                    className="img-fluid w-100 h-100"
                    style={{ width: "100%", height: "250px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-4 image-section-landing">
                  <Skeleton.Image
                    className="img-fluid w-100 h-100"
                    style={{ width: "100%", height: "250px" }}
                  />
                </div>
                <div className="col-4 info-section-landing d-flex align-items-center justify-content-start">
                  <div>
                    <Skeleton.Input active={true} size={"small"} block={true} />

                    <Skeleton.Input active={true} size={"large"} block={true} />
                  </div>
                </div>
                <div className="col-4 price-section-landing d-flex align-items-center justify-content-end ">
                  <div className="pe-4 text-end">
                    <Skeleton.Input active={true} size={"large"} block={true} />

                    <Skeleton.Input active={true} size={"small"} block={true} />

                    <Skeleton.Input
                      active={true}
                      size={"default"}
                      block={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default HotelCardLanding;
