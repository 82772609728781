import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { ReactElement } from "react";
import FormMainFilter from "../FormMainFilter";

interface IFields {
  name: string;
  price: { min: number; max: number };
  locations: CheckboxValueType[];
  ratings: string[];
  facilities: CheckboxValueType[];
  maxDistance: number;
}

interface MainSideFilterProps {
  filterOpen?: boolean;
  locations: string[];
  locationsCant: number[];
  facilities: string[];
  facilitiesCant: number[];
  maxPrice: number;
  minPrice: number;
  maxDistance: number;

  filter: (fieldsObj: IFields) => void;
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
}

const MainSideFilter = ({
  filterOpen,
  locations,
  locationsCant,
  facilities,
  facilitiesCant,
  maxPrice,
  minPrice,
  maxDistance,
  filter,
  setSortOption,
}: MainSideFilterProps): ReactElement => (
  <div
    className={`side-main-filter-wrapper p-3 ${
      filterOpen ? "side-filter-open" : ""
    }`}
  >
    <FormMainFilter
      setSortOption={setSortOption}
      locations={locations}
      locationsCant={locationsCant}
      facilitiesCant={facilitiesCant}
      facilitiesGroup={facilities}
      maxPrice={maxPrice}
      minPrice={minPrice}
      maxDistance={maxDistance}
      filter={filter}
    />
  </div>
);
export default MainSideFilter;
