import { getByName } from "./requestSetting";

export const validateDevice = async (isMobile: boolean, providerId: string) => {
  const twoFactorActive = await getByName("SMSTwoFactorActive");
  if (!twoFactorActive) return false;

  const parsedTwoFactorActive = JSON.parse(twoFactorActive.value);
  const applicableList = isMobile
    ? parsedTwoFactorActive.mobile
    : parsedTwoFactorActive.desktop;

  return applicableList && parsedTwoFactorActive.providers.includes(providerId);
};
