import React, { ReactElement, useEffect, useState } from "react";
import { Divider } from "antd";
import { IImage, IPolicy } from "../../../../../Models/HotelContent";
import {
  IRate,
  IRecommendation,
  IRoom,
} from "../../../../../Models/RoomDetail";
import ContentRoomCard from "./ContentRoomCard";
import ImageRoomCard from "./ImageRoomCard";
import { IFakePrice } from "../../../../../Models/GooglePrice";

interface RoomCardProp {
  correlationId: string;
  deeplink: string | undefined;
  room: IRoom;
  numOfAdults: number;
  numOfChildren: number;
  rates?: IRate[];
  recommendation: IRecommendation[];
  defaultImage: IImage[] | undefined;
  fakePrice: IFakePrice | undefined;
  provider: string;
  hotelDetailPolicy?: IPolicy[];
  rawRates: IRate[];
  closeOtherOffers?: () => void;
  setOpenDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  setcheckoutUrl?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedRate?: React.Dispatch<React.SetStateAction<IRate | undefined>>;
  showTotalNightly: boolean;
  sFakePrice: IFakePrice | undefined;
  isIpExcempt: boolean;
}

const RoomCard = ({
  room,
  rates,
  recommendation,
  correlationId,
  deeplink,
  numOfAdults,
  numOfChildren,
  defaultImage,
  fakePrice,
  provider,
  hotelDetailPolicy,
  rawRates,
  closeOtherOffers,
  setOpenDrawer,
  setcheckoutUrl,
  setSelectedRate,
  showTotalNightly,
  sFakePrice,
  isIpExcempt
}: RoomCardProp): ReactElement => {
  const isMultiRoom = (
    rawRates: IRate[],
    recommendation: IRecommendation[]
  ) => {
    const hasMultipleOccupancies = rawRates.some(
      (rate) => rate.occupancies.length > 1
    );
    const hasMultipleRates = recommendation.some(
      (item) => item.rates.length > 1
    );
    return hasMultipleOccupancies || hasMultipleRates;
  };
  return (
    <>
      <div className="row room-card">
        {room.images.length > 0 && (
          <ImageRoomCard images={room.images} altText={room.name} />
        )}
        {room.images.length === 0 &&
          defaultImage &&
          defaultImage.length > 0 && (
            <ImageRoomCard images={defaultImage[0].links} altText={room.name} />
          )}
        <ContentRoomCard
          setSelectedRate={setSelectedRate}
          setcheckoutUrl={setcheckoutUrl}
          setOpenDrawer={setOpenDrawer}
          correlationId={correlationId}
          deeplink={deeplink}
          room={room}
          name={room?.name}
          numOfAdults={numOfAdults}
          numOfChildren={numOfChildren}
          description={room?.description}
          rates={rates}
          recommendations={recommendation}
          id={room?.id}
          facilities={room.facilities}
          fakePrice={fakePrice}
          provider={provider}
          hotelDetailPolicy={hotelDetailPolicy}
          rawRates={rawRates}
          closeOtherOffers={closeOtherOffers}
          isMultiRoom={isMultiRoom(rawRates, recommendation)}
          showNightlyRate={showTotalNightly}
          sFakePrice={sFakePrice}
          isIpExcempt={isIpExcempt}
        />
      </div>
      <Divider />
    </>
  );
};
export default RoomCard;
