import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { debounce } from "lodash";

interface IVIewport {
  width: number;
  height: number;
}

interface IProps {
  children?: React.ReactNode | undefined;
}

const ViewPortContext = createContext<IVIewport>({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const ViewportProvider: FC = ({ children }: IProps) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const viewPortProviderValue = useMemo(
    () => ({ width, setWidth, height, setHeight }),
    [width, height]
  );
  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <ViewPortContext.Provider value={viewPortProviderValue}>
      {children}
    </ViewPortContext.Provider>
  );
};

export function useViewport() {
  return useContext<IVIewport>(ViewPortContext);
}
