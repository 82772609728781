import * as CryptoJS from "crypto-js";
import APIClient from "../Components/Services/APIClient";
import { ILocationRadius } from "../Models/LocationRadius";
import { IWebConfig } from "../Models/SettingsModel";

const campaignProductConfigName = "CampaignProduct";
const CreateMembershipAlwaysConfigName = "CreateMembershipAlways";
const radiusKmConfigName = "RadiusInKM";

export interface ISetting {
  id: number;
  name: string;
  value: string;
}

export const getEnableActiveCampaign = (): Promise<ISetting | void> => {
  if (localStorage.getItem("EnableActiveCampaign")) {
    return new Promise<ISetting>((resolve, reject) => {
      try {
        const settingData = JSON.parse(
          localStorage.getItem("EnableActiveCampaign") || ""
        ) as ISetting;
        resolve(settingData);
      } catch (error) {
        reject(error);
      }
    });
  }

  const client = new APIClient();

  return client.client
    .get<ISetting>("EnableActiveCampaign")
    .then((response) => {
      localStorage.setItem(
        "EnableActiveCampaign",
        JSON.stringify(response.data)
      );
      response.data;
    });
};

export const getByName = async (name: string): Promise<ISetting> => {
  const client = new APIClient();
  return client.client
    .get<ISetting>(`Setting/${name}`)
    .then((response) => response.data);
};

export const getLocationRadius = async (): Promise<ILocationRadius[]> => {
  const client = new APIClient();
  return client.client
    .get<ILocationRadius[]>("LocationWithExceptionRadius")
    .then((response) => response.data);
};

export const createLocationRadius = async (locationObj: {
  name: string;
  coordinates: string;
  radiusInKm: number;
}) => {
  const client = new APIClient();

  return client.client
    .post("LocationWithExceptionRadius", locationObj)
    .then((r) => r.data);
};

export const updateLocationRadius = async (locationObj: {
  id: number;
  name: string;
  coordinates: string;
  radiusInKm: number;
}) => {
  const client = new APIClient();

  return client.client
    .put("LocationWithExceptionRadius", locationObj)
    .then((r) => r.data);
};

export const deleteLocationRadius = async (id: number) =>
  new APIClient().client
    .delete(`LocationWithExceptionRadius?id=${id}`)
    .then((r) => r.data);

export const getCampaignSetting = async () =>
  getByName(campaignProductConfigName);

export const getCreateMembershipAlwaysConfig = async () =>
  getByName(CreateMembershipAlwaysConfigName);

export const getAll = async (): Promise<ISetting[]> => {
  const client = new APIClient();
  return client.client
    .get<ISetting[]>("Setting")
    .then((response) => response.data);
};

export const getRaiusKM = async () => {
  try {
    return parseInt((await getByName(radiusKmConfigName)).value, 10) || 30;
  } catch {
    return 30;
  }
};

function decryptData(plainText: string): string {
  if (process.env.REACT_APP_ENCRYPT_KEY && process.env.REACT_APP_IV_KEY) {
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPT_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);
    // Decrypt
    const decryptedBytes = CryptoJS.AES.decrypt(plainText, key, { iv });
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  }
  return "An error ocurrend in the decryption";
}

export const getWebConfig = async (): Promise<IWebConfig> => {
  if (sessionStorage.getItem("webConfig")) {
    const decrypted = decryptData(sessionStorage.getItem("webConfig") || "");
    const webConfig: IWebConfig = JSON.parse(decrypted);
    return webConfig;
  }

  const webConfigEncrypted = await new APIClient().client.get<string>(
    "SecretManager/EncryptWebConfig"
  );
  sessionStorage.setItem("webConfig", webConfigEncrypted.data);

  // Decrypt the bytes
  const decrypted = decryptData(webConfigEncrypted.data);
  const webConfig: IWebConfig = JSON.parse(decrypted);
  return webConfig;
};

export const setAppChannelId = async (): Promise<void> => {
  const webConfig = await getWebConfig();
  if (webConfig.REACT_APP_CHANNEL_ID)
    localStorage.setItem(
      "REACT_APP_CHANNEL_ID",
      webConfig.REACT_APP_CHANNEL_ID.Organic
    );
};

export const update = async (setting: ISetting): Promise<number> =>
  new APIClient().client.put<number>("Setting", setting).then((r) => r.data);

export const del = async (id: number): Promise<number> =>
  new APIClient().client.delete(`Setting?id=${id}`).then((r) => r.data);

export const add = async (setting: {
  name: string;
  value: string;
}): Promise<number> =>
  new APIClient().client.post("Setting", setting).then((r) => r.data);
