import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useSearchParams } from "react-router-dom";
import { Col, Typography } from "antd";
import { IFacility } from "../../../Models/CommonType";
import { IFacilityGroup } from "../../../Models/HotelContent";
import AmenitiesSection from "./AmenitiesSection";
import RateSection from "./RateSection";
import TagFeature from "../TagFeature";
import SupplierLabel from "./SupplierLabel";

const { Text } = Typography;

export interface InformationHotelProps {
  id: string;
  name?: string;
  country?: string;
  city?: string;
  facilities?: IFacility[] | IFacilityGroup[];
  correlationId: string;
  currency: string;
  saveHotelArrayCache?: () => void;
  startRating: number;
  isRecomended?: boolean;
  totalReviews?: number;
  score?: number | string;
  hotelIndex: string;
  supplier: string;
  clickNavigate: () => void;
}

const InformationHotelSection = ({
  id,
  name,
  country,
  city,
  facilities,
  correlationId,
  currency,
  startRating,
  isRecomended,
  saveHotelArrayCache,
  totalReviews,
  score,
  hotelIndex,
  supplier,
  clickNavigate,
}: InformationHotelProps): ReactElement => {
  const [searchParams] = useSearchParams();
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const checkIn = searchParams.get("checkin");
  const checkOut = searchParams.get("checkout");
  return (
    <Col xs={12} md={8} lg={10}>
      <div
        className={
          hotelIndex === id ? "card-body hotel-content-card" : "card-body"
        }
      >
        <div className=" card-content ml-3 content-hotel-card">
          {isRecomended && <TagFeature />}
          <Text onClick={clickNavigate} className="hotel-name">
            {name}
          </Text>
          <FontAwesomeIcon
            icon={faMapMarkerAlt as IconProp}
            className="icon-address-hotel"
          />
          <span className="hotel-address ms-2">
            {city},&nbsp;{country}
          </span>
        </div>
        <div className="row">
          <AmenitiesSection
            facilities={facilities}
            id={id}
            checkin={checkIn}
            checkout={checkOut}
            correlationId={correlationId}
            currency={currency}
            adults={adults}
            children={
              children
                ?.split(",")
                .filter(Number)
                .map((item) => parseInt(item, 10)) || []
            }
            onSave={saveHotelArrayCache}
          />
        </div>
        <div className="footer-card-hotel">
          <RateSection
            startRating={startRating}
            totalReviews={totalReviews}
            score={score}
          />
          <div>
            <SupplierLabel supplier={supplier} className="card-hotel-result" />
          </div>
        </div>
      </div>
    </Col>
  );
};
export default InformationHotelSection;
