import React, { ReactElement } from "react";
import { RoutesPath } from "../../Routes";

interface MainLogoProps {
  className: string;
  checkIn: string;
  checkOut: string;
}

const MainLogo = ({
  className,
  checkIn,
  checkOut,
}: MainLogoProps): ReactElement => (
  <div className={`logo p-2 ${className}`}>
    <a href={`${RoutesPath.home}/?checkIn=${checkIn}&checkOut=${checkOut}`}>
      <img
        src={`${process.env.PUBLIC_URL}/alogo_transparent.svg`}
        alt="logo"
        width={180}
      />
    </a>
  </div>
);
export default MainLogo;
