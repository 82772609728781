import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Checkbox, Col, Divider, Row, Tag } from "antd";
import React, { ReactElement } from "react";
import { IRolesTransform } from "../../../Models/UsersModel";

interface BookCardProps {
  role: IRolesTransform;
  editForm: (id: number) => void;
  roleIndex: number;
}

const RolesCard = ({
  role,
  editForm,
  roleIndex,
}: BookCardProps): ReactElement => (
  <Card className="card rounded-dp shadow-lg booking-card">
    <Row justify="space-between">
      <Col style={{ marginLeft: "10%" }}>
        <h4 style={{ textAlign: "left" }}>{role.roleName}</h4>
      </Col>
      <Col>
        <button
          className="btn btn-success-dp-xs"
          type="button"
          onClick={() => editForm(roleIndex)}
        >
          <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
          Edit
        </button>
      </Col>
    </Row>

    <Divider orientation="left">ID</Divider>
    <p className="booking-card-info">{role.id}</p>
    <Divider orientation="left">Active</Divider>
    <p className="booking-card-info">
      <Checkbox checked={role.active} disabled />
    </p>
    <Divider orientation="left">Role Description</Divider>
    <p className="booking-card-info">{role.roleDescription}</p>
    <Divider orientation="left">Access</Divider>
    <div className="booking-card-info">
      {role.access.map((item) => (
        <Tag color="green" key={item.accessId}>
          {item.path}
        </Tag>
      ))}
    </div>
  </Card>
);
export default RolesCard;
