import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import { ReactElement, useEffect, useState } from "react";
import DataFaqs from "../DataFaqs";
import QuestionItem from "../QuestionItem";

const SearchFormFaqComponent = (): ReactElement => {
  const [faqArray, setFaqArray] = useState<any>([]);
  const [termTex, setTermText] = useState<string | string[]>("");
  const [arrayFiltered, setArrayFiltered] = useState<any>([]);

  const onFinish = (values: any) => {
    setTermText(values.searchField.toLowerCase());
  };

  const createArray = () => {
    const questionsList: any[] = [];
    DataFaqs.map((questionsObject) =>
      questionsObject.questions.map((questionObject: any) =>
        questionsList.push(questionObject)
      )
    );
    setFaqArray(questionsList);
  };

  const filterArray = (text: any, array: any[]) => {
    const currentArrayFiltered = array.filter(
      (res: any) =>
        res.question.includes(text.toLowerCase()) ||
        res.request.includes(text.toLowerCase())
    );

    setArrayFiltered(currentArrayFiltered);
  };

  const resetArrayQuestions = () => {
    setArrayFiltered([]);
  };

  useEffect(() => {
    createArray();
    filterArray(termTex, faqArray);
  }, [termTex]);

  const [form] = Form.useForm();

  return (
    <>
      <div className="col-lg-10 mx-auto col-xs-12 my-4">
        <Form name="search-question" onFinish={onFinish} form={form}>
          <div className="row">
            <div className="col-lg-9 col-xs-12">
              <Form.Item name="searchField">
                <Input placeholder="Search" prefix={<SearchOutlined />} />
              </Form.Item>
            </div>
            <div className="col-lg-3 col-xs-12">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  title="Search Faq"
                  shape="round"
                  block
                  size="large"
                >
                  Search Faq
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <div className="col-lg-10"></div>
      {arrayFiltered.length ? (
        <div className="col-lg-2">
          <Button
            onClick={resetArrayQuestions}
            title="Reset search"
            type="primary"
            icon={<CloseOutlined />}
          >
            Reset Search
          </Button>
        </div>
      ) : null}
      {arrayFiltered.map((question: any, index: number) => {
        return <QuestionItem {...question} key={index} />;
      })}
    </>
  );
};
export default SearchFormFaqComponent;
