import React, { useContext, useState } from "react";
import { Input, Select, Space, Drawer, Spin, Modal } from "antd";
import { useNavigate } from "react-router";
import { createContactSalesMate } from "../../../../helpers/salesMate";
import { getIp } from "../../../../helpers/configHelper";
import { useViewport } from "../../../../Context/useViewport";
import { ISearch } from "../../../../Models/Search";
import { SearchContext } from "../../../../Context/context";
import { sendCode, verifyCode } from "../../../../helpers/requestCodeValidator";
import VerificationInput from "react-verification-input";
import {
  ISendMessage,
  IVerificationRequest,
} from "../../../../Models/TwoFactorAuth";
import { isPreApproved } from "../../../../helpers/TwoFactorAuth";
import { IRate, ITax } from "../../../../Models/RoomDetail";
import { getFormattedTimestamp } from "../../../../helpers/getFormattedTimestamp";

interface props {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  checkoutURL: string;
  selectedRate?: IRate;
  nameHotel?: string;
}

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

const options = [
  {
    value: "US (+1)",
    label: "US (+1)",
  },
];

const AuthDrawer = ({
  openDrawer,
  setOpenDrawer,
  checkoutURL,
  selectedRate,
  nameHotel,
}: props) => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;
  const [authIdentifier, setAuthIdentifier] = useState("");
  const [inputStatus, setinputStatus] = useState<
    "" | "warning" | "error" | undefined
  >("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);
  // mode as true is phone as false is email
  const [modePhone, setMode] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (value: string) => {
    if (modePhone) {
      const cleaned = value.replace(/\D/g, "");

      const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

      setAuthIdentifier(formatted);
    } else {
      setAuthIdentifier(value);
    }
  };

  const totalTaxes = (taxesArr: ITax[] | undefined) => {
    if (!taxesArr) return 0;
    const resp = taxesArr.reduce((a, b) => a + b.amount, 0);
    return resp;
  };
  const isValidPhoneNumber = (phoneNumber: string) =>
    /^\+\d{11}$/.test(phoneNumber);

  const errorMessage = (key: string) => {
    setinputStatus("error");
    switch (key) {
      case "format":
        Modal.error({
          title: `The format of the ${
            modePhone ? "Phone Number" : "Email"
          } is incorrect`,
        });
        break;
      case "verify":
        Modal.error({
          title: "The code is incorrect",
        });
        break;
      default:
        Modal.error({
          title: "The user cannot receive the code.",
        });
        break;
    }
    setTimeout(() => {
      setinputStatus("");
    }, 2000);
  };

  const handleSMS = async () => {
    try {
      if (modePhone) {
        const phoneNumber = authIdentifier.replace(/\D/g, "");
        try {
          if (await isPreApproved(phoneNumber, "AcceptedTwoFactorPhone")) {
            localStorage.setItem("UserAuth", "true");
            navigate(checkoutURL);
            return;
          }
        } catch (error) {
          console.error(error);
        }
        const isValid = isValidPhoneNumber(`+1${phoneNumber}`);
        if (!isValid) {
          errorMessage("format");
          return;
        }
        setLoading(true);
        const codeResponse: ISendMessage = await sendCode(`+1${phoneNumber}`);
        if (
          codeResponse.status != "error" &&
          codeResponse.data &&
          codeResponse.data.messages &&
          codeResponse.data.messages.length > 0 &&
          codeResponse.data.messages[0].status.toUpperCase() == "SUCCESS"
        ) {
          setStep(2);
          createContactSalesMate(
            window.location.href,
            searchContent.userIp ?? (await getIp()),
            phoneNumber
          );
          setSearchContent({
            ...searchContent,
            phone: phoneNumber,
          });
        } else {
          errorMessage("another");
        }
      } else {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(authIdentifier);
        if (!isValidEmail) {
          errorMessage("format");
          return;
        }
        setLoading(true);
        const codeResponse: ISendMessage = await sendCode(authIdentifier);
        if (codeResponse.status === "200") {
          setStep(2);
          createContactSalesMate(
            window.location.href,
            searchContent.userIp ?? (await getIp()),
            undefined,
            authIdentifier
          );
          setSearchContent({
            ...searchContent,
            email: authIdentifier,
          });
        } else {
          errorMessage("another");
        }
      }
    } catch (e) {
      errorMessage("another");
    } finally {
      setLoading(false);
    }
  };

  const onChangeCode = (value: string) => {
    setCode(value);
  };

  const onChangeMode = () => {
    setAuthIdentifier("");
    setMode(!modePhone);
  };

  const reSendCode = async () => {
    try {
      await handleSMS();
      Modal.error({
        title: "Code re-sent!",
      });
    } catch (error) {
      Modal.error({
        title: "Something went wrong please contact support!",
      });
    }
  };

  const handleAuth = async () => {
    if (modePhone) {
      try {
        const cleaned = authIdentifier.replace(/\D/g, "");
        try {
          if (await isPreApproved(code, "AcceptedTwoFactorCode")) {
            localStorage.setItem("UserAuth", "true");
            navigate(checkoutURL);
            return;
          }
        } catch (error) {
          console.error(error);
        }
        const requestBody: IVerificationRequest = {
          to: `+1${cleaned}`,
          code: Number(code),
        };
        const verifyResponse = await verifyCode(requestBody);
        if (verifyResponse && verifyResponse.isValid === true) {
          localStorage.setItem("UserAuth", "true");
          navigate(checkoutURL);
        } else {
          errorMessage("verify");
        }
      } catch (error) {
        console.error(error);
        errorMessage("verify");
      }
    } else {
      try {
        const requestBody: IVerificationRequest = {
          to: authIdentifier,
          code: Number(code),
        };
        const verifyResponse = await verifyCode(requestBody);
        if (verifyResponse && verifyResponse.isValid === true) {
          localStorage.setItem("UserAuth", "true");
          navigate(checkoutURL);
        } else {
          errorMessage("verify");
        }
      } catch (error) {
        console.error(error);
        errorMessage("verify");
      }
    }
  };

  return (
    <div>
      <Drawer
        autoFocus
        title={
          <div className="auth-drawer-body d-flex justify-content-center align-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/alogo_transparent.svg`}
              alt="logo"
              width={180}
            />
          </div>
        }
        onClose={() => setOpenDrawer(false)}
        placement="right"
        open={openDrawer}
        width={width < 930 ? "100%" : "35%"}
      >
        {step == 1 ? (
          <div className="auth-drawer-body">
            <Spin spinning={loading}>
              <div>
                <div>
                  <h4>
                    To take advantage of these SMS Exclusive Member Only Rates &
                    to Make Sure Your Not a Robot please Verify
                  </h4>
                </div>
                <div>
                  <Space.Compact>
                    {modePhone && (
                      <Select
                        defaultValue="US (+1)"
                        options={options}
                        showArrow={options.length > 1}
                      />
                    )}
                    <Input
                      placeholder={modePhone ? "Phone Number" : "Email"}
                      status={inputStatus}
                      value={authIdentifier}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  </Space.Compact>
                </div>
                <div className="mt-2 mb-3">
                  <button
                    type="button"
                    className="btn btn-success-dp-sky-blue px-3 rounded-pill"
                    onClick={() => handleSMS()}
                    aria-hidden="true"
                  >
                    <span>Get Code</span>
                  </button>
                </div>
                <div>
                  <p>
                    By entering your phone number, you authorize DealPeak LLC
                    and Rezervco LLC (our travel facilitator) to contact you at
                    the number provided (mobile or otherwise), and understand we
                    may use automated technology (phone, messaging, prerecorded,
                    etc.) to contact you on that number for general and
                    marketing purposes. Your consent is not a requirement to
                    purchase. You agree to our&nbsp;
                    <a
                      href={`${process.env.REACT_APP_API_BASE_WP_URL}/privacy-policy/`}
                    >
                      Privacy Policy
                    </a>
                    &nbsp;including arbitration. Standard messaging and data
                    rates may apply.
                  </p>
                </div>

                <div className="d-flex flex-column">
                  <a onClick={() => onChangeMode()} className="mt-3">
                    Unable to receive the code in your device? Click here to
                    send it to your {modePhone ? "email" : "phone"}
                  </a>
                  <p>
                    {nameHotel},
                    {selectedRate && selectedRate.baseRate ? (
                      <> Base rate, ${selectedRate.baseRate}</>
                    ) : (
                      ""
                    )}
                    ,
                    {selectedRate && selectedRate.taxes ? (
                      <> Taxes, ${totalTaxes(selectedRate.taxes)}</>
                    ) : (
                      ""
                    )}
                    ,
                    {selectedRate && selectedRate.totalRate ? (
                      <> Total Rate, ${selectedRate.totalRate}</>
                    ) : (
                      ""
                    )}
                    ,{" "}
                    {selectedRate &&
                    selectedRate.otherRateComponents &&
                    selectedRate.otherRateComponents.length > 0 ? (
                      <>
                        Additional Charges, $
                        {selectedRate.otherRateComponents[0].amount}
                      </>
                    ) : (
                      ""
                    )}{" "}
                    {getFormattedTimestamp()}
                  </p>
                </div>
              </div>
            </Spin>
          </div>
        ) : (
          <div className="auth-drawer-body">
            <div>
              <div>
                <h2>Verify this device</h2>
                <h5>{` A code has been sent to ${authIdentifier}`}</h5>
                <h5>this code will be valid for 1 hour</h5>
              </div>
            </div>
            <div className="mt-2 mb-4">
              <VerificationInput
                length={4}
                placeholder={""}
                onChange={onChangeCode}
                classNames={{
                  character: "twofa-input",
                }}
              />
            </div>
            <div className="mt-4 mb-3">
              <button
                type="button"
                className="btn btn-success-dp-sky-blue px-3 rounded-pill"
                onClick={() => handleAuth()}
                aria-hidden="true"
              >
                <span>Verify</span>
              </button>
            </div>
            <div className="d-flex flex-column">
              <a onClick={() => reSendCode()} className="mt-3">
                Send code again
              </a>
              <a onClick={() => setStep(1)} className="mt-1">
                Go back
              </a>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default AuthDrawer;
