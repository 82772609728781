import React from "react";
import moment from "moment";
import { Image } from "antd";
import { useSearchParams } from "react-router-dom";
import { IAddress } from "../../../../Models/HotelContent";
import { IRoom } from "../../../../Models/RoomDetail";
import FacilityRoom from "../../../HotelDetail/BodyComponents/Rooms/RoomCard/ContentRoomCard/FacilityRoom";
import { IFacility } from "../../../../Models/CommonType";
import { Rate } from "antd";
import noImage from "../../../../img/icons/no-image.png";

interface props {
  name?: string;
  image?: string;
  address?: IAddress;
  starRating?: string;
  score?: string | number;
  room?: IRoom;
}

const RoomInformation = ({
  image,
  name,
  address,
  starRating,
  score,
  room,
}: props) => {
  const [searchParams] = useSearchParams();
  const checkin = searchParams.get("checkin");
  const transformCheckIn = moment(checkin, "YYYY-MM-DD");
  const checkIn = moment(transformCheckIn);
  const checkout = searchParams.get("checkout");
  const transformCheckOut = moment(checkout, "YYYY-MM-DD");
  const checkOut = moment(transformCheckOut);
  const rooms = parseInt(searchParams.get("rooms") || "1", 10);
  const removeFacilitiesDuplicated = (arr: IFacility[]) => {
    const withoutDuplicates = arr.filter(
      (v, i, a) => a.findIndex((v2) => v2.name === v.name) === i
    );
    return withoutDuplicates;
  };

  return (
    <div className="checkout-boxes">
      <div className="room-information_div">
        <div className="flex_room">
          <div className="room_image">
            <Image src={image ? image : noImage} preview={false} />
          </div>
          <div className="room-information_div2">
            <span className="hotel_name">{name}</span>
            <div className="more_information_checkout">
              <div>
                <span className="hotel_address_more_information">
                  {address?.line1}, {address?.city.name},{" "}
                  {address?.country.name}
                </span>
                <div className="ratings_hotel_information">
                  <span className="ratings_hotel_information_text">
                    <Rate
                      value={parseFloat(starRating || "0")}
                      allowHalf
                      disabled
                      className="rate-similar-listing"
                    />
                  </span>
                  <span> | </span>
                  <span className="ratings_hotel_information_text">
                    {" "}
                    {starRating}-STAR HOTEL
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="more_info_room">
        <div className="check_info_room">
          <div className="check_in_check_out_info">
            <div className="title">
              <span>CHECK-IN</span>
            </div>
            <div className="text">
              <span>
                {checkIn.format("dddd").slice(0, 3)},{" "}
                {checkIn.format("MMMM").slice(0, 3)} {checkIn.format("DD")},{" "}
                {checkIn.format("YYYY")}
              </span>
            </div>
          </div>
          <hr className="line_middle" />
          <div className="check_in_check_out_info">
            <div className="title">
              <span className="check-out-text">CHECK-OUT </span>
            </div>
            <div className="text">
              <span>
                {checkOut.format("dddd").slice(0, 3)},{" "}
                {checkOut.format("MMMM").slice(0, 3)} {checkOut.format("DD")},{" "}
                {checkOut.format("YYYY")}
              </span>
            </div>
          </div>
          <hr className="line_middle" />
          <div className="check_in_check_out_info">
            <div className="title">
              <span>NIGHTS</span>
            </div>
            <div className="text">
              <span>{Math.abs(checkIn.diff(checkOut, "days"))}</span>
            </div>
          </div>
          <hr className="line_middle_2" />
          <div className="check_in_check_out_info">
            <div className="title">
              <span>ROOMS</span>
            </div>
            <div className="text">
              <span>{rooms}</span>
            </div>
          </div>
        </div>
        <div className="room-information">
          <span className="room-name-info">{room?.name}</span>
          {room?.facilities && (
            <div className="room-facilities-info">
              {removeFacilitiesDuplicated(room.facilities).map(
                (facility, index) => (
                  <FacilityRoom nameFacility={facility.name} key={index} />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomInformation;
