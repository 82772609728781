import { useRef, useState, useEffect, useContext } from "react";
import { Divider, Image } from "antd";
import { v4 as uuidv4 } from "uuid";
import DOMPurify from "dompurify";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import LoaderComponent from "../../../Components/LoaderComponent";
import { getBookingDetails } from "../../../helpers/requestBookings";
import { IBookDetailResponse, Rate } from "../../../Models/BookDetailRequest";
import FacilityRoom from "../../HotelDetail/BodyComponents/Rooms/RoomCard/ContentRoomCard/FacilityRoom";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { IWebConfigContext } from "../../../Models/SettingsModel";
import { IAdditionalCharges, ITax } from "../../../Models/RoomDetail";
import { addWithinTag } from "../../../helpers/addRemoveClickTripz";
import { decodeHtmlEntities } from "../../../helpers/decoderHelper";

const ThankYou = () => {
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [bookInformation, setBookInformation] = useState<IBookDetailResponse>();
  const [searchParams] = useSearchParams();
  const bookId = searchParams.get("bookid");

  const sumBaseRate = (hotelArray: Rate[] | undefined) => {
    if (!hotelArray) return 0;
    return hotelArray.reduce((totals, hotels) => totals + (hotels.baseRate ?? 0), 0);
  };

  const sumTotalRate = (hotelArray: Rate[] | undefined) => {
    if (!hotelArray) return 0;
    return hotelArray.reduce((totals, hotels) => totals + hotels.totalRate, 0);
  };

  const sumAddRates = (rates: Rate[] | undefined): number => {
    if (!rates) return 0;

    let TotalRate = 0;

    rates.map(
      (item, index) =>
        item.otherRateComponents &&
        item.otherRateComponents.length > 0 &&
        item.otherRateComponents[index] &&
        item.otherRateComponents[index].amount > 0 &&
        (TotalRate = TotalRate + item.otherRateComponents[index].amount)
    );
    return TotalRate;
  };

  const CalculateTotalTaxes = (rates: Rate[]) => {
    let totalTaxesComplete = 0;
    for (let i = 0; i < rates.length; i++) {
      const taxArray = rates[i].taxes;
      for (let j = 0; j < taxArray.length; j++) {
        totalTaxesComplete += taxArray[j].amount;
      }
    }

    return totalTaxesComplete;
  };

  const sumAdditionalFee = () => {
    let total = 0;
    if (
      bookInformation?.hotelBooking.optionalFeeState != undefined &&
      bookInformation?.hotelBooking.optionalFeeState === "Fee Charged" &&
      bookInformation?.hotelBooking.optionalFeeCharged != undefined &&
      bookInformation?.hotelBooking.optionalFeeCharged > 0
    ) {
      total = bookInformation.hotelBooking.optionalFeeCharged;
    }
    if (
      bookInformation?.hotelBooking.rates[0].otherRateComponents != undefined &&
      bookInformation?.hotelBooking.rates[0].otherRateComponents.length > 0 &&
      bookInformation?.hotelBooking.rates[0].otherRateComponents[0].amount !==
        undefined
    ) {
      total =
        total +
        bookInformation?.hotelBooking.rates[0].otherRateComponents[0].amount;
    }
    return total;
  };

  const totalTaxes = (taxesArr: ITax[] | undefined) => {
    if (!taxesArr) return 0;
    const resp = taxesArr.reduce((a, b) => a + b.amount, 0);
    return resp;
  };

  const sumTaxes = (hotelArray: Rate[]) =>
    hotelArray.reduce((totals, hotels) => totals + totalTaxes(hotels.taxes), 0);

  const totalAddCharges = (
    additionalCharges: IAdditionalCharges[] | undefined
  ) => {
    if (!additionalCharges) return 0;
    const resp = additionalCharges.reduce((a, b) => {
      if (b.charge && typeof b.charge.amount === "number") {
        return a + b.charge.amount;
      }
      return a;
    }, 0);
    return resp;
  };

  const sumAddCharges = (hotelArray: Rate[]) =>
    hotelArray.reduce(
      (totals, hotels) => totals + totalAddCharges(hotels.additionalCharges),
      0
    );

  const readableTaxes = (myString: string) => {
    if (myString === "TAXESANDFEES" || !myString) return "Taxes";

    myString = myString.replace(/[\W_]+/g, " ");

    myString = myString.replace(/([a-z])([A-Z])/g, "$1 $2");
    myString = myString.replace(/and|fee/gi, "");
    myString = myString.charAt(0).toUpperCase() + myString.slice(1);

    return myString;
  };

  const sumOptionalFees = () => {
    if (
      bookInformation?.hotelBooking.optionalFeeState != undefined &&
      bookInformation?.hotelBooking.optionalFeeState === "Fee Charged" &&
      bookInformation?.hotelBooking.optionalFeeCharged != undefined &&
      bookInformation?.hotelBooking.optionalFeeCharged > 0
    ) {
      return bookInformation.hotelBooking.optionalFeeCharged;
    } else {
      return 0;
    }
  };

  const readableFee = (myString: string) => {
    if (myString === "TAXESANDFEES" || !myString) return "Taxes and Fees";

    myString = myString.replace(/[\W_]+/g, " ");

    myString = myString.replace(/([a-z])([A-Z])/g, "$1 $2");

    myString = myString.charAt(0).toUpperCase() + myString.slice(1);
    if (myString.toLowerCase().includes("fee")) {
      myString = "Hotel " + myString;
    }
    return myString;
  };

  const sum = (...args: number[]) =>
    args.reduce((partialSum, a) => partialSum + a, 0).toFixed(2);

  const formatDate = (isoDate: Date | undefined) => {
    if (isoDate) {
      const date = new Date(isoDate);

      const options = {
        weekday: "short" as const,
        year: "numeric" as const,
        month: "long" as const,
        day: "numeric" as const,
        timeZone: "UTC",
      };

      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    }
    const date = new Date();

    const options = {
      weekday: "short" as const,
      year: "numeric" as const,
      month: "long" as const,
      day: "numeric" as const,
      timeZone: "UTC",
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedDate = formatter.format(date);
    return formattedDate;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const Init = async () => {
      setLoading(true);
      if (bookId) {
        const correlationId = uuidv4();
        const obj = {
          channelId: webConfigContext.selectedChannel ?? "",
          bookingId: bookId,
        };
        try {
          const response = await getBookingDetails(obj, correlationId);
          setBookInformation(response);
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    };
    Init();
  }, [searchParams]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.location.href.includes("app.dealpeak") && bookInformation) {
      const scriptSP = document.createElement("script");
      scriptSP.setAttribute("type", "text/javascript");
      scriptSP.innerHTML = `
      var sa_values = {
        "site":37869,
        "token":'60f9NsSq',
        "orderid":'${bookInformation?.hotelBooking.bookingId}',
        "name":'${bookInformation?.hotelBooking.billingContact?.firstName}',
        "email":'${bookInformation?.hotelBooking.billingContact?.contact.email}' };
      function saLoadScript(src) { var js = window.document.createElement("script"); js.src = src; js.type = "text/javascript";
      document.getElementsByClassName("shopperAproved")[0].appendChild(js); } saLoadScript("https://www.shopperapproved.com/thankyou/rate/37869.js") `;
      document.head.appendChild(scriptSP);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflow = "auto";
      addWithinTag(
        bookInformation?.hotelBooking.bookingId,
        sum(
          sumBaseRate(bookInformation?.hotelBooking?.rates),
          bookInformation?.hotelBooking?.rates[0].taxes &&
            bookInformation?.hotelBooking?.rates[0].taxes.length > 0
            ? sumTaxes(bookInformation?.hotelBooking?.rates)
            : 0,
          sumAddRates(bookInformation?.hotelBooking?.rates),
          sumOptionalFees()
        ),
        bookInformation?.hotelBooking?.hotel?.id ?? "",
        formatDate(bookInformation?.hotelBooking.tripStartDate),
        formatDate(bookInformation?.hotelBooking.tripEndDate)
      );
    }
  }, [bookInformation]);

  const numberOfNights = (
    tripStartDate: string | undefined,
    tripEndDate: string | undefined
  ): number => {
    const timeDiff = Math.abs(
      (tripEndDate ? new Date(tripEndDate) : new Date()).getTime() -
        (tripStartDate ? new Date(tripStartDate) : new Date()).getTime()
    );
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  };

  return (
    <section>
      <div
        className="shopperAproved"
        ref={containerRef}
        style={{ overflowY: "scroll" }}
      />

      {loading && <LoaderComponent title="Loading Book Summary..." />}
      {!loading && (
        <div className="checkout_page_styles mt-3 mb-5">
          <div className="checkout_parent">
            <div className="checkout_form_left">
              <span className="bold-letters">
                Booking #{bookInformation?.hotelBooking.bookingId} Summary
              </span>
              <div className="checkout-boxes">
                <div className="room-information_div">
                  <div className="flex_room">
                    <div className="room_image">
                      <Image
                        src={bookInformation?.hotelBooking.hotel?.heroImage}
                        preview={false}
                      />
                    </div>
                    <div className="room-information_div2">
                      <span className="hotel_name">
                        {bookInformation?.hotelBooking.hotel?.name}
                      </span>
                      <div className="more_information_checkout">
                        <div>
                          <span className="hotel_address_more_information">
                            {
                              bookInformation?.hotelBooking.hotel?.contact
                                .address?.line1
                            }
                            ,{" "}
                            {
                              bookInformation?.hotelBooking.hotel?.contact
                                .address?.city.name
                            }
                            ,{" "}
                            {
                              bookInformation?.hotelBooking.hotel?.contact
                                .address?.country.name
                            }
                          </span>
                          <div className="ratings_hotel_information">
                            <div className="ratings_hotel_information_rate">
                              <span>
                                {
                                  bookInformation?.hotelBooking.hotel?.relevanceScore
                                }
                              </span>
                            </div>
                            <span className="ratings_hotel_information_text">
                              {" "}
                              GUEST RATING
                            </span>
                            <span> | </span>
                            <span className="ratings_hotel_information_text">
                              {" "}
                              {bookInformation?.hotelBooking.hotel?.starRating}
                              -STAR HOTEL
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="more_info_room">
                  <div className="check_info_room">
                    <div className="check_in_check_out_info">
                      <div className="title">
                        <span>CHECK-IN</span>
                      </div>
                      <div className="text">
                        <span>
                          {formatDate(
                            bookInformation?.hotelBooking.tripStartDate
                          )}
                        </span>
                      </div>
                    </div>
                    <hr className="line_middle" />
                    <div className="check_in_check_out_info">
                      <div className="title">
                        <span>CHECK-OUT</span>
                      </div>
                      <div className="text">
                        <span>
                          {formatDate(
                            bookInformation?.hotelBooking.tripEndDate
                          )}
                        </span>
                      </div>
                    </div>
                    <hr className="line_middle" />
                    <div className="check_in_check_out_info">
                      <div className="title">
                        <span>NIGHTS</span>
                      </div>
                      <div className="text">
                        <span>
                          {Math.abs(
                            moment(
                              bookInformation?.hotelBooking.tripStartDate
                            ).diff(
                              moment(bookInformation?.hotelBooking.tripEndDate),
                              "days"
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <hr className="line_middle_2" />
                    <div className="check_in_check_out_info">
                      <div className="title">
                        <span>ROOMS</span>
                      </div>
                      <div className="text">
                        <span>
                          {
                            bookInformation?.hotelBooking.roomsAllocations
                              .length
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="room-information">
                    {bookInformation?.hotelBooking.rooms.map((room) => (
                      <>
                        <span className="room-name-info">{room.name}</span>
                        <div className="room-facilities-info">
                          {room?.facilities?.map((facility, index) => (
                            <FacilityRoom
                              nameFacility={facility.name}
                              key={index}
                            />
                          ))}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="checkout-boxes">
                <div className="form-billing_div mt-2">
                  <div>
                    <h5>Guests for this room</h5>
                    <Divider />
                    <li className="mb-2">
                      {
                        bookInformation?.hotelBooking.roomsAllocations[0]
                          .guests[0].firstName
                      }{" "}
                      {
                        bookInformation?.hotelBooking.roomsAllocations[0]
                          .guests[0].lastName
                      }{" "}
                    </li>
                  </div>
                  <div>
                    <h5>Billing Information</h5>
                    <Divider />

                    <p>
                      Name:{" "}
                      <span>
                        {bookInformation?.hotelBooking.billingContact?.firstName}{" "}
                        {bookInformation?.hotelBooking.billingContact?.lastName}
                      </span>
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {
                          bookInformation?.hotelBooking.billingContact?.contact
                            .phone
                        }
                      </span>
                    </p>
                    <p>
                      Email:{" "}
                      <span>
                        {
                          bookInformation?.hotelBooking.billingContact?.contact
                            .email
                        }
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="checkout-boxes">
                <div className="mb-2">
                  <span className="form-titles">Confirmation number</span>
                </div>
                <div className="mb-2">
                  {bookInformation?.hotelBooking.providerConfirmationNumber}
                </div>
              </div>
              <div className="checkout-boxes">
                <div className="mb-2">
                  <span className="form-titles">Check In Instructions</span>
                </div>
                {bookInformation?.hotelBooking.rates[0].policies[0] ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${DOMPurify.sanitize(
                        decodeHtmlEntities(
                          bookInformation.hotelBooking.rates[0].policies[0].text
                        )
                      )}`,
                    }}
                  />
                ) : (
                  <p>This room does not have any check in policies</p>
                )}
              </div>
              <div className="checkout-boxes">
                <div className="mb-2">
                  <span className="form-titles">Cancellation Policy</span>
                </div>
                {bookInformation?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                  (item, index) => (
                    <div key={index}>
                      Cancellation penalty between{" "}
                      {moment(item.start).format("MM/DD/YYYY")} to{" "}
                      {moment(item.end).format("MM/DD/YYYY")} is{" "}
                      {bookInformation.hotelBooking.rates[0].currency}
                      {bookInformation.hotelBooking.rates[0].currency ===
                        "USD" && " $"}
                      {item.estimatedValue}
                    </div>
                  )
                )}
              </div>
              <div className="checkout-boxes">
                <div className="mb-2">
                  <span className="form-titles">Hotel Description</span>
                </div>
                {bookInformation?.hotelBooking.hotel?.descriptions?.map(
                  (description, index) => (
                    <div key={index}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${DOMPurify.sanitize(description.text)}`,
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="sidebar-form">
              <div className="aside_info">
                <div className="sticky_aside">
                  <div className="sidebar_info">
                    <div className="first_info">
                      <div className="first_info_box">
                        <div className="first mt-1">
                          <span>Number of nights</span>
                          <span>
                            {numberOfNights(
                              bookInformation?.hotelBooking.tripStartDate.toString(),
                              bookInformation?.hotelBooking.tripEndDate.toString()
                            )}{" "}
                          </span>
                        </div>
                        <div className="first mt-1">
                          <span>Booking Status</span>
                          <span>
                            {bookInformation?.hotelBooking.bookingStatus}
                          </span>
                        </div>
                        <hr />
                        <div className="first" id="base-rate-section">
                          <b>Base rate</b>
                          <span>
                            $
                            {sumBaseRate(
                              bookInformation?.hotelBooking.rates
                            ).toFixed(2)}
                          </span>
                        </div>
                        <div className="room_line" id="number-rooms-section">
                          <span>
                            {
                              bookInformation?.hotelBooking.roomsAllocations
                                .length
                            }{" "}
                            {bookInformation?.hotelBooking.roomsAllocations
                              .length &&
                            bookInformation?.hotelBooking.roomsAllocations
                              .length > 1
                              ? "Rooms"
                              : "Room"}
                          </span>
                        </div>
                      </div>{" "}
                      {bookInformation?.hotelBooking.rates[0]
                        .otherRateComponents != undefined &&
                        bookInformation?.hotelBooking.rates[0]
                          .otherRateComponents.length > 0 &&
                        bookInformation?.hotelBooking.rates[0]
                          .otherRateComponents[0].amount !== undefined && (
                          <div className="first mt-1" id="other-rate-section">
                            <span>Service Fee</span>
                            <span>
                              {" "}
                              {
                                bookInformation.hotelBooking?.rates[0].currency
                              }{" "}
                              {bookInformation.hotelBooking?.rates[0]
                                .currency === "USD"
                                ? "$"
                                : " "}
                              {bookInformation.hotelBooking.rates[0].otherRateComponents[0].amount.toFixed(
                                2
                              )}
                            </span>
                          </div>
                        )}
                      {bookInformation?.hotelBooking.optionalFeeState !=
                        undefined &&
                        bookInformation?.hotelBooking.optionalFeeState ===
                          "Fee Charged" &&
                        bookInformation?.hotelBooking.optionalFeeCharged !=
                          undefined &&
                        bookInformation?.hotelBooking.optionalFeeCharged >
                          0 && (
                          <div className="first mt-1" id="other-rate-section">
                            <span>CreditCard Fee</span>
                            <span>
                              {" "}
                              {
                                bookInformation.hotelBooking?.rates[0].currency
                              }{" "}
                              {bookInformation.hotelBooking?.rates[0]
                                .currency === "USD"
                                ? "$"
                                : " "}
                              {bookInformation?.hotelBooking.optionalFeeCharged.toFixed(
                                2
                              )}
                            </span>
                          </div>
                        )}
                      <hr />
                      {sumAdditionalFee() > 0 && (
                        <div className="first mt-1" id="total-taxes">
                          <b>Total Additional Fees</b>
                          <b>USD ${sumAdditionalFee().toFixed(2)}</b>
                        </div>
                      )}
                      {bookInformation?.hotelBooking.rates[0].taxes !==
                        undefined &&
                        bookInformation?.hotelBooking.rates[0].taxes.map(
                          (item, index) => (
                            <div
                              key={`${item}${index}`}
                              className="first mt-1"
                              id="tax-section"
                            >
                              <span>{readableTaxes(item.description)}</span>
                              <span>
                                {" "}
                                {
                                  bookInformation.hotelBooking?.rates[0]
                                    .currency
                                }{" "}
                                {bookInformation.hotelBooking?.rates[0]
                                  .currency === "USD"
                                  ? "$"
                                  : " "}
                                {item.amount.toFixed(2)}
                              </span>
                            </div>
                          )
                        )}
                      <hr />
                      {bookInformation?.hotelBooking.rates[0].taxes !==
                        undefined && (
                        <div className="first mt-1" id="total-taxes">
                          <b>Total Taxes</b>
                          <b>
                            {" "}
                            {
                              bookInformation.hotelBooking?.rates[0].currency
                            }{" "}
                            {bookInformation.hotelBooking?.rates[0].currency ===
                            "USD"
                              ? "$"
                              : " "}
                            {CalculateTotalTaxes(
                              bookInformation?.hotelBooking.rates
                            ).toFixed(2)}
                          </b>
                        </div>
                      )}
                      {bookInformation?.hotelBooking.rates[0]
                        .additionalCharges !== undefined &&
                        bookInformation?.hotelBooking.rates[0].additionalCharges.map(
                          (item, index) => (
                            <div
                              key={`${item}${index}`}
                              className="first mt-1"
                              id="fee-section"
                            >
                              <span>{readableFee(item.charge.type)}</span>
                              <span>
                                {" "}
                                {
                                  bookInformation.hotelBooking?.rates[0]
                                    .currency
                                }{" "}
                                {bookInformation.hotelBooking?.rates[0]
                                  .currency === "USD"
                                  ? "$"
                                  : " "}
                                {item.charge.amount.toFixed(2)}
                              </span>
                            </div>
                          )
                        )}
                      {bookInformation?.hotelBooking.rates != undefined &&
                        bookInformation?.hotelBooking.rates.length > 0 &&
                        bookInformation?.hotelBooking.rates[0]
                          .additionalCharges !== undefined && (
                          <>
                            <hr />
                            <div className="first mt-1">
                              <b>Total Fees</b>
                              <b>
                                {" "}
                                {
                                  bookInformation.hotelBooking?.rates[0]
                                    .currency
                                }{" "}
                                {bookInformation.hotelBooking?.rates[0]
                                  .currency === "USD"
                                  ? "$"
                                  : " "}
                                {sum(
                                  sumAddCharges(
                                    bookInformation?.hotelBooking.rates
                                  )
                                )}
                              </b>
                            </div>
                          </>
                        )}
                    </div>

                    <hr />
                    <div className="second_info">
                      <div>
                        <span className="green">You Paid Today</span>
                        <span className="green">
                          $
                          {sum(
                            sumBaseRate(bookInformation?.hotelBooking?.rates),
                            bookInformation?.hotelBooking?.rates[0].taxes &&
                              bookInformation?.hotelBooking?.rates[0].taxes
                                .length > 0
                              ? sumTaxes(bookInformation?.hotelBooking?.rates)
                              : 0,
                            sumAddRates(bookInformation?.hotelBooking?.rates),
                            sumOptionalFees()
                          )}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="second_info">
                      <div>
                        <span>Total charges</span>
                        <span>
                          $
                          {bookInformation &&
                            sum(
                              sumBaseRate(bookInformation.hotelBooking?.rates),
                              bookInformation.hotelBooking?.rates[0]
                                .additionalCharges &&
                                bookInformation.hotelBooking?.rates[0]
                                  .additionalCharges.length > 0
                                ? sumAddCharges(
                                    bookInformation.hotelBooking?.rates
                                  )
                                : 0,
                              bookInformation.hotelBooking?.rates[0].taxes &&
                                bookInformation.hotelBooking?.rates[0].taxes
                                  .length > 0
                                ? CalculateTotalTaxes(
                                    bookInformation.hotelBooking?.rates
                                  )
                                : 0,
                              (bookInformation.hotelBooking.rates[0]
                                .otherRateComponents &&
                                bookInformation.hotelBooking.rates[0]
                                  .otherRateComponents.length > 0 &&
                                bookInformation.hotelBooking.rates[0]
                                  .otherRateComponents[0].amount) ||
                                0,
                              sumOptionalFees()
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ThankYou;
