import React from "react";
import { Card, Col, Row, Image, Carousel } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IHotelFullInformation } from "../../Models/HotelContent";
import InformationHotelSection from "./InformationHotelSection";
import PriceSection from "./PriceSection";
import MainHotelImage from "./MainHotelImage";
import { useViewport } from "../../Context/useViewport";
import { RoutesPath } from "../../Routes";
import noImage from "../../img/icons/no-image.png";

interface HotelCardProps {
  hotel: IHotelFullInformation | undefined;
  className?: string;
  correlationId: string;
  isRecomended?: boolean;
  hotelIndex: string;
  discount: number | undefined;
}

const HotelCard: React.FC<HotelCardProps> = ({
  hotel,
  correlationId,
  className,
  isRecomended,
  hotelIndex,
  discount,
}: HotelCardProps) => {
  const { width } = useViewport();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const rooms = searchParams.get("rooms");
  const currency = searchParams.get("currency") || "USD";
  const checkIn = searchParams.get("checkin");
  const checkOut = searchParams.get("checkout");
  const locationParam = searchParams.get("countryname");
  const handleClick = () => {
    navigate(
      `${RoutesPath.hotelDetail}?hotelId=${hotel?.id}&countryname=${locationParam}&checkin=${checkIn}&checkout=${checkOut}&adults=${adults}&children=${children}&rooms=${rooms}&correlationId=${correlationId}&currency=${currency}&nationality=US&countryOfResidence=US&culture=en-US`
    );
  };
  const redirectTohotelDetail = () => {
    if (width <= 480) {
      handleClick();
    }
  };

  return (
    <Col span={24} className={`${className} p-3`}>
      <Card
        hoverable
        bodyStyle={{ padding: "0" }}
        className="hotel-card"
        onClick={redirectTohotelDetail}
      >
        <Row>
          {hotel && (
            <>
              {hotel.images.length > 0 ? (
                <MainHotelImage
                  images={[
                    {
                      category: "hotel",
                      roomCodes: undefined,
                      links: [{ size: "xl", url: hotel.heroImage }],
                    },
                    ...hotel.images,
                  ]}
                  altText={hotel.name}
                />
              ) : (
                <Col
                  xs={10}
                  lg={8}
                  className={`hotel-img-wrapper ${className}`}
                >
                  <Carousel>
                    <div
                      className="img-wrapper"
                      style={{ height: "480px", overflow: "hidden" }}
                    >
                      <Image width="100%" src={noImage} />
                    </div>
                  </Carousel>
                </Col>
              )}

              <InformationHotelSection
                id={hotel.id}
                name={hotel.name}
                hotelIndex={hotelIndex}
                country={hotel.contact.address.country.name}
                city={hotel.contact.address.city.name}
                facilities={hotel.facilityGroups}
                correlationId={correlationId}
                startRating={hotel.starRating}
                isRecomended={isRecomended}
                score={hotel.relevanceScore}
                totalReviews={hotel.reviews?.length}
                supplier={hotel.rate.providerId}
                currency={currency || ""}
                clickNavigate={handleClick}
              />

              <PriceSection
                rate={hotel.rate.baseRate}
                supplier={hotel.rate.providerId}
                discount={discount || 0}
                clickNavigate={handleClick}
              />
            </>
          )}
        </Row>
      </Card>
    </Col>
  );
};
export default HotelCard;
