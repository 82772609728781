import React, { ReactElement, useState } from "react";
import SearchBoxForm from "../SearchBoxForm";

const SearchBoxWidget = (): ReactElement => {
  const [isWpWidget] = useState<boolean>(true);
  const [showAlert, setAlert] = useState(false);
  const [Errmsg, setErrmsg] = useState("");
  return (
    <div className="wp-card">
      <div className="p-2">
        <SearchBoxForm
          Err={false}
          onChangeUbication={() => {}}
          onUpdate={() => {}}
          isWpWidget={isWpWidget}
          setErr={setAlert}
          setErrmsg={setErrmsg}
        />
      </div>
    </div>
  );
};
export default SearchBoxWidget;
