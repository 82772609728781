import React, { useState, useEffect, ReactElement, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Modal,
  Button,
  Result,
  Tooltip,
  Input,
  Alert,
  DatePicker,
  Radio,
  Space,
  Menu,
  Divider,
  Spin,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { onAuthStateChanged } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faSliders } from "@fortawesome/free-solid-svg-icons";
import {
  cancelBooking,
  cancelBookingReason,
  getBookingDetails,
  getBookings,
  postCancelBooking,
  postCancelReasonBooking,
  sendEmail,
  sendEmailCancelBooking,
} from "../../../helpers/requestBookings";
import {
  IBookTransform,
  IBookingsListResponse,
} from "../../../Models/BookingsList";
import LoaderComponent from "../../../Components/LoaderComponent";
import { SearchContext } from "../../../Context/context";
import { ISearch } from "../../../Models/Search";
import { useScrollLock } from "../../../helpers/scrollLock";
import { useViewport } from "../../../Context/useViewport";
import BookingCard from "../../../Components/BookingCard";
import { IBookDetailResponse, Rule } from "../../../Models/BookDetailRequest";
import { EBookingSatus } from "../../../Common/Enums";
import { IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { formatDateStringWithAMPM } from "../../../helpers/validationsInputs";
import { SortOrder } from "antd/lib/table/interface";
import PriceMatchComponent from "../../../Components/ExpandableRowBookList";

interface objTransform {
  key: number;
  bookId: number;
  hotelId: string;
  hotelname: string;
  status: string;
  providerId: string;
  checkIn: string;
  checkOut: string;
  cancel: number | string;
  creationDate: string;
}

const { TextArea } = Input;

const BookList = (): ReactElement => {
  interface DataType {
    key: React.Key;
    reason: string;
  }
  const columnsCancel: ColumnsType<DataType> = [
    {
      title: "Why are you cancelling?",
      dataIndex: "reason",
      render: (text) => <span className="mx-2">{text}</span>,
    },
  ];

  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisiblemail, setIsModalVisiblemail] = useState(false);
  const [bookInformation, setbookInformation] = useState<IBookDetailResponse>();
  const [bookId, setBookId] = useState<string>("");
  const { width } = useViewport();
  const [correlationId, setcorrelationId] = useState<string>("");
  const { unlockScroll } = useScrollLock();
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [email, setemail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notLogged, setnotLogged] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [bookTransform, setbookTransform] = useState<IBookTransform[]>([]);
  const [selectedReason, setSelectedReason] = useState<string>();
  const [otherReason, setOtherReason] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [loadingCancelModal, setLoadingCancelModal] = useState(false);
  const [dataReason, setDataReason] = useState<DataType[]>();
  const [reasonObject, setReasonObject] = useState<DataType>();
  const [bookList, setbookList] = useState<IBookTransform[]>([]);
  const [dotwScreen, setDotwScreen] = useState<boolean>(false);
  const [priceClaim, setpriceClaim] = useState<boolean>();
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );

  const [filterObject, setfilterObject] = useState({
    BookingId: "",
    ProviderConfirmationNumber: "",
    BookingStatus: "",
    HotelId: "",
    HotelName: "",
    CheckInDateInit: "",
    CheckInDateEnd: "",
    CheckOutDateInit: "",
    CheckOutDateEnd: "",
    CreationDateInit: "",
    CreationDateEnd: "",
  });

  const navigate = useNavigate();

  const { SubMenu } = Menu;

  const setInfo = (response: IBookingsListResponse[]) => {
    const newInfo = response.map((item) => {
      const obj: objTransform = {
        key: item.bookingId,
        bookId: item.bookingId,
        hotelId: item.hotelId,
        hotelname: item.hotelName,
        status: item.bookingStatus,
        providerId: item.providerConfirmationNumber,
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        creationDate: item.creationDate,
        cancel:
          item.bookingStatus !== EBookingSatus.Cancelled
            ? item.bookingId
            : "Cancelled",
      };
      return obj;
    });
    setbookTransform(
      newInfo.sort((a, b) => (a.checkOut < b.checkOut ? -1 : 1))
    );
    setLoading(false);
  };

  const handleNavigate = (id: string) =>
    navigate(`/bookings/detail?bookId=${id}`);

  const constructFilter = (optionalFilter?: {}) => {
    setLoading(true);
    const URLsearchParams = new URLSearchParams(optionalFilter ?? filterObject);
    getBookingsList(URLsearchParams.toString());
    setLoading(false);
  };

  const getBookingsList = async (params?: string) => {
    setLoading(true);
    const listener = onAuthStateChanged(searchContent.auth, async (user) => {
      if (user?.email) {
        const correlationIdBook = uuidv4();
        setcorrelationId(correlationIdBook);
        try {
          const response = await getBookings(
            user.email,
            correlationIdBook,
            params
          );
          setInfo(response);
        } catch (err) {
          console.error(err);
        }
      } else {
        setnotLogged(true);
        setLoading(false);
      }
    });
    listener();
  };

  const showErrorMessage = () => {
    Modal.error({
      title: "Booking Cancellation Error",
    });
  };

  const filterIconSearch = () => (
    <FontAwesomeIcon icon={faSearch as IconProp} />
  );

  const handleFilter = () => {
    constructFilter();
  };

  const getCancelReason = async () => {
    const data = await cancelBookingReason();
    const newData = data.map((item: { id: any; reason: any }) => {
      const obj = {
        key: item.id,
        reason: item.reason,
      };
      return obj;
    });
    setDataReason(newData);
  };

  const hasAtLeastDifferentLetters = (str: string) => {
    const uniqueLetters = str.match(/[a-zA-Z]/g);

    return uniqueLetters && new Set(uniqueLetters).size >= 5;
  };

  const handleConfirm = async () => {
    setLoadingCancelModal(true);
    try {
      const response = await cancelBooking(bookId, correlationId);
      setOpenModal(false);
      setCurrentStep(1);
      setLoadingCancelModal(false);

      if (response.bookingStatus !== "Cancelled") {
        showErrorMessage();
      } else {
        const newReasonObject = {
          cancelReasonId: reasonObject?.key,
          reason: otherReason || "",
        };
        await postCancelReasonBooking(bookId, newReasonObject);
        await postCancelBooking(bookId, response);
        getBookingsList();
        Modal.info({
          title: "Cancel",
          content: "Your booking has been cancelled succesfully",
        });
      }
      setSelectedReason("");
      setOtherReason("");
    } catch (err) {
      setCurrentStep(1);
      console.error(err);
      setSelectedReason("");
      setOtherReason("");
    }
  };

  const handleSendEmail = async () => {
    setLoadingEmail(true);
    try {
      if (bookId && bookInformation) {
        if (
          bookInformation?.hotelBooking.rooms &&
          bookInformation.hotelBooking.bookingStatus.toUpperCase() ===
            "CONFIRMED"
        )
          await sendEmail(
            email,
            "NewBooking",
            webConfigContext.selectedChannel ?? "",
            bookId
          );
        if (
          bookInformation.hotelBooking.bookingStatus.toUpperCase() ===
          "CANCELLED"
        ) {
          await sendEmailCancelBooking(bookId);
        }

        Modal.info({
          title: "Email re-sent!",
          content: "The email has been re-sent!",
        });
      }

      setLoadingEmail(false);
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  const reSendEmail = async (id: string) => {
    setBookId(id);
    setIsModalVisiblemail(true);
    setLoadingEmail(true);
    const obj = {
      channelId: localStorage.getItem("REACT_APP_CHANNEL_ID") ?? "",
      bookingId: id.toString(),
    };
    try {
      const response = await getBookingDetails(obj, correlationId);
      setbookInformation(response);
      setemail(response.hotelBooking.billingContact?.contact.email ?? "");
      setLoadingEmail(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getBookingDetail = async (id: string) => {
    setDotwScreen(false);
    const obj = {
      channelId: localStorage.getItem("REACT_APP_CHANNEL_ID") ?? "",
      bookingId: id.toString(),
    };
    try {
      const response = await getBookingDetails(obj, correlationId);
      setbookInformation(response);
      if (
        response &&
        response.hotelBooking &&
        response.hotelBooking.rates &&
        response.hotelBooking.rates.length > 0 &&
        response.hotelBooking.rates[0].cancellationPolicies &&
        response.hotelBooking.rates[0].cancellationPolicies.length > 0 &&
        response.hotelBooking.rates[0].cancellationPolicies[0].rules &&
        response.hotelBooking.rates[0].cancellationPolicies[0].rules.length > 0
      ) {
        const currentDate = new Date();
        const hasValidCancellationPolicy =
          response.hotelBooking.rates[0].cancellationPolicies[0].rules.some(
            (rule: Rule) =>
              (rule.estimatedValue >=
                response.hotelBooking.rates[0].totalRate &&
                currentDate >= new Date(rule.start)) ||
              (rule.value == 100 &&
                rule.valueType == "Percentage" &&
                currentDate >= new Date(rule.start))
          );
        setDotwScreen(hasValidCancellationPolicy);
      }

      setLoadingCancelModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const confirmCancel = (id: string) => {
    setLoadingCancelModal(true);
    getBookingDetail(id);
    setOpenModal(true);
    setBookId(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisiblemail(false);
  };

  const returnSearchBookID = () => (
    <div className="p-2 booking-filter-box">
      <Input
        className="filter-input"
        placeholder="Search by ID"
        autoFocus
        value={filterObject.BookingId}
        onChange={(e) =>
          setfilterObject({ ...filterObject, BookingId: e.target.value })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, BookingId: "" });
            constructFilter({ ...filterObject, BookingId: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchConfirmation = () => (
    <div className="p-2 booking-filter-box">
      <Input
        className="filter-input"
        placeholder="Search by Confirmation Number"
        autoFocus
        value={filterObject.ProviderConfirmationNumber}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            ProviderConfirmationNumber: e.target.value,
          })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              ProviderConfirmationNumber: "",
            });
            constructFilter({
              ...filterObject,
              ProviderConfirmationNumber: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchStatus = () => (
    <div className="p-2 booking-filter-box">
      <Radio.Group
        value={filterObject.BookingStatus}
        onChange={(e) =>
          setfilterObject({ ...filterObject, BookingStatus: e.target.value })
        }
      >
        <Space direction={width < 1200 ? "horizontal" : "vertical"}>
          <Radio value="Confirmed">Confirmed</Radio>
          <Radio value="Cancelled">Cancelled</Radio>
        </Space>
      </Radio.Group>
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, BookingStatus: "" });
            constructFilter({ ...filterObject, BookingStatus: "" });
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          className="ml-3"
          onClick={handleFilter}
        >
          Ok
        </Button>
      </div>
    </div>
  );

  const returnSearchHotelID = () => (
    <div className="p-2 booking-filter-box">
      <Input
        className="filter-input"
        placeholder="Search by Hotel ID"
        autoFocus
        value={filterObject.HotelId}
        onChange={(e) =>
          setfilterObject({ ...filterObject, HotelId: e.target.value })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, HotelId: "" });
            constructFilter({ ...filterObject, HotelId: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchHotelName = () => (
    <div className="p-2 booking-filter-box">
      <Input
        placeholder="Search by Hotel Name"
        autoFocus
        value={filterObject.HotelName}
        onChange={(e) =>
          setfilterObject({ ...filterObject, HotelName: e.target.value })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, HotelName: "" });
            constructFilter({ ...filterObject, HotelName: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchCheckIn = () => (
    <div className="p-2 booking-filter-box">
      <DatePicker.RangePicker
        format="MM/DD/YYYY"
        onChange={(dates, dateStrings) => {
          setfilterObject({
            ...filterObject,
            CheckInDateInit: dateStrings[0],
            CheckInDateEnd: dateStrings[1],
          });
        }}
        value={
          filterObject.CheckInDateInit && filterObject.CheckInDateEnd
            ? [
                moment(filterObject.CheckInDateInit, "MM/DD/YYYY"),
                moment(filterObject.CheckInDateEnd, "MM/DD/YYYY"),
              ]
            : null
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              CheckInDateInit: "",
              CheckInDateEnd: "",
            });
            constructFilter({
              ...filterObject,
              CheckInDateInit: "",
              CheckInDateEnd: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchCheckOut = () => (
    <div className="p-2 booking-filter-box">
      <DatePicker.RangePicker
        format="MM/DD/YYYY"
        onChange={(dates, dateStrings) => {
          setfilterObject({
            ...filterObject,
            CheckOutDateInit: dateStrings[0],
            CheckOutDateEnd: dateStrings[1],
          });
        }}
        value={
          filterObject.CheckOutDateInit && filterObject.CheckOutDateEnd
            ? [
                moment(filterObject.CheckOutDateInit, "MM/DD/YYYY"),
                moment(filterObject.CheckOutDateEnd, "MM/DD/YYYY"),
              ]
            : null
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              CheckOutDateInit: "",
              CheckOutDateEnd: "",
            });
            constructFilter({
              ...filterObject,
              CheckOutDateInit: "",
              CheckOutDateEnd: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchCreationDate = () => (
    <div className="p-2 booking-filter-box">
      <DatePicker.RangePicker
        format="MM/DD/YYYY"
        onChange={(dates, dateStrings) => {
          setfilterObject({
            ...filterObject,
            CreationDateInit: dateStrings[0],
            CreationDateEnd: dateStrings[1],
          });
        }}
        value={
          filterObject.CreationDateInit && filterObject.CreationDateEnd
            ? [
                moment(filterObject.CreationDateInit, "MM/DD/YYYY"),
                moment(filterObject.CreationDateEnd, "MM/DD/YYYY"),
              ]
            : null
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              CreationDateInit: "",
              CreationDateEnd: "",
            });
            constructFilter({
              ...filterObject,
              CreationDateInit: "",
              CreationDateEnd: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const checkDates = (item: IBookTransform) => {
    if (filterObject.CheckInDateEnd && filterObject.CheckInDateInit) {
      const startCheck = new Date(filterObject.CheckInDateInit).getTime();
      const endCheck = new Date(filterObject.CheckInDateEnd).getTime();
      const check = new Date(item.checkIn.substring(0, 10)).getTime();
      if (check > endCheck || check < startCheck) return false;
    }
    if (filterObject.CheckOutDateEnd && filterObject.CheckOutDateInit) {
      const startCheck = new Date(filterObject.CheckOutDateInit).getTime();
      const endCheck = new Date(filterObject.CheckOutDateEnd).getTime();
      const check = new Date(item.checkOut.substring(0, 10)).getTime();
      if (check > endCheck || check < startCheck) return false;
    }
    if (filterObject.CreationDateEnd && filterObject.CreationDateInit) {
      const startCheck = new Date(filterObject.CreationDateInit).getTime();
      const endCheck = new Date(filterObject.CreationDateEnd).getTime();
      const check = new Date(item.creationDate.substring(0, 10)).getTime();
      if (check > endCheck || check < startCheck) return false;
    }

    return true;
  };

  const handleOkModal = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleCloseModal = () => {
    setSelectedReason("");
    setOtherReason("");
    setOpenModal(false);
    setCurrentStep(1);
    setImageFiles([]);
    setpriceClaim(undefined);
  };

  const handleTextAreaChange = (e: { target: { value: any } }) => {
    setOtherReason(e.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedReason(selectedRows[0].reason);
      setReasonObject(selectedRows[0]);
    },
  };

  useEffect(() => {
    const copyArr = bookList;
    setbookTransform(
      copyArr.filter(
        (item) =>
          item.bookId.toString().includes(filterObject.BookingId) &&
          item.providerId.includes(filterObject.ProviderConfirmationNumber) &&
          item.status
            .toLowerCase()
            .includes(filterObject.BookingStatus.toLowerCase()) &&
          item.hotelId.includes(filterObject.HotelId) &&
          item.hotelname
            .toLowerCase()
            .includes(filterObject.HotelName.toLowerCase()) &&
          checkDates(item)
      )
    );
  }, [filterObject]);

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "bookId",
      key: "bookId",
      width: 160,
      sorter: (a: any, b: any) => a.bookId - b.bookId,
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchBookID(),
      render: (id: string) => (
        <button
          type="button"
          className="btn btn-primary"
          aria-hidden="true"
          onClick={() => handleNavigate(id)}
        >
          #{id}
        </button>
      ),
    },
    {
      title: "Confirmation Number",
      dataIndex: "providerId",
      key: "providerId",
      width: 140,
      sorter: (a: any, b: any) => (a.providerId < b.providerId ? -1 : 1),
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchConfirmation(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      sorter: (a: any, b: any) =>
        a.status.toLowerCase() < b.status.toLowerCase() ? -1 : 1,
      filterDropdown: () => returnSearchStatus(),
    },
    {
      title: "Hotel ID",
      dataIndex: "hotelId",
      key: "hotelId",
      width: 130,
      sorter: (a: any, b: any) => (a.hotelId < b.hotelId ? -1 : 1),
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchHotelID(),
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelname",
      key: "hotelname",
      width: 180,
      sorter: (a: any, b: any) =>
        a.hotelname.toLowerCase() < b.hotelname.toLowerCase() ? 1 : 1,
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchHotelName(),
    },
    {
      title: "Check In",
      dataIndex: "checkIn",
      key: "checkIn",
      width: 140,
      sorter: (a: any, b: any) => (a.checkIn < b.checkIn ? 1 : 1),
      render: (value: string) =>
        value ? new Date(value).toLocaleDateString("en-US") : "",
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchCheckIn(),
    },
    {
      title: "Check Out",
      dataIndex: "checkOut",
      key: "checkOut",
      width: 140,
      sorter: (a: any, b: any) => (a.checkOut < b.checkOut ? 1 : 1),
      render: (value: string) =>
        value ? new Date(value).toLocaleDateString("en-US") : "",
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchCheckOut(),
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 140,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: any, b: any) => (a.creationDate < b.creationDate ? -1 : 1),
      render: (value: string) => formatDateStringWithAMPM(value),
      filterIcon: () => filterIconSearch(),
      filterDropdown: () => returnSearchCreationDate(),
    },
    {
      title: "Cancel Booking",
      dataIndex: "cancel",
      key: "cancel",
      width: 80,
      render: (id?: string) => (
        <>
          {id === "Cancelled" ? (
            <span>Cancelled</span>
          ) : (
            <Tooltip title="You cannot cancel a booking after the checkin date.">
              <span
                className="text-sky-blue"
                role="button"
                aria-hidden="true"
                onClick={() => {
                  if (id) confirmCancel(id);
                }}
              >
                Manage bookings
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Re-send email",
      dataIndex: "bookId",
      key: "bookId",
      width: 80,
      render: (id?: string) => (
        <>
          <span
            className="text-sky-blue"
            role="button"
            aria-hidden="true"
            onClick={() => {
              if (id) reSendEmail(id);
            }}
          >
            Re-send email
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    getCancelReason();
    unlockScroll();
    getBookingsList();
  }, []);

  return (
    <div>
      {loading && <LoaderComponent title="Loading Bookings..." />}
      {!loading && notLogged && (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, you need to log in, in order to see your bookings."
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Back Home
            </Button>
          }
        />
      )}
      {!loading && !notLogged && (
        <>
          <Modal
            title={`We are sorry you are cancelling, ${bookInformation?.hotelBooking.billingContact?.firstName}`}
            open={openModal}
            width={1000}
            onCancel={handleCloseModal}
            footer={
              currentStep === 1
                ? [
                    <Button key="back" onClick={handleCloseModal}>
                      Cancel
                    </Button>,
                    <>
                      {!priceClaim && (
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleOkModal}
                          disabled={
                            !selectedReason
                              ? true
                              : selectedReason === "Other"
                              ? !(
                                  otherReason.length > 0 &&
                                  hasAtLeastDifferentLetters(otherReason)
                                )
                              : false
                          }
                        >
                          Next
                        </Button>
                      )}
                    </>,
                  ]
                : [
                    <Button key="back" onClick={handleCloseModal}>
                      I want to keep my booking
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handleConfirm}
                      className="button-cancel-modal"
                    >
                      I understand the cancellation policy and I wish to cancel
                    </Button>,
                  ]
            }
          >
            {!loadingCancelModal && currentStep === 1 ? (
              <>
                {dotwScreen ? (
                  <div className="mt-3">
                    <span className="small-titles">
                      We are very sorry this booking cannot longer be cancelled
                      due to the penalty being equal the total rate
                    </span>
                    <ul>
                      {bookInformation &&
                        bookInformation.hotelBooking &&
                        bookInformation.hotelBooking.rates &&
                        bookInformation.hotelBooking.rates.length > 0 &&
                        bookInformation.hotelBooking.rates[0].providerName &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies.length > 0 &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies[0].rules &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies[0].rules.length > 0 &&
                        bookInformation.hotelBooking.rates[0].providerName.toLowerCase() ===
                          "dotw" &&
                        bookInformation?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                          (item) => (
                            <li>
                              Cancellation Penalty between{" "}
                              {moment(item.start).format("MM/DD/YYYY")} to{" "}
                              {moment(item.end).format("MM/DD/YYYY")} is{" "}
                              {bookInformation.hotelBooking.rates[0].currency}
                              {bookInformation.hotelBooking.rates[0]
                                .currency === "USD" && "$"}{" "}
                              {item.estimatedValue.toFixed(2)}
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                ) : (
                  <>
                    <Table
                      columns={columnsCancel}
                      dataSource={dataReason}
                      pagination={false}
                      rowSelection={{
                        type: "radio",
                        ...rowSelection,
                      }}
                      expandable={{
                        expandedRowRender: (record) => (
                          <PriceMatchComponent
                            bookID={bookId}
                            setpriceClaim={setpriceClaim}
                            priceClaim={priceClaim}
                            setImageFiles={setImageFiles}
                            imageFiles={imageFiles}
                            setOpenModal={setOpenModal}
                          />
                        ),
                        expandedRowKeys:
                          selectedReason == "Found a better price" ? [1] : [0],
                        showExpandColumn: false,
                      }}
                    />
                    {selectedReason === "Other" && (
                      <TextArea
                        rows={4}
                        placeholder="Please state your reason"
                        className="mt-2"
                        onChange={handleTextAreaChange}
                        value={otherReason}
                      />
                    )}
                  </>
                )}
              </>
            ) : !loadingCancelModal && currentStep === 2 ? (
              <div className="mt-3">
                <span className="small-titles">Cancellation Policy</span>
                <ul>
                  {bookInformation?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                    (item) => (
                      <li>
                        Cancellation Penalty between{" "}
                        {moment(item.start).format("MM/DD/YYYY")} to{" "}
                        {moment(item.end).format("MM/DD/YYYY")} is{" "}
                        {bookInformation.hotelBooking.rates[0].currency}
                        {bookInformation.hotelBooking.rates[0].currency ===
                          "USD" && "$"}{" "}
                        {item.estimatedValue.toFixed(2)}
                      </li>
                    )
                  )}
                </ul>
                <strong className="text-danger">
                  {" "}
                  I acknowledge and agree to the cancellation terms and policies
                  and accept any any all penalities and charges if they apply
                </strong>
              </div>
            ) : (
              <div className="text-center">
                <Spin size="large" />
              </div>
            )}
          </Modal>
          <div className="book-title mt-3">
            <h4>Booking List</h4>
          </div>
          {width < 1200 ? (
            <>
              <Menu mode="inline" className="pb-3">
                <SubMenu
                  key="filters"
                  title={
                    <span className="filter-menu-title">
                      <FontAwesomeIcon icon={faSliders as IconProp} />
                      <span>Filters</span>
                    </span>
                  }
                >
                  <Menu.Item className="filter-menu-item" key="filterBookId">
                    {returnSearchBookID()}
                  </Menu.Item>
                  <Menu.Item
                    className="filter-menu-item"
                    key="filterConfNumber"
                  >
                    {returnSearchConfirmation()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterStatus">
                    {returnSearchStatus()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterHotelId">
                    {returnSearchHotelID()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterName">
                    {returnSearchHotelName()}
                  </Menu.Item>
                  <div>
                    <span className="ps-2">Check In</span>
                    <Menu.Item
                      className="filter-menu-item my-2"
                      key="filterCheckIn"
                    >
                      {returnSearchCheckIn()}
                    </Menu.Item>
                  </div>
                  <div>
                    <span className="ps-2">Check Out</span>
                    <Menu.Item
                      className="filter-menu-item my-2"
                      key="filterCheckOut"
                    >
                      {returnSearchCheckOut()}
                    </Menu.Item>
                  </div>
                  <div>
                    <span className="ps-2">Book Date</span>
                    <Menu.Item
                      className="filter-menu-item my-2"
                      key="filterBookDate"
                    >
                      {returnSearchCreationDate()}
                    </Menu.Item>
                  </div>
                </SubMenu>
              </Menu>
              <BookingCard
                bookTransform={bookTransform}
                confirmCancel={confirmCancel}
                handleNavigate={handleNavigate}
              />
            </>
          ) : (
            <>
              <Divider />
              <div
                className="card rounded-dp shadow-lg"
                style={{ width: "100%" }}
              >
                <div className="card-body">
                  <Table
                    dataSource={bookTransform}
                    columns={columns}
                    scroll={{ x: 240 }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      <Modal
        title="Cancel Booking"
        open={isModalVisible}
        okButtonProps={{ loading: loadingCancel }}
        onOk={handleConfirm}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to cancel this booking?</p>
      </Modal>
      <Modal
        title="Re-send email"
        open={isModalVisiblemail}
        okButtonProps={{ loading: loadingEmail }}
        onOk={handleSendEmail}
        onCancel={handleCancel}
      >
        {error && (
          <Alert
            type="error"
            message="Error"
            description="Something went wrong please try again later"
            closable
            className="dateAlert"
          />
        )}
        <p>Email</p>
        <Input
          value={email}
          placeholder="Email"
          onChange={(e) => setemail(e.target.value)}
          className="mb-2"
        />
      </Modal>
    </div>
  );
};

export default BookList;
