import React, { ReactElement } from "react";
import { renderRate } from "../../../../../../../helpers/reviewText";

interface RateIndicatorReviewProps {
  rate: number;
}

const RateIndicatorReview = ({
  rate,
}: RateIndicatorReviewProps): ReactElement => (
  <div className="rate-indicator-review">
    <div className="hotel-card-rate-reviews-wrapper">
      <span className="hotel-card-rate-badge">{rate}</span>
      <span className="mx-2" />
      <span
        className="hotel-card-hotel-star-review"
        style={{ fontWeight: "bold" }}
      >
        {renderRate(rate)}
      </span>
    </div>
  </div>
);
export default RateIndicatorReview;
