import React from "react";
import LoaderComponent from "../../Components/LoaderComponent";

const Loading = () => (
  <div className="container mt-5">
    <LoaderComponent title="" />
  </div>
);

export default Loading;
