import { Col, Row } from "antd";
import React from "react";
import DealPeakProLogo from "../../../img/dealmaker.png";
import { useViewport } from "../../../Context/useViewport";

const HeroBannerMembership = () => {
  const { width } = useViewport();
  return (
    <section className="hero-banner-membership">
      <div className="container">
        <Row>
          <Col lg={4} md={8} xs={16} className="mx-auto my-4">
            <img
              src={DealPeakProLogo}
              alt="DealPeak Memebership"
              className="img-fluid"
            />
          </Col>
          <Col span={24}>
            <h1 className="text-white text-center">Welcome to the VIP Club!</h1>
          </Col>
          <Col span={24}>
            <h6 className="text-white text-center my-4">
              <span className="text-info">Book More</span> -{" "}
              <span className="text-info">Save More</span> -{" "}
              <span className="text-info">Travel More!</span>
            </h6>
          </Col>
          <Col span={24}>
            <h3 className="text-white text-center my-4">Start Saving Now!</h3>
          </Col>
        </Row>
        {width > 500 && (
          <Row className="step-labels-wrapper">
            <Col xs={24} md={24} lg={20} className="mx-auto shadow-lg">
              <ul className="labels-steps-hero-banner">
                <li>
                  <span className="number-label">1</span>
                  <span className="step-label">
                    <b>Search</b> Your Destination
                  </span>
                </li>
                <li>
                  <span className="number-label">2</span>
                  <span className="step-label">
                    <b>Choose</b> Your Hotel
                  </span>
                </li>
                <li>
                  <span className="number-label number-label-orange">3</span>
                  <span className="step-label">
                    <b>SAVE up to 70%!</b>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        )}
      </div>
    </section>
  );
};
export default HeroBannerMembership;
