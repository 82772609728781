import React, { ReactElement, useEffect, useState } from "react";
import LightBoxComponent from "../../../Components/LightBoxComponent";
import NavigationBar from "../../../Components/NavigationBar";
import { IFacilityGroup, IImage, IReview } from "../../../Models/HotelContent";
import { ILink } from "../../../Models/RoomDetail";

import HeroBanner from "./HeroBanner";

interface MainheaderDetailProps {
  nameHotel?: string;
  images?: IImage[];
  reviews?: Array<IReview>;
  facilities?: IFacilityGroup[] | undefined;
}

const MainHeaderDetail = ({
  nameHotel,
  images,
  reviews,
  facilities,
}: MainheaderDetailProps): ReactElement => {
  const [arrayHeroBanner, setArrayHeroBanner] = useState<IImage[] | undefined>(
    []
  );
  const [visible, setVisible] = useState<boolean>(false);
  const [currentImg, setCurrentImg] = useState<number>(0);
  const [imagesForHeroBanner, setImagesForHeroBanner] = useState<
    Array<ILink> | undefined
  >([]);

  const handlerVisibleImage = (numIndex: number) => {
    setVisible(!visible);
    setCurrentImg(numIndex);
  };

  useEffect(() => {
    if (images?.length && images?.length > 4) {
      setArrayHeroBanner(images?.slice(0, 6));
    }
  }, [images]);

  useEffect(() => {
    const imgToHeroBanner = images?.map((p) => p.links[0]);
    setImagesForHeroBanner(imgToHeroBanner);
  }, [images]);

  return (
    <>
      <div className="container-fluid" id="main-header">
        <HeroBanner
          images={images}
          onClickLightBox={handlerVisibleImage}
          nameHotel={nameHotel}
          arrayHeroBanner={arrayHeroBanner}
        />
        <LightBoxComponent
          visible={visible}
          images={imagesForHeroBanner}
          onClickVisible={handlerVisibleImage}
          current={currentImg}
        />
      </div>
      <NavigationBar
        className="mt-3"
        reviews={reviews}
        facilities={facilities}
      />
    </>
  );
};
export default MainHeaderDetail;
