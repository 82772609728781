import { Button, Form, Input, Modal } from "antd";
import {
  FacebookAuthProvider,
  reauthenticateWithPopup,
  updateEmail,
  User,
} from "firebase/auth";
import React, { useContext, useState } from "react";
import { SearchContext } from "../../Context/context";
import { ISearch } from "../../Models/Search";

interface props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
}

const EmailUdpateModal = ({ setVisible, visible }: props) => {
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [emailAccount, setEmailAccount] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const validEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const reauthenticate = async (userlog: User) => {
    const provider = new FacebookAuthProvider();
    try {
      const newCred = await reauthenticateWithPopup(userlog, provider);
      await updateEmail(newCred.user, emailAccount);
      window.location.reload();
    } catch (err) {
      setLoadingUpdate(false);
      setErrorEmail("Email already in use");
    }
  };

  const updateUserEmail = async () => {
    setLoadingUpdate(true);
    if (searchContent.auth.currentUser) {
      try {
        await updateEmail(searchContent.auth.currentUser, emailAccount);
        window.location.reload();
      } catch (err) {
        reauthenticate(searchContent.auth.currentUser);
      }
    }
  };
  return (
    <Modal
      okText="Submit"
      closable={false}
      footer={false}
      maskClosable={false}
      width={350}
      onCancel={() => setVisible(false)}
      open={visible}
    >
      <div className="modal-update_email">
        <span>
          You need to add an email to your account in order to see your
          bookings.
        </span>
        <Form name="basic" className="mt-3">
          <Form.Item>
            <Input
              placeholder="Email"
              type="email"
              onChange={(e) => setEmailAccount(e.target.value)}
            />
            {errorEmail && (
              <span className="error_text-email">{errorEmail}</span>
            )}
          </Form.Item>
        </Form>
        <div className="email_update-buttons">
          <div>
            <Button type="default" onClick={() => setVisible(false)}>
              Cancel
            </Button>
          </div>
          <Button
            type="primary"
            disabled={validEmail(emailAccount) ? false : true}
            loading={loadingUpdate}
            onClick={() => updateUserEmail()}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EmailUdpateModal;
