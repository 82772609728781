import React, { useMemo } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Divider } from "antd";
import marker from "../../img/marker.png";
import markerDetail from "../../img/marker_room.png";
import LocationSection from "../../Pages/HotelDetail/BodyComponents/LocationSection";

interface props {
  lat: number;
  long: number;
  type: string;
  apiKey: string;
  address?: {
    line1: string;
    line2: string | null;
    city: { code: string | null; name: string };
    state: { code: string | null; name: string };
    country: { code: string | null; name: string };
    postalCode: string;
  };
}

const MapInterface = ({ lat, long, type, apiKey, address }: props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const center = useMemo(() => ({ lat, lng: long }), []);
  return (
    <>
      {!isLoaded && <div>loading...</div>}
      {isLoaded && (
        <>
          {type === "detail" && <LocationSection address={address} />}
          <GoogleMap
            streetView={undefined}
            clickableIcons={false}
            options={{ streetViewControl: false }}
            zoom={type === "hotels" ? 9 : 17}
            center={center}
            mapContainerClassName={
              type === "hotels" ? "map_hotels" : "map_detail"
            }
          >
            {type === "hotels" && (
              <Marker position={center} icon={{ url: marker }} />
            )}
            {type === "detail" && (
              <Marker
                position={center}
                icon={{ url: markerDetail, strokeColor: "#0068ef" }}
              />
            )}
          </GoogleMap>
        </>
      )}
    </>
  );
};

export default MapInterface;
