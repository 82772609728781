import React, { ReactElement, useState } from "react";

interface FloatLabelProps {
  children: ReactElement;
  label: string;
  value: any;
  isRequired?: boolean;
}

const FloatLabel = ({
  children,
  label,
  value,
  isRequired,
}: FloatLabelProps): ReactElement => {
  const [focus, setFocus] = useState(false);

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label
        className={
          focus || value !== undefined ? "label-dp label-float" : "label-dp"
        }
      >
        {isRequired ? <strong className="text-danger me-2">*</strong> : null}
        {label}
      </label>
    </div>
  );
};
export default FloatLabel;
