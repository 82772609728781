import React, { useState, useEffect, useContext, useMemo } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import MainSideFilter from "../../Components/MainSideFilter";
import {
  getAvailabilityResults,
  getHotelContent,
  getToken,
} from "../../helpers/requestHotels";
import ListSection from "../Home/ListSection";
import MapContainerMain from "../../Components/MapContainerMain";
import {
  IHotelContent,
  IHotelFullInformation,
} from "../../Models/HotelContent";
import SkeletonLoaderSideFilter from "./SkeletonLoaderSideFilter";
import SkeletonLoaderListHotels from "./SkeletonLoaderListHotels";
import LoadMoreButton from "./LoadMoreButton";
import { getWebConfig } from "../../helpers/requestSetting";
import { IWebConfigContext } from "../../Models/SettingsModel";
import { WebConfigContext } from "../../Context/WebConfigContext";
import { IAvailability, IHotelsAvailability } from "../../Models/InitResponse";
import {
  addClickTripz,
  removeClickTripz,
} from "../../helpers/addRemoveClickTripz";
import { fetchCity, fetchLocationInfo } from "../../helpers/fetchLocation";
import { SearchContext } from "../../Context/context";
import { ISearch } from "../../Models/Search";
import MapInterface from "../../Components/Map";

interface IFields {
  name: string;
  price: { min: number; max: number };
  locations: CheckboxValueType[];
  ratings: string[];
  facilities: CheckboxValueType[];
  maxDistance: number;
}

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

interface ResultProps {
  menuOpen: boolean;
}

const Results = ({ menuOpen }: ResultProps) => {
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;
  const [hotels, setHotels] = useState<(IHotelFullInformation | undefined)[]>(
    []
  );
  const [isJoinCompleted, setIsJoinCompleted] = useState(false);

  // State para manejar error en content
  const [loadingError, setLoadingError] = useState({
    error: false,
    isLoadingHotels: true,
    isLoadingFilters: true,
  });

  // states de puntuación.
  const [totalHotels, setTotalHotels] = useState<number | undefined>();
  const [hotelsFilter, setHotelsFilter] = useState<
    (IHotelFullInformation | undefined)[]
  >([]);
  const [searchParams] = useSearchParams();
  const lat = searchParams.get("lat");
  const long = searchParams.get("long");
  const [showMap] = useState<boolean>(false);
  const [hotelsPerPage] = useState<number>(20);
  const [next, setNext] = useState<number>(hotelsPerPage);
  const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);
  const [hotelIndex, setHotelIndex] = useState("");
  const [functionInvoked, setFunctionInvoked] = useState(false);
  const [filters, setFilters] = useState<{
    locations: string[];
    locationsCant: number[];
    facilities: string[];
    facilitiesCant: number[];
    maxPrice: number;
    minPrice: number;
    maxDistance: number;
  }>({
    locations: [],
    locationsCant: [],
    facilities: [],
    facilitiesCant: [],
    maxPrice: 0,
    minPrice: 0,
    maxDistance: 0,
  });
  const [sortOption, setsortOption] = useState<string>("");

  const check = moment(searchParams.get("checkin"));
  const check2 = moment(searchParams.get("checkout"));
  const numberNigths = Number.isNaN(Math.abs(check.diff(check2, "days")))
    ? 1
    : Math.abs(check.diff(check2, "days"));

  const displayHotels = hotelsFilter.slice(0, next);

  const filterByFacilityGroupNames = (
    hotelsRaw: (IHotelFullInformation | undefined)[],
    namesToMatch: string[]
  ) => {
    if (hotelsRaw) {
      return hotelsRaw.filter((hotel) => {
        const facilityGroupNames = hotel?.facilityGroups.map(
          (group) => group.name
        );
        return namesToMatch.every((name) => facilityGroupNames?.includes(name));
      });
    }
  };

  function convertToStringArray(checkboxValues: CheckboxValueType[]): string[] {
    return checkboxValues.filter(
      (value) => typeof value === "string"
    ) as string[];
  }

  const sortHotels = (mainArray: any) => {
    let array = [...mainArray];
    if (searchContent.sortOption === "") return setHotelsFilter(array);
    if (searchContent.sortOption === "Lowest price") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? a.rate.baseRate - b.rate.baseRate : 0
      );
    }
    if (searchContent.sortOption === "Highest price") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? b.rate.baseRate - a.rate.baseRate : 0
      );
    }
    if (searchContent.sortOption === "Highest Star Rating") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? b.starRating - a.starRating : 0
      );
    }
    if (searchContent.sortOption === "Lowest Star Rating") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? a.starRating - b.starRating : 0
      );
    }
    array = array.filter((x: undefined) => x !== undefined);
    setHotelsFilter(array);
  };

  const filterHotels = (filterObj: IFields) => {
    let array = [...hotels];

    // Filter for the name
    if (filterObj.name) {
      array = array.filter((item) =>
        item?.name.toLowerCase().includes(filterObj.name.toLowerCase())
      );
    }
    // Filter for the locations
    if (filterObj.locations.length > 0) {
      array = array.filter((item) =>
        filterObj.locations.some((v) =>
          item?.contact.address.city.name
            .toLowerCase()
            .includes(v.toString().toLowerCase())
        )
      );
    }
    // Filter for the ratings
    if (filterObj.ratings.length > 0) {
      array = array.filter((item) =>
        filterObj.ratings.some(
          (v) => item && Math.floor(item?.starRating) >= parseInt(v, 10)
        )
      );
    }
    // Filter for the facilities
    if (filterObj.facilities.length > 0) {
      array = filterByFacilityGroupNames(
        array,
        convertToStringArray(filterObj.facilities)
      )!;
    }
    // Filter for the price
    if (filterObj.price.min !== 0 || filterObj.price.max !== filters.maxPrice) {
      array = array.filter(
        (item) =>
          item &&
          Math.trunc(item.rate.baseRate / numberNigths) >=
            filterObj.price.min &&
          Math.trunc(item.rate.baseRate / numberNigths) <= filterObj.price.max
      );
    }
    // Filter for the disance
    if (filterObj.maxDistance > 0) {
      array = array.filter(
        (item) => item && item.distance <= filterObj.maxDistance
      );
    }
    array = array.filter((x) => x !== undefined);
    sortHotels(array);
    if (
      !filterObj.name &&
      filterObj.locations.length < 1 &&
      filterObj.ratings.length < 1 &&
      filterObj.facilities.length < 1 &&
      filterObj.price.min === 0 &&
      filterObj.price.max === filters.maxPrice &&
      filterObj.maxDistance === 0
    ) {
      sortHotels(array);
      setHotelsFilter(array);
    }
  };

  const loadMorePages = () => {
    setNext(next + hotelsPerPage);
  };

  const removeDuplicates = (
    Rawhotels: (IHotelFullInformation | undefined)[]
  ) => {
    const hotelMap = new Map();

    for (const hotel of Rawhotels) {
      if (
        !hotelMap.has(hotel?.id) ||
        hotel?.rate.baseRate! < hotelMap.get(hotel?.id).rate.baseRate!
      ) {
        hotelMap.set(hotel?.id, hotel);
      }
    }

    return Array.from(hotelMap.values());
  };

  const setAllFilters = (fullArr: (IHotelFullInformation | undefined)[]) => {
    const cityNames = fullArr.map((hotel) => {
      if (hotel !== undefined) {
        return hotel.contact.address.city.name.toLowerCase().trim();
      }
      return "";
    });
    const reducedLocations = cityNames.reduce(
      (previousValue: any, currentValue: any) => {
        if (previousValue[currentValue]) {
          previousValue[currentValue]++;
        } else {
          previousValue[currentValue] = 1;
        }
        return previousValue;
      },
      {}
    );

    // Get the facilities
    const facilitiesNew = fullArr.map((hotel) => {
      if (hotel !== undefined) {
        return hotel.facilityGroups.map((facility) => facility.name);
      }
      return "";
    });
    const reducedFacilities = facilitiesNew
      .flat()
      .reduce((previousValue: any, currentValue: any) => {
        if (previousValue[currentValue]) {
          previousValue[currentValue]++;
        } else {
          previousValue[currentValue] = 1;
        }
        return previousValue;
      }, {});
    // Get the max price
    const maxPrice = fullArr.map((hotel) => {
      if (hotel !== undefined) {
        return Math.trunc(hotel.rate.baseRate / numberNigths);
      }
      return 0;
    });
    const minValue = Math.min(...maxPrice);
    const cheapestHotel = fullArr.find(
      (item) =>
        item?.rate.baseRate &&
        Math.trunc(item.rate.baseRate / numberNigths) === minValue
    );
    if (cheapestHotel) setHotelIndex(cheapestHotel.id);

    // Get max distance
    const maxDistance = fullArr.map((hotel) => {
      if (hotel !== undefined) {
        return hotel.distance;
      }
      return 0;
    });
    setFilters({
      ...filters,
      locations: Object.keys(reducedLocations),
      locationsCant: Object.values(reducedLocations),
      facilities: Object.keys(reducedFacilities),
      facilitiesCant: Object.values(reducedFacilities),
      minPrice: maxPrice.length > 0 ? Math.min(...maxPrice) : 0,
      maxPrice: maxPrice.length > 0 ? Math.max(...maxPrice) : 0,
      maxDistance: Math.max(...maxDistance),
    });
  };

  useEffect(() => {
    setLoadingError({
      isLoadingHotels: true,
      isLoadingFilters: true,
      error: false,
    });

    const getInfo = async () => {
      const correlationId = searchParams.get("correlationId");
      const radius = searchParams.get("radius");
      const checkIn = searchParams.get("checkin");
      const checkOut = searchParams.get("checkout");
      const adults = searchParams.get("adults");
      const currency = searchParams.get("currency") || "USD";
      const children = searchParams.get("children");
      const rooms = parseInt(searchParams.get("rooms") || "1", 10);

      if (
        lat &&
        long &&
        radius &&
        correlationId &&
        checkIn &&
        checkOut &&
        adults &&
        currency &&
        rooms
      ) {
        try {
          let contentFinished = false;
          let availabilityFinished = false;
          let hotelsAvailability: IHotelsAvailability[] = [];
          let contentResult: Map<string, IHotelContent>;

          const channelID =
            webConfigContext.selectedChannel ??
            (await getWebConfig()).REACT_APP_CHANNEL_ID.Organic;

          const joinHotelsResults = () => {
            if (
              contentFinished &&
              availabilityFinished &&
              hotelsAvailability.length === 0
            ) {
              setIsJoinCompleted(true);
              setLoadingError({
                isLoadingHotels: false,
                isLoadingFilters: false,
                error: true,
              });
            }

            if (!contentFinished || !availabilityFinished) return;
            let newArr = hotelsAvailability
              .map((hotelAvailability) => {
                const hotelContentSingle = contentResult?.get(
                  hotelAvailability.id
                );
                if (hotelContentSingle) {
                  const {
                    id,
                    name,
                    relevanceScore,
                    providerId,
                    providerName,
                    providerHotelId,
                    geoCode,
                    descriptions,
                    contact,
                    chainCode,
                    chainName,
                    neighbourhoods,
                    nearByAttractions,
                    type,
                    website,
                    category,
                    starRating,
                    imageCount,
                    distance,
                    facilities,
                    facilityGroups,
                    heroImage,
                    images,
                    Rooms,
                    policies,
                    fees,
                    reviews,
                    checkinInfo,
                    checkoutInfo,
                  } = hotelContentSingle;
                  return {
                    ...hotelAvailability,
                    id,
                    name,
                    relevanceScore,
                    providerId,
                    providerName,
                    providerHotelId,
                    geoCode,
                    descriptions,
                    contact,
                    chainCode,
                    chainName,
                    neighbourhoods,
                    nearByAttractions,
                    type,
                    website,
                    category,
                    starRating,
                    imageCount,
                    distance,
                    facilities,
                    facilityGroups,
                    heroImage,
                    images,
                    Rooms,
                    policies,
                    fees,
                    reviews,
                    checkinInfo,
                    checkoutInfo,
                  };
                }
                return undefined;
              })
              .filter((p) => p !== undefined);
            newArr = removeDuplicates(newArr).sort((a, b) =>
              a && b ? a.rate.baseRate - b.rate.baseRate : 0
            );
            setHotels(newArr);
            setTotalHotels(newArr.length);
            setAllFilters(newArr);

            setHotelsFilter(newArr);
            setIsJoinCompleted(true);
            setLoadingError({
              isLoadingHotels: false,
              isLoadingFilters: false,
              error: false,
            });
          };
          getToken(
            parseFloat(lat),
            parseFloat(long),
            parseInt(radius, 10),
            correlationId,
            checkIn === "null"
              ? moment().add(1, "days").format("YYYY-MM-DD")
              : checkIn,
            checkOut === "null"
              ? moment().add(2, "days").format("YYYY-MM-DD")
              : checkOut,
            searchContent.occupancies,
            currency,
            channelID ?? ""
          ).then((token) => {
            const processPartialAvailabilityResult = (data: IAvailability) => {
              function callNextStep() {
                availabilityFinished = false;
                setTimeout(() => {
                  getPartialAvailabilityResults(data.nextResultsKey);
                }, 500);
              }
              if (data.hotels) {
                hotelsAvailability = [...hotelsAvailability, ...data.hotels];
              }
              if (
                (data.status !== "Completed" && data.nextResultsKey !== undefined) ||
                (data.status === "InProgress" && data.nextResultsKey === undefined)
              ) {
                callNextStep();
              }
              else if (data.status === "Completed")
              {
                availabilityFinished = true;
                joinHotelsResults();
              }
            };

            const getPartialAvailabilityResults = (
              nextResultsKey: string | null | undefined
            ) => {
              getAvailabilityResults(token, correlationId, nextResultsKey)
                .then((p) => {
                  if (p.status === 200)
                    processPartialAvailabilityResult(p.data);
                })
                .catch(() => {
                  setLoadingError({
                    isLoadingHotels: false,
                    isLoadingFilters: false,
                    error: true,
                  });
                });
            };
            setTimeout(() => {
              getPartialAvailabilityResults(null);
            }, 1000);
          });

          getHotelContent(
            correlationId,
            parseFloat(lat),
            parseFloat(long),
            parseInt(radius, 10),
            channelID ?? "",
            4
          )
            .then((response) => {
              contentResult = response;
              contentFinished = true;
              joinHotelsResults();
            })
            .catch(() => {
              setLoadingError({
                isLoadingHotels: false,
                isLoadingFilters: false,
                error: true,
              });
            });
        } catch (err) {
          setLoadingError({
            isLoadingHotels: false,
            isLoadingFilters: false,
            error: true,
          });
        }
      }
    };

    const createUCO = async () => {
      if (lat && long) {
        const locationData = await fetchLocationInfo(lat, long);
        const city = await fetchCity(lat, long);
        window._CTZ = {
          enabled: true, // Enables or disables the integration from running
          verticals: {
            hotel: {
              active: true, // Enables or disables auction from running
              search: {
                city: city || locationData.address.city, // Destination city
                province: locationData.address["ISO3166-2-lvl4"].split("-")[1], // Province or state abbreviation
                countryCode:
                  locationData.address["ISO3166-2-lvl4"].split("-")[0], // two-letter ISO country code
                checkInDate: moment(moment(check)).format("MM/DD/YYYY"), // MM/DD/YYYY format
                checkOutDate: moment(moment(check2)).format("MM/DD/YYYY"), // MM/DD/YYYY format
              },
            },
          },
        };
      }
    };

    getInfo();

    if (window.location.href.includes("app.dealpeak")) {
      const script = addClickTripz();
      if (lat && long) {
        createUCO();
      }
      return () => {
        removeClickTripz(script);
      };
    }
  }, [searchParams]);

  useEffect(() => {
    if (totalHotels && next >= hotelsFilter.length) {
      setDisableLoadMore(true);
    }
  }, [next, hotelsFilter.length]);

  const discountsList = useMemo(() => {
    const testedHotels: string[] = [];
    const discountsArray: any = [];

    hotelsFilter.forEach((item) => {
      if (item) {
        if (testedHotels.includes(item.name)) return;

        const repeated = hotelsFilter.filter((x) => x!.name === item.name);
        if (repeated.length > 1) {
          testedHotels.push(item.name);
          const min = repeated.reduce((prev, curr) =>
            prev!.rate.totalRate < curr!.rate.totalRate ? prev : curr
          );
          const max = repeated.reduce((prev, curr) =>
            prev!.rate.totalRate > curr!.rate.totalRate ? prev : curr
          );

          discountsArray.push({
            id: min!.id,
            discount: Math.round(
              ((max!.rate.totalRate - min!.rate.totalRate) * 100) /
                max!.rate.totalRate
            ),
          });
        }
      }
    });

    return discountsArray;
  }, [hotelsFilter]);

  useEffect(() => {
    if (sortOption === "") return;
    setSearchContent({
      ...searchContent,
      sortOption: sortOption,
    });
    let array = [...hotelsFilter];
    if (sortOption === "Lowest price") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? a.rate.baseRate - b.rate.baseRate : 0
      );
    }
    if (sortOption === "Highest price") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? b.rate.baseRate - a.rate.baseRate : 0
      );
    }
    if (sortOption === "Highest Star Rating") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? b.starRating - a.starRating : 0
      );
    }
    if (sortOption === "Lowest Star Rating") {
      array = removeDuplicates(array).sort((a, b) =>
        a && b ? a.starRating - b.starRating : 0
      );
    }
    array = array.filter((x) => x !== undefined);
    setHotelsFilter(array);
  }, [sortOption]);

  return (
    <div className="container-fluid main-container result-container">
      <div className="row">
        <div className="col-lg-3">
          {loadingError.error && <h1>No Results Found!</h1>}
          {loadingError.isLoadingFilters && <SkeletonLoaderSideFilter />}
          {!loadingError.isLoadingFilters && !loadingError.error && (
            <>
              {lat &&
                long &&
                webConfigContext.webConfig.REACT_APP_GOOGLE_MAPS_KEY && (
                  <MapInterface
                    lat={parseFloat(lat)}
                    long={parseFloat(long)}
                    type="hotels"
                    apiKey={
                      webConfigContext.webConfig.REACT_APP_GOOGLE_MAPS_KEY
                    }
                  />
                )}
              <MainSideFilter
                setSortOption={setsortOption}
                filterOpen={menuOpen}
                facilitiesCant={filters.facilitiesCant}
                locations={filters.locations}
                locationsCant={filters.locationsCant}
                facilities={filters.facilities}
                maxPrice={filters.maxPrice}
                minPrice={filters.minPrice}
                maxDistance={filters.maxDistance}
                filter={filterHotels}
              />
            </>
          )}
        </div>
        <div className="col-lg-9">
          <div className="row">
            {loadingError.isLoadingHotels && <SkeletonLoaderListHotels />}
            {!loadingError.isLoadingHotels && !loadingError.error && (
              <>
                {hotelsFilter.length > 0 && (
                  <>
                    <ListSection
                      totalHotels={totalHotels}
                      cheapestHotel={hotelIndex}
                      hotelInformation={displayHotels}
                      discounts={discountsList}
                      totalFilteredHotels={hotelsFilter.length}
                    />
                    <div className="col-12">
                      <LoadMoreButton
                        disableLoadMore={disableLoadMore}
                        onClickLoadMore={loadMorePages}
                      />
                    </div>
                  </>
                )}
                {displayHotels.length === 0 && isJoinCompleted && (
                  <h1>No Results Found!</h1>
                )}
              </>
            )}
          </div>
        </div>
        {showMap && (
          <div className="col-lg-4 map-container">
            <MapContainerMain />
          </div>
        )}
      </div>
    </div>
  );
};

export default Results;
