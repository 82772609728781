export const getFormattedTimestamp = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear() % 100;
  let hours = currentDate.getHours();
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const period = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;

  const timestamp = `${month}/${day}/${year} ${hours}:${minutes}${period}`;

  return timestamp;
};

export const getCurrentDateYmd = () => {
  const currentDate: Date = new Date();
  const year: number = currentDate.getFullYear();
  let month: string | number = currentDate.getMonth() + 1; // Months are zero-indexed
  let day: string | number = currentDate.getDate();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  const result: string = `${year}${month}${day}`;
  return result;
};
