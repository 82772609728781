import { Divider } from "antd";
import { ReactElement } from "react";

const QuestionItemComponent = ({question, request}: any): ReactElement => {
    return (
        <div className="px-3">
         <h6>{question}</h6>
         <span>{request}</span>
         <Divider dashed />
        </div>
    )
}
export default QuestionItemComponent;