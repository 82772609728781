import { Col } from "antd";
import React from "react";

interface CardBookDiscountProps {
  title?: string;
  img?: string;
  icon?: string;
  content?: string;
}

const CardBookDiscount = ({
  title,
  img,
  icon,
  content,
}: CardBookDiscountProps) => (
  <Col lg={8} xs={24} md={12} className="my-2">
    <div className="card card-discount shadow">
      <img src={img} className="card-img-top" alt={title} />
      <div className="icon-discount-wrapper">
        <div className="icon-discount">
          <img src={icon} alt={title} />
        </div>
      </div>
      <h5 className="text-center">{title}</h5>
      <div className="card-body justify-content-center align-items-center d-flex">
        <div className="content-wrapper">
          <small style={{ whiteSpace: "pre-wrap" }}>{content}</small>
        </div>
      </div>
      <div className="card-footer text-center">
        <button className="btn btn-primary rounded-pill px-4" type="button">
          MORE INFO
        </button>
      </div>
    </div>
  </Col>
);
export default CardBookDiscount;
