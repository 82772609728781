import React, { useContext } from "react";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { sendPasswordReset } from "../../../Auth/firebase";
import { SearchContext } from "../../../Context/context";
import { ISearch } from "../../../Models/Search";
import { RoutesPath } from "../../../Routes";

const validateMessages = {
  required: "Please input your ${label}",
  types: {
    email: "${label} is not a valid email!",
  },
};

const ResetPass = () => {
  const navigate = useNavigate();
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const onFinish = async (values: any) => {
    await sendPasswordReset(values.user.email, searchContent.auth);
    navigate(RoutesPath.root);
  };

  return (
    <div>
      <Card className="sign-card">
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <h6 className="reset-text">
            Reset your password to continue enjoying faster checkouts and easy
            access to your trip info. Just enter your email so we can send a
            secure link.
          </h6>
          <Form.Item
            name={["user", "email"]}
            rules={[{ type: "email", required: true }]}
          >
            <Input placeholder="Enter your email" allowClear />
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-success-dp btn-block btn-login"
              htmlType="submit"
            >
              Submit
            </Button>
            <Button
              type="link"
              htmlType="button"
              onClick={() => navigate(RoutesPath.root)}
            >
              Continue Browsing or checking out as a guest
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPass;
