import { ReactElement } from 'react';
import SearchFormFaqComponent from '../SearchBarFaq';

const mainTitle = 'You got questions? We got answer!';
const subTitle = 'F.A.Q is our middle name';
const secondaryMessage = "Don't know where the look? Use our search bar to quickly find what you are looking for";

const HeaderFaqComponent = (): ReactElement => {
    return (
        <>
        <div className="container my-3">
        <div className="row">
          <div className="col-lg-10 col-xs-12 mx-auto mt-5">
            <h1 className="text-center text-info">
              {mainTitle}
            </h1>
            <div className="text-center">
              <span className="h1 fw-bold text-primary-color">
                {subTitle}
              </span>
            </div>
            <div className="text-center my-2">
              <span className="h4">
                {secondaryMessage}
              </span>
            </div>
          </div>
          <SearchFormFaqComponent/>
        </div>
      </div>
        </>
    )
}
    
export default HeaderFaqComponent;