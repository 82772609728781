import { EBookingSatus } from "../Common/Enums";
import APIClient from "../Components/Services/APIClient";
import { IBook3ds, IBookRequest } from "../Models/Book3dsRequest";
import { IBookDetailResponse } from "../Models/BookDetailRequest";
import { ICancelBooking } from "../Models/Cancel";
import { refundEventTag } from "./addRemoveClickTripz";
import { getConfig } from "./configHelper";

export const sendEmail = async (
  email: string,
  subject: string,
  channelId: string,
  bookingId: string
) => {
  const client = new APIClient();
  const contentResponse = await client.client.post(
    `Book/SendMail?emailTo=${email}&subject=${subject}&channelId=${channelId}&bookingId=${bookingId}`
  );
  return contentResponse;
};

export const sendEmailUserCreated = async (
  email: string,
  subject: string,
  password: string = ""
) => {
  const client = new APIClient();

  const contentResponse = await client.client.post(
    `Book/SendMailUserCreated?emailTo=${email}&subject=${subject}&password=${password}`
  );
  return contentResponse;
};

export const priceCheck = async (
  hotelId: string,
  recommendationId: string,
  correlationId: string
) => {
  const config = await getConfig(correlationId);
  let token = sessionStorage.getItem("token");
  let tokenOtherOffers = sessionStorage.getItem("tokenOtherOffers");

  const client = new APIClient();

  let response;

  if (token) {
    const contentResponse = await client.client.get(
      `Book/${hotelId}/${token}/Price/recommendation/${recommendationId}`,
      config
    );
    response = { ...contentResponse.data, checked: true };

    if (contentResponse.data.token) {
      return response;
    }
  }
  if (tokenOtherOffers) {
    const contentResponseOther = await client.client.get(
      `Book/${hotelId}/${tokenOtherOffers}/Price/recommendation/${recommendationId}`,
      config
    );
    response = { ...contentResponseOther.data, checked: true };
    if (contentResponseOther.data.token) {
      return response;
    }
  }

  // If no valid token is found, return a default response
  return response || { checked: true };
};

export const book3ds = async (
  hotelId: string,
  correlationId: string,
  obj: IBook3ds
) => {
  const token = sessionStorage.getItem("tokenBooking");

  const config = await getConfig(correlationId);

  const client = new APIClient();

  return await client.client.post(
    `Book/${hotelId}/${token}/book3ds`,
    obj,
    config
  );
};

export const bookHotel = async (
  hotelId: string,
  correlationId: string,
  obj?: any
) => {
  const config = await getConfig(correlationId);
  const token = sessionStorage.getItem("tokenBooking");
  const client = new APIClient();
  return await client.client.post(`Book/${hotelId}/${token}/book`, obj, config);
};

export const sendEmailCancelBooking = async (bookingId: string) => {
  const client = new APIClient();
  const contentResponse = await client.client.post(
    `Cancel/SendMail?bookingId=${bookingId}&subject=CancelBooking`
  );
  return contentResponse;
};

export const postCancelBooking = async (
  bookId: string,
  response: ICancelBooking
) => {
  sendEmailCancelBooking(bookId);
  refundEventTag(
    bookId,
    response.totalRate.toString(),
    response.hotelId,
    response.tripStartDate.toString(),
    response.tripEndDate.toString()
  );
};

interface bookObj {
  channelId: string | undefined;
  bookingId: string;
}

export const getBookingDetails = async (
  obj: bookObj,
  correlationId: string,
  callNumber: number = 0,
  bookRequest: IBookRequest | IBook3ds | undefined = undefined
): Promise<IBookDetailResponse> => {
  try {
    const config = await getConfig(correlationId);
    const client = new APIClient();
    const contentResponse = await client.client.post(
      `Book/getBookingDetails`,
      { BookObjectRequest: obj, bookRequest: bookRequest },
      config
    );

    if (callNumber > 29) return contentResponse.data;

    if (
      contentResponse.data.hotelBooking.bookingStatus === EBookingSatus.Pending
    ) {
      setTimeout(() => {
        return getBookingDetails(obj, correlationId, callNumber + 1);
      }, 10000);
    }

    return contentResponse.data;
  } catch {
    return getBookingDetails(obj, correlationId, callNumber + 1);
  }
};

export const getBookings = async (
  userId: string,
  correlationId: string,
  params?: string
) => {
  const config = await getConfig(correlationId);
  const client = new APIClient();
  const contentResponse = await client.client.get(
    `Book/getBookingByUser/${userId}?${params}`,
    config
  );
  return contentResponse.data;
};

export const getBookingsAdmin = async (
  correlationId: string,
  pageNumber: number,
  pageSize: number,
  params?: string
) => {
  const config = await getConfig(correlationId);
  const client = new APIClient();
  const contentResponse = await client.client.get(
    `Book/getBooking/${pageNumber}/${pageSize}?${params}`,
    config
  );
  return contentResponse.data;
};

export const getallBookingsAdmin = async () => {
  const client = new APIClient();
  const contentResponse = await client.client.get(`Book/getAllBookings`);
  return contentResponse.data;
};

export const cancelBooking = async (
  bookingId: string,
  correlationId: string
): Promise<ICancelBooking> => {
  const config = await getConfig(correlationId);
  const client = new APIClient();
  const contentResponse = await client.client.post<ICancelBooking>(
    `Cancel/booking/${bookingId}/cancel`,
    undefined,
    config
  );
  return contentResponse.data;
};

export const cancelBookingReason = async () => {
  const client = new APIClient();
  const contentResponse = await client.client.get(`/CancelReason`);
  return contentResponse.data;
};

export const get3DSToken = async (actionUrlValue: string) => {
  const client = new APIClient();
  const contentResponse = await client.client.get(actionUrlValue);
  return contentResponse.data;
};

export const postCancelReasonBooking = async (bookingId: string, obj: any) => {
  const client = new APIClient();
  const contentResponse = await client.client.post(
    `/Cancel/bookingreason/${bookingId}`,
    obj
  );
  return contentResponse.data;
};

export const getBookingCancelReason = async (bookingId: string) => {
  const client = new APIClient();
  const contentResponse = await client.client.get(
    `/CancelBookingReason/${bookingId}`
  );
  return contentResponse.data;
};

export const priceGuaranteedSubmit = async (
  formData: FormData,
  bookingId: string
) => {
  const client = new APIClient();
  const contentResponse = await client.client.post(
    `/Cancel/CancelGuarantee/${bookingId}`,
    formData
  );
  return contentResponse.data;
};
