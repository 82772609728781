import APIClient from "../Components/Services/APIClient";

export interface ISetting {
  id: number;
  name: string;
  value: string;
}

export const isIpExempt2fa = async (ip: string): Promise<boolean> => {
  const client = new APIClient();
  return client.client
    .get<boolean>(`IpValidation/${ip}`)
    .then((response) => response.data);
};
