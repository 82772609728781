import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";

interface InputPeopleProps {
  label: string;
  value: number;

  onClickPlus(value: number, label: string): void;

  onClickMinus(value: number, label: string): void;
  min?: number;
  max?: number;
  disabledPlus?: boolean;
  disabledMinus?: boolean;
}

const InputPeople = ({
  min,
  max,
  label,
  value,
  onClickPlus,
  onClickMinus,
  disabledPlus,
  disabledMinus,
}: InputPeopleProps): ReactElement => {
  const [plusDisabled, setPlusDisabled] = useState<boolean>(false);
  const [minusDisabled, setMinusDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (value === min) {
      setMinusDisabled(true);
    } else {
      setMinusDisabled(false);
    }

    if (value === max) {
      setPlusDisabled(true);
    } else {
      setPlusDisabled(false);
    }
  }, [value, min, max]);
  return (
    <div className="rooms-input-wrapper">
      <span className="h6 text-secondary me-2">{label}</span>
      <div>
        <Button
          type="link"
          icon={<MinusSquareOutlined color="#0068EF" />}
          className="mx-3"
          onClick={() => onClickMinus(value, label)}
          disabled={minusDisabled || disabledMinus}
        />
        <span className="h6 px-4">{value}</span>
        <Button
          onClick={() => onClickPlus(value, label)}
          type="link"
          icon={<PlusSquareOutlined color="#0068EF" />}
          className="mx-3"
          disabled={plusDisabled || disabledPlus}
        />
      </div>
    </div>
  );
};

export default InputPeople;
