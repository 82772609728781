import { Typography } from "antd";
import React from "react";
import { INearByAttraction } from "../../Models/HotelContent";

interface props {
  nearByAttractions: INearByAttraction[];
}

const NearByLocations = ({ nearByAttractions }: props) => (
  <>
    <h4>Nearby Attractions</h4>
    <div className="attractions">
      {nearByAttractions.map((item, index) => (
        <div className="child" key={index}>
          <Typography.Text
            ellipsis={{ tooltip: true }}
            className="expandable-text"
          >
            {item.name}
          </Typography.Text>
          <p>{Math.round(parseFloat(item.distance) / 100) / 10} km</p>
        </div>
      ))}
    </div>
  </>
);

export default NearByLocations;
