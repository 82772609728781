import { Button, Form, Input, Image, notification, Card, Divider } from "antd";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword } from "../../../Auth/firebase";
import { SearchContext } from "../../../Context/context";
import {
  getUserRol,
  getUsers,
  postUser,
} from "../../../helpers/requestUserAndRole";
import { ISearch } from "../../../Models/Search";
import { IAccess, IUsers } from "../../../Models/UsersModel";
import fbicon from "../../../img/facebook.png";
import googleicon from "../../../img/google.png";
import { RoutesPath } from "../../../Routes";
import { IWebConfig, IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { isMobile } from "react-device-detect";
import { getWebConfig } from "../../../helpers/requestSetting";
import { getMembershipStatus } from "../../../helpers/requestMembership";
import { isMembershipApproved } from "../../../Data/MembershiStatusData";

interface SignInProps {
  setAccesRole: React.Dispatch<React.SetStateAction<IAccess[]>>;
}

const SignIn = ({ setAccesRole }: SignInProps) => {
  const { setSearchContent, searchContent } = useContext<any | ISearch>(
    SearchContext
  );
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const { setWebConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const navigate = useNavigate();

  useEffect(() => {
    window._CTZ = {
      enabled: false,
      verticals: {
        hotel: {
          active: false,
          search: {
            city: undefined,
            province: undefined,
            countryCode: undefined,
            checkInDate: undefined,
            checkOutDate: undefined,
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    if (searchContent.filters) {
      const { filters, ...newObject } = searchContent;
      setSearchContent(newObject);
    }
    if (searchContent.sortOption) {
      setSearchContent({
        ...searchContent,
        sortOption: "Lowest price",
      });
    }
  }, []);

  const handlerChangeForm = (type: string) => {
    switch (type) {
      case "register":
        navigate(RoutesPath.register);
        break;
      case "reset":
        navigate(RoutesPath.reset);
        break;
    }
  };

  const getAccesRoles = async (email: string) => {
    try {
      const response = await getUserRol(email);
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = async (values: any) => {
    const webConfigResp = await getWebConfig();
    const userActive = await getUserRol(values.username);
    const membershipResponse = await getMembershipStatus(values.username);

    if (userActive && !userActive.active) {
      notification.error({
        message: "Error",
        description: `Your user has been disabled`,
      });
      return;
    }

    const userCredential = await logInWithEmailAndPassword(
      values.username,
      values.password,
      searchContent.auth
    );

    if (userCredential?.user) {
      notification.success({
        message: "success",
        description: `Welcome ${userCredential.user.email}`,
      });

      try {
        if (
          membershipResponse &&
          isMembershipApproved(membershipResponse[0].order_status)
        ) {
          setChannelId("MembershipDP", webConfigResp);
        } else {
          setChannelId(userActive.role.roleName, webConfigResp);
        }
      } catch (err) {
        if (webConfigResp)
          setOnLocalChannel(
            webConfigResp.REACT_APP_CHANNEL_ID.Organic,
            webConfigResp,
            isMobile
          );
      }

      const response = await getAccesRoles(values.username);
      if (response && response.role && response.role.access)
        setAccesRole(response.role.access);

      if (
        response &&
        response.role &&
        response.role.access &&
        response.role.access.some(
          (item) => item.path === "accounts" || item.path === "sales"
        )
      ) {
        navigate(RoutesPath.accounts);
      } else {
        navigate(RoutesPath.bookings);
      }
    }
  };

  const setOnLocalChannel = (
    channel: string,
    webConfigResp: IWebConfig,
    mobile: boolean
  ) => {
    setWebConfigContext({
      webConfig: webConfigResp,
      selectedChannel: channel,
      mobile,
    });
  };

  const setChannelId = async (roles: string, webConfigResp: IWebConfig) => {
    switch (roles) {
      case "SalesPerson":
        localStorage.setItem("UserAuth", "true");
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.Sales,
          webConfigResp,
          true
        );
        break;
      case "MembershipDP":
        localStorage.setItem("UserAuth", "true");
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
          webConfigResp,
          true
        );
        break;
      case "GoogleMeta":
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.GoogleMeta,
          webConfigResp,
          true
        );
        break;
      case "Admin":
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
          webConfigResp,
          true
        );
        return;
      default:
        setOnLocalChannel(
          isMobile
            ? webConfigResp.REACT_APP_CHANNEL_ID.Mobile
            : webConfigResp.REACT_APP_CHANNEL_ID.Organic,
          webConfigResp,
          isMobile
        );
        break;
    }
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          window.location.replace(`${RoutesPath.home}`);
        } catch (err) {
          console.error(err);
        }
      } else {
        navigate(RoutesPath.bookings);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          window.location.replace(`${RoutesPath.home}`);
        } catch (err) {
          console.error(err);
        }
      } else {
        navigate(RoutesPath.bookings);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xs-12 mx-md-auto">
          <Card className="sign-card shadow">
            <Divider orientation="left">
              <h5>Sign In</h5>
            </Divider>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={(values: any) => {
                setLoadingButton(true);
                onFinish(values);
                setLoadingButton(false);
              }}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your Username!" },
                ]}
              >
                <Input placeholder="Email Address" allowClear />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  htmlType="button"
                  className="login-form-forgot"
                  onClick={() => handlerChangeForm("reset")}
                >
                  Forgot password
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loadingButton}
                  className="btn-success-dp btn-block btn-login"
                  htmlType="submit"
                >
                  Sign In
                </Button>
                <div className="mt-2 or-text">
                  <span>Or</span>
                </div>
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={signInWithFacebook}
                  >
                    <Image
                      src={fbicon}
                      preview={false}
                      width={20}
                      height={20}
                      className="me-3"
                    />{" "}
                    Sign In with Facebook
                  </button>
                </div>
                <div className="d-grid my-3">
                  <button
                    className="btn btn-light"
                    type="button"
                    onClick={signInWithGoogle}
                  >
                    <Image
                      src={googleicon}
                      preview={false}
                      width={20}
                      height={20}
                      className="me-4"
                    />{" "}
                    Sign In with Google
                  </button>
                </div>
                <div className="d-flex py-3">
                  <small className="mt-2">Do not have an account?</small>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => handlerChangeForm("register")}
                  >
                    Create account!
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
