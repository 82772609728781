import React, { ReactElement } from "react";
import { Col } from "antd";
import { useNavigate } from "react-router-dom";
import {
  IDescription,
  IFacilityGroup,
  IImage,
  INearByAttraction,
} from "../../../../Models/HotelContent";
import AboutDetail from "../../BodyComponents/About";
import Facilities from "../../BodyComponents/Facilities";
import Reviews from "../../BodyComponents/Reviews";
import AlertCovid from "../../BodyComponents/AlertCovid";
import AmenitiesSectionRoomDetail from "../../BodyComponents/Amenities";
import InformationSection from "../../BodyComponents/InformationSection";
import MapSection from "../../BodyComponents/MapSection";
import { IWebConfig } from "../../../../Models/SettingsModel";
import NearByLocations from "../../../../Components/NearByLocations";

interface ExtDetailInfoProps {
  deeplink: string | undefined;
  coordenates?: { lat: string; long: string };
  images: IImage[] | undefined;
  facilities?: IFacilityGroup[] | undefined;
  address?: {
    line1: string;
    line2: string | null;
    city: { code: string | null; name: string };
    state: { code: string | null; name: string };
    country: { code: string | null; name: string };
    postalCode: string;
  };
  settings: IWebConfig;
  nameHotel?: string;
  starRating?: string;
  descriptionText?: IDescription[];
  nearByAttractions: INearByAttraction[];
  provider: string;
}

const ExtDetailInfo = ({
  facilities,
  address,
  coordenates,
  nameHotel,
  starRating,
  settings,
  descriptionText,
  nearByAttractions,
}: ExtDetailInfoProps): ReactElement => {
  const navigate = useNavigate();
  return (
    <div className="container mt-3" id="gallery-detail-hotel">
      <div className="row">
        <Col lg={24}>
          <AboutDetail
            titleHotel={nameHotel}
            starRating={starRating}
            address={address}
          />
          <Facilities facilities={facilities} />
          <AlertCovid />
          <Reviews hidden reviews={undefined} />
          <AmenitiesSectionRoomDetail facilities={facilities} />
          <InformationSection descriptionText={descriptionText} />
          {settings.REACT_APP_GOOGLE_MAPS_KEY && (
            <div
              className={`${
                nearByAttractions.length > 0 ? "hotel-detail_location" : ""
              }`}
            >
              <MapSection
                coordenates={coordenates}
                address={address}
                apiKey={settings.REACT_APP_GOOGLE_MAPS_KEY}
              />
              {nearByAttractions.length > 0 && (
                <div className="mt-4">
                  <NearByLocations nearByAttractions={nearByAttractions} />
                </div>
              )}
            </div>
          )}
          <div className="d-grid">
            <button
              className="btn btn-primary back-button col-4 align-self-center offset-4 "
              type="button"
              onClick={() => navigate(-1)}
            >
              Back to all hotels
            </button>
          </div>
        </Col>
      </div>
    </div>
  );
};
export default ExtDetailInfo;
