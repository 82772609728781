import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Divider, Row, Space } from "antd";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { IUsersTransform } from "../../../Models/UsersModel";

interface BookCardProps {
  user: IUsersTransform;

  resetPass: (email: string) => void;
}

const SalesManageCard = ({ user, resetPass }: BookCardProps): ReactElement => {
  const navigate = useNavigate();
  return (
    <Card className="card rounded-dp shadow-lg booking-card">
      <Row justify="space-between">
        <Col style={{ marginLeft: "10%" }}>
          <h4 style={{ textAlign: "left" }}>{user.firstName}</h4>
        </Col>
        <Col>
          <Space size="small">
            <button
              type="button"
              className="btn btn-primary-dp-xs"
              onClick={() => navigate(`/sales?user=${user.userId}`)}
            >
              <FontAwesomeIcon icon={faSearch as IconProp} className="me-2" />
              Bookings
            </button>

            <button
              type="button"
              onClick={() => resetPass(user.userId)}
              className="btn btn-success-dp-xs"
            >
              <FontAwesomeIcon icon={faReply as IconProp} className="me-2" />
              Send reset password email
            </button>
          </Space>
        </Col>
      </Row>

      <Divider orientation="left">Last Name</Divider>
      <p className="booking-card-info">{user.lastName}</p>
      <Divider orientation="left">User Email</Divider>
      <p className="booking-card-info">{user.userId}</p>
      <Divider orientation="left">Role</Divider>
      <p className="booking-card-info">{user.rol}</p>
    </Card>
  );
};
export default SalesManageCard;
