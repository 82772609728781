import React from "react";
import { Col } from "antd";
import { RoutesPath } from "../../../../Routes";

interface BenefitCardMembershipProps {
  img?: string;
  title?: string;
  price?: string;
  subtitle?: string;
  content?: string[];
  type?: number;
}

const BenefitCardMembership = ({
  img,
  title,
  price,
  subtitle,
  content,
  type,
}: BenefitCardMembershipProps) => (
  <Col xs={24} lg={6} md={12} className="benefit-col">
    <div className="benefit-img-wrapper">
      <img
        src={img}
        alt={title}
        className={`benefit-img ${type === 2 ? "border-orange" : ""}`}
      />
    </div>
    <div className="content-container">
      <div className="title-wrapper">
        <span className="title-benefit">{title}</span>
        <span className="price-benefit">{price}</span>
      </div>
      <div className="subtitle-wrapper">
        <small>{subtitle}</small>
      </div>
      <ul className="content-benefits">
        {content?.map((item: string, index) =>
          item.includes("Click Here" || "click here") ? (
            <li key={index}>
              <a href={`${RoutesPath.home}/login-members/`}>{item}</a>
            </li>
          ) : (
            <li key={index}>{item}</li>
          )
        )}
      </ul>
    </div>
  </Col>
);
export default BenefitCardMembership;
