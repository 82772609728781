import React, { ReactElement } from "react";
import FacilitiesHotelComponent from "../../../../../../../Components/HotelCard/FacilitiesHotelComponent";

interface FacilityRoomProps {
  nameFacility: string;
}

const FacilityRoom = ({ nameFacility }: FacilityRoomProps): ReactElement => (
  <small className="facility-room-option">
    <FacilitiesHotelComponent
      name={nameFacility}
      className="me-2 room-description"
    />
    {nameFacility}
  </small>
);
export default FacilityRoom;
