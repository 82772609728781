import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "antd";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchContext } from "../../Context/context";
import { useViewport } from "../../Context/useViewport";
import { useScrollLock } from "../../helpers/scrollLock";
import { ISearch } from "../../Models/Search";
import SearchBoxFilterMobile from "./SearchBoxFilterMobile";
import SearchBoxForm from "./SearchBoxForm";

interface SearchBoxFilterProps {
  isOpen?: boolean;
  onScrollBox?(): void;
  onClickOpenMenu(): void;
  isVisible?: boolean;
  isWidget?: boolean;
}

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

const SearchBoxFilter = ({
  isOpen,
  onClickOpenMenu,
  isVisible,
  isWidget,
}: SearchBoxFilterProps): ReactElement => {
  const { width } = useViewport();
  const { searchContent } = useContext(SearchContext) as ContextType;
  const { lockScroll, unlockScroll } = useScrollLock();
  const [ubicationName, setUbicationName] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [scrollLock, setLockScroll] = useState<boolean>(false);
  const [showAlert, setAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const [Errmsg, setErrmsg] = useState<string>("");
  const url = window.location.href;
  const found = url.match("hotel/results");
  const locationParam = searchParams.get("countryname");

  useEffect(() => {
    if (
      locationParam !== null &&
      locationParam !== undefined &&
      locationParam !== "null"
    ) {
      setUbicationName(locationParam.replace(/%/g, " "));
    } else {
      setUbicationName("");
    }
  }, [locationParam]);

  const obtainUbicationName = (ubication: string) => {
    setUbicationName(ubication);
  };

  const handleClickOpenMenuMobile = () => {
    lockScroll();
    setLockScroll(true);
    setIsMenuOpen(true);
  };

  const handleClickCloseMenuMobile = () => {
    unlockScroll();
    setLockScroll(false);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const scrollTop = window.scrollX;
    const scrollLeft = window.scrollY;
    if (scrollLock && width <= 1000) {
      window.onscroll = () => {
        window.scrollTo(scrollLeft, scrollTop);
      };
    } else {
      window.onscroll = () => { };
    }
  }, [scrollLock]);

  const accesibleOnclick = (handler: any) => ({
    role: "button",
    tabIndex: 0,
    onClick: handler,
  });

  return (
    <>
      {isVisible !== undefined && !isVisible && <></>}
      {width > 992 && (isVisible === true || isVisible === undefined) && (
        <div
          className={
            isWidget
              ? `search-box-filter-widget `
              : `container-fluid search-box-filter aligment`
          }
        >
          {showAlert && (
            <Alert
              type="error"
              message="Error"
              description={Errmsg}
              closable
              className="dateAlert"
            />
          )}
          <div className={isWidget ? `` : `container`}>
            <SearchBoxForm
              Err={showAlert}
              onChangeUbication={obtainUbicationName}
              setErr={setAlert}
              setErrmsg={setErrmsg}
              isWidget={isWidget != undefined ? isWidget : false}
            />
          </div>
        </div>
      )}
      {width <= 992 && (isVisible === true || isVisible === undefined) && (
        <>
          <div className="search-box-filter-mobile">
            <div
              className="ubication-wrapper"
              {...accesibleOnclick(handleClickOpenMenuMobile)}
            >
              <div className="ubication-icon">
                <FontAwesomeIcon icon={faSearch as IconProp} />
              </div>
              <div className="ubication-content">
                {ubicationName ? (
                  <span className="ubication-label d-inline-block text-truncate">
                    {ubicationName}
                  </span>
                ) : (
                  <span className="ubication-label">
                    Please select your location
                  </span>
                )}
                <div className="date-wrapper">
                  <span className="date-label">
                    {searchContent.checkIn.replace(/-/g, "/")}
                  </span>
                  <span className="date-label">-</span>
                  <span className="date-label">
                    {searchContent.checkOut.replace(/-/g, "/")}
                  </span>
                </div>
              </div>
            </div>
            {found && (
              <div className="button-ubication-content">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={onClickOpenMenu}
                >
                  <FontAwesomeIcon icon={faSliders as IconProp} />
                  <small>Filters</small>
                </button>
              </div>
            )}
          </div>
          <SearchBoxFilterMobile
            isFilterOpen={isMenuOpen}
            onChangeUbication={obtainUbicationName}
            onCloseMenu={handleClickCloseMenuMobile}
            setAlert={setAlert}
            showAlert={showAlert}
            setErrmsg={setErrmsg}
            errMsg={Errmsg}
          />
        </>
      )}
    </>
  );
};
export default SearchBoxFilter;
