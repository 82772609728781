import { Card, Modal } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import ReviewItem from "./ReviewItem";
import { IReview, IReviews } from "../../../../Models/Review";
import { renderRate } from "../../../../helpers/reviewText";

interface props {
  hidden?: boolean;
  reviews: IReviews | undefined;
}

const average = (reviews: IReviews | undefined): number => {
  if (reviews && reviews.verified && reviews.verified.recent) {
    const sum = reviews?.verified.recent.reduce(
      (accumulator, object) => accumulator + Number(object.rating),
      0
    );
    if (sum === undefined) return 0;

    const limit =
      reviews?.verified?.recent.length === undefined
        ? 1
        : reviews?.verified?.recent.length;

    return sum / limit;
  }
  return 0;
};

const totalReviews = (reviews: IReviews | undefined): number => {
  if (reviews && reviews.verified && reviews.verified.recent) {
    const limit =
      reviews?.verified?.recent.length === undefined
        ? 1
        : reviews?.verified?.recent.length;

    return limit;
  }
  return 0;
};

const Reviews = ({ hidden, reviews }: props): ReactElement => {
  const [openModal, setopenModal] = useState(false);
  const [reviewsModal, setreviewsModal] = useState<IReview[]>();
  const [pageNumber, setpageNumber] = useState(1);
  const [visiblePagination, setvisiblePagination] = useState(true);

  useEffect(() => {
    if (
      reviews &&
      reviews.verified &&
      reviews.verified.recent &&
      reviews.verified.recent.length &&
      pageNumber * 25 >= reviews.verified.recent.length
    ) {
      setvisiblePagination(false);
      setreviewsModal(reviews?.verified.recent);
    } else {
      setvisiblePagination(true);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (
      reviews &&
      reviews.verified &&
      reviews.verified.recent &&
      reviews.verified.recent.length &&
      pageNumber * 25 >= reviews.verified.recent.length
    ) {
      setreviewsModal(reviews?.verified.recent.slice(0, 3));
    }
  }, [reviews]);

  return (
    <Card id="reviews" className="my-3" hidden={hidden}>
      <h4>Verified Guest Reviews</h4>
      <div className="hotel-card-rate-reviews-wrapper my-3">
        <div
          style={{ fontSize: 24 }}
          className="hotel-card-rate-badge-summary hotel-card-rate-badge-modal"
        >
          {average(reviews).toFixed(2)}
          <div className="triangle" />
        </div>
        <span
          className="hotel-card-hotel-star-review"
          style={{
            fontWeight: "bold",
            marginLeft: "50px",
            fontSize: "20px",
            color: "#0b6ff0",
          }}
        >
          {renderRate(Math.round(average(reviews)))}
        </span>
      </div>
      {reviews?.verified?.recent
        .sort((a, b) => Number(b.rating) - Number(a.rating))
        .map((review, index) => {
          if (index < 3) {
            return <ReviewItem key={index} review={review} />;
          }
          return null;
        })}

      <Modal
        title={
          <div className="modal-title-reviews">
            Guest Score & Reviews{" "}
            <div onClick={() => setopenModal(false)}>x</div>
          </div>
        }
        open={openModal}
        onOk={() => setopenModal(false)}
        onCancel={() => setopenModal(false)}
        width={1200}
        closable={false}
        cancelButtonProps={{ style: { visibility: "hidden" } }}
        okButtonProps={{ style: { visibility: "hidden" } }}
      >
        <div className="col-12 ">
          <h4>{totalReviews(reviews)} Verified Guest Reviews</h4>
          <div className="hotel-card-rate-reviews-wrapper my-3">
            <div
              style={{ fontSize: 24 }}
              className="hotel-card-rate-badge-summary hotel-card-rate-badge-modal"
            >
              {average(reviews).toFixed(2)}
              <div className="triangle" />
            </div>
            <span
              className="hotel-card-hotel-star-review"
              style={{
                fontWeight: "bold",
                marginLeft: "50px",
                fontSize: "20px",
                color: "#0b6ff0",
              }}
            >
              {renderRate(Math.round(average(reviews)))}
            </span>
          </div>
        </div>
        {reviewsModal &&
          reviewsModal
            .sort((a, b) => Number(b.rating) - Number(a.rating))
            .map((review, index) => <ReviewItem key={index} review={review} />)}
        <div className="d-grid">
          <button
            className="btn btn-primary back-button col-4 align-self-center offset-4 "
            type="button"
            onClick={() => setpageNumber(pageNumber + 1)}
          >
            {visiblePagination ? "Show More Reviews" : "No more reviews"}
          </button>
        </div>
      </Modal>
      <div className="d-grid">
        <button
          className="btn btn-primary back-button col-4 align-self-center offset-4 "
          type="button"
          onClick={() => setopenModal(true)}
        >
          Show All Reviews
        </button>
      </div>
    </Card>
  );
};

export default Reviews;
