import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SupplierLabelProp {
  supplier: string;
  className?: string;
}

const SupplierLabel = ({ supplier, className }: SupplierLabelProp) => {
  const flag = <FontAwesomeIcon icon={faFlag as IconProp} className="me-3" />;
  const renderSupplier = (_supplier: string) => {
    const supplierLowercase = _supplier.toLowerCase();
    switch (supplierLowercase) {
      case "dp-getaroomlive":
      case "dp-garppnlive":
      case "dp-garmobilelive":
      case "dp-garlive-channel":
        return (
          <span className="text-danger">
            {flag}Hurry Last Rooms Available - Member Rate
          </span>
        );
      case "dp-hb5-live":
      case "dp-hblive":
        return (
          <span className="text-primary">
            {flag}Rooms Selling Fast! Book Now! - Member Rate
          </span>
        );
      case "dp-hp-live":
        return (
          <span className="text-orange">
            {flag}These Prices Wont Last! - Member Rate
          </span>
        );
      case "dp-ratehawk-test":
      case "dp-ratehawklive":
        return (
          <span className="text-orange">
            {flag}Prices Change Quickly! Book Now! - Member Rate
          </span>
        );
      case "dp-ean-sa-mapp-live":
      case "dp-ean-sa-web-live":
      case "dp-ean-sa-web-test":
      case "dp-eantest-channel":
        return (
          <span className="text-green">
            {flag}High Demand Hotel! Lock in Your Price! - Member Rate
          </span>
        );
      case "dp-dotwtest":
      case "dp-dotw-live":
        return (
          <span className="text-purple">
            {flag}Dont Miss This Deal! Book Now! - Member Rate
          </span>
        );
      case "dp-hotelbeds-meta-live":
        return (
          <span className="text-purple">
            {flag}Special DealPeak Member Rate
          </span>
        );
      case "dp-hbb2c12-live":
        return (
          <span className="text-purple">
            {flag}Hot Deals - High Demand! - Member Rate
          </span>
        );
      default:
        return (
          <span className="text-purple">{flag}Hot deals! - Member Rate</span>
        );
    }
  };

  return (
    <div className={`supplier-label ${className}`}>
      {renderSupplier(supplier)}
    </div>
  );
};
export default SupplierLabel;
