import React from "react";
import { useNavigate } from "react-router-dom"; // Import react-route-dom

import "antd/dist/antd.min.css";
import { Result } from "antd";

interface MessageResultProp {
  message: string;
  otherOfferStateHandle?: () => void;
}

const ErrorResult = ({ message, otherOfferStateHandle }: MessageResultProp) => {
  const navigate = useNavigate();

  return (
    <Result
      status="warning"
      title="Room options have changed for your selection, we have found additional room offerings."
      subTitle={message}
      extra={[
        <div className="row">
          {otherOfferStateHandle &&
          typeof otherOfferStateHandle === "function" ? (
            <div className="col">
              <button
                type="button"
                className="btn btn-success-dp"
                onClick={() => otherOfferStateHandle()}
              >
                See other offers
              </button>
            </div>
          ) : (
            <div className="col">
              <button
                type="button"
                className="btn btn-success-dp"
                onClick={() => navigate(-1)}
              >
                Go back
              </button>
            </div>
          )}
        </div>,
      ]}
    />
  );
};

export default ErrorResult;
