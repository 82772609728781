import {
  Modal,
  Form,
  Select,
  Input,
  Switch,
  InputNumber,
  Col,
  Row,
} from "antd";
import { ISetting, getByName } from "../../../../helpers/requestSetting";
import { useEffect, useState } from "react";

const { Option } = Select;

interface props {
  open: boolean;
  onCancel: () => void;
  setting: ISetting;
  onOk: (values: ISetting) => void;
}
const AdditionalFeeConfig = ({ open, onCancel, onOk, setting }: props) => {
  const [form] = Form.useForm();
  const [isActive, setisActive] = useState(false);
  const [providers, setProviders] = useState<Array<string>>([]);

  const fillForm = () => {
    if (setting && setting.value) {
      const parsedValue = JSON.parse(setting.value);
      setisActive(parsedValue.active);
      form.setFieldsValue({
        active: parsedValue.active,
        device: parsedValue.device,
        amount: parsedValue.amount,
        issuer: parsedValue.issuer,
        provider: parsedValue.provider,
        politics: parsedValue.politics,
      });
    }
  };

  useEffect(() => {
    if (
      setting.name === "CreditCardFeeMobile" ||
      setting.name === "CreditCardFeeDesktop"
    ) {
      fillForm();
    }
  }, [setting, form]);

  useEffect(() => {
    const init = async () => {
      const setting = await getByName("Providers");
      const providerArray = setting.value.split(",");
      setProviders(providerArray);
    };
    init();
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const jsonValue = JSON.stringify(values);
        const editSetting: ISetting = {
          id: setting.id,
          name: setting.name,
          value: jsonValue,
        };
        onOk(editSetting);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  return (
    <Modal
      open={open}
      title="Credit card fee configuration"
      onCancel={onCancel}
      style={{ minWidth: "60%" }}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="device"
              label="Device"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select device" disabled showArrow={false}>
                <Option value="CreditCardFeeMobile">Mobile</Option>
                <Option value="CreditCardFeeDesktop">Desktop</Option>
              </Select>
            </Form.Item>
            <Form.Item name="active" label="Active">
              <Switch
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, type: "number", min: 0 }]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                placeholder="Enter amount"
                size="middle"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="issuer"
              label="Issuer"
              rules={[{ required: true }]}
            >
              <Select mode="multiple" placeholder="Select issuers">
                <Option value="vi">Visa</Option>
                <Option value="ca">Mastercard</Option>
                <Option value="ds">Discover</Option>
                <Option value="ax">American Express</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="provider"
              label="Provider"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                placeholder="Select providers ids"
                style={{ width: "100%" }}
                maxTagCount="responsive"
              >
                {providers.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="politics" label="Policies">
              <Input.TextArea
                showCount
                rows={6}
                placeholder="Enter policies here"
                style={{ height: 220, resize: "none" }}
                maxLength={200}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AdditionalFeeConfig;
