import React, { ReactElement, useState } from "react";

interface GuestRatingFilterProps {
  values: { label: string; value: number }[];
  type: "rating" | "starRating";

  onChange?(value: string): void;
  selectedRatings: string[];
}

const GuestRatingFilter = ({
  values,
  type,
  onChange,
  selectedRatings,
}: GuestRatingFilterProps): ReactElement => {
  const [selected, setSelected] = useState<boolean>(false);
  const selectValueOfFilter = (_index: number) => {
    setSelected(!selected);
    if (onChange) {
      onChange(_index.toString());
    }
  };

  return (
    <div className="guest-rating-filter">
      {values.map((itemValue) => (
        <span
          className={`${
            selectedRatings.find(
              (star) => star === itemValue.value.toString()
            ) !== undefined && type === "rating"
              ? "selected"
              : ""
          } ${
            selectedRatings.find(
              (star) => star === itemValue.value.toString()
            ) !== undefined && type === "starRating"
              ? "selected"
              : ""
          }`}
          key={itemValue.value}
          onClick={() => selectValueOfFilter(itemValue.value)}
        >
          {itemValue.label}
        </span>
      ))}
    </div>
  );
};
export default GuestRatingFilter;
