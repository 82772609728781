import APIClient from "../Components/Services/APIClient";

export interface IProspect {
  campaignId: string;
  email: string;
  ipAddress: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  AFID: string;
  SID: string;
  AFFID: string;
  C1: string;
  C2: string;
  C3: string;
  AID: string;
  OPT: string;
  click_id: string;
  notes: string;
}
export const addNewProspect = async (
  newProspectRequest: IProspect
): Promise<void> => {
  const client = new APIClient();
  await client.client.post("Prospect", newProspectRequest);
};
