import {
  DrawingManager,
  GoogleMap,
  Polygon,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useMemo } from "react";

type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];

const libraries: Libraries = ["drawing"];

interface Props {
  apiKey: string;
  setCoordinates: React.Dispatch<React.SetStateAction<number[][]>>;
  edit: boolean;
  coordinatesPolygon?: { lat: number; lng: number }[];
}

const DrawMap = ({
  apiKey,
  setCoordinates,
  edit,
  coordinatesPolygon,
}: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  function getPaths(polygon: google.maps.Polygon) {
    const polygonBounds = polygon.getPath();
    const bounds: number[][] = [];
    polygonBounds.forEach((item) => {
      const point = [item.lat(), item.lng()];
      bounds.push(point);
    });
    const newArr = [...bounds];
    newArr.push(bounds[0]);
    setCoordinates(newArr);
  }

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    getPaths(polygon);
  };

  const center = useMemo(() => ({ lat: 44.5, lng: -89.5 }), []);

  return (
    <>
      {!isLoaded && <div>loading...</div>}
      {isLoaded && (
        <>
          {!edit && (
            <GoogleMap
              id="drawing-manager-example"
              mapContainerClassName="map_drawer"
              zoom={3}
              center={center}
              options={{ mapTypeControl: false, streetViewControl: false }}
            >
              <DrawingManager
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                  },
                  polygonOptions: { fillColor: "red" },
                }}
                drawingMode={google.maps.drawing.OverlayType.POLYGON}
                onPolygonComplete={onPolygonComplete}
              />
            </GoogleMap>
          )}
          {edit && coordinatesPolygon && (
            <GoogleMap
              id="drawing-manager-example"
              mapContainerClassName="map_drawer"
              zoom={coordinatesPolygon.length > 0 ? 6 : 4}
              center={
                coordinatesPolygon.length > 0 ? coordinatesPolygon[0] : center
              }
              options={{ mapTypeControl: false, streetViewControl: false }}
            >
              <Polygon
                path={coordinatesPolygon}
                options={{ fillColor: "red" }}
              />
              <DrawingManager
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                  },
                  polygonOptions: { fillColor: "red" },
                }}
                drawingMode={google.maps.drawing.OverlayType.POLYGON}
                onPolygonComplete={onPolygonComplete}
              />
            </GoogleMap>
          )}
        </>
      )}
    </>
  );
};

export default DrawMap;
