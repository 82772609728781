import APIClient from "../Components/Services/APIClient";
import { IAvailabilityRequest } from "../Models/AvailabilityRequest";
import {
  IAvailability,
  IHotelsAvailability,
  InitResponse,
} from "../Models/InitResponse";
import { IHotelContentResponse, IHotelContent } from "../Models/SearchContent";
import { IResponseHotelDetail } from "../Models/HotelContent";
import { getConfig } from "./configHelper";
import { IOccupancy } from "../Models/CommonType";
import { ContentFields } from "../Common/Enums";
import IGetHotelContent from "../Models/HotelContentBody";

interface IHotelDetailContentRequest {
  channelId: string | undefined;
  includeAllProviders: boolean;
  hotelIds: Array<string>;
  culture: string;
  contentFields: Array<string>;
}

export const getToken = async (
  centerLat: number,
  centerLong: number,
  radiusInKM: number,
  correlationId: string,
  checkIn: string,
  checkOut: string,
  occupancies: IOccupancy[],
  currency: string,
  channelId: string,
  culture: string = "en-us",
  nationality: string = "US",
  countryOfResidence: string = "US"
) => {
  const config = await getConfig(correlationId);
  const client = new APIClient();
  const availabilityRequest: IAvailabilityRequest = {
    channelId,
    currency,
    culture,
    checkIn,
    checkOut,
    occupancies,
    circularRegion: {
      centerLat,
      centerLong,
      radiusInKM,
    },
    nationality,
    countryOfResidence,
    rectangularRegion: null,
    polygonalRegion: null,
    multiPolygonalRegion: null,
    hotelIds: null,
    destinationCountryCode: null,
    filterBy: null,
  };

  const availability = await client.client.post<InitResponse>(
    `Search/availability/init`,
    availabilityRequest,
    config
  );
  sessionStorage.setItem("token", availability.data.token);
  return availability.data.token;
};

export const getHotelContent = async (
  correlationId: string,
  centerLat: number,
  centerLong: number,
  radiusInKM: number,
  channelId: string,
  imagesLimit: number | null | undefined,
  contentField: string[] = ContentFields,
  culture: string = "en-US"
): Promise<Map<string, IHotelContent>> => {
  const client = new APIClient();
  const requestBody: IGetHotelContent = {
    channelId,
    culture,
    contentFields: contentField,
    filterBy: null,
    providerPreferences: null,
    distanceFrom: {
      centerLat,
      centerLong,
    },
    circularRegion: {
      centerLat,
      centerLong,
      radiusInKM,
    },
    multiPolygonalRegion: null,
    polygonalRegion: null,
    rectangularRegion: null,
    hotelIds: null,
    imagesLimit: imagesLimit,
  };
  const map = new Map<string, IHotelContent>();
  const config = await getConfig(correlationId);
  const contentResponse = await client.client.post<IHotelContentResponse>(
    "Search/Content",
    requestBody,
    config
  );
  contentResponse.data.hotels.map((hotel) => map.set(hotel.id, hotel));

  return map;
};

export const getAvailabilityResults = async (
  token: string,
  correlationId: string,
  nextResultsKey: string | undefined | null
) => {
  const config = await getConfig(correlationId);
  const client = new APIClient();
  let url = `/Search/availability/async/${token}/results`;

  if (nextResultsKey) url += `?nextResultsKey=${nextResultsKey}`;

  return client.client.get<IAvailability>(url, config);
};

export const getHotelDetail = async (
  correlationId: string,
  hotelId: string,
  channelId: string,
  deeplinkid: string = "",
  contentField: string[] = ["All"],
  culture: string = "en-US"
): Promise<IResponseHotelDetail> => {
  const client = new APIClient();
  const requestBody: IHotelDetailContentRequest = {
    channelId,
    includeAllProviders: true,
    hotelIds: [hotelId],
    culture,
    contentFields: contentField,
  };
  const config = await getConfig(correlationId);
  const contentResponse = await client.client.post<IResponseHotelDetail>(
    `Search/Content/${deeplinkid}`,
    requestBody,
    config
  );
  return contentResponse.data;
};

export const getHotelsResult = async (
  centerLat: number,
  centerLong: number,
  radiusInKM: number,
  correlationId: string,
  checkIn: string,
  checkOut: string,
  occupancies: IOccupancy[],
  currency: string,
  channelId: string,
  culture: string = "en-us",
  nationality: string = "US",
  countryOfResidence: string = "US"
): Promise<IHotelsAvailability[]> => {
  const config = await getConfig(correlationId);
  const client = new APIClient();
  const availabilityRequest: IAvailabilityRequest = {
    channelId,
    currency,
    culture,
    checkIn,
    checkOut,
    occupancies,
    circularRegion: {
      centerLat,
      centerLong,
      radiusInKM,
    },
    nationality,
    countryOfResidence,
    rectangularRegion: null,
    polygonalRegion: null,
    multiPolygonalRegion: null,
    hotelIds: null,
    destinationCountryCode: null,
    filterBy: null,
  };

  const availability = await client.client.post<IAvailability>(
    `Search/availability`,
    availabilityRequest,
    config
  );
  sessionStorage.setItem("nextResultsKey", availability.data.token);
  return availability.data.hotels.sort(
    (x, y) =>
      Number(y.isRecommended) - Number(x.isRecommended) ||
      y.rate.baseRate - x.rate.baseRate
  );
};
