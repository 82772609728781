import { getByName } from "./requestSetting";

const settingWhiteListName = "Exempt2faIpList";

export const fetchLocationInfo = async (lat: string, long: string) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${long}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching location info:", error);
  }
};

export const isApiExempt = async (ipAddress: string) => {
  const ipWhiteList = getByName(settingWhiteListName);
};

const ipApiResponse = async (ipAddress: string): Promise<IPDataResponse> => {
  const response = await fetch(`https://ipapi.co/${ipAddress}/json`);
  const data = await response.json();
  return data as IPData;
};

export const fetchCity = async (lat: string, long: string) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBbDnA2Fh5_c33w_FVJd424efQszpEk75I`
    );
    const data = await response.json();
    const city = data.results[0].address_components.find(
      (component: { types: string | string[] }) =>
        component.types.includes("locality")
    ).long_name;

    return city;
  } catch (error) {
    console.error("Error fetching location info:", error);
  }
};

export const fetchCountry = async (ip: string): Promise<string> => {
  try {
    const response = await fetch(`https://api.country.is/${ip}`);
    const data = await response.json();
    if (data && data.country) {
      return data.country;
    } else {
      return "us";
    }
  } catch (error) {
    console.error("Error fetching location info:", error);
    return "us";
  }
};

const getRegionIpApi = async (ipAddress: string) => {
  try {
    const response = await fetch(`https://ipapi.co/${ipAddress}/region`);
    if (!response.ok) throw new Error("Failed to fetch region data");

    const region = await response.text(); // or response.json() depending on the response format
    if (region.toLowerCase() === "undefined") return undefined;
    return region;
  } catch {
    return undefined;
  }
};

interface LocationResponse {
  location: Location;
  accuracy: number;
}

interface Location {
  lat: number;
  lng: number;
}

const fetchLocation = async (): Promise<LocationResponse> => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    homeMobileCountryCode: 310,
    homeMobileNetworkCode: 410,
    radioType: "gsm",
    carrier: "Vodafone",
    considerIp: true,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const response = await fetch(
    "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBbDnA2Fh5_c33w_FVJd424efQszpEk75I",
    requestOptions
  );
  if (!response.ok) throw new Error("Failed to fetch location data");

  const data = await response.json();
  return data as LocationResponse;
};

export const getLocationInfoFromIP = async (ipAddress: string) => {
  try {
    // Make a request to ipapi.co to obtain latitude and longitude from IP address
    const apiIpRegion = await getRegionIpApi(ipAddress);
    if (apiIpRegion) return apiIpRegion;

    const location = await fetchLocation();

    // Make a request to the Google Maps Geocoding API
    const geoApiResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.location.lat},${location.location.lng}&key=AIzaSyBbDnA2Fh5_c33w_FVJd424efQszpEk75I`
    );
    const data = await geoApiResponse.json();

    // Parse the response to extract state information
    const results = data.results;
    if (results && results.length > 0) {
      for (const component of results[0].address_components) {
        if (component.types.includes("administrative_area_level_1")) {
          return component.long_name; // Return the state
        }
      }
    }
    return "State information not found";
  } catch (error) {
    return "Error fetching location information";
  }
};

type IPDataResponse = IPData | IPDataError;

interface IPDataError {
  ip: string;
  error: boolean | undefined;
  reason: string | undefined;
}

interface IPData {
  ip: string;
  network: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
}
