import React from "react";
import bedMaker from "../../../../img/icons/bed-icon-marker.svg";

interface LocationSectionProps {
  address?: {
    line1: string;
    line2: string | null;
    city: { code: string | null; name: string };
    state: { code: string | null; name: string };
    country: { code: string | null; name: string };
    postalCode: string;
  };
}

const LocationSection = ({ address }: LocationSectionProps) => (
  <div className="my-4">
    <h4>Location</h4>
    <div className="d-flex">
      <img alt={address?.city.name} src={bedMaker} className="me-4" />
      <div>
        <h6>
          {address?.line1}
          &nbsp;{address?.line2}
        </h6>
        <span>
          {address?.city.name} - {address?.country.name}
        </span>
      </div>
    </div>
  </div>
);
export default LocationSection;
