export const enum EBookingSatus {
  Confirmed = "Confirmed",
  Pending = "Pending",
  Failed = "Failed",
  Cancelled = "Cancelled",
  ConfirmationPending = "ConfirmationPending",
  Ghost = "Ghost",
}

export const enum EFeeStatus {
  Failed = "Fee Failed",
  Charged = "Fee Charged",
  NoFee="No Fee"
}

export const MembershipMenuItems = [
  "Home",
  "Explore",
  "Access",
  "Redeem",
  "Manage Membership",
];
export const MembershipMenuLinks = ["/", "/", "/memberPerks", "/", "/bookings"];

export const ContentFields = ["basic", "masterfacilities", "images"];
