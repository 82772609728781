import { useState, useEffect, ReactElement, useContext } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Result, message } from "antd";
import moment from "moment";
import MainHeaderDetail from "./MainHeaderDetail";
import BodyComponents from "./BodyComponents";
import { IHotelDetailFullInformation } from "../../Models/HotelContent";
import { getHotelDetail } from "../../helpers/requestHotels";
import { IRate, IRecommendation, IRoom } from "../../Models/RoomDetail";
import SkeletonHotelDetail from "./SkeletonHotelDetail";
import { SearchContext } from "../../Context/context";
import {
  getContentAndRooms,
  getRoomsAndRates,
} from "../../helpers/requestRoomsrates";
import {
  getByName,
  getWebConfig,
  setAppChannelId,
} from "../../helpers/requestSetting";
import { IContentRoomBody } from "../../Models/ContentRooms";
import { IFakePrice, IHotelDetailProps } from "../../Models/GooglePrice";
import { useScrollLock } from "../../helpers/scrollLock";
import { IWebConfig, IWebConfigContext } from "../../Models/SettingsModel";
import { WebConfigContext } from "../../Context/WebConfigContext";
import {
  addClickTripz,
  removeClickTripz,
} from "../../helpers/addRemoveClickTripz";
import {
  fetchLocationInfo,
  getLocationInfoFromIP,
} from "../../helpers/fetchLocation";
import { ISearch } from "../../Models/Search";
import { getReviews } from "../../helpers/requestReviews";
import { IReviews } from "../../Models/Review";
import { IOccupancy } from "../../Models/CommonType";
import AuthDrawer from "./BodyComponents/AuthDrawer";
import { getIp } from "../../helpers/configHelper";
import { isIpExempt2fa } from "../../helpers/requestIpExempt2fa";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getMembershipStatus } from "../../helpers/requestMembership";
import { getUserRol } from "../../helpers/requestUserAndRole";
import { isMembershipApproved } from "../../Data/MembershiStatusData";

const HotelDetail = (): ReactElement => {
  const { searchContent, setSearchContent } = useContext<any | ISearch>(
    SearchContext
  );
  const [, setStarRate] = useState<string>("");
  const { unlockScroll } = useScrollLock();
  const [error, setError] = useState(false);
  const { webConfigContext, setWebConfigContext } = useContext<
    any | IWebConfigContext
  >(WebConfigContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hotelDetail, setHotelDetail] = useState<
    IHotelDetailFullInformation | undefined
  >();
  const [searchParams] = useSearchParams();
  const [fakePrice, setFakePrice] = useState<IFakePrice>();
  const [sfakePrice, setSFakePrice] = useState<IFakePrice>();
  const [rooms, setRooms] = useState<Array<IRoom>>([]);
  const [showTotalNightly, setshowTotalNightly] = useState(false);
  const location = useLocation();
  const [reviews, setReviews] = useState<IReviews>();
  const [correlationId, setCorrelationId] = useState<string>("");
  const [rates, setRates] = useState<Array<IRate>>([]);
  const [openAuthDrawer, setopenAuthDrawer] = useState(false);
  const [checkoutUrl, setcheckoutUrl] = useState("");
  const [selectedRate, setSelectedRate] = useState<IRate>();
  const [recommendations, setRecommendations] = useState<
    Array<IRecommendation>
  >([]);
  const [deepLink, setdeepLink] = useState<string>();
  const [isIpExcempt, setIsIpExcempt] = useState<boolean>(false);

  const validCurrencys = ["USD"];

  const [messageApi, contextHolder] = message.useMessage();

  const errorCurrency = () => {
    messageApi.open({
      type: "error",
      content: "The currency is not valid, therefore the prices will be in USD",
    });
  };

  async function getParams(): Promise<IHotelDetailProps> {
    if (!validCurrencys.includes(searchParams.get("currency") || ""))
      errorCurrency();

    const corre = searchParams.get("correlationId") || uuidv4();
    setCorrelationId(corre);
    const hotelId = searchParams.get("hotelId") || "";
    const checkIn =
      searchParams.get("checkin") === "null" ||
      searchParams.get("checkin") === undefined
        ? moment().add(1, "days").format("YYYY-MM-DD")
        : searchParams.get("checkin");
    const checkOut =
      searchParams.get("checkout") === "null" ||
      searchParams.get("checkout") === undefined
        ? moment().add(2, "days").format("YYYY-MM-DD")
        : searchParams.get("checkout");
    const adults = parseInt(searchParams.get("adults") || "", 10);
    const currency = validCurrencys.includes(searchParams.get("currency") || "")
      ? searchParams.get("currency") || ""
      : "USD";
    const culture = "en-US";
    const context = searchParams.get("context");
    const provider = searchParams.get("provider");
    const nationality = searchParams.get("nationality") || "";
    const countryOfResidence = searchParams.get("countryOfResidence") || "";
    const children = searchParams.get("children") || "";
    const room = parseInt(searchParams.get("rooms") || "0", 10);
    const price = parseFloat(searchParams.get("price") || "0");
    const rateId = searchParams.get("rateid");
    const roomIdentifierKey = searchParams.get("roomIdentifierKey");
    const tax = parseFloat(searchParams.get("tax") || "0");
    const fee = parseFloat(searchParams.get("fee") || "0");
    const s_roomIdentifierKey = searchParams.get("s_roomIdentifierKey") || "";
    const s_price = parseFloat(searchParams.get("s_price") || "0");
    const s_tax = parseFloat(searchParams.get("s_tax") || "0");
    const s_fee = parseFloat(searchParams.get("s_fee") || "0");
    const s_roomName = searchParams.get("s_name");

    const otherRateComponent = parseFloat(
      searchParams.get("otherRateComponent") || "0"
    );
    const s_otherRateComponent = parseFloat(
      searchParams.get("s_otherRateComponent") || "0"
    );

    const callId = searchParams.get("callid");
    const roomName = searchParams.get("name");

    // ToDo: token se generara desde Prices/DeepLinkAndPrices.
    const deeplinkid = uuidv4();
    setdeepLink(deeplinkid);
    return {
      hotelId,
      correlationId: corre,
      checkIn,
      checkOut,
      adults,
      currency,
      culture,
      context,
      provider,
      nationality,
      countryOfResidence,
      children,
      room,
      deeplinkid,
      price,
      rateId,
      roomIdentifierKey,
      tax,
      fee,
      callId,
      roomName,
      otherRateComponent,
      s_roomIdentifierKey,
      s_price,
      s_fee,
      s_roomName,
      s_tax,
      s_otherRateComponent,
    };
  }

  const getToken = async (paramObj: IHotelDetailProps) => {
    const device = searchParams.get("device");

    const objectRequest: IContentRoomBody = {
      includeAllProviders: paramObj.provider === null,
      hotelIds: [paramObj.hotelId],
      contentFields: ["All"],
      checkIn: paramObj.checkIn,
      checkOut: paramObj.checkOut,
      occupancies: [
        {
          numOfAdults: paramObj.adults,
          childAges: paramObj.children
            .split(",")
            .filter(Number)
            .map((item) => parseInt(item, 10)),
        },
      ],
      nationality: paramObj.nationality,
      countryOfResidence: paramObj.countryOfResidence,
      channelId: webConfigContext.selectedChannel || "",
      culture: paramObj.culture,
      currency: paramObj.currency,
      price: paramObj.price,
      tax: paramObj.tax,
      otherRateComponent: paramObj.otherRateComponent,
      callId: paramObj.callId,
      isMobile: device?.toLocaleLowerCase() !== "desktop",
      roomName: paramObj.roomName,
      mail: searchContent.auth?.currentUser?.email,
      roomIdentifierKey: paramObj.roomIdentifierKey,
      context: paramObj.context?.toUpperCase(),
    };
    try {
      const result = await getContentAndRooms(
        paramObj.hotelId,
        paramObj.correlationId,
        paramObj.deeplinkid,
        objectRequest
      );
      setHotelDetail(result.data.contentDetail);
      setStarRate(result.data.contentDetail.starRating);
      setRooms(result.data.roomsAndRates.hotel?.rooms);
      setRates(result.data.roomsAndRates.hotel.rates);
      setRecommendations(result.data.roomsAndRates.hotel?.recommendations);
    } catch (err: any) {
      setIsLoading(false);
      setError(true);
      throw Error("Error on api call", err);
    }
  };
  const updateOccupancies = () => {
    const rooms = parseInt(searchParams.get("rooms") || "0");
    const children = searchParams.get("children") || "";
    const adults = parseInt(searchParams.get("adults") || "0");
    const occupancies: IOccupancy[] = [];
    const childAges =
      children === ""
        ? []
        : children.split(",").map((age) => parseInt(age, 10));
    let remainingAdults = adults;

    for (let i = 0; i < rooms; i++) {
      const adultsAtRoom = Math.floor(remainingAdults / (rooms - i));
      const childrenAtRoom = Math.max(
        Math.floor(childAges.length / (rooms - i)),
        1
      );

      const occupancy: IOccupancy = {
        numOfAdults: adultsAtRoom,
        childAges: [],
      };

      if (childAges.length > 0)
        for (let j = 0; j < childrenAtRoom; j++) {
          occupancy.childAges.push(childAges.shift() || 0);
        }

      if (occupancy.numOfAdults > 0 || occupancy.childAges.length > 0) {
        occupancies.push(occupancy);
      }
      remainingAdults -= adultsAtRoom;
    }

    // Distribuir niños adicionales si los hay
    let currentRoom = 0;
    while (childAges.length > 0) {
      occupancies[currentRoom].childAges.push(childAges.shift() || 0);
      currentRoom = (currentRoom + 1) % rooms;
    }

    setSearchContent({
      ...searchContent,
      occupancies: occupancies,
    });
    return occupancies;
  };

  useEffect(() => {
    unlockScroll();
    window.scrollTo(0, 0);

    const setOnLocalChannel = (
      channel: string,
      webConfigResp: IWebConfig,
      mobile: boolean
    ) => {
      setWebConfigContext({
        webConfig: webConfigResp,
        selectedChannel: channel,
        mobile,
      });
    };
    const loadReviews = async (providerHotelId: string) => {
      try {
        const response = await getReviews(providerHotelId);
        setReviews(response);
      } catch {
        console.error("Error on review");
      }
    };

    const init = async () => {
      const setNightlyRate = async (): Promise<void> => {
        const StatesShowTotalRate = await getByName("StatesShowTotalRate");
        if (StatesShowTotalRate && StatesShowTotalRate.value) {
          let stateToShow = JSON.parse(StatesShowTotalRate.value);
          const userState = await getLocationInfoFromIP(
            searchContent.userIp ?? (await getIp())
          );
          const lowerCaseStetes = stateToShow.map((str: string) =>
            str.toLowerCase()
          );

          setshowTotalNightly(
            lowerCaseStetes.includes(userState.toLowerCase()) ||
              lowerCaseStetes.includes("all")
          );
        }
      };

      const IpExempted = async (): Promise<void> => {
        const ip = searchContent.userIp ?? (await getIp());
        const isIpExempt = await isIpExempt2fa(ip);
        setIsIpExcempt(isIpExempt);
      };

      setNightlyRate();
      IpExempted();
      try {
        setSearchContent({
          ...searchContent,
          hotelDetailURL: window.location.href,
        });
      } catch (error) {
        console.error(error);
      }
      setIsLoading(true);
      const paramObj = await getParams();
      async function loadDetailBasicWf() {
        try {
          const result = await getHotelDetail(
            paramObj.correlationId,
            paramObj.hotelId,
            webConfigContext.selectedChannel ?? ""
          );

          setHotelDetail(result.hotels[0]);
          setStarRate(result.hotels[0].starRating);
          const token = sessionStorage.getItem("token");

          if (token) {
            try {
              const roomResult = await getRoomsAndRates(
                paramObj.hotelId,
                token,
                {
                  ...result.hotels[0],
                  isMobile: webConfigContext.mobile,
                  mail: searchContent.auth?.currentUser?.email,
                  selectedChannel: webConfigContext.selectedChannel ?? "",
                },
                paramObj.correlationId
              );
              // Room info
              setRooms(roomResult.data.hotel.rooms);
              setRates(roomResult.data.hotel.rates);
              setRecommendations(roomResult.data.hotel.recommendations);

              if (result.hotels[0].providerId.toLowerCase() === "ean") {
                loadReviews(result.hotels[0].providerHotelId);
              }
            } catch (error) {
              setError(true);
              setIsLoading(false);
              console.error(error);
            }
          } else await getToken(paramObj);
        } catch (err) {
          setError(true);
          setIsLoading(false);
        }
      }
      async function loadDirectHotelWf() {
        const isMobile = webConfigContext.mobile;
        let channelId = isMobile
          ? webConfigContext.webConfig.REACT_APP_CHANNEL_ID.Mobile
          : webConfigContext.webConfig.REACT_APP_CHANNEL_ID.Organic;
        if (webConfigContext.selectedChannel != undefined) {
          channelId = webConfigContext.selectedChannel;
        }
        let occupancies = [];

        const fromWithinApp = location.state?.fromwithinapp;
        if (fromWithinApp) {
          occupancies = searchContent.occupancies;
        } else {
          occupancies = updateOccupancies();
        }

        const objectRequest: IContentRoomBody = {
          includeAllProviders: true,
          hotelIds: [paramObj.hotelId],
          contentFields: ["All"],
          checkIn: paramObj.checkIn,
          checkOut: paramObj.checkOut,
          occupancies: occupancies,
          nationality: paramObj.nationality,
          countryOfResidence: paramObj.countryOfResidence,
          channelId,
          culture: paramObj.culture,
          currency: paramObj.currency,
          price: paramObj.price,
          tax: paramObj.tax,
          otherRateComponent: paramObj.otherRateComponent,
          callId: paramObj.callId,
          isMobile,
          roomName: paramObj.roomName,
          mail: searchContent.auth?.currentUser?.email,
          roomIdentifierKey: paramObj.roomIdentifierKey,
          context: paramObj.context?.toUpperCase(),
        };

        try {
          const result = await getContentAndRooms(
            paramObj.hotelId,
            paramObj.correlationId,
            paramObj.deeplinkid,
            objectRequest
          );

          setHotelDetail(result.data.contentDetail);
          setStarRate(result.data.contentDetail.starRating);
          setRooms(result.data.roomsAndRates.hotel?.rooms);
          setRates(result.data.roomsAndRates.hotel.rates);
          setRecommendations(result.data.roomsAndRates.hotel.recommendations);

          if (
            !sessionStorage.getItem(`roomsAndRatesToken`) &&
            result.data.roomsAndRates.token
          ) {
            sessionStorage.setItem(
              `roomsAndRatesToken`,
              JSON.stringify(result.data.roomsAndRates ?? "")
            );
          }

          sessionStorage.setItem("token", result.data.roomsAndRates.token);
          setIsLoading(false);
        } catch (err: any) {
          console.error(err);
          setIsLoading(false);
          setError(true);
        }
      }
      async function loadDetailDeepLinkWf() {
        const lat = searchParams.get("lat");
        const long = searchParams.get("long");
        setSearchContent({
          ...searchContent,
          occupancies: [
            {
              numOfAdults: paramObj.adults,
              childAges: paramObj.children
                .split(",")
                .filter(Number)
                .map((item) => parseInt(item, 10)),
            },
          ],
        });
        if (
          lat !== null &&
          lat !== undefined &&
          lat !== "" &&
          long !== null &&
          long !== undefined &&
          long !== "" &&
          window.location.href.includes("app.dealpeak")
        ) {
          const locationData = await fetchLocationInfo(lat, long);
          window._CTZ = {
            enabled: true, // Enables or disables the integration from running
            verticals: {
              hotel: {
                active: true, // Enables or disables auction from running
                search: {
                  city: locationData.address.city
                    ? locationData.address.city
                    : locationData.address.state, // Destination city
                  province:
                    locationData.address["ISO3166-2-lvl4"].split("-")[1], // Province or state abbreviation
                  countryCode:
                    locationData.address["ISO3166-2-lvl4"].split("-")[0], // two-letter ISO country code
                  checkInDate: moment(moment(paramObj.checkIn)).format(
                    "MM/DD/YYYY"
                  ),
                  checkOutDate: moment(moment(paramObj.checkOut)).format(
                    "MM/DD/YYYY"
                  ),
                },
              },
            },
          };
        }
        const webConfigResp = await getWebConfig();
        const device = searchParams.get("device");
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          const setChannelId = async (
            roles: string,
            webConfigResp: IWebConfig
          ) => {
            switch (roles) {
              case "SalesPerson":
                localStorage.setItem("UserAuth", "true");
                setOnLocalChannel(
                  webConfigResp.REACT_APP_CHANNEL_ID.Sales,
                  webConfigResp,
                  true
                );
                return;
              case "MembershipDP":
                localStorage.setItem("UserAuth", "true");
                setOnLocalChannel(
                  webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
                  webConfigResp,
                  true
                );
                return;
              case "GoogleMeta":
                setOnLocalChannel(
                  webConfigResp.REACT_APP_CHANNEL_ID.GoogleMeta,
                  webConfigResp,
                  device !== "desktop"
                );
                return;
              case "Admin":
                setOnLocalChannel(
                  webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
                  webConfigResp,
                  device !== "desktop"
                );
                return;
              default:
                setOnLocalChannel(
                  device !== "desktop"
                    ? webConfigResp.REACT_APP_CHANNEL_ID.Mobile
                    : webConfigResp.REACT_APP_CHANNEL_ID.Organic,
                  webConfigResp,
                  device !== "desktop"
                );
            }
          };
          const setRoleChannel = async (user: string) => {
            try {
              const membershipResponse = await getMembershipStatus(user);
              const response = await getUserRol(user);
              if (
                membershipResponse &&
                isMembershipApproved(membershipResponse[0].order_status)
              ) {
                setChannelId("MembershipDP", webConfigResp);
              } else {
                setChannelId(response.role.roleName, webConfigResp);
              }
            } catch (err) {
              if (webConfigResp)
                if (device !== "desktop") {
                  setOnLocalChannel(
                    webConfigResp.REACT_APP_CHANNEL_ID.Mobile,
                    webConfigResp,
                    device !== "desktop"
                  );
                } else {
                  setOnLocalChannel(
                    webConfigResp.REACT_APP_CHANNEL_ID.Organic,
                    webConfigResp,
                    device !== "desktop"
                  );
                }
            }
          };
          if (user) {
            if (user.email) {
              setRoleChannel(user.email);
            }
          } else {
            setOnLocalChannel(
              device !== "desktop"
                ? webConfigResp.REACT_APP_CHANNEL_ID.Mobile
                : webConfigResp.REACT_APP_CHANNEL_ID.Organic,
              webConfigResp,
              device !== "desktop"
            );
          }
        });

        setFakePrice({
          roomId: undefined,
          price: paramObj.price,
          roomName: paramObj.roomName,
          device: device,
          roomIdentifierKey: paramObj.roomIdentifierKey,
          fee: paramObj.fee,
          tax: paramObj.tax,
          otherRateComponent: paramObj.otherRateComponent,
        });
        setSFakePrice({
          roomId: undefined,
          price: paramObj.s_price,
          roomName: paramObj.s_roomName,
          device: device,
          roomIdentifierKey: paramObj.s_roomIdentifierKey,
          fee: paramObj.s_fee,
          tax: paramObj.s_tax,
          otherRateComponent: paramObj.s_otherRateComponent,
        });
        const occupancies =
          paramObj.adults && paramObj.adults > 0
            ? [
                {
                  numOfAdults: paramObj.adults,
                  childAges: paramObj.children
                    .split(",")
                    .filter(Number)
                    .map((item) => parseInt(item, 10)),
                },
              ]
            : searchContent.occupancies;
        const objectRequest: IContentRoomBody = {
          includeAllProviders: paramObj.provider === null,
          hotelIds: [paramObj.hotelId],
          contentFields: ["All"],
          checkIn: paramObj.checkIn,
          checkOut: paramObj.checkOut,
          occupancies: occupancies,
          mail: searchContent.auth?.currentUser?.email,
          nationality: paramObj.nationality,
          countryOfResidence: paramObj.countryOfResidence,
          channelId:
            paramObj.context?.toUpperCase() === "GOOGLE"
              ? webConfigResp.REACT_APP_CHANNEL_ID.GoogleMeta
              : webConfigResp.REACT_APP_CHANNEL_ID.TrivagoMeta,
          culture: paramObj.culture,
          currency: paramObj.currency,
          price: paramObj.price,
          tax: paramObj.tax,
          otherRateComponent: paramObj.otherRateComponent,
          callId: paramObj.callId,
          isMobile: device?.toLowerCase() !== "desktop",
          roomName: paramObj.roomName,
          s_price: searchParams.get("s_price")
            ? parseFloat(searchParams.get("s_price") || "")
            : undefined,
          s_tax: searchParams.get("s_tax")
            ? parseFloat(searchParams.get("s_tax") || "")
            : undefined,
          s_fee: searchParams.get("s_fee")
            ? parseFloat(searchParams.get("s_fee") || "")
            : undefined,
          s_name: searchParams.get("s_name") ?? "",
          s_roomidentifierkey: searchParams.get("s_roomIdentifierKey") ?? "",
          roomIdentifierKey: paramObj.roomIdentifierKey,
          context: paramObj.context?.toUpperCase(),
        };

        try {
          const result = await getContentAndRooms(
            paramObj.hotelId,
            paramObj.correlationId,
            paramObj.deeplinkid,
            objectRequest
          );
          let frates = [] as IRate[];
          if (
            result.data.roomsAndRates !== undefined &&
            result.data.roomsAndRates.hotel !== undefined &&
            result.data.roomsAndRates.hotel?.rates !== undefined
          ) {
            frates = result.data.roomsAndRates.hotel?.rates?.filter(
              (element) => {
                const key = element.additionalInformation?.filter(
                  (p) => p.type === "RoomIdentifierKey"
                )[0];

                return (
                  (key?.text ?? "") === (paramObj.roomIdentifierKey ?? "") ||
                  (key?.text ?? "") === (paramObj.s_roomIdentifierKey ?? "") ||
                  element.baseRate >= paramObj.price ||
                  element.baseRate <= paramObj.price ||
                  element.baseRate <= paramObj.s_price ||
                  element.baseRate >= paramObj.s_price
                );
              }
            );

            const rateMatchRoomIdentifierKey = frates.filter(
              (p) =>
                (p.additionalInformation &&
                  p.additionalInformation.length > 0 &&
                  p.additionalInformation[0].text ===
                    paramObj.roomIdentifierKey) ||
                (p.additionalInformation &&
                  p.additionalInformation.length > 0 &&
                  p.additionalInformation[0].text ===
                    paramObj.s_roomIdentifierKey)
            );
            if (
              rateMatchRoomIdentifierKey.length > 0 &&
              rateMatchRoomIdentifierKey[0]?.occupancies.length > 0
            ) {
              const fakePriceObject = frates.filter(
                (single_rate) =>
                  single_rate &&
                  single_rate.additionalInformation &&
                  single_rate.additionalInformation?.length > 0 &&
                  single_rate.additionalInformation[0].text ==
                    searchParams.get("roomIdentifierKey")
              );

              setFakePrice({
                roomId: fakePriceObject[0]?.occupancies[0].roomId,
                price: paramObj.price,
                roomName: paramObj.roomName,
                device: device,
                roomIdentifierKey: paramObj.roomIdentifierKey,
                fee: paramObj.fee,
                tax: paramObj.tax,
                otherRateComponent: paramObj.otherRateComponent,
              });

              const sFakePriceObject = frates.filter(
                (single_rate) =>
                  single_rate &&
                  single_rate.additionalInformation &&
                  single_rate.additionalInformation?.length > 0 &&
                  single_rate.additionalInformation[0].text ==
                    searchParams.get("s_roomIdentifierKey")
              );
              let roomId = sFakePriceObject[0]?.occupancies[0].roomId;
              if (sFakePriceObject && sFakePriceObject.length > 1) {
                roomId = sFakePriceObject[1]?.occupancies[0].roomId;
              }

              setSFakePrice({
                roomId: roomId,
                price: paramObj.s_price,
                roomName: paramObj.s_roomName,
                device: device,
                roomIdentifierKey: paramObj.s_roomIdentifierKey,
                fee: paramObj.s_fee,
                tax: paramObj.s_tax,
                otherRateComponent: paramObj.s_otherRateComponent,
              });
            }
          }

          setHotelDetail(result.data.contentDetail);
          setStarRate(result.data.contentDetail.starRating);
          setRooms(result.data.roomsAndRates.hotel?.rooms);
          setRates(frates);
          setRecommendations(result.data.roomsAndRates.hotel?.recommendations);

          if (
            !sessionStorage.getItem(`roomsAndRatesToken`) &&
            result.data.roomsAndRates.token
          ) {
            sessionStorage.setItem(
              `roomsAndRatesToken`,
              JSON.stringify(result.data.roomsAndRates ?? "")
            );
          }
          sessionStorage.setItem("token", result.data.roomsAndRates.token);
          setIsLoading(false);
        } catch (err: any) {
          console.error(err);
          setIsLoading(false);
          setError(true);
        }
      }

      if (paramObj.correlationId && paramObj.hotelId) {
        if (!webConfigContext.selectedChannel) await setAppChannelId();

        if (
          paramObj.context?.toUpperCase() === "GOOGLE" ||
          paramObj.context?.toUpperCase() === "TRIVAGO"
        ) {
          await loadDetailDeepLinkWf();
        } else if (paramObj.context?.toUpperCase() === "DIRECTHOTEL") {
          await loadDirectHotelWf();
        } else {
          await loadDetailBasicWf();
        }
        setIsLoading(false);
      }
    };
    init();
    if (window.location.href.includes("app.dealpeak")) {
      const script = addClickTripz();
      return () => {
        removeClickTripz(script);
      };
    }
  }, [searchParams]);

  const isInValidContentDetail = (
    hotelDetail: IHotelDetailFullInformation | undefined
  ) => {
    return (
      hotelDetail?.id === null ||
      hotelDetail?.name === null ||
      hotelDetail?.Rooms === null ||
      hotelDetail?.contact === null
    );
  };

  return (
    <section id="main-header-detail-hotel">
      {contextHolder}
      <AuthDrawer
        checkoutURL={checkoutUrl}
        openDrawer={openAuthDrawer}
        setOpenDrawer={setopenAuthDrawer}
        selectedRate={selectedRate}
        nameHotel={hotelDetail?.name}
      />
      {isLoading ? (
        <SkeletonHotelDetail />
      ) : (
        <>
          {error ||
            (isInValidContentDetail(hotelDetail) && (
              <Result
                status="500"
                title="Hotel Not Found"
                subTitle="Sorry, the hotel you were looking for was not found."
              />
            ))}
          {!error && !isInValidContentDetail(hotelDetail) && (
            <>
              <MainHeaderDetail
                facilities={hotelDetail?.facilityGroups}
                nameHotel={hotelDetail?.name}
                images={hotelDetail?.images}
              />

              {hotelDetail && (
                <BodyComponents
                  setSelectedRate={setSelectedRate}
                  setcheckoutUrl={setcheckoutUrl}
                  setOpenDrawer={setopenAuthDrawer}
                  correlationId={correlationId}
                  policy={hotelDetail.policies}
                  deeplink={deepLink}
                  starRating={hotelDetail?.starRating}
                  nameHotel={hotelDetail?.name}
                  images={hotelDetail?.images}
                  facilities={hotelDetail?.facilityGroups}
                  address={hotelDetail?.contact.address}
                  nearByAttractions={hotelDetail.nearByAttractions}
                  recommendation={recommendations}
                  rooms={rooms}
                  rates={rates}
                  fakePrice={fakePrice}
                  coordenates={{
                    lat: hotelDetail.geoCode.lat.toString(),
                    long: hotelDetail.geoCode.long.toString(),
                  }}
                  settings={webConfigContext.webConfig}
                  descriptionText={hotelDetail?.descriptions}
                  reviews={reviews}
                  showTotalNightly={showTotalNightly}
                  sFakePrice={sfakePrice}
                  isIpExcempt={isIpExcempt}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default HotelDetail;
