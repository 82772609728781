import { notification } from "antd";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  UserCredential,
  Auth,
} from "firebase/auth";

const logInWithEmailAndPassword = async (
  email: string,
  password: string,
  auth: Auth
): Promise<UserCredential | undefined> => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    if (
      err.code === "auth/wrong-password" ||
      err.code === "auth/user-not-found" ||
      err.code === "auth/invalid-email"
    ) {
      notification.error({
        message: "Error",
        description: `Invalid credentials`,
      });
    } else {
      notification.error({
        message: "Error",
        description: `Message: ${err.message}`,
      });
    }
  }
};

const registerWithEmailAndPassword = async (
  email: string,
  password: string,
  auth: Auth
): Promise<UserCredential | null> => {
  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    notification.error({
      message: "Error",
      description: `${err.message}`,
    });
    return null;
  }
};

const sendPasswordReset = async (email: string, auth: Auth) => {
  try {
    await sendPasswordResetEmail(auth, email);
    notification.success({
      message: "Link sent",
      description: "Password reset link sent",
    });
  } catch (err: any) {
    notification.error({
      message: "Error",
      description: `${err.message}`,
    });
  }
};

const logout = (auth: Auth) => {
  localStorage.setItem("UserAuth", "false");
  signOut(auth);
};

export {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
