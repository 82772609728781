import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { Product } from "../../../../helpers/requestMembership";
import cardVipImage from "../../../../img/card-vip.png";
import BestPrice from "../../../../img/BestPrice.png";
import { useViewport } from "../../../../Context/useViewport";
import image from "../../../../img/membership_header_image.png";
import imageGold from "../../../../img/Gold_word.png";
import one from "../../../../img/Number_1.png";
import two from "../../../../img/Number_2.png";
import three from "../../../../img/Number_3.png";
import { IMembership } from "../../../../Models/Checkout";
import { updateThreeDsForm } from "../../../../helpers/domManual";

interface props {
  setMembership: Dispatch<SetStateAction<IMembership | undefined>>;
  membership: IMembership | undefined;
  products: Product[];
  updateMember: (create: boolean | undefined) => void;
  createMembership: boolean | undefined;
  membershipMandatory: boolean;
}

const MembershipSection = ({
  setMembership,
  membership,
  products,
  updateMember,
  createMembership,
  membershipMandatory,
}: props) => {
  const [wpBaseUrl] = useState<string>(
    process.env.REACT_APP_API_BASE_WP_URL || ""
  );
  const { width } = useViewport();

  const onSelected = (checked: boolean) => {
    setMembership(membership);
    const product = products.find(
      (p) => p.product_id === membership?.productId
    );
    if (product) {
      updateThreeDsForm(
        product.product_rebill?.product_price.toString() || "",
        "x_rebill_price"
      );
    }
    updateMember(checked);
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  useEffect(() => {
    //ToDo: no tiene sentido esto, siempre se pasa al menos un producto por la configuración de `CampaignProduct`
    //ToDo: qué pasaría si la membresia no es obligatoria y el usuario no selecciono ninguna membresia?.
    if (products[0] && membershipMandatory) {
      updateThreeDsForm(
        products[0].product_rebill?.product_price.toString() || "",
        "x_rebill_price"
      );
    }
    setMembership({
      productId: products[0].product_id,
      campaignId: products[0].campaign_id,
      password: membership?.password,
    });
  }, [products]);

  return (
    <div>
      {width > 1023 && !membershipMandatory && (
        <div className="card membership-card">
          <div className="card-body titleMembership d-flex flex-column align-items-center text-center">
            <Row className="text-center">
              <h1 className="qtyDaysText">60 Day Complimentary Trial</h1>{" "}
            </Row>
            <Row className="text-center subtitleMembership">
              <b> L I M I T E D</b>
              <span className="mx-2"> </span>
              <b> T I M E </b>
              <span className="mx-2"> </span>
              <b> O F F E R </b>
            </Row>
          </div>
          <div className="offer-section">
            <div className="text-center row ">
              <span className="left-text-membership text-white">
                Join <b style={{ fontSize: 40, letterSpacing: 0 }}>DEAL</b>
                <b style={{ fontSize: 40, letterSpacing: 0, color: "#3fc9f7" }}>
                  PEAK
                </b>
                <b style={{ fontSize: 40, letterSpacing: 0, color: "#eeb902" }}>
                  GOLD!
                </b>
              </span>
            </div>
            <div className=" row my-3">
              <div className="col-lg-7 col-xs-12">
                <h5 className="text-golden">MEMBER PERKS:</h5>
                <ul className="list-benefits-vip-club">
                  <li>
                    <b>Save Up To 70% of 400,000 Hotels</b>
                  </li>
                  <li>
                    <b>Discount Coupons</b>
                  </li>
                  <li>
                    <b>Room Upgrades</b>
                  </li>
                  <li>
                    <b>Last Minute Deals</b>
                  </li>
                  <li>
                    <b>$100 Hotel Savers Card</b>
                  </li>
                </ul>
                <div>
                  <div className="containerMembership">
                    <h3 className="onlyPriceoriignal">Only</h3>{" "}
                    <h3 className="onlyPriceoriignal">$</h3>{" "}
                    <h3 className="onlyPrice">69.95</h3>
                  </div>
                  <div className="underonlyPrice">
                    <div className="container">
                      <strong>60 Day Complimentary Trial</strong>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xs-12 text-center">
                <img
                  src={cardVipImage}
                  alt="VIP card Dealpeak"
                  className="img-fluid"
                />
                <img
                  src={BestPrice}
                  alt="VIP card Dealpeak"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="content-membership-card card-body d-flex flex-column align-items-center">
            <Row className="text-center">
              <h5>
                Do you want acces instant savings by joining DealPeakGold?
              </h5>{" "}
            </Row>
            <Row>
              <Col className="my-2">
                <button
                  type="button"
                  onClick={() => updateMember(true)}
                  className={
                    createMembership
                      ? "membership-Button-pressed membership-Button-yes "
                      : "membership-Button membership-Button-yes "
                  }
                  style={{ marginLeft: "20px" }}
                >
                  <strong className="checkbox-color-text">YES!</strong>
                  <br />
                  <strong>I want to get lower prices!</strong>
                </button>
                <button
                  type="button"
                  onClick={() => updateMember(false)}
                  style={{ marginLeft: "20px" }}
                  className={
                    createMembership === false
                      ? "membership-Button-pressed membership-Button-no "
                      : "membership-Button membership-Button-no "
                  }
                >
                  <strong className="checkbox-color-text ">NO</strong>
                  <br />I do not want lower prices.
                </button>
              </Col>
            </Row>
            Membership Terms and Conditions.
            <Row style={{ marginTop: "15px", fontSize: "12px" }}>
              <b>
                By clicking 'YES', I confirm that I have read and agree to&nbsp;
                the
                <a
                  href={`${wpBaseUrl}/terms-conditions/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;Membership Terms and Conditions&nbsp;
                </a>
                Today, I am choosing to join DealPeak Gold and receive a 60-day
                complimentary trial subscription. I am aware that at the end of
                the trial period, which is approximately 60 days from today, the
                payment method I have provided will be charged $69 for the
                annual DealPeak Gold subscription. This subscription will
                automatically renew each year at the prevailing renewal rate. I
                understand that I have the option to cancel my subscription at
                any time and discontinue future billings by calling
                1-800-919-2339 or logging into my DealPeak Account online.
                Please note that residents of Iowa and Indiana are not eligible
                for this program.
              </b>
            </Row>
          </div>
        </div>
      )}
      {width <= 1023 && (
        <div className="card membership-card membership-card-sidebar">
          <img
            className="card-img-top header-membership"
            src={image}
            alt="headerImage"
          />
          <div className="card-body d-flex flex-column ">
            {membershipMandatory ? (
              <>
                <Row className="subtitleMembership subtitleMembershipSideBar">
                  <b>Included in today's purchase</b>
                </Row>
                <Row className="h4">
                  <b>DealPeak</b>
                  <img
                    className="gold_word_membership"
                    src={imageGold}
                    alt="gold_logo"
                  />
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <img
                    className="gold_word_membership"
                    src={imageGold}
                    alt="gold_logo"
                  />
                </Row>
                <Row className=" subtitleMembership subtitleMembershipSideBar">
                  <b>Discover DealPeak Gold</b>
                </Row>
              </>
            )}
          </div>
          <div className="offer-section">
            <span className="left-text-membership-sidebar">
              <div className="sideWhen ">
                Never overpay for hotels again with our ridiculously crazy hotel
                deals.
              </div>
            </span>
            <div className="row ">
              <div className="col-lg-12 col-xs-12">
                <ul className="list-benefits-vip-club-sidebar">
                  <li>
                    <b>70% off Hotels & Resorts</b>
                  </li>
                  <li>
                    <b>Last Minute Unsold Rooms</b>
                  </li>
                  <li>
                    <b>Wholesale Rates</b>
                  </li>
                  <li>
                    <b>Suite Upgrades</b>
                  </li>
                  <li>
                    <b>$100 Hotel Savers Card</b>
                  </li>
                  <li>
                    <b>60 Day Trial</b>
                  </li>
                </ul>
              </div>
            </div>
            {!membershipMandatory && (
              <div className="col-lg-12 col-xs-12 text-left">
                <span className="membership-sidebar-subtitle">
                  Subscribing To DealPeak Gold is easy:
                </span>
                <Row className="list-benefits-member-club-sidebar">
                  <Row>
                    <Col>
                      {" "}
                      <img
                        className="list_number_icon"
                        src={one}
                        alt="gold_logo"
                      />
                    </Col>
                    <Col
                      className="d-flex align-items-center justify-content-center"
                      style={{ fontSize: "15px" }}
                    >
                      Join Dealpeak
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {" "}
                      <img
                        className="list_number_icon_two"
                        src={two}
                        alt="gold_logo"
                      />
                    </Col>
                    <Col
                      className="d-flex align-items-center justify-content-center"
                      style={{ fontSize: "15px" }}
                    >
                      Get ridiculously crazy hotel deals
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img
                        className="list_number_icon"
                        src={three}
                        alt="gold_logo"
                      />
                    </Col>
                    <Col
                      className="d-flex align-items-center justify-content-center"
                      style={{ fontSize: "15px" }}
                    >
                      Go one vacation at a fraction of the cost!
                    </Col>
                  </Row>
                </Row>
                <Row>
                  <Col>
                    <Checkbox
                      onChange={(e) => onSelected(e.target.value)}
                      className="mt-2 mb-2"
                      value={true}
                      checked={createMembership == true}
                    >
                      <span className="text-start">
                        <strong className="text-start">YES!</strong> I am a
                        smarty pants and I want lower prices!{" "}
                        <strong style={{ color: "#34ca7f" }}>
                          (recommended)
                        </strong>
                      </span>
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox
                      value={false}
                      onChange={(e) => onSelected(e.target.value)}
                      checked={createMembership == false}
                    >
                      <span className="text-start">
                        No I hit my head on a rock and I love paying more!
                      </span>
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <span className="smallCaps-sidebar">
                    By clicking 'YES', I confirm that I have read and agree to
                    the
                    <a
                      href={`${wpBaseUrl}/terms-conditions/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      &nbsp;Membership Terms and Conditions&nbsp;
                    </a>
                    . Today, I am choosing to join DealPeak Gold and receive a
                    60-day complimentary trial subscription. I am aware that at
                    the end of the trial period, which is approximately 60 days
                    from today, the payment method I have provided will be
                    charged $69 for the annual DealPeak Gold subscription. This
                    subscription will automatically renew each year at the
                    prevailing renewal rate. I understand that I have the option
                    to cancel my subscription at any time and discontinue future
                    billings by calling 1-800-919-2339 or logging into my
                    DealPeak Account online. Please note that residents of Iowa
                    and Indiana are not eligible for this program.
                  </span>
                </Row>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipSection;
