import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { getByName } from "../../../helpers/requestSetting";

interface IReport {
  name: string;
  src: string;
}
const Reports = () => {
  const [reports, setReports] = useState<IReport[]>([]);

  useEffect(() => {
    const init = async () => {
      const settingReportList = await getByName("ReportList");
      const list = JSON.parse(settingReportList.value);
      const resultList: IReport[] = list.map((item: any) => ({
        name: item.name,
        src: item.src,
      }));
      setReports(resultList);
    };
    init();
  }, []);

  return (
    <div>
      <div style={{ margin: "1%" }}>
        <h3 className="mb-3">Reports</h3>
        <Tabs
          defaultActiveKey="0"
          items={reports.map((source, i) => {
            const id = String(i);
            return {
              label: <span>{source.name}</span>,
              key: id,
              children: (
                <div className="card rounded-dp shadow-lg">
                  <iframe
                    title="Report"
                    width="100%"
                    src={source.src}
                    className="report-frame rounded-dp"
                  />
                </div>
              ),
            };
          })}
        />
      </div>
    </div>
  );
};

export default Reports;
