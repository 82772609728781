import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBaby,
  faBed,
  faBuilding,
  faChair,
  faCocktail,
  faCoffee,
  faConciergeBell,
  faCouch,
  faDice,
  faDoorClosed,
  faDumbbell,
  faExchangeAlt,
  faGolfBall,
  faHome,
  faParking,
  faPaw,
  faPlane,
  faSmokingBan,
  faHotTub,
  faSpa,
  faSwimmer,
  faBurger,
  faLanguage,
  faTv,
  faWheelchair,
  faUtensils,
  faWater,
  faWifi,
  faHandHoldingUsd,
  faMoneyBillAlt,
  faFileInvoice,
  faCoins,
  faBook,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import RoomSize from "../../../img/icons/bunk-bed.png";
import BedRooms from "../../../img/icons/double-bed.png";
import SingleBed from "../../../img/icons/bed.png";
import Cooker from "../../../img/icons/cooker.png";
import Desk from "../../../img/icons/desk.png";
import Queen from "../../../img/icons/bedroom.png";
import Cot from "../../../img/icons/cot.png";
import Lodge from "../../../img/icons/hut.png";
import Suites from "../../../img/icons/suites.png";
import Slippers from "../../../img/icons/sliper.png";
import Alarm from "../../../img/icons/alarm.png";
import Tea from "../../../img/icons/tea.png";
import SmokeDetector from "../../../img/icons/smokeDetector.png";
import WakeUp from "../../../img/icons/wake-up.png";
import Bungalow from "../../../img/icons/bungalow.png";
import Ironing from "../../../img/icons/ironing.png";
import SmokingRoom from "../../../img/icons/pipe.png";
import Botel from "../../../img/icons/botella-de-vino.png";
import AC from "../../../img/icons/air-conditioner.png";
import Energy from "../../../img/icons/plug.png";
import Toiletries from "../../../img/icons/toiletries.png";
import Security from "../../../img/icons/security.png";
import Terrace from "../../../img/icons/terrace.png";
import Crib from "../../../img/icons/crib.png";
import Satellite from "../../../img/icons/tv-program.png";
import TelephoneBathRoom from "../../../img/icons/telephone.png";
import Townhouse from "../../../img/icons/townhouse.png";
import IconImage from "../../IconImage";
import bbq from "../../../img/icons/bbq.png";
import roomServiceImage from "../../../img/icons/room-service.png";
import atmImage from "../../../img/icons/atm.png";
import tennisImage from "../../../img/icons/tennis.png";

interface props {
  name: string;
  className?: string;
}

const FacilitiesHotelComponent = ({ name, className }: props): ReactElement => {
  const renderIconsFacilities = (facility: string) => {
    switch (facility) {
      case "Parking":
        return <FontAwesomeIcon icon={faParking as IconProp} />;
      case "Lounge":
        return <FontAwesomeIcon icon={faCouch as IconProp} />;
      case "Childcare Service":
        return <FontAwesomeIcon icon={faBaby as IconProp} />;
      case "Casino":
        return <FontAwesomeIcon icon={faDice as IconProp} />;
      case "Breakfast":
        return <FontAwesomeIcon icon={faCoffee as IconProp} />;
      case "Business Center":
        return <FontAwesomeIcon icon={faBuilding as IconProp} />;
      case "Currency Exchange":
        return <FontAwesomeIcon icon={faExchangeAlt as IconProp} />;
      case "Bar":
        return <FontAwesomeIcon icon={faCocktail as IconProp} />;
      case "Spa":
        return <FontAwesomeIcon icon={faSpa as IconProp} />;
      case "Non Smoking":
        return <FontAwesomeIcon icon={faSmokingBan as IconProp} />;
      case "Television":
        return <FontAwesomeIcon icon={faTv as IconProp} />;
      case "Laundry Services":
        return <FontAwesomeIcon icon={faWater as IconProp} />;
      case "Swimming Pool":
        return <FontAwesomeIcon icon={faSwimmer as IconProp} />;
      case "Restaurant":
        return <FontAwesomeIcon icon={faUtensils as IconProp} />;
      case "Pets Allowed":
        return <FontAwesomeIcon icon={faPaw as IconProp} />;
      case "Internet":
        return <FontAwesomeIcon icon={faWifi as IconProp} />;
      case "Fitness Facility":
        return <FontAwesomeIcon icon={faDumbbell as IconProp} />;
      case "Airport Shuttle":
        return <FontAwesomeIcon icon={faPlane as IconProp} />;
      case "Refrigerator":
        return <FontAwesomeIcon icon={faDoorClosed as IconProp} />;
      case "Balcony":
        return <FontAwesomeIcon icon={faChair as IconProp} />;
      case "Golf":
        return <FontAwesomeIcon icon={faGolfBall as IconProp} />;
      case "Suite":
        return <FontAwesomeIcon icon={faBed as IconProp} />;
      case "Villas":
        return <FontAwesomeIcon icon={faHome as IconProp} />;
      case "Room Service":
        return <FontAwesomeIcon icon={faConciergeBell as IconProp} />;
      case "Kitchenette":
        return <FontAwesomeIcon icon={faDumbbell as IconProp} />;
      case "Multilingual":
        return <FontAwesomeIcon icon={faLanguage as IconProp} />;
      case "Disable Friendly":
        return <FontAwesomeIcon icon={faWheelchair as IconProp} />;
      case "Sauna":
        return <FontAwesomeIcon icon={faHotTub as IconProp} />;
      case "Banquet":
        return <FontAwesomeIcon icon={faBurger as IconProp} />;
      case "payment":
        return <FontAwesomeIcon icon={faHandHoldingUsd as IconProp} />;
      case "withdrawal":
        return <FontAwesomeIcon icon={faMoneyBillAlt as IconProp} />;
      case "invoice":
        return <FontAwesomeIcon icon={faFileInvoice as IconProp} />;
      case "deposit":
        return <FontAwesomeIcon icon={faCoins as IconProp} />;
      case "Library":
        return <FontAwesomeIcon icon={faBook as IconProp} />;
      case "Tennis":
        return <IconImage altText={name} icon={tennisImage} />;
      case "Barbeque":
        return <IconImage altText={name} icon={bbq} />;
      case "Room service":
        return <IconImage altText={name} icon={roomServiceImage} />;
      case "ATM":
        return <IconImage altText={name} icon={atmImage} />;
      // iconos para facilities en card room - seccion room detail
      case "Suites":
        return <IconImage altText={name} icon={Suites} />;
      case "lodge":
        return <IconImage altText={name} icon={Lodge} />;
      case "Room size (sqm)":
        return <IconImage altText={name} icon={RoomSize} />;
      case "Number of bedrooms":
        return <IconImage altText={name} icon={BedRooms} />;
      case "Double bed 131-150 width":
        return <IconImage altText={name} icon={BedRooms} />;
      case "Single bed 80-130 width":
        return <IconImage altText={name} icon={SingleBed} />;
      case "Cooker":
        return <IconImage altText={name} icon={Cooker} />;
      case "Desk":
        return <IconImage altText={name} icon={Desk} />;
      case "Queen-size bed 150-154 width":
        return <IconImage altText={name} icon={Queen} />;
      case "King-size bed 150-183 width":
        return <IconImage altText={name} icon={Queen} />;
      case "Cot on demand":
        return <IconImage altText={name} icon={Cot} />;
      case "Slippers":
        return <IconImage altText={name} icon={Slippers} />;
      case "Alarm clock":
        return <IconImage altText={name} icon={Alarm} />;
      case "Tea and coffee making facilities":
        return <IconImage altText={name} icon={Tea} />;
      case "Smoke detector":
        return <IconImage altText={name} icon={SmokeDetector} />;
      case "Cable TV":
        return <FontAwesomeIcon icon={faTv as IconProp} />;
      case "TV":
        return <FontAwesomeIcon icon={faTv as IconProp} />;
      case "Wake-up service":
        return <IconImage altText={name} icon={WakeUp} />;
      case "Bungalows":
        return <IconImage altText={name} icon={Bungalow} />;
      case "Ironing set":
        return <IconImage altText={name} icon={Ironing} />;
      case "Smoking rooms":
        return <IconImage altText={name} icon={SmokingRoom} />;
      case "botel":
        return <IconImage altText={name} icon={Botel} />;
      case "Individually adjustable air conditioning":
        return <IconImage altText={name} icon={AC} />;
      case "Single rooms":
        return <IconImage altText={name} icon={SingleBed} />;
      case "220V power supply":
        return <IconImage altText={name} icon={Energy} />;
      case "Extra beds on demand":
        return <IconImage altText={name} icon={RoomSize} />;
      case "Toiletries":
        return <IconImage altText={name} icon={Toiletries} />;
      case "Safe":
        return <IconImage altText={name} icon={Security} />;
      case "Terrace":
        return <IconImage altText={name} icon={Terrace} />;
      case "Children share the bed with parents":
        return <IconImage altText={name} icon={Crib} />;
      case "Satellite TV":
        return <IconImage altText={name} icon={Satellite} />;
      case "Telephone in the bathroom":
        return <IconImage altText={name} icon={TelephoneBathRoom} />;
      case "vacation townhouse":
        return <IconImage altText={name} icon={Townhouse} />;
      default:
        return <FontAwesomeIcon icon={faCheck as IconProp} />;
    }
  };

  return (
    <div className={`${className} facility-item`}>
      {renderIconsFacilities(name)}
    </div>
  );
};
export default FacilitiesHotelComponent;
