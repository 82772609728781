import React, { useContext, useEffect, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faReply,
  faSliders,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  notification,
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Menu,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "../../../Auth/firebase";
import SalesManageCard from "../../../Components/AccountManagment/SalesManageCard";
import LoaderComponent from "../../../Components/LoaderComponent";
import { SearchContext } from "../../../Context/context";
import { useViewport } from "../../../Context/useViewport";
import { sendEmailUserCreated } from "../../../helpers/requestBookings";
import {
  getRolesFetch,
  getUsers,
  postUser,
} from "../../../helpers/requestUserAndRole";
import { ISearch } from "../../../Models/Search";
import { IUsers, IUsersTransform, IRole } from "../../../Models/UsersModel";
import { getWebConfig } from "../../../helpers/requestSetting";

const SalesManagment = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [usersList, setUsersList] = useState<IUsersTransform[]>([]);
  const [usersTransform, setUsersTransform] = useState<IUsersTransform[]>([]);
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [roleSubmit, setRoleSubmit] = useState(0);
  const [formEdit, setFormEdit] = useState({
    userId: 0,
    firstName: "",
    lastName: "",
    userName: "",
    roleId: 0,
    status: true,
  });
  const [filterObject, setfilterObject] = useState({
    firstName: "",
    lastName: "",
    userName: "",
  });
  const { SubMenu } = Menu;

  const { width } = useViewport();

  const filterIconSearch = () => (
    <FontAwesomeIcon icon={faSearch as IconProp} />
  );

  const resetFilters = () => {
    setfilterObject({ firstName: "", lastName: "", userName: "" });
  };

  const resetPassword = async (email: string) => {
    await sendPasswordReset(email, searchContent.auth);
  };

  const returnSearchName = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by First Name"
        autoFocus
        value={filterObject.firstName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            firstName: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchLastName = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Last Name"
        autoFocus
        value={filterObject.lastName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            lastName: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchUserid = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Email"
        autoFocus
        value={filterObject.userName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            userName: e.target.value,
          })
        }
      />
    </div>
  );

  const getRoles = async () => {
    try {
      const response = await getRolesFetch();
      setRoles(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const setInfo = (response: IUsers[]) => {
    const newInfo = response.map((item, index) => {
      const obj = {
        key: item.userId,
        id: item.userId,
        bookings: index,
        userId: item.userName,
        firstName: item.firstName,
        lastName: item.lastName,
        rol: item.role.roleName,
        rolId: item.role.roleId,
        edit: index,
      };
      return obj;
    });
    setUsersList(newInfo);
    setUsersTransform(newInfo);
    if (roles.length === 0) {
      getRoles();
    } else {
      setLoading(false);
    }
  };

  const renderInfo = async () => {
    try {
      const response = await getUsers();
      setInfo(response);
    } catch (err) {
      console.error(err);
    }
  };

  const SalesPersonColumns = [
    {
      title: "ID",
      dataIndex: "bookings",
      key: "bookings",
      width: 120,
      render: (id: number) => (
        <button
          type="button"
          className="btn btn-primary-dp-xs"
          onClick={() => navigate(`/sales?user=${usersTransform[id].userId}`)}
        >
          <FontAwesomeIcon icon={faSearch as IconProp} className="me-2" />
          Bookings
        </button>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      filterDropdown: () => returnSearchName(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      filterDropdown: () => returnSearchLastName(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.lastName.toLowerCase() < b.lastName.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "User Email",
      dataIndex: "userId",
      key: "userId",
      filterDropdown: () => returnSearchUserid(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.userId.toLowerCase() < b.userId.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "Role",
      dataIndex: "rol",
      key: "rol",
      sorter: (a: any, b: any) =>
        a.rol.toLowerCase() < b.rol.toLowerCase() ? -1 : 1,
      width: 150,
    },
    {
      title: "Reset account",
      dataIndex: "userId",
      key: "userId",
      width: 90,
      render: (email: string) => (
        <button
          type="button"
          onClick={() => resetPassword(email)}
          className="btn btn-success-dp-xs"
        >
          <FontAwesomeIcon icon={faReply as IconProp} className="me-2" />
          Send reset password email
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (
      filterObject.firstName === "" &&
      filterObject.lastName === "" &&
      filterObject.userName === ""
    ) {
      renderInfo();
    } else {
      const copyArr = usersList;
      setUsersTransform(
        copyArr.filter(
          (item) =>
            item.userId
              .toLowerCase()
              .includes(filterObject.userName.toLowerCase()) &&
            item.firstName
              .toLowerCase()
              .includes(filterObject.firstName.toLowerCase()) &&
            item.lastName
              .toLowerCase()
              .includes(filterObject.lastName.toLowerCase())
        )
      );
    }
  }, [filterObject]);

  const onFinishForm = async (values: any) => {
    setLoading(true);
    setModalCreateVisible(false);
    const webConfigResp = await getWebConfig();
    const app = initializeApp(webConfigResp.firebaseConfig, "secondary");
    const auth = getAuth(app);
    try {
      const userCredential = await registerWithEmailAndPassword(
        values.userName,
        values.password,
        auth
      );
      if (userCredential) {
        const obj = {
          userName: values.userName,
          firstName: values.firstName,
          lastName: values.lastName || "",
          password: values.password,
          roleId: roleSubmit,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            sendEmailUserCreated(values.userName, "NewUser", values.password);
          notification.success({
            message: "Succces Registred",
            description: "User registerd!",
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      } else setLoading(false);
      renderInfo();
    } catch (err) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Error",
      });
      console.error(err);
    }
  };

  useEffect(() => {
    window._CTZ = {
      enabled: false,
      verticals: {
        hotel: {
          active: false,
          search: {
            city: undefined,
            province: undefined,
            countryCode: undefined,
            checkInDate: undefined,
            checkOutDate: undefined,
          },
        },
      },
    };
    setLoading(true);
    renderInfo();
  }, []);

  return (
    <div className="container mt-5">
      {loading && <LoaderComponent title="Loading Accounts..." />}
      {!loading && (
        <>
          <h3>Accounts Management List</h3>
          <div className="book-title mt-3">
            <Button
              type="dashed"
              shape="round"
              htmlType="button"
              onClick={resetFilters}
            >
              Reset Filters
            </Button>
            <Space size="middle">
              <button
                type="button"
                className="btn btn-primary-dp"
                onClick={() => navigate("/sales")}
              >
                <FontAwesomeIcon
                  icon={faFileAlt as IconProp}
                  className="me-2"
                />
                My Bookings
              </button>

              <button
                type="button"
                className="btn btn-primary-dp"
                onClick={() => setModalCreateVisible(true)}
              >
                Create User
              </button>
            </Space>
          </div>
          <Divider />

          {width < 1200 ? (
            <>
              <Menu mode="inline" className="pb-3">
                <SubMenu
                  key="filters"
                  title={
                    <span className="filter-menu-title">
                      <FontAwesomeIcon icon={faSliders as IconProp} />
                      <span>Filters</span>
                    </span>
                  }
                >
                  <Menu.Item className="filter-menu-item" key="filterName">
                    {returnSearchName()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterLastName">
                    {returnSearchLastName()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterId">
                    {returnSearchUserid()}
                  </Menu.Item>
                </SubMenu>
              </Menu>
              {usersTransform.map((user: IUsersTransform, index) => (
                <SalesManageCard
                  user={user}
                  resetPass={resetPassword}
                  key={index}
                />
              ))}
            </>
          ) : (
            <div className="card rounded-dp shadow-lg">
              <div className="card-body">
                <Table
                  columns={SalesPersonColumns}
                  dataSource={usersTransform}
                  scroll={{ x: 240 }}
                />
              </div>
            </div>
          )}
          <Modal
            title="Create Account"
            open={modalCreateVisible}
            onCancel={() => setModalCreateVisible(false)}
            footer={null}
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinishForm}
                autoComplete="off"
                layout="vertical"
                className="create-account-form"
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user first name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, firstName: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user last name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, lastName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="userName"
                  label="User Name"
                  rules={[
                    { required: true, message: "Please input the user email!" },
                  ]}
                >
                  <Input
                    type="email"
                    value={formEdit.userName}
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, userName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="User Role"
                  rules={[
                    { required: true, message: "Please select the user role!" },
                  ]}
                >
                  <Select
                    placeholder="Select the role"
                    onChange={(value) => setRoleSubmit(value)}
                  >
                    {roles.map((item) => (
                      <Select.Option value={item.roleId} key={item.roleId}>
                        {item.roleName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password type="password" />
                </Form.Item>
                <div className="buttons-modal">
                  <button
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SalesManagment;
