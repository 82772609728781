import React, { ReactElement, useState, useContext, useEffect } from "react";
import { Select, Divider } from "antd";
import { ISearch } from "../../../Models/Search";
import { SearchContext } from "../../../Context/context";
import InputPeople from "./InputPeople";
import { IOccupancy } from "../../../Models/CommonType";

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

interface SearchBoxFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

const ChildrenBox = ({ setOpen, open }: SearchBoxFormProps): ReactElement => {
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;
  const [roomsValue, setRoomsValue] = useState<IOccupancy[]>([
    {
      numOfAdults: 2,
      childAges: [],
    },
  ]);

  const options = [
    { label: "Infant", value: 0 },
    { label: "1 Year old", value: 1 },
    { label: "2 Years old", value: 2 },
    { label: "3 Years old", value: 3 },
    { label: "4 Years old", value: 4 },
    { label: "5 Years old", value: 5 },
    { label: "6 Years old", value: 6 },
    { label: "7 Years old", value: 7 },
    { label: "8 Years old", value: 8 },
    { label: "9 Years old", value: 9 },
    { label: "10 Years old", value: 10 },
    { label: "11 Years old", value: 11 },
    { label: "12 Years old", value: 12 },
    { label: "13 Years old", value: 13 },
    { label: "14 Years old", value: 14 },
    { label: "15 Years old", value: 15 },
    { label: "16 Years old", value: 16 },
    { label: "17 Years old", value: 17 },
  ];

  const handleSelect = (id: number, value: number, index: number) => {
    const array = [...roomsValue];
    array[index].childAges[id] = value;
    setRoomsValue(array);
  };

  const handleClickPlusInputPeople = (
    value: number,
    label: string,
    index?: number
  ) => {
    const array = [...roomsValue];
    if (label === "Rooms") {
      array.push({
        numOfAdults: 2,
        childAges: [],
      });
      setRoomsValue(array);
    } else if (label === "Adults" && index !== undefined) {
      array[index].numOfAdults = array[index].numOfAdults + 1;
      setRoomsValue(array);
    } else if (label === "Children" && index !== undefined) {
      array[index].childAges.push(0);
      setRoomsValue(array);
    }
  };

  const handleClickMinusInputPeople = (
    value: number,
    label: string,
    index?: number
  ) => {
    const array = [...roomsValue];
    if (label === "Rooms") {
      array.pop();
      setRoomsValue(array);
    } else if (label === "Adults" && index !== undefined) {
      array[index].numOfAdults = array[index].numOfAdults - 1;
      setRoomsValue(array);
    } else if (label === "Children" && index !== undefined) {
      array[index].childAges.pop();
      setRoomsValue(array);
    }
  };

  const handleDone = () => {
    setOpen(false);
    setSearchContent({
      ...searchContent,
      occupancies: roomsValue,
    });
  };

  useEffect(() => {
    setRoomsValue(searchContent.occupancies);
  }, []);

  useEffect(() => {
    setSearchContent({
      ...searchContent,
      occupancies: roomsValue,
    });
  }, [roomsValue]);

  useEffect(() => {
    if (open) {
      setSearchContent({
        ...searchContent,
        occupancies: roomsValue,
      });
    }
  }, [roomsValue]);

  useEffect(() => {
    if (!open) {
      setRoomsValue(searchContent.occupancies);
    }
  }, [searchContent.occupancies]);

  return (
    <div>
      <div className="children_box-first_box">
        {/* Input for rooms */}
        <InputPeople
          label="Rooms"
          value={roomsValue.length}
          onClickPlus={handleClickPlusInputPeople}
          onClickMinus={handleClickMinusInputPeople}
          min={1}
          max={9}
        />
      </div>
      <div className="children-box_select row">
        {roomsValue.length > 0 && (
          <div className="col-12 ">
            {roomsValue.map((item, index) => (
              <div key={index}>
                {/* <Divider /> */}
                <div className="d-flex justify-content-between flex-row">
                  <div className="">
                    <span className="h6 text-secondary">Room {index + 1}</span>
                  </div>
                  <InputPeople
                    label="Adults"
                    value={roomsValue[index].numOfAdults}
                    onClickPlus={(value, label) =>
                      handleClickPlusInputPeople(value, label, index)
                    }
                    onClickMinus={(value, label) =>
                      handleClickMinusInputPeople(value, label, index)
                    }
                    disabledPlus={roomsValue[index].numOfAdults >= 4}
                    disabledMinus={roomsValue[index].numOfAdults === 1}
                  />
                  <InputPeople
                    label="Children"
                    value={roomsValue[index].childAges.length}
                    onClickPlus={(value, label) =>
                      handleClickPlusInputPeople(value, label, index)
                    }
                    onClickMinus={(value, label) =>
                      handleClickMinusInputPeople(value, label, index)
                    }
                    disabledPlus={roomsValue[index].childAges.length >= 4}
                    disabledMinus={roomsValue[index].childAges.length === 0}
                  />
                </div>
                <div className="children-box_select row">
                  {roomsValue[index].childAges.length > 0 && (
                    <div className="col-12 ">
                      {roomsValue[index].childAges.map(
                        (itemChild, indexChild) => (
                          <div key={indexChild} className="select-row row">
                            <div className="col-6">
                              <span className="h6 text-secondary">
                                Child {index + 1}
                              </span>
                            </div>
                            <div className="col-6">
                              <Select
                                className="select-children_box"
                                value={itemChild}
                                size="large"
                                bordered={false}
                                onChange={(value) =>
                                  handleSelect(indexChild, value, index)
                                }
                                options={options}
                                placeholder="Select Age"
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="text-secondary">
        <Divider />
        <button
          className="btn btn-primary btn-lg"
          type="button"
          onClick={() => {
            handleDone();
          }}
        >
          Done
        </button>
      </div>
      <div className="text-end">
        <small>
          Enter the age of each child at check-in for the best prices and
          recommendations
        </small>
      </div>
    </div>
  );
};

export default ChildrenBox;
