import { Card, Col, Divider, Row, Skeleton } from "antd";
import React, { ReactElement } from "react";

const cardsSkeleton = [1, 2, 3];

const SkeletonLoaderListHotels = (): ReactElement => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <h2 className="text-secondary">Loading Hotels result...</h2>
        <Divider />
      </div>
      {cardsSkeleton.map((ele, index) => (
        <div className="col-10 mx-auto" key={index}>
          <Card className="my-2">
            <Row>
              <Col lg={6} xs={12}>
                <Skeleton.Image className="img-fluid w-100 h-100" />
              </Col>
              <Col lg={12} xs={12} className="p-3 border-end">
                <Skeleton active />
              </Col>
              <Col lg={6} xs={24} className="p-3">
                <Skeleton.Button active block className="mt-5" />
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  </div>
);
export default SkeletonLoaderListHotels;
