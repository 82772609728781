export const RoutesPath = {
  home: process.env.REACT_APP_API_BASE_WP_URL,
  root: "/",
  login: "/login",
  reset: "/reset",
  register: "/register",
  hotelDetail: "/hotelDetail",
  checkoutForm: "/checkout",
  landing: "/landing",
  results: "/hotel/results",
  thankyou: "/checkout/confirmation",
  bookDetail: "/bookings/detail",
  bookings: "/bookings",
  accounts: "/accounts",
  accountsBookings: "/accounts/bookings",
  roles: "/accounts/roles",
  users: "/accounts/users",
  settings: "/settings",
  rateFilter: "/rateFilter",
  locationRadius: "/settings/locations",
  bookCancel: "/bookings/cancel",
  wpWidget: "/findHotels",
  debug: "/debuginfo",
  reports: "/reports",
  membership: "/membership",
  SignIn: "/sign",
  Register: "/register",
  Reset: "/reset",
  Sales: "/sales",
  HotelDetailSimple: "/HotelDetailSimple",
  faqs: "/faqs",
  memberPerks: "/memberPerks",
};
export default RoutesPath;
