import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import type { ColumnsType } from "antd/es/table";
import {
  cancelBooking,
  getBookingDetails,
  postCancelBooking,
} from "../../../helpers/requestBookings";
import { IBookDetailResponse } from "../../../Models/BookDetailRequest";
import { IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { useViewport } from "../../../Context/useViewport";
import { ChildrenCancelBooking } from "./childrenCancelBooking";

const BookCancel = () => {
  interface DataType {
    key: React.Key;
    reason: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "Why are you cancelling?",
      dataIndex: "reason",
      render: (text) => <span className="mx-2">{text}</span>,
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      reason: "Found a better price",
    },
    {
      key: "2",
      reason: "Resort Fees were not shown",
    },
    {
      key: "3",
      reason: "My plans changed",
    },
    {
      key: "4",
      reason: "I booked the wrong dates",
    },
    {
      key: "5",
      reason: "Other",
    },
  ];

  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );

  const [searchParams] = useSearchParams();
  const bookId = searchParams.get("bookId");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [BookDetail, setBookDetail] = useState<IBookDetailResponse>();
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [providerNumber, setProviderNumber] = useState<string>("");
  const [SelectedReason, setSelectedReason] = useState<string>();
  const [OtherReason, setOtherReason] = useState("");
  const [CurrentStep, setCurrentStep] = useState(1);
  const [OpenModal, setOpenModal] = useState(false);
  const correlationId = uuidv4();
  const navigate = useNavigate();
  const { width } = useViewport();

  const showCancelledOk = (msg: string) => {
    Modal.info({
      title: "Cancelled correctly",
      content: msg,
    });
  };

  const handleCancel = async () => {
    if (bookId) {
      try {
        const response = await cancelBooking(bookId, correlationId);
        if (response.Code && response.Code === "5002") {
          setErrorMessage(response.Message || "Error on Cancellation.");
          setStep(3);
          setLoading(false);
          return;
        }
        setStep(2);
        setLoading(false);
        await postCancelBooking(bookId, response);
        showCancelledOk("Booking cancelled correctly.");
      } catch (err) {
        console.error(err);
        setLoading(false);
        setError(true);
      }
    }
  };

  const handleConfirm = async () => {
    setOpenModal(true);
    setLoading(true);
    if (
      BookDetail?.hotelBooking.providerConfirmationNumber === providerNumber
    ) {
      handleCancel();
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedReason(selectedRows[0].reason);
    },
  };

  const handleOkModal = () => {
    setCurrentStep(CurrentStep + 1);
  };

  const handleCloseModal = () => {
    setSelectedReason("");
    setOpenModal(false);
    setCurrentStep(1);
  };

  const handleTextAreaChange = (e: { target: { value: any } }) => {
    setOtherReason(e.target.value);
  };
  useEffect(() => {
    const loadInfo = async () => {
      setLoading(true);
      if (bookId) {
        const obj = {
          channelId: webConfigContext.selectedChannel ?? "",
          bookingId: bookId,
        };

        try {
          const response: IBookDetailResponse = await getBookingDetails(
            obj,
            correlationId
          );
          setBookDetail(response);
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    loadInfo();
  }, [bookId]);

  return (
    <div className="container mt-5">
      {ChildrenCancelBooking(
        BookDetail,
        OpenModal,
        handleCloseModal,
        CurrentStep,
        handleOkModal,
        SelectedReason,
        OtherReason,
        columns,
        data,
        rowSelection,
        handleTextAreaChange,
        loading,
        navigate,
        step,
        width,
        providerNumber,
        setProviderNumber,
        error,
        handleConfirm,
        errorMessage
      )}
    </div>
  );
};

export default BookCancel;

export interface DataType {
  key: React.Key;
  reason: string;
}
