import { AxiosResponse } from "axios";
import APIClient from "../Components/Services/APIClient";
import { IContentRoomBody, IContentRooms } from "../Models/ContentRooms";
import {
  IDirectRoomsRates,
  IHotelDetailFullInformation,
} from "../Models/HotelContent";
import { getConfig } from "./configHelper";
import { IDirectRoomsAndRatesResponse } from "../Models/RoomDetail";

export const getRoomsAndRates = async (
  hotelId: string,
  token: string | null,
  hotelDetail: IHotelDetailFullInformation,
  correlationId: string
): Promise<AxiosResponse<any, any>> => {
  const client = new APIClient();

  hotelDetail.id = hotelDetail.id.toString();
  hotelDetail.starRating = hotelDetail.starRating.toString();
  const config = await getConfig(correlationId);
  return client.client.post(
    `Search/${hotelId}/RoomsAndRates/${token}`,
    hotelDetail,
    config
  );
};

export const getRoomsAndRatesDirect = async (
  hotelId: string,
  directRoomsRates: IDirectRoomsRates,
  correlationId: string
): Promise<AxiosResponse<any, IDirectRoomsAndRatesResponse>> => {
  const client = new APIClient();

  const config = await getConfig(correlationId);
  return client.client.post(
    `Search/${hotelId}/roomsandrates`,
    directRoomsRates,
    config
  );
};

export const getContentAndRooms = async (
  hotelId: string,
  correlationId: string,
  deeplinkid: string,
  body: IContentRoomBody
): Promise<AxiosResponse<IContentRooms>> => {
  const client = new APIClient();
  const config = await getConfig(correlationId);

  return client.client.post(
    `Search/${hotelId}/ContentAndRooms/${deeplinkid}`,
    body,
    config
  );
};
