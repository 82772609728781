import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { IFacilityGroup } from "../../../../Models/HotelContent";

interface AmenitiesSectionRoomDetailProps {
  facilities: IFacilityGroup[] | undefined;
}

const AmenitiesSectionRoomDetail = ({
  facilities,
}: AmenitiesSectionRoomDetailProps) => {
  const [amenitiesArray, setAmenitiesArray] = useState<
    IFacilityGroup[] | undefined
  >([]);
  const [totalAmenities, setTotalAmenities] = useState<number>(0);
  const [limitOfAmenities] = useState<number>(5);

  useEffect(() => {
    const amenityFiltered = facilities;
    setAmenitiesArray(amenityFiltered);
  }, [facilities]);

  useEffect(() => {
    if (facilities?.length) {
      setTotalAmenities(facilities?.length);
    }
  }, [facilities?.length]);

  return (
    <>
      {amenitiesArray && amenitiesArray?.length > 0 && (
        <>
          <h4 id="rooms-availibles">Amenities section</h4>
          <div className="amenities-section-wrapper">
            <ul>
              {amenitiesArray?.slice(0, limitOfAmenities).map((item, index) => (
                <li key={index}>
                  <FontAwesomeIcon
                    icon={faCheck as IconProp}
                    className="me-2"
                  />
                  {item.name}
                </li>
              ))}
            </ul>
            <ul>
              {amenitiesArray
                ?.slice(limitOfAmenities, totalAmenities - 1)
                .map((item, index) => (
                  <li key={index}>
                    <FontAwesomeIcon
                      icon={faCheck as IconProp}
                      className="me-2"
                    />
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
          <Divider />
        </>
      )}
    </>
  );
};
export default AmenitiesSectionRoomDetail;
