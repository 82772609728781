import React from "react";
import { useSearchParams } from "react-router-dom";
import HotelCard from "../../../Components/HotelCard";
import { IHotelFullInformation } from "../../../Models/HotelContent";
import ControlBar from "./ControlBar";

interface props {
  hotelInformation: (IHotelFullInformation | undefined)[];
  totalHotels?: number;
  cheapestHotel: string;
  discounts: any[];
  totalFilteredHotels?: number;
}
const ListSection: React.FC<props> = ({
  hotelInformation,
  totalHotels,
  cheapestHotel,
  discounts,
  totalFilteredHotels,
}: props) => {
  const HotelArray = Object.values(hotelInformation);
  const [searchParams] = useSearchParams();

  return (
    <>
      <ControlBar
        className=""
        result={searchParams.get("countryname") || ""}
        totalHotels={totalHotels}
        totalFilteredHotels={totalFilteredHotels}
      />
      {HotelArray.map((hotel) => {
        const hotelDiscount = discounts.find((item) => item.id === hotel?.id);
        return (
          <HotelCard
            key={hotel?.id}
            hotelIndex={cheapestHotel}
            hotel={hotel}
            correlationId={searchParams.get("correlationId") || ""}
            className="hotel-card-wrapper"
            isRecomended={hotel?.isRecommended}
            discount={hotelDiscount?.discount}
          />
        );
      })}
    </>
  );
};
export default ListSection;
