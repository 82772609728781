import { ReactElement, useContext, useEffect, useState } from "react";
import { Alert, Modal, message } from "antd";
import { AxiosResponse } from "axios";
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { EBookingSatus, EFeeStatus } from "../../Common/Enums";
import generateRandomPassword, {
  getRandom,
} from "../../Common/GeneratePassword";
import LoaderComponent from "../../Components/LoaderComponent";
import { SearchContext } from "../../Context/context";
import { PriceContext } from "../../Context/RoomContext";
import { WebConfigContext } from "../../Context/WebConfigContext";
import { getIp } from "../../helpers/configHelper";
import {
  bookHotel,
  priceCheck,
  sendEmailUserCreated,
  book3ds,
  getBookingDetails,
  get3DSToken,
} from "../../helpers/requestBookings";
import { getHotelDetail } from "../../helpers/requestHotels";
import {
  getMemberships,
  INewOrderRequest,
  newOrder,
  Product,
} from "../../helpers/requestMembership";
import { getNoFraudPost } from "../../helpers/requestNoFraud";
import {
  getRoomsAndRates,
  getRoomsAndRatesDirect,
} from "../../helpers/requestRoomsrates";
import { getByName, getWebConfig } from "../../helpers/requestSetting";
import { getUserRol, postUser } from "../../helpers/requestUserAndRole";
import {
  GENERIC_ERROR_CODE,
  PRICE_CHECK_RESPONSE_CODES,
} from "../../helpers/responseCodes";
import { IBadResponse } from "../../Models/BadResponse";
import {
  IBilling,
  IMembership,
  IMembershipVerify,
  IPayment,
  IPriceResponse,
} from "../../Models/Checkout";
import { IHotelDetailFullInformation } from "../../Models/HotelContent";
import { NoFraudBody } from "../../Models/NoFraud";
import {
  IstandardizedRooms,
  IAdditionalCharges,
  IDirectRoomsAndRatesResponse,
  IOtherRate,
  IRoom,
  ITax,
} from "../../Models/RoomDetail";
import { ISearch } from "../../Models/Search";
import { ICardPolicy, IWebConfigContext } from "../../Models/SettingsModel";
import { IPostUser } from "../../Models/UsersModel";
import {
  errorUserCreation,
  modalError,
  noFraudError,
  showConfirmPrice,
  successUserCreation,
} from "./Helpers/CheckoutMessage";
import {
  generateGuestAdult,
  generateGuestChildren,
  NewBillingObject,
  NewPaymentObject,
} from "./ObjectConstructors/NewBillingForm";
import SidebarForm from "./SidebarForm";
import StepsLine from "./Steps";
import FirstStep from "./Steps/firstStep";
import { getCreditCardType } from "../../Common/CreditCardHelper";
import { useViewport } from "../../Context/useViewport";
import MembershipSectionSideBar from "./SidebarForm/MembershipAdd";
import { RoutesPath } from "../../Routes";
import pcieLogo from "../../img/pcidss.png";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IBook3ds, IBookRequest } from "../../Models/Book3dsRequest";
import { addNoFraud, enableTidioChat } from "../../helpers/addRemoveClickTripz";
import RoomCard from "../HotelDetail/BodyComponents/Rooms/RoomCard";
import { I3dsResponse } from "../../Models/Order3dsResponse";
import { salesMateBigUpdate } from "../../helpers/salesMate";
import { trigger3dsverify } from "../../helpers/requestThreeDs";
import { getLocationInfoFromIP } from "../../helpers/fetchLocation";
import { isIpExempt2fa } from "../../helpers/requestIpExempt2fa";
const GO_NEXT = "next";
const GO_BACK = "back";

const CheckoutForm = (): ReactElement => {
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const { webConfigContext, setWebConfigContext } = useContext<
    any | IWebConfigContext
  >(WebConfigContext);
  const [user] = useAuthState(searchContent.auth);
  const { priceContext } = useContext<IPriceResponse | any>(PriceContext);
  const { width } = useViewport();
  const billingObj = NewBillingObject(user);
  const paymentObj = NewPaymentObject();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTotalNightly, setshowTotalNightly] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [membership, setMembership] = useState<IMembership>();
  const [nationality, setNationality] = useState<string>("US");
  const [errorOR, setErrorOR] = useState(false);
  const [billingForm, setBillingForm] = useState<IBilling>(billingObj);
  const [products, setProducts] = useState<Product[]>([]);
  const [createMembership, setCreateMembership] = useState<
    boolean | undefined
  >();
  const [paymentInfo, setPaymentInfo] = useState<IPayment>(paymentObj);
  const [rooms, setRooms] = useState<Array<IRoom>>([]);
  const [OtherRates, setOtherRates] = useState<IDirectRoomsAndRatesResponse>();
  const [roomDetail, setRoomDetail] = useState<IRoom>();
  const [, setCardRequired] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [price, setPrice] = useState<IPriceResponse>();
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const navigate = useNavigate();
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [openModalOtheroptions, setModalOtheroptions] = useState(false);
  const [hotelDetail, setHotelDetail] = useState<IHotelDetailFullInformation>();
  const [, setAccountCreated] = useState(false);
  const [membershipMandatory, setMembershipMandatory] = useState(true);
  const [roomPriceError, setPriceError] = useState<IBadResponse>();
  const [modalDiffPrice, setModalDiffPrice] = useState(false);
  const [cardFee, setcardFee] = useState<ICardPolicy>();
  const [isIpExcempt, setIsIpExcempt] = useState<boolean>(false);

  const deeplink = searchParams.get("deeplink") || "";

  const correlationId = searchParams.get("correlationId")?.trim() || "";
  const steps = [
    { step: 0, show: true, title: "Billing Address" },
    { step: 1, show: true, title: "Guests" },
  ];

  const showConfirm = () => {
    Modal.confirm({
      title:
        "Are you sure you do NOT want to save more on travel and a 60 Day Complimentary Trial?",

      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateMember(false);
      },
      onCancel() {
        updateMember(undefined);
      },
    });
  };

  const updateStep = (type: string, goStep: number) => {
    if (goStep === -1) {
      if (type === GO_NEXT) {
        const sumStep = steps[currentStep + 1].show
          ? currentStep + 1
          : currentStep + 2;
        setCurrentStep(sumStep);
      }
      if (type === GO_BACK) {
        const sumStep = steps[currentStep - 1].show
          ? currentStep - 1
          : currentStep - 2;
        setCurrentStep(sumStep);
      }
    } else {
      setCurrentStep(goStep);
    }
  };

  const createUser = async (isSales: boolean) => {
    const password = generateRandomPassword(8);
    const webConfigResp = await getWebConfig();
    const app = initializeApp(webConfigResp.firebaseConfig, "secondary");
    const auth = getAuth(app);
    const userInfo = await getUserRol(billingForm.contact.email);

    if (userInfo && !userInfo.active) {
      errorUserCreation();
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        isSales ? auth : searchContent.auth,
        billingForm.contact.email,
        password
      );

      if (userCredential) {
        const userObj: IPostUser = {
          userName: billingForm.contact.email,
          firstName: billingForm.firstName,
          lastName: billingForm.lastName,
          password,
          roleId: 0,
        };
        try {
          const response = await postUser(userObj);
          if (response !== null) {
            sendEmailUserCreated(
              billingForm.contact.email,
              "NewUser",
              password
            );
            successUserCreation(billingForm.contact.email, password);
            setAccountCreated(true);
            if (!isSales) {
              searchContent.getAuthTest(searchContent.auth);
            }
          }
        } catch (err) {
          errorUserCreation();
          console.error(err);
        }
      }
      return password;
    } catch (err) {
      return null;
    }
  };

  const updateBillingForm = (objBillingForm: IBilling) =>
    setBillingForm(objBillingForm);

  const returnCC = () => {
    if (
      webConfigContext.selectedChannel === "dp-hbtest-channel" &&
      price?.hotel.rates &&
      price?.hotel.rates.length > 0
    ) {
      return price?.hotel.rates[0].cardRequired ? paymentInfo : null;
    }
    paymentInfo.nameoncard = paymentInfo.nameoncard.trim();
    return paymentInfo;
  };

  const sum = (...args: number[]) => {
    const total = args.reduce((partialSum, a) => partialSum + a, 0).toFixed(2);
    return total;
  };

  const totalTaxes = (additionalCharges: ITax[]) =>
    additionalCharges.reduce((a, b) => a + b.amount, 0);

  const total = (additionalCharges: IAdditionalCharges[]) => {
    const resp = additionalCharges.reduce((a, b) => a + b.charge.amount, 0);
    return resp;
  };

  const totalPaymentSum = () => {
    if (
      price &&
      price?.hotel.rates &&
      price?.hotel.rates.length > 0 &&
      price?.hotel.rates[0].additionalCharges
    ) {
      const totalPayment = sum(
        price?.hotel.rates[0].baseRate,
        totalTaxes(price?.hotel.rates[0].taxes || []),
        total(price?.hotel.rates[0].additionalCharges)
      );
      return totalPayment.toString();
    } else if (
      price &&
      price?.hotel.rates &&
      price?.hotel.rates.length > 0 &&
      !price?.hotel.rates[0].additionalCharges
    ) {
      return price?.hotel.rates[0].totalRate.toFixed(2);
    }
  };
  const errorDisplay = (code: string): void => {
    switch (code) {
      case "4000":
        modalError(
          "There is missing data please check the form or call support for help 1-800-919-2339"
        );
        break;
      case "4001":
        modalError(
          "There is invalid data please check the form or call support for help 1-800-919-2339"
        );
        break;
      case "4004":
        modalError(
          "This hotel is soldout please contact support for help 1-800-919-2339"
        );
        break;
      case "4005":
        modalError(
          "Room price have change plese select another room or call support for help 1-800-919-2339"
        );
        break;
      case "4006":
        modalError(
          "Room price have expired plese select another room or call support for help 1-800-919-2339"
        );
        break;
      case "4007":
        modalError(
          "Booking with the same details already exists select another room or call support for help 1-800-919-2339"
        );
        break;
      case "4008":
        modalError(
          "Insufficient funds in credit card please use other card or call support for help 1-800-919-2339"
        );
        break;
      case "4012":
        modalError(
          "Insufficient funds in account please use other card or call support for help 1-800-919-2339"
        );
        break;
      case "5000":
        modalError(
          "Please verify your credit card information  or contact support for help 1-800-919-2339"
        );
        break;
      case "5001":
        modalError(
          "Something went wrong, please call support for help 1-800-919-2339"
        );
        break;
      case "5002":
        modalError(
          "Timeout occurred while processing your booking request, please call support for help 1-800-919-2339"
        );
        break;
      case "5003":
        modalError(
          "Please verify your credit card information for help call 1-800-919-2339"
        );
        break;
      case "5004":
        modalError(
          "Booking confirmation pending from supplier end for help call 1-800-919-2339"
        );
        break;
      case "409":
        modalError("Fraudulent booking detected for help call 1-800-919-2339");
        break;
      case "500":
        modalError(
          "Rate no longer available contact support for help 1-800-919-2339"
        );
        break;

      default:
        modalError(
          "Please verify your credit card information  or contact support for help 1-800-919-2339"
        );
    }
  };
  const postBooking = async (
    correlationId: string,
    hotelId: string,
    bookingId: string,
    providerConfirmationNumber: string,
    feeCharged: boolean | undefined
  ) => {
    window.showSuccessToast(bookingId, providerConfirmationNumber);
    if (sessionStorage.getItem("noFraudPass")) {
      sessionStorage.removeItem("noFraudPass");
    }
    if (correlationId && hotelId && hotelDetail) {
      const countryName =
        searchParams.get("countryname") || hotelDetail.contact.address.country;
      await executePostBookingTasks(bookingId);
      navigate(
        `/checkout/confirmation?email=${billingForm.contact.email}&bookid=${bookingId}&countryname=${countryName}`
      );
    }
    if (
      !feeCharged &&
      price?.hotel.rates &&
      price?.hotel.rates.length > 0 &&
      price?.hotel.rates[0].otherRateComponents &&
      price?.hotel.rates[0].otherRateComponents.length > 0
    ) {
      await reTryFee(
        bookingId,
        price?.hotel.rates[0].otherRateComponents[0].amount
      );
    }
  };

  const book3dsSuccessPostBack = async (
    book3dsRequest: IBook3ds,
    payload: string,
    hotelId: string,
    correlationId: string
  ) => {
    book3dsRequest.paymentInformation = [
      {
        key: "REZ_TOKEN",
        value: null,
      },
      {
        key: "DEVICE_PAYLOAD",
        value: payload,
      },
    ];

    const bookingHotel = await book3ds(hotelId, correlationId, book3dsRequest);

    const { bookingId, providerConfirmationNumber, feeCharged } =
      bookingHotel.data;

    bookingHotel.data.bookingId,
      bookingHotel.data.providerConfirmationNumber,
      bookingHotel.data.feeCharged;
    if (
      bookingHotel.status === 200 &&
      bookingHotel.data.bookingStatus === EBookingSatus.Confirmed
    ) {
      await postBooking(
        correlationId,
        hotelId,
        bookingId,
        providerConfirmationNumber,
        feeCharged
      );
    } else {
      setLoadingConfirm(false);
      setIsLoading(false);
      errorDisplay(bookingHotel.data.error.code || "default");
    }
  };

  const executePostBookingTasks = async (bookingId: string) => {
    try {
      const tophostname = window?.location.hostname.toString();
      const isContext = searchParams.get("context")?.toUpperCase() === "GOOGLE";
      if (tophostname === "app.dealpeak.com" && isContext) {
        window.sendGtag(
          "ConfirmationPage",
          bookingId.toString(),
          (price?.hotel.rates &&
            price?.hotel.rates.length > 0 &&
            price?.hotel.rates[0].baseRate.toFixed(2)) ||
            "0.00",
          "USD",
          searchParams.get("hotelId") ?? "",
          searchParams.get("checkin") ?? "",
          searchParams.get("checkout") ?? ""
        );
      }
    } catch (error) {
      console.error("Unable to send stats, error: ", error);
    }
  };

  const handleContextMenu = (event: { preventDefault: () => void }) => {
    const d = new Date();

    alert(
      `Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard ${d.getFullYear()}.`
    );
    event.preventDefault();
    return false;
  };

  // The ammount of the fee is related to the product 696 if said product change the parameter
  const reTryFee = async (bookingID: string, amountCharge: number) => {
    const newOrderRequest: INewOrderRequest = {
      billingSameAsShipping: "yes",
      firstName: billingForm.firstName,
      lastName: billingForm.lastName,
      shippingAddress1: billingForm.contact.address.line1,
      shippingAddress2: billingForm.contact.address.line2,
      shippingCity: billingForm.contact.address.city.name,
      shippingState: billingForm.contact.address.state.code,
      shippingZip: billingForm.contact.address.postalCode,
      shippingCountry: billingForm.contact.address.country.code || "US",
      phone: billingForm.contact.phone,
      email: billingForm.contact.email,
      creditCardType: getCreditCardType(paymentInfo.issuer),
      creditCardNumber: paymentInfo.number.toString(),
      expirationDate:
        paymentInfo.expirymonth.padStart(2, "0") +
        paymentInfo.expiryyear.substring(2, 4),
      cvv: paymentInfo.cvv.toString(),
      tranType: "Sale",
      ipAddress: await getIp(),
      productId: "696",
      CampaignId: "269",
      shippingId: 2,
      quantity: "1",
      notes: "attempt number 2",
      isRetry: true,
      c3: `{"bookingId":${bookingID}}`,
      c2: "",
      dynamic_product_price_696: amountCharge,
    };
    try {
      await newOrder(newOrderRequest);
    } catch (e) {
      console.error(e);
    }
  };

  const otherOfferStateHandle = () => {
    if (errorOR) {
      modalError(
        "Rooms no longer available please contact 1-800-919-2339 for more availability during business hours or try booking another property"
      );
    } else {
      setModalOtheroptions(!openModalOtheroptions);
    }
  };

  const newUser = async () => {
    if (!user && membership) return await createUser(false);
    if (user && user.email) {
      const response = await getUserRol(user.email);
      if (
        response &&
        response.role &&
        response.role.access &&
        response.role.access.filter((item) => item.path === "sales").length > 0
      )
        return await createUser(true);
    }
  };

  const processCheckout = async () => {
    setLoadingConfirm(true);
    const Payment = totalPaymentSum();
    try {
      salesMateBigUpdate(
        billingForm.contact.address.line1,
        billingForm.contact.address.city.name,
        billingForm.contact.address.state.name,
        billingForm.contact.address.line2,
        billingForm.contact.address.postalCode,
        billingForm.contact.address.country.name,
        billingForm.contact.phone,
        searchContent.hotelDetailURL,
        searchContent.userIp ?? (await getIp()),
        billingForm.firstName,
        billingForm.lastName,
        billingForm.contact.email
      );
    } catch (error) {
      console.error(error);
    }

    const password: string | null | undefined = await newUser();
    try {
      setMembership({
        productId: membership?.productId,
        campaignId: membership?.campaignId,
        password: password,
      });

      const noFraudBody: NoFraudBody = {
        nfToken: "asdasd",
        amount: Payment || "0",
        customer: {
          email: billingForm.contact.email,
        },
        payment: {
          creditCard: {
            cardNumber: paymentInfo.number,
            expirationDate: `${paymentInfo.expirymonth}${paymentInfo.expiryyear[2]}${paymentInfo.expiryyear[3]}`,
            cardCode: `${paymentInfo.cvv}`,
          },
        },
        billTo: {
          firstName: billingForm.firstName,
          lastName: billingForm.lastName,
          address: billingForm.contact.address.line1,
          city: billingForm.contact.address.city.code,
          state: billingForm.contact.address.state.code,
          country: billingForm.contact.address.country.code,
          zip: billingForm.contact.address.postalCode,
          phoneNumber: billingForm.contact.phone,
        },
        customerIP: await getIp(),
      };
      let noFraudPass = sessionStorage.getItem("noFraudPass");
      let alreadyPassed = noFraudPass ? JSON.parse(noFraudPass) : false;
      const validateWithNoFraud = await getByName("ValidateWithNoFraud");
      if (
        JSON.parse(validateWithNoFraud.value.toLowerCase()) &&
        !alreadyPassed
      ) {
        const noFraudResponse = await getNoFraudPost(noFraudBody);
        if (noFraudResponse.data.decision !== "pass") {
          noFraudError();
          enableTidioChat();
          return;
        } else {
          sessionStorage.setItem("noFraudPass", "true");
        }
      }
      const roomsAllocations = [];

      const rateIds = [];

      for (let i = 0; i < (price?.hotel?.rates?.length || 0); i = 1 + i) {
        const rate = price?.hotel.rates[i];
        rateIds[i] = rate?.id || "";
        const rateId = rate?.id || "";

        for (let j = 0; j < (rate?.occupancies.length || 0); j = 1 + j) {
          const roomId = rate?.occupancies[j].roomId || "";
          const occupancies = rate?.occupancies[j];

          roomsAllocations.push({
            roomId,
            rateId,
            guests: [
              ...generateGuestAdult(
                firstName,
                lastName,
                billingForm.contact.email,
                rate?.occupancies[j].numOfAdults as number
              ),
              ...generateGuestChildren(
                firstName,
                lastName,
                billingForm.contact.email,
                occupancies?.childAges
              ),
            ],
          });
        }
      }

      const correlationId = searchParams.get("correlationId")?.trim() || "";
      const checkIn =
        searchParams.get("checkin") === "null" ||
        searchParams.get("checkin") === null ||
        searchParams.get("checkin") === undefined
          ? moment().add(1, "days").format("YYYY-MM-DD")
          : searchParams.get("checkin");
      const checkOut =
        searchParams.get("checkout") === "null" ||
        searchParams.get("checkout") === null ||
        searchParams.get("checkout") === undefined
          ? moment().add(2, "days").format("YYYY-MM-DD")
          : searchParams.get("checkout");

      const isDotw =
        price?.hotel.rates &&
        price?.hotel.rates.length > 0 &&
        price?.hotel.rates[0].providerName === "dotw";

      const isRatehawk =
        price?.hotel.rates &&
        price?.hotel.rates.length > 0 &&
        price?.hotel.rates[0].providerName === "RateHawk";

      const token = isDotw
        ? await window.tokenize(
            paymentInfo.nameoncard,
            paymentInfo.number,
            paymentInfo.expirymonth.padStart(2, "0"),
            paymentInfo.expiryyear,
            paymentInfo.cvv.toString()
          )
        : "";

      const payload = sessionStorage.getItem("devicePayload") || "none";

      if (
        price?.hotel.rates &&
        price?.hotel.rates.length > 0 &&
        price?.hotel.rates[0].otherRateComponents &&
        price?.hotel.rates[0].otherRateComponents.length > 0
      ) {
        price.hotel.rates[0].otherRateComponents[0]["ipAddress"] =
          await getIp();
      }
      let membershipRequest: IMembershipVerify = {
        productId: membership?.productId,
        campaignId: membership?.campaignId,
        password,
        notes: undefined,
        cavv: undefined,
        dsTransId: undefined,
        acsTransId: undefined,
        version: undefined,
        eci: undefined,
      };
      const userState = await getLocationInfoFromIP(await getIp());

      if (userState && userState.toLowerCase() === "nevada") {
        throw new Error("Something went wrong please try again");
      }
      const bookId = Math.floor(getRandom()).toString();

      const hotelId = searchParams.get("hotelId") || "";
      const boughtMembership = bougthMembershipResponse(membershipRequest);
      const book3dsRequest: IBook3ds = {
        rateIds,
        providerId:
          (price?.hotel?.rates &&
            price?.hotel?.rates.length > 0 &&
            price?.hotel?.rates[0].providerId) ||
          "",
        nationality,
        culture: "en-US",
        countryOfResidence: billingForm.contact.address.country.code,
        bookingRefId: bookId,
        creditCard: returnCC(),
        membership: membershipRequest || {},
        paymentInformation: [
          {
            key: "REZ_TOKEN",
            value: token,
          },
          {
            key: "DEVICE_PAYLOAD",
            value: payload,
          },
        ],
        roomsAllocations,
        billingContact: billingForm,
        userId: billingForm.contact.email,
        checkIn,
        checkOut,
        isMobile,
        boughtMembership: boughtMembership,
        otherRateCharges:
          price?.hotel.rates &&
          price?.hotel.rates.length > 0 &&
          price?.hotel.rates[0].otherRateComponents &&
          price?.hotel.rates[0].otherRateComponents.length > 0
            ? price.hotel.rates[0].otherRateComponents[0]
            : null,
        metasearch: {
          trv_reference: searchParams.get("trv_reference") ?? "",
          advertiser_id: searchParams.get("advertiser_id") ?? "",
          hotel: hotelDetail?.id ?? "",
          locale: searchParams.get("locale") ?? "",
        },
      };

      if (isRatehawk) {
        book3dsRequest.creditCard = returnCC();
        book3dsRequest.paymentInformation = null;
      }
      //ToDo: que hace esto, porque no se ocupa el objeto billingForm?
      let billingContactComplete = billingForm;

      billingContactComplete["stateCode"] =
        billingForm.contact.address.state.code || "";

      const bookRequest: IBookRequest = {
        rateIds,
        providerId:
          (price?.hotel?.rates &&
            price?.hotel?.rates.length > 0 &&
            price?.hotel?.rates[0].providerId) ||
          "",
        bookingRefId: bookId,
        specialRequests: [],
        roomsAllocations,
        billingContact: billingContactComplete,
        creditCard: returnCC(),
        hotelInformation: {
          hotelName: hotelDetail?.name,
          hotelDescription: hotelDetail?.descriptions[0].text,
        },
        userId: billingForm.contact.email,
        checkIn,
        checkOut,
        membership: membershipRequest || {},
        isMobile,
        boughtMembership: boughtMembership,
        otherRateCharges:
          price?.hotel.rates &&
          price?.hotel.rates.length > 0 &&
          price?.hotel.rates[0].otherRateComponents &&
          price?.hotel.rates[0].otherRateComponents.length > 0
            ? price?.hotel.rates[0].otherRateComponents[0]
            : null,
        metasearch: {
          trv_reference: searchParams.get("trv_reference") ?? "",
          advertiser_id: searchParams.get("advertiser_id") ?? "",
          hotel: hotelDetail?.id ?? "",
          locale: searchParams.get("locale") ?? "",
        },
      };

      const bookingHotel =
        isDotw || isRatehawk
          ? await book3ds(hotelId, correlationId, book3dsRequest)
          : await bookHotel(hotelId, correlationId, bookRequest);

      const { bookingStatus, paymentInformation } = bookingHotel.data;

      const getActionUrlValue = (params: any[]) => {
        const actionUrlParam = params.find(
          (param: { key: string; value: string }) => param.key === "ActionUrl"
        );
        return actionUrlParam ? actionUrlParam.value : null;
      };

      if (paymentInformation) {
        const actionUrlValue = getActionUrlValue(paymentInformation);
        if (actionUrlValue) {
          await get3DSToken(actionUrlValue);
        } else {
          console.log("No se encontró el key 'ActionUrl'");
        }
      }

      if (
        bookingHotel.status === 200 &&
        bookingStatus === EBookingSatus.Ghost
      ) {
        const bookingDetail = await getBookingDetails(
          {
            channelId: webConfigContext.selectedChannel ?? "",
            bookingId: bookId,
          },
          correlationId,
          0,
          isDotw || isRatehawk ? book3dsRequest : bookRequest
        );

        const { bookingStatus, providerConfirmationNumber, feeState } =
          bookingDetail.hotelBooking;
        const feeFailed = feeState && feeState.trim() === EFeeStatus.Failed;

        if (bookingStatus === EBookingSatus.Confirmed) {
          setLoadingConfirm(false);
          await postBooking(
            correlationId,
            hotelId,
            bookId,
            providerConfirmationNumber,
            typeof feeFailed === "boolean" ? feeFailed : undefined
          );
        } else {
          enableTidioChat();
          setLoadingConfirm(false);
          errorDisplay(bookingHotel.data.error.code || "default");
        }
      }
      if (
        bookingHotel.status === 200 &&
        bookingStatus === EBookingSatus.Confirmed
      ) {
        const { bookingId, providerConfirmationNumber, feeCharged } =
          bookingHotel.data;
        await postBooking(
          correlationId,
          hotelId,
          bookingId,
          providerConfirmationNumber,
          feeCharged
        );
      } else if (
        (bookingHotel.status === 200 &&
          bookingStatus === EBookingSatus.ConfirmationPending &&
          isDotw) ||
        isRatehawk
      ) {
        const token3dsObj = paymentInformation.find(
          (info: any) => info.key === "TOKEN_3DS"
        );

        const token3ds = token3dsObj?.value || "";

        const post = async function postBack(success: boolean) {
          setLoadingConfirm(true);

          window.showModal3DS(false);
          if (success) {
            setIsLoading(true);
            await book3dsSuccessPostBack(
              book3dsRequest,
              payload,
              hotelId,
              correlationId
            );
          } else {
            setLoadingConfirm(false);
            setIsLoading(false);
            message.error({
              key: "3dsError",
              icon: <></>,
              type: "error",
              content:
                "The 3ds secure payment process has failed please contact support for help 1-800-919-2339 ",
              className: "message-error-3ds",
              duration: 5,
              onClick: () => message.destroy("3dsError"),
            });
          }
        };

        window.showModal3DS(true);
        await window.callXmlApi(token3ds, "threedsWidgetContainer", post);
      } else {
        if (
          bookingHotel.data?.error?.code === "4004" ||
          bookingHotel.data?.error?.code === "5000"
        ) {
          showModalOtherOffers();
        }
        enableTidioChat();
        setLoadingConfirm(false);
        errorDisplay(bookingHotel.data.error.code || "default");
      }
    } catch (err: any) {
      enableTidioChat();
      setLoadingConfirm(false);
      errorDisplay("default");
    } finally {
      setLoadingConfirm(false);
    }
  };

  function bougthMembershipResponse(membershipRequest: IMembershipVerify) {
    const boughtMembership = membershipMandatory || createMembership;
    const dsRebillResponse = sessionStorage.getItem(
      "3ds_verify_response"
    ) as string;

    if (boughtMembership && dsRebillResponse) {
      const membershipVerifyResponse: I3dsResponse =
        JSON.parse(dsRebillResponse);

      if (membershipVerifyResponse.status === "Y") {
        membershipRequest.eci = membershipVerifyResponse.eci;
        membershipRequest.cavv = membershipVerifyResponse.authenticationValue;
        membershipRequest.dsTransId = membershipVerifyResponse.dsTransId;
        membershipRequest.acsTransId = membershipVerifyResponse.acsTransId;
        membershipRequest.notes = dsRebillResponse;
        membershipRequest.version = membershipVerifyResponse.protocolVersion;
      } else if (
        membershipVerifyResponse.status === "N" &&
        membershipVerifyResponse.eci
      ) {
        membershipRequest.notes = `3D process failed, eci:${membershipVerifyResponse.eci} protocolVersion:${membershipVerifyResponse.protocolVersion} protocolVersion:${membershipVerifyResponse.protocolVersion} authentication value:${membershipVerifyResponse.authenticationValue}`;
      } else {
        membershipRequest.notes = `3D process failed, eci:${membershipVerifyResponse.eci} protocolVersion:${membershipVerifyResponse.protocolVersion} protocolVersion:${membershipVerifyResponse.protocolVersion} authentication value:${membershipVerifyResponse.authenticationValue}`;
      }
    }
    return boughtMembership;
  }

  const confirmCheckout = async () => {
    setLoadingConfirm(true);
    await trigger3dsverify();
    const hotelId = searchParams.get("hotelId") || "";
    const correlationId = searchParams.get("correlationId") || "";
    const recommendationId = searchParams.get("recommendationId") || "";
    const priceResult = await priceCheck(
      hotelId,
      recommendationId,
      correlationId
    );

    const errorIndex = PRICE_CHECK_RESPONSE_CODES.findIndex(
      (response) => response.code === priceResult.code
    );

    if (
      priceResult.code !== undefined ||
      errorIndex !== -1 ||
      priceResult.error !== undefined
    ) {
      if (priceResult.error !== undefined) {
        showModalOtherOffers(priceResult);
      } else if (errorIndex !== -1) {
        showModalOtherOffers(priceResult);
      } else {
        showModalOtherOffers(priceResult);
      }
      setLoadingConfirm(false);
      return;
    }

    const baseRate = parseFloat(searchParams.get("price") || "0");
    const isContext =
      searchParams.get("context")?.toUpperCase() === "GOOGLE" && baseRate > 0;
    if (priceResult.hotel == undefined) {
      setPriceError({
        code: PRICE_CHECK_RESPONSE_CODES[1].code,
        message: PRICE_CHECK_RESPONSE_CODES[1].message,
      });
      showModalOtherOffers();
    } else {
      setPrice(priceResult);
      sessionStorage.removeItem("tokenBooking");
      sessionStorage.setItem("tokenBooking", priceResult.token);
    }

    setPrice(priceResult);
    sessionStorage.removeItem("tokenBooking");
    sessionStorage.setItem("tokenBooking", priceResult.token);
    const diffForSoldout = await getByName("DiffForSoldout");
    const diffValue = parseFloat(
      diffForSoldout && diffForSoldout.value ? diffForSoldout.value : "10"
    );
    let shouldApplyCardFee = false;
    try {
      shouldApplyCardFee =
        (cardFee &&
          cardFee.active === true &&
          cardFee.provider.includes(priceResult.hotel.rates[0].providerId) &&
          cardFee.issuer.includes(paymentInfo.issuer)) ||
        false;
    } catch (error) {
      console.error(error);
    }

    let totalFakePrice =
      baseRate +
      parseFloat(searchParams.get("tax") || "0") +
      parseFloat(searchParams.get("otherRateComponent") || "0");

    let realPrice = priceResult.hotel.rates[0].totalRate;

    if (
      priceResult?.hotel.rates &&
      priceResult?.hotel.rates.length > 0 &&
      priceResult.hotel.rates[0].otherRateComponents &&
      priceResult.hotel.rates[0].otherRateComponents.length > 0 &&
      priceResult.hotel.rates[0].otherRateComponents[0].amount
    ) {
      const totalAmount = priceResult.hotel.rates[0].otherRateComponents.reduce(
        (sum: number, item: IOtherRate) => sum + item.amount,
        0
      );
      realPrice = realPrice + totalAmount;
    }

    if (cardFee && shouldApplyCardFee) {
      realPrice = realPrice + cardFee.amount;
    }

    const diffPrice =
      searchParams.get("price") === null ? 0 : totalFakePrice - realPrice;

    if (diffPrice !== 0 && !Number.isNaN(diffPrice) && isContext) {
      let contentMessage = "";
      if (diffPrice > 1)
        contentMessage = `We've been DealPeaking and saved your ${
          price?.currency
        } 
        ${Math.abs(diffPrice).toFixed(2)}`;
      if (diffPrice < 1)
        contentMessage = `Due to high demand the price for your accommodation has increased by ${
          price?.currency
        }
        ${Math.abs(diffPrice).toFixed(2)}. Do you want continue?`;
      if (diffPrice <= -diffValue) {
        setModalDiffPrice(true);
        showModalOtherOffers(priceResult);
      } else {
        showConfirmPrice(contentMessage, processCheckout);
      }
    } else if (Number.isNaN(diffPrice)) {
      setPriceError({
        code: PRICE_CHECK_RESPONSE_CODES[1].code,
        message: PRICE_CHECK_RESPONSE_CODES[1].message,
      });
    } else {
      await processCheckout();
    }
    setLoadingConfirm(false);
  };

  const updatePaymentForm = (objPaymentForm: IPayment) => {
    setPaymentInfo(objPaymentForm);
  };

  const updateMember = (e: boolean | undefined) => {
    setCreateMembership(e);
  };

  const resetMembershipSelection = () => {
    setCreateMembership(undefined);
  };

  const updateStepNext = () => {
    setNextStep(true);
    setPaymentInfo({
      ...paymentInfo,
      contact: {
        ...paymentInfo.contact,
        email: billingForm.contact.email,
        billingaddress: {
          ...paymentInfo.contact.billingaddress,
          city: billingForm.contact.address.city,
          state: billingForm.contact.address.state,
          country: billingForm.contact.address.country,
          line1: billingForm.contact.address.line1,
          line2: billingForm.contact.address.line2,
        },
      },
    });
  };

  const changeNacionality = (code: string) => {
    setNationality(code);
  };

  const updateGuestsInfo = (
    firstNameGuest: string,
    lastNameGuest: string,
    emailGuest: string
  ) => {
    setEmail(emailGuest);
    setfirstName(firstNameGuest);
    setlastName(lastNameGuest);
  };

  useEffect(() => {
    if (
      !sessionStorage.getItem("devicePayload") &&
      window.hasOwnProperty("collector")
    ) {
      window.getDevicePayload();
    }
    const setNightlyRate = async (): Promise<void> => {
      const StatesShowTotalRate = await getByName("StatesShowTotalRate");
      if (StatesShowTotalRate && StatesShowTotalRate.value) {
        let stateToShow = JSON.parse(StatesShowTotalRate.value);
        const userState = await getLocationInfoFromIP(
          searchContent.userIp ?? (await getIp())
        );
        const lowerCaseStetes = stateToShow.map((str: string) =>
          str.toLowerCase()
        );

        setshowTotalNightly(
          lowerCaseStetes.includes(userState.toLowerCase()) ||
            lowerCaseStetes.includes("all")
        );
      }
    };
    const IpExempted = async (): Promise<void> => {
      const ip = searchContent.userIp ?? (await getIp());
      const isIpExempt = await isIpExempt2fa(ip);
      setIsIpExcempt(isIpExempt);
    };
    setNightlyRate();
    IpExempted();
  }, []);

  useEffect(() => {
    if (createMembership === false) showConfirm();
  }, [createMembership]);

  const updateSame = (objBillingForm: IBilling) => {
    setPaymentInfo({
      ...paymentInfo,
      contact: {
        email: objBillingForm.contact.email,
        phone: objBillingForm.contact.phone,
        billingaddress: {
          line1: objBillingForm.contact.address.line1,
          line2: objBillingForm.contact.address.line2,
          city: { ...objBillingForm.contact.address.city },
          country: { ...objBillingForm.contact.address.country },
          state: { ...objBillingForm.contact.address.state },
          postalcode: objBillingForm.contact.address.postalCode,
        },
      },
    });
  };

  const showModalOtherOffers = async (priceResult?: any) => {
    setModalDiffPrice(true);

    const hotelId = searchParams.get("hotelId") || "";
    const roomName = searchParams.get("name") || "";
    const basePrice = parseFloat(searchParams.get("basePrice") || "0");
    const baserate = searchParams.get("price");
    const isContext =
      searchParams.get("context")?.toUpperCase() === "GOOGLE" ||
      (searchParams.get("context")?.toUpperCase() === "TRIVAGO" &&
        parseFloat(baserate || "0") > 0);
    const br = isContext
      ? parseFloat(baserate || "0").toFixed(2)
      : basePrice.toFixed(2);

    const requestRRD = {
      checkIn: searchContent.checkIn,
      checkOut: searchContent.checkOut,
      channelId: webConfigContext?.selectedChannel || "",
      currency: "USD",
      culture: "en-US",
      roomName: roomName,
      baseRate: br,
      occupancies: searchContent.occupancies,
      nationality: "US",
      countryOfResidence: "US",
    };
    if (priceResult) {
      const priceError =
        PRICE_CHECK_RESPONSE_CODES.find((x) => x.code === priceResult.code) ||
        PRICE_CHECK_RESPONSE_CODES.find((x) => x.code === GENERIC_ERROR_CODE);
      setPriceError(priceError);
    }

    try {
      const roomsAndRates = await getRoomsAndRatesDirect(
        hotelId,
        requestRRD,
        correlationId
      );
      if (
        roomsAndRates &&
        roomsAndRates.data &&
        roomsAndRates.data.hotel.rates &&
        roomsAndRates.data.hotel.rates.length > 0
      ) {
        const responseData = roomsAndRates.data;
        sessionStorage.setItem("tokenOtherOffers", responseData.token);
        setOtherRates(responseData);
        setModalOtheroptions(true);
      } else {
        modalError(
          "Room no longer available please contact 1-800-919-2339 for more availability during business hours or try booking another property"
        );
        setErrorOR(true);
        setModalOtheroptions(false);
      }
    } catch (error) {
      setErrorOR(true);
    }
  };

  const handleClose = () => {
    setErrorOR(false);

    setModalOtheroptions(false);
    setModalDiffPrice(false);
  };

  const getRatesInfo = (id: string) => {
    if (!OtherRates) {
      setErrorOR(true);
      return [];
    }

    const ratesDetail = OtherRates.hotel.rates.filter(
      (res) => res.occupancies[0].roomId === id
    );
    return ratesDetail;
  };

  const firstNonEmptyImagesArray = (
    standardizedRooms: IstandardizedRooms[]
  ) => {
    return (
      standardizedRooms.find((room) => room.images && room.images.length > 0)
        ?.images || []
    );
  };
  useEffect(() => {
    const init = async () => {
      window.scrollTo(0, 0);
      addNoFraud();
      setPriceError(undefined);
      setIsLoading(true);
      const cardFeePolicyName = isMobile
        ? "CreditCardFeeMobile"
        : "CreditCardFeeDesktop";
      const membershipMandatory = await getByName("membershipMandatory");
      const cardFeePolicy = await getByName(cardFeePolicyName);
      if (cardFeePolicy) {
        setcardFee(JSON.parse(cardFeePolicy.value));
      }
      setMembershipMandatory(
        JSON.parse(membershipMandatory.value?.toLowerCase() ?? false)
      );

      let priceLocalContext: any;
      const isPriceContextEmpty =
        Object.keys(sessionStorage.getItem(`priceContextFake`) || {}).length ===
        0
          ? true
          : false;

      if (isPriceContextEmpty && priceContext) {
        sessionStorage.setItem(
          "priceContextFake",
          JSON.stringify(priceContext)
        );
      }
      if (sessionStorage.getItem(`priceContextFake`)) {
        priceLocalContext = JSON.parse(
          sessionStorage.getItem(`priceContextFake`) || ""
        );
      } else {
        priceLocalContext = priceContext;
      }

      const correlationId = searchParams.get("correlationId");
      const token = sessionStorage.getItem("token");
      const hotelId = searchParams.get("hotelId");
      const recommendationId = searchParams.get("recommendationId") || "";
      const roomId = searchParams.get("roomid");
      const isCallRoomsAndRates =
        searchParams.get("context") === null ||
        sessionStorage.getItem(`roomsAndRates${token}`) === null;

      if (!correlationId || !hotelId) return;
      const hotelResult = await getHotelDetail(
        correlationId,
        hotelId,
        webConfigContext.selectedChannel ?? ""
      );
      setHotelDetail(hotelResult.hotels[0]);
      const memberships = await getMemberships();
      setProducts(memberships);
      const roomResult = !isCallRoomsAndRates
        ? JSON.parse(sessionStorage.getItem(`roomsAndRates${token}`) || "")
        : await getRoomsAndRates(
            hotelId,
            token ?? priceLocalContext.token,
            {
              ...hotelResult.hotels[0],
              isMobile,
              mail: searchContent.auth?.currentUser?.email,
            },
            correlationId
          );

      const myHotel = isCallRoomsAndRates
        ? roomResult.data.hotel
        : roomResult.hotel;
      setRooms(myHotel.rooms);

      const roomD = myHotel.rooms.filter(
        (item: { id: string }) => item.id === roomId
      );

      setRoomDetail(roomD[0]);
      const baserate = searchParams.get("price");
      const isContext =
        (searchParams.get("context")?.toUpperCase() === "GOOGLE" ||
          searchParams.get("context")?.toUpperCase() === "TRIVAGO") &&
        parseFloat(baserate || "0") > 0;

      if (priceLocalContext && isContext && priceLocalContext.hotel) {
        setPrice(priceLocalContext);
        setCardRequired(true);
      } else {
        const priceResult = await priceCheck(
          hotelId,
          recommendationId,
          correlationId
        );
        if (
          webConfigContext.webConfig.REACT_APP_CHANNEL_ID.Organic ===
          "dp-hbtest-channel"
        ) {
          setCardRequired(priceResult?.hotel.rates[0].cardRequired || false);
        } else {
          setCardRequired(true);
        }
        if (priceResult.code === undefined) {
          if (priceResult.hotel === undefined) {
            setPriceError({
              code: PRICE_CHECK_RESPONSE_CODES[1].code,
              message: PRICE_CHECK_RESPONSE_CODES[1].message,
            });
            showModalOtherOffers();
          } else {
            setPrice(priceResult);
            sessionStorage.setItem("tokenBooking", priceResult.token);
          }
        } else {
          showModalOtherOffers(priceResult);
        }
      }
      setIsLoading(false);

      if (currentStep === 2 && paymentInfo.number === "") {
        updateStep(GO_BACK, -1);
      }
    };
    init();
  }, [searchParams]);

  return (
    <>
      {!errorOR && (
        <Modal
          title={
            <div className="other-offers-title">
              <span>
                Room options have changed for your selection, we have found
                additional room offerings.
              </span>
            </div>
          }
          open={openModalOtheroptions}
          onCancel={handleClose}
          footer={null}
          className="other-offers-modal"
          width={width > 1023 ? "90%" : "100%"}
          bodyStyle={{
            maxHeight: "75vh",
            overflowY: "auto",
          }}
          centered
        >
          <div>
            {OtherRates !== undefined &&
            OtherRates &&
            OtherRates.hotel &&
            OtherRates.hotel.rooms &&
            OtherRates.hotel.rooms.length > 0 ? (
              OtherRates.hotel.rooms?.map(
                (room, index) =>
                  getRatesInfo(room.id).length > 0 && (
                    <div key={index}>
                      <RoomCard
                        closeOtherOffers={handleClose}
                        room={room}
                        rates={getRatesInfo(room.id)}
                        recommendation={OtherRates.hotel.recommendations}
                        correlationId={correlationId}
                        deeplink={deeplink}
                        defaultImage={firstNonEmptyImagesArray(
                          OtherRates.hotel.standardizedRooms
                        )}
                        key={index}
                        numOfAdults={
                          room.MaxAdultAllowed !== 0
                            ? room.MaxAdultAllowed
                            : parseInt(searchParams.get("adults") || "", 10)
                        }
                        numOfChildren={
                          room.MaxChildrenAllowed !== 0
                            ? room.MaxChildrenAllowed
                            : (searchParams.get("children") || "")
                                .split(",")
                                .filter(
                                  (child: string) => !isNaN(parseInt(child))
                                ).length || 0
                        }
                        fakePrice={undefined}
                        provider={getRatesInfo(room.id)[0].providerId || ""}
                        rawRates={OtherRates.hotel.rates}
                        showTotalNightly={showTotalNightly}
                        sFakePrice={undefined}
                        isIpExcempt={isIpExcempt}
                      />
                    </div>
                  )
              )
            ) : (
              <Alert
                message={<h3>No rooms available</h3>}
                description={
                  <strong>The are not available rooms for this hotel.</strong>
                }
                type="warning"
                showIcon
              />
            )}
          </div>
        </Modal>
      )}
      {isLoading && <LoaderComponent title="DealMaking in Progress!" />}
      {loadingConfirm && (
        <>
          <div className="steps_parent">
            <div className="steps_second_layer">
              <div className="steps_third_layer">
                <StepsLine current={currentStep} />
              </div>
            </div>
          </div>
          <LoaderComponent
            title="Please wait while we confirm your booking. This may take a few minutes, do not go back or refresh this page"
            subtitle="Almost Done"
          />
        </>
      )}
      {!isLoading && !loadingConfirm && (
        <section className="mb-5">
          <div className="steps_parent">
            <div className="steps_second_layer">
              <div className="steps_third_layer">
                <StepsLine current={currentStep} />
              </div>
            </div>
          </div>
          <div className="checkout_page_styles">
            <div className="checkout_parent">
              <div className="checkout_form_left">
                {currentStep === 1 && (
                  <FirstStep
                    cardFee={cardFee}
                    otherOfferStateHandle={otherOfferStateHandle}
                    nationality={nationality}
                    changeNacionality={changeNacionality}
                    nextStep={nextStep}
                    roomDetail={roomDetail}
                    hotelDetail={hotelDetail}
                    roomPriceError={roomPriceError}
                    paymentInfo={paymentInfo}
                    billingForm={billingForm}
                    updateGuestsInfo={updateGuestsInfo}
                    updateSame={updateSame}
                    updateStepNext={updateStepNext}
                    updateBillingForm={updateBillingForm}
                    confirmCheckout={confirmCheckout}
                    updatePaymentForm={updatePaymentForm}
                    setMembership={setMembership}
                    membership={membership}
                    products={products}
                    createMembership={createMembership}
                    loadingConfirm={loadingConfirm}
                    price={price}
                    updateMember={updateMember}
                    resetMembershipSelection={resetMembershipSelection}
                    membershipMandatory={membershipMandatory}
                  />
                )}
              </div>
              {width > 1023 && (
                <div className="sidebar-form mt-5">
                  <div className="aside_info">
                    <div className="sticky_aside">
                      {price && price.hotel && (
                        <SidebarForm
                          cardFee={cardFee}
                          paymentInfo={paymentInfo}
                          price={price}
                          membershipMandatory={membershipMandatory}
                        />
                      )}
                      <MembershipSectionSideBar
                        updateMember={updateMember}
                        setMembershipObject={setMembership}
                        membershipObject={membership}
                        createMembership={createMembership}
                        membershipMandatory={membershipMandatory}
                      />
                      <div className="extra_info">
                        <p>
                          Prices in{" "}
                          {price && price.currency ? price.currency : "USD"}.
                          Prices include all{" "}
                          <a
                            rel="noreferrer"
                            href={`${RoutesPath.home}/?s=tax+fee&post_type=dealpeak_faqs`}
                            target="_blank"
                          >
                            taxes and fees
                          </a>{" "}
                          charged by DealPeak.
                        </p>
                        {price &&
                          price.hotel &&
                          price?.hotel.rates &&
                          price?.hotel.rates.length > 0 &&
                          price?.hotel.rates[0].providerName === "EAN" && (
                            <>
                              {" "}
                              <hr
                                style={
                                  price?.hotel.rates[0].providerName === "EAN"
                                    ? {}
                                    : { visibility: "hidden" }
                                }
                              />
                              <a
                                href="https://developer.expediapartnersolutions.com/terms/en"
                                style={
                                  price?.hotel.rates[0].providerName === "EAN"
                                    ? {}
                                    : { visibility: "hidden" }
                                }
                              >
                                Expedia Terms & Conditions
                              </a>
                              <hr
                                style={
                                  price.hotel.rates[0].providerName === "EAN"
                                    ? {}
                                    : { visibility: "hidden" }
                                }
                              />
                              <div
                                className="text-center"
                                style={
                                  price.hotel.rates[0].providerName === "EAN"
                                    ? {}
                                    : { visibility: "hidden" }
                                }
                              >
                                <img
                                  src={pcieLogo}
                                  className="mb-2"
                                  alt="pcie"
                                  width="50"
                                  height="auto"
                                />
                                <br />
                                <p>
                                  Payment process will be done at Seattle City
                                  in Washington State
                                </p>
                              </div>
                              <hr />
                              <link
                                rel="stylesheet"
                                href="https://tgscript.s3.amazonaws.com/cert-style-v1.024.css"
                                integrity="sha384-PzfduepNOPTKNfShxcius5IlrRQUUwINOCc14DrQlKzVnKWHX2OvyT01RRPVD43C"
                                crossOrigin="anonymous"
                              />
                            </>
                          )}
                        <div className="tgfloat tgStackSeal tgBright tg_1-bright ">
                          <img
                            alt="Trust Guard Security Scanned"
                            id="tgfloat-img"
                            className="tgfloat-inner"
                            src="https://seal.trustguard.com/sites/dealpeak.com/641279e87fc058504155857a.svg"
                            style={{ border: "0", width: "320px" }}
                            onContextMenu={handleContextMenu}
                          />
                        </div>
                        <div id="tgSlideContainer" />
                        <script
                          src="https://tgscript.s3.amazonaws.com/tgscript-v1.038.js"
                          integrity="sha384-iGCCKxLboYe8bcyNkB91JJrQAwJdL7MBD7NI96RjhAtW5lnFDO/cTFZdfNoeAFVv"
                          crossOrigin="anonymous"
                        />
                        <hr />
                        {price &&
                        price?.hotel &&
                        price?.hotel.rates &&
                        price?.hotel.rates.length > 0 &&
                        price?.hotel.rates[0] &&
                        price?.hotel.rates[0].additionalCharges &&
                        price?.hotel.rates[0].additionalCharges?.length > 0 ? (
                          <p>
                            *Resort fee is payable at the property. Provide a
                            valid form of payment at check in.
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CheckoutForm;
