import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

const FooterCopyright = (): ReactElement => {
  const thisYear = new Date();

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-lg-6 mx-auto text-center">
          <small>
            All Rights Reserved |{" "}
            <span>
              {thisYear.getFullYear()} |{" "}
              <Link to="/">
                <img
                  src={`${process.env.PUBLIC_URL}/logo-monochrome.svg`}
                  alt="main logo"
                  width={80}
                />
              </Link>
            </span>
          </small>
        </div>
      </div>
    </div>
  );
};
export default FooterCopyright;
