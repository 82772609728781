import React from "react";
import { Row, Col } from "antd";
import BenefitCardMembership from "./BenefitCardMembership";
import Benefit1 from "../../../img/benefit-1.png";
import Benefit2 from "../../../img/benefit-2.png";
import Benefit3 from "../../../img/benefit-3.png";
import Benefit4 from "../../../img/benefit-4.png";

const dataBenefits = [
  {
    img: Benefit1,
    type: 1,
    title: "HOTELS UP TO",
    price: "80% OFF!",
    subtitle: "Choose from 1000's of Destinations",
    content: [
      "Lowest prices on 400,000+ Hotels",
      "Room Upgrades",
      "Free Amenities",
      "Member Only Coupons",
    ],
  },
  {
    img: Benefit2,
    type: 2,
    title: "Welcome to the CLUB",
    price: "",
    subtitle: "Exclusive family of savings",
    content: [
      "You're now part of the exclusive family of savings",
      "You have joined 1,000's of members who enjoy savings not avalible to the public. Start Booking Now",
      "Simply Select from the below Travel Options",
    ],
  },
  {
    img: Benefit3,
    type: 1,
    title: "Want even MORE?",
    price: "",
    subtitle: "Hotel Savers Club",
    content: ["Click Here to Sign up and enjoy our Hotel Savers Club Options"],
  },
  {
    img: Benefit4,
    type: 2,
    title: "Discount Resort Weeks",
    price: "",
    subtitle: "Only the best for you",
    content: ["Get access to HOT discount resort weeks around the world"],
  },
];

const BenefistVipSection = () => (
  <section className="benefit-container py-5 ">
    <div className="container">
      <Row>
        <Col span={24}>
          <h3 className="text-center">
            Explore your DealPeak VIP Club benefits
          </h3>
        </Col>
      </Row>
      <Row className="mt-4">
        {dataBenefits.map((benefit, index) => (
          <BenefitCardMembership
            key={index}
            img={benefit.img}
            title={benefit.title}
            price={benefit.price}
            subtitle={benefit.subtitle}
            content={benefit.content}
            type={benefit.type}
          />
        ))}
      </Row>
    </div>
  </section>
);
export default BenefistVipSection;
