import React, { ReactElement } from "react";
import RateIndicatorReview from "./RateIndicator";
import DateReview from "../ContentCardReview/DateReview";

interface HeaderCardReviewProps {
  rate: string;
  reviewer: string;
  date: string;
}

const HeaderCardReview = ({
  rate,
  reviewer,
  date,
}: HeaderCardReviewProps): ReactElement => (
  <div className="header-card-review">
    <RateIndicatorReview rate={Number(rate)} />
    <DateReview date={date} />
    <h6 style={{ fontSize: 14, fontWeight: 700 }} className="text-secondary">
      {reviewer}
    </h6>
  </div>
);
export default HeaderCardReview;
