import { Col, Divider, Modal, Row, Typography } from "antd";
import DOMPurify from "dompurify";
import moment from "moment";
import React from "react";
import { IPolicy } from "../../../../../Models/HotelContent";
import { IRule } from "../../../../../Models/RoomDetail";

interface RoomPolicyProps {
  policyObject: any;
  refundable: boolean;
  viewPolicies: boolean;
  minimalRestrictDate: string | null;
  setViewPolicies: (option: boolean) => void;
  hotelDetailPolicy?: IPolicy[];
}

const RoomPolicy = ({
  policyObject,
  refundable,
  viewPolicies,
  minimalRestrictDate,
  setViewPolicies,
  hotelDetailPolicy,
}: RoomPolicyProps) => {
  const { Title, Text } = Typography;
  const setIsCancellable = (ruleValue: number, fromPolicy: string) => {
    if (minimalRestrictDate != null) {
      const datePolicy = new Date(fromPolicy);
      const dateRestrict = new Date(minimalRestrictDate);
      if (dateRestrict <= datePolicy) {
        return <>The cancellation is no longer available</>;
      }
    }
    return <>${ruleValue}</>;
  };
  return (
    <Modal
      title="Policies"
      centered
      open={viewPolicies}
      destroyOnClose
      closable
      closeIcon={<small>Close</small>}
      onCancel={() => setViewPolicies(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      width={700}
    >
      {policyObject.map((policy: any, index: number) => (
        <div key={index}>
          <Title level={5}>
            This room is {refundable ? "refundable" : "not refundable"}
          </Title>
          {refundable && (
            <>
              <Row gutter={[0, 32]}>
                <Title level={5}>Cancellation policy</Title>
              </Row>
              <br />
              <Row gutter={[0, 32]}>
                <p>{policy.text}</p>
              </Row>
              <Divider />
            </>
          )}
          {refundable &&
            policy.rules.map((rule: IRule, i: number) => (
              <div key={i}>
                <Row>
                  <Col span={8} order={1}>
                    <Text strong>From:</Text>
                  </Col>
                  <Col span={8} order={2}>
                    <Text strong>To:</Text>
                  </Col>
                  <Col span={8} order={3}>
                    <Text strong>Penalty:</Text>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={8} order={1}>
                    <Text>{`${moment(rule.start).format("MM-DD-YYYY")}`}</Text>
                  </Col>
                  <Col span={8} order={2}>
                    <Text>{`${moment(rule.end).format("MM-DD-YYYY")}`}</Text>
                  </Col>
                  <Col span={8} order={3}>
                    <Text>
                      {setIsCancellable(
                        rule.estimatedValue,
                        moment(rule.start).format("MM-DD-YYYY")
                      )}
                    </Text>
                  </Col>
                </Row>
                <br />
              </div>
            ))}
          {policyObject.additionalCharges !== undefined
            ? policyObject.additionalCharges.map((extra: any, i: number) => (
                <div key={i}>
                  <Row gutter={[0, 32]}>
                    <Text strong>Cancellation policy- {extra.text}</Text>
                  </Row>
                  <Row gutter={[0, 32]}>
                    <Col span={8} order={1}>
                      <p>
                        {extra.description} - {extra.frequency} - {extra.unit}
                      </p>
                    </Col>
                    <Col span={8} order={2}>
                      <p>
                        {extra.currency}
                        {extra.amount}
                      </p>
                    </Col>
                  </Row>
                </div>
              ))
            : ""}
        </div>
      ))}
      {hotelDetailPolicy?.map((item: IPolicy, i) => (
        <div key={i}>
          <Row gutter={[0, 32]}>
            <Text strong>{item.type}</Text>
          </Row>
          <Row gutter={[0, 32]}>
            <Col span={24}>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(item.text)}`,
                }}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Modal>
  );
};

export default RoomPolicy;
