import {
  Form,
  Input,
  message,
  Modal,
  notification,
  Spin,
  Switch,
  Upload,
} from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  processList,
  getHotelCenter,
} from "../../../helpers/requestHotelCenter";

interface HotelCenterProps {
  modalHotelCenterShow: boolean;
  setModalHotelCenterShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const HotelCenterForm = ({
  modalHotelCenterShow,
  setModalHotelCenterShow,
}: HotelCenterProps) => {
  const [listId, setListId] = useState<string>("");
  const [listUploaded, setListUploaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const onChange = (info: UploadChangeParam) => {
    if (info.file.status !== "uploading") {
      setListUploaded(false);
    }
    if (info.file.status === "done") {
      setListUploaded(true);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      setListUploaded(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinishForm = async (values: any) => {
    try {
      setProcessing(true);
      const hotelCenter = await getHotelCenter(listId);
      if (hotelCenter && hotelCenter.data && hotelCenter.data.processed) {
        notification.info({
          message: "Information",
          description: "The List is already processed",
        });
        setProcessing(false);
        return;
      }

      const response = await processList(listId, values.setLiveOnGoogle);
      if (response) {
        notification.success({
          message: "Success",
          description: "Processed Successfully",
        });
      } else
        notification.error({
          message: "Error",
          description: "Error Processing List",
        });
    } catch (err) {
      notification.error({
        message: "Error",
        description: "Error Processing List",
      });
      console.error(err);
    }
    setProcessing(false);
  };

  const modalClose = () => {
    setListId("");
    setListUploaded(false);
  };

  const authorizationHeader = {
    Authorization: process.env.REACT_APP_DEALPEAK_AUTH_API_KEY as string,
  };

  const [form] = Form.useForm();
  const beforeUpload = () => {
    const newCode = uuidv4();
    setListId(newCode);
    form.setFieldValue("listId", newCode);
  };
  return (
    <Modal
      title="Set Live Process List"
      open={modalHotelCenterShow}
      onCancel={() => setModalHotelCenterShow(false)}
      footer={null}
      destroyOnClose
      closable
      afterClose={modalClose}
      closeIcon={<small>Close</small>}
    >
      <div>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinishForm}
          autoComplete="off"
          layout="vertical"
          className="create-account-form"
        >
          <Form.Item name="listId" label="List Id">
            <Input value={listId} disabled />
          </Form.Item>
          <Form.Item
            name="setLiveOnGoogle"
            label="Set live on Google"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch defaultChecked={false} />
          </Form.Item>
          <div className="upload-container">
            <Form.Item>
              <Upload
                name="file"
                accept=".csv"
                maxCount={1}
                showUploadList
                beforeUpload={beforeUpload}
                multiple={false}
                headers={authorizationHeader}
                action={`${
                  process.env.REACT_APP_API_BASE_URL as string
                }HotelCenter/UploadFile?listId=${listId}`}
                onChange={onChange}
              >
                <button type="button" className="btn btn-primary-dp">
                  <FontAwesomeIcon
                    icon={faUpload as IconProp}
                    className="me-2"
                  />
                  Upload
                </button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <a
                className="btn btn-primary-dp"
                href={`${process.env.PUBLIC_URL}/HotelCenterList-template.csv`}
                download="HotelCenterList-template"
              >
                <FontAwesomeIcon
                  icon={faDownload as IconProp}
                  className="me-2"
                />
                Template
              </a>
            </Form.Item>
          </div>
          <div className="buttons-modal">
            <Spin spinning={processing}>
              <button
                type="submit"
                className="btn btn-success-dp btn-block"
                disabled={!listUploaded}
              >
                Process
              </button>
            </Spin>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default HotelCenterForm;
