import React from "react";
import moment from "moment";
import { Input, Button } from "antd";
import { IBookDetailResponse } from "../../../Models/BookDetailRequest";

export function cancelBookingFirstStep(
  width: number,
  providerNumber: string,
  setProviderNumber: React.Dispatch<React.SetStateAction<string>>,
  error: boolean,
  handleConfirm: () => Promise<void>,
  BookDetail: IBookDetailResponse | undefined
): React.ReactNode {
  return (
    <div className="cancel-booking">
      <h2>Cancel Booking</h2>
      <h6 className="mt-3">
        In order to cancel your order insert the confirmation number provided in
        the email we sent you.
      </h6>
      <Input
        style={{ width: width < 1200 ? "100%" : "62%" }}
        value={providerNumber}
        placeholder="Provider confirmation number"
        onChange={(e) => setProviderNumber(e.target.value)}
        className="mt-3"
      />
      {error && (
        <span className="mt-3 error">The confirmation number is incorrect</span>
      )}
      <Button type="primary" className="mt-3" onClick={handleConfirm}>
        Confirm
      </Button>
      <div className="mt-3">
        <span className="small-titles">Cancellation Policy</span>
        <ul>
          {BookDetail?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
            (item) => (
              <li>
                Cancellation Penalty between{" "}
                {moment(item.start).format("MM/DD/YYYY")} to{" "}
                {moment(item.end).format("MM/DD/YYYY")} is{" "}
                {BookDetail.hotelBooking.rates[0].currency}
                {BookDetail.hotelBooking.rates[0].currency === "USD" &&
                  "$"}{" "}
                {item.estimatedValue}
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}
