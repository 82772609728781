import React, { useContext, useEffect } from "react";
import { Button, Form, Input, notification, Image, Card, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { registerWithEmailAndPassword } from "../../../Auth/firebase";
import {
  getUserRol,
  getUsers,
  postUser,
} from "../../../helpers/requestUserAndRole";
import { SearchContext } from "../../../Context/context";
import { ISearch } from "../../../Models/Search";
import fbicon from "../../../img/facebook.png";
import googleicon from "../../../img/google.png";
import { IAccess, IUsers } from "../../../Models/UsersModel";
import { RoutesPath } from "../../../Routes";
import { sendEmailUserCreated } from "../../../helpers/requestBookings";
import { IWebConfig, IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { getWebConfig } from "../../../helpers/requestSetting";
import { isMobile } from "react-device-detect";

interface SignUpProps {
  setAccesRole: React.Dispatch<React.SetStateAction<IAccess[]>>;
}

const validateMessages = {
  required: "Please input your ${label}",
  types: {
    email: "${label} is not a valid email!",
  },
};

const SignUp = ({ setAccesRole }: SignUpProps) => {
  const navigate = useNavigate();
  const { setSearchContent, searchContent } = useContext<any | ISearch>(
    SearchContext
  );
  const { setWebConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          window.location.replace(`${RoutesPath.home}`);
        } catch (err) {
          console.error(err);
        }
      } else {
        window.location.replace(`${RoutesPath.home}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const account = await signInWithPopup(searchContent.auth, provider);
      const users: IUsers[] = await getUsers();
      const userFound = users.filter(
        (item) => item.userName === account.user.email
      );
      if (userFound.length === 0 && account.user.email) {
        const name = account.user.displayName?.split(" ") || "";
        const obj = {
          userName: account.user.email,
          firstName: name[0] || "",
          lastName: name[1] || "",
          password: undefined,
          roleId: 0,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            notification.success({
              message: "Succces Registred",
              description: "User registerd!",
            });
          window.location.replace(`${RoutesPath.home}`);
        } catch (err) {
          console.error(err);
        }
      } else {
        window.location.replace(`${RoutesPath.home}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlerChangeForm = (type: string) => {
    switch (type) {
      case "sign":
        navigate(RoutesPath.SignIn);
        break;
    }
  };

  const setOnLocalChannel = (
    channel: string,
    webConfigResp: IWebConfig,
    mobile: boolean
  ) => {
    setWebConfigContext({
      webConfig: webConfigResp,
      selectedChannel: channel,
      mobile,
    });
  };

  const setChannelId = async (roles: string, webConfigResp: IWebConfig) => {
    switch (roles) {
      case "SalesPerson":
        localStorage.setItem("UserAuth", "true");
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.Sales,
          webConfigResp,
          true
        );
        break;
      case "MembershipDP":
        localStorage.setItem("UserAuth", "true");
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
          webConfigResp,
          true
        );
        break;
      case "GoogleMeta":
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.GoogleMeta,
          webConfigResp,
          true
        );
        break;
      case "Admin":
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
          webConfigResp,
          true
        );
        return;
      default:
        setOnLocalChannel(
          isMobile
            ? webConfigResp.REACT_APP_CHANNEL_ID.Mobile
            : webConfigResp.REACT_APP_CHANNEL_ID.Organic,
          webConfigResp,
          isMobile
        );
        break;
    }
  };

  const onFinish = async (values: any) => {
    const userCredential = await registerWithEmailAndPassword(
      values.user.email,
      values.user.password,
      searchContent.auth
    );
    if (userCredential) {
      const webConfigResp = await getWebConfig();
      const obj = {
        userName: values.user.email,
        firstName: values.user.firstName,
        lastName: values.user.lastName || "",
        password: values.user.password,
        roleId: 0,
      };
      try {
        const response = await postUser(obj);
        if (response !== null)
          sendEmailUserCreated(
            values.user.email,
            "NewUser",
            values.user.password
          );
        notification.success({
          message: "Succces Registred",
          description: "User registerd!",
        });
        try {
          const responseAcces = await getUserRol(values.user.email);
          setAccesRole(responseAcces.role.access);
          setChannelId(response.roleName, webConfigResp);
        } catch (err) {
          if (webConfigResp)
            setOnLocalChannel(
              webConfigResp.REACT_APP_CHANNEL_ID.Organic,
              webConfigResp,
              isMobile
            );
        }
        navigate(`${RoutesPath.bookings}`);
      } catch (err) {
        console.error(err);
      }
    } else
      notification.error({
        message: "Error",
        description: "Error",
      });
  };

  useEffect(() => {
    if (searchContent.filters) {
      const { filters, ...newObject } = searchContent;
      setSearchContent(newObject);
    }
    if (searchContent.sortOption) {
      setSearchContent({
        ...searchContent,
        sortOption: "Lowest price",
      });
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xs-12 mx-md-auto">
          <Card className="sign-card shadow">
            <Divider orientation="left">
              <h5>Sign Up</h5>
            </Divider>
            <Form
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={["user", "firstName"]}
                rules={[
                  { required: true, message: "Please input your First Name" },
                ]}
              >
                <Input placeholder="First Name" allowClear />
              </Form.Item>
              <Form.Item name={["user", "lastName"]}>
                <Input placeholder="Last Name" allowClear />
              </Form.Item>
              <Form.Item
                name={["user", "email"]}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email",
                  },
                ]}
              >
                <Input placeholder="Email" allowClear />
              </Form.Item>
              <Form.Item
                name={["user", "password"]}
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item
                name={["user", "confirmpassword"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("user").password === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="btn-success-dp btn-block btn-login"
                  htmlType="submit"
                >
                  Create Account
                </Button>
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={signInWithFacebook}
                  >
                    <Image
                      src={fbicon}
                      preview={false}
                      width={20}
                      height={20}
                      className="me-3"
                    />{" "}
                    Sign In with Facebook
                  </button>
                </div>
                <div className="d-grid my-3">
                  <button
                    className="btn btn-light"
                    type="button"
                    onClick={signInWithGoogle}
                  >
                    <Image
                      src={googleicon}
                      preview={false}
                      width={20}
                      height={20}
                      className="me-4"
                    />{" "}
                    Sign In with Google
                  </button>
                </div>
                <div className="d-flex py-3">
                  <small className="pt-2">Already have an account?</small>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => handlerChangeForm("sign")}
                  >
                    Sign in
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
