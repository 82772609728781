import { Col } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { IFacilityGroup } from "../../../../Models/HotelContent";
import FacilityItem from "./FacilityItem";

interface facilitiesProps {
  facilities: Array<IFacilityGroup> | undefined;
}
const FacilitiesDetail = ({ facilities }: facilitiesProps): ReactElement => {
  const [topFacilitiesArr, setTopFacilitiesArr] = useState<IFacilityGroup[]>(
    []
  );
  useEffect(() => {
    if (facilities !== undefined && facilities?.length >= 4) {
      setTopFacilitiesArr(facilities?.slice(0, 4));
    }
  }, [facilities]);

  return (
    <div className="row">
      <Col lg={24}>
        <h4>Top Amenities</h4>
        <div className="row">
          {topFacilitiesArr.map((topFacility, index) => (
            <FacilityItem facility={topFacility.name} key={index} />
          ))}
        </div>
      </Col>
    </div>
  );
};

export default FacilitiesDetail;
