import React from "react";

const TermsConditionsBox = () => {
  return (
    <div className="checkout-boxes">
      <p>
        <b>By selecting 'Complete Booking',</b> I consent to the&nbsp;
        <a
          href={`${process.env.REACT_APP_API_BASE_WP_URL}/traveler-services-agreement/`}
          target="_blank"
          rel="noreferrer"
          className="font-weight-bold"
        >
          travel services agreement
        </a>
        ,
        <a
          href={`${process.env.REACT_APP_API_BASE_WP_URL}/privacy-policy/`}
          target="_blank"
          rel="noreferrer"
          className="font-weight-bold"
        >
          &nbsp;privacy policy
        </a>
        , and{" "}
        <a
          href={`${process.env.REACT_APP_API_BASE_WP_URL}/terms-conditions/`}
          target="_blank"
          rel="noreferrer"
          className="font-weight-bold"
        >
          Membership terms and conditions
        </a>
        . In order to access the special reservation rate, I'll join DealPeak
        Gold as part of my booking, complete with a 60-day free trial. I
        acknowledge that at the trial's conclusion, roughly 60 days from now, my
        payment method will be billed $69 for the annual DealPeak Gold
        subscription. This membership auto-renews annually at the then-current
        rate. Should I choose, I can cancel and prevent further charges anytime
        by calling 1-800-919-2339 or through my DealPeak account online. Iowa
        residents are not eligible for this offer
      </p>
    </div>
  );
};

export default TermsConditionsBox;
