import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Divider, Popconfirm, Row, Space } from "antd";
import React, { ReactElement } from "react";
import { ILocationRadius } from "../../../Models/LocationRadius";

interface BookCardProps {
  locationRadius: ILocationRadius;
  handleEdit: (locRad: ILocationRadius) => void;
  handleDelete: (locRad: ILocationRadius) => void;
}

const LocationRadiusCard = ({
  locationRadius,
  handleEdit,
  handleDelete,
}: BookCardProps): ReactElement => (
  <Card className="card rounded-dp shadow-lg booking-card">
    <Row justify="space-between">
      <Col style={{ marginLeft: "10%" }}>
        <h4 style={{ textAlign: "left" }}>{locationRadius.name}</h4>
      </Col>
      <Col>
        <Space size="small">
          <button
            className="btn btn-success-dp-xs"
            type="button"
            aria-hidden="true"
            onClick={() => handleEdit(locationRadius)}
          >
            <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
            Edit
          </button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(locationRadius)}
          >
            <Button
              htmlType="button"
              type="dashed"
              shape="round"
              aria-hidden="true"
              icon={
                <FontAwesomeIcon icon={faClose as IconProp} className="me-2" />
              }
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      </Col>
    </Row>

    <Divider orientation="left">ID</Divider>
    <p className="booking-card-info">{locationRadius.id}</p>
    <Divider orientation="left">Geom</Divider>
    <p className="booking-card-info">{locationRadius.geomText}</p>
    <Divider orientation="left">Radius in KM</Divider>
    <p className="booking-card-info">{locationRadius.radiusInKm}</p>
  </Card>
);
export default LocationRadiusCard;
