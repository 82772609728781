import React from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Auth } from "firebase/auth";
import { sendPasswordReset } from "../../../Auth/firebase";
import { RoutesPath } from "../../../Routes";

const validateMessages = {
  required: "Please input your ${label}",
  types: {
    email: "${label} is not a valid email!",
  },
};

interface props {
  auth: Auth;
}

const Reset = ({ auth }: props) => {
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    await sendPasswordReset(values.user.email, auth);
    navigate(RoutesPath.root);
  };

  return (
    <Form
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["user", "email"]}
        rules={[{ type: "email", required: true }]}
      >
        <Input placeholder="Enter your email" allowClear />
      </Form.Item>
      <Form.Item>
        <Button
          className="btn-success-dp btn-block btn-login"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Reset;
