import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const TagFeature = (): ReactElement => (
  <div className="tag-wrapper">
    <span className="tag-recomended">
      <FontAwesomeIcon icon={faAward as IconProp} className="me-3" />
      Recomended
    </span>
  </div>
);
export default TagFeature;
