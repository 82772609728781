import React, { ReactElement } from "react";
import { Divider, Col, Row, Card } from "antd";
import ContentCardReview from "./ContentCardReview";
import HeaderCardReview from "./HeaderCardReview";
import { IReview } from "../../../../../Models/Review";

interface IProp {
  review: IReview;
}
const ReviewItem = ({ review }: IProp): ReactElement => (
  <>
    <div className="my-2 review-item">
      <div className="row">
        <Card>
          <Row>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <HeaderCardReview
                rate={review.rating}
                reviewer={review.reviewer_name}
                date={review.date_submitted}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-8 col-lg-9">
              <ContentCardReview contentText={review.text} />
            </div>
          </Row>
        </Card>
      </div>
    </div>
    <Divider />
  </>
);
export default ReviewItem;
