import APIClient from "../Components/Services/APIClient";
import { ICampaignProduct } from "../Models/Checkout";
import { IMembershipObject, IOrderResponse } from "../Models/OrderResponse";
import { getConfig } from "./configHelper";
import { getCampaignSetting } from "./requestSetting";

export interface Product {
  product_id: string;
  product_name: string;
  product_description: string;
  product_sku: string;
  product_price: number;
  product_category_name: string;
  vertical_name: string;
  product_is_shippable: string;
  product_max_quantity: number;
  cost_of_goods_sold: number;
  campaign_id: string;
  product_rebill_product: string | null;
  product_rebill_days: string | null;
  product_rebill: Product | null;
}

export interface Shipping {
  shipping_id: string;
  shipping_name: string;
  shipping_description: string;
  shipping_initial_price: string;
  shipping_recurring_price: string;
}

export interface CampaignView {
  response_code: string;
  campaign_name: string;
  campaign_description: string;
  gateway_id: string;
  is_payment_routed: string;
  payment_router_id: string;
  countries: string[];
  payment_name: string[];
  products: Product[];
  shipping: Shipping[];
}

export interface INewOrderRequest {
  billingSameAsShipping: string;
  firstName: string;
  lastName: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  shippingCountry: string;
  phone: string;
  email: string;
  creditCardType: string;
  creditCardNumber: string;
  expirationDate: string;
  cvv: string;
  tranType: string;
  ipAddress: string;
  productId: string | undefined;
  CampaignId: string | undefined;
  shippingId: number;
  quantity: string;
  notes: string;
  c2: string | undefined;
  isRetry?: boolean;
  c3?: string;
  dynamic_product_price_696?: number;
}

export interface orderIDs {
  ordersId: string[];
}

export const getProductsByCampaignId = async (
  campaignId: number
): Promise<CampaignView> => {
  const client = new APIClient();
  const response = await client.client.post<CampaignView>(
    "Membership/products_by_campaign_id",
    {
      campaignId,
    },
    await getConfig("")
  );
  return response.data;
};

export const newOrder = async (
  newOrderRequest: INewOrderRequest
): Promise<IOrderResponse> => {
  const client = new APIClient();
  const response = await client.client.post(
    "Membership/new_order",
    newOrderRequest
  );
  return response.data;
};

export const postMembership = async (orderIds: orderIDs): Promise<void> => {
  const client = new APIClient();
  const response = await client.client.post(
    "Membership/register_membership",
    orderIds
  );
  return response.data;
};

export const getMembershipStatus = async (
  username: string
): Promise<IMembershipObject[]> => {
  const client = new APIClient();
  const response = await client.client.get(
    `Membership/orders_by_user/${username}`
  );
  return response.data;
};

export const getProducts = async (
  products: ICampaignProduct[]
): Promise<Product[]> => {
  const client = new APIClient();
  const response = await client.client.post(
    `Membership/get_products`,
    products
  );
  return response.data;
};

export const getMemberships = async () => {
  const campaignSetting = await getCampaignSetting();
  const campaigns = JSON.parse(campaignSetting.value);
  const productsResult: ICampaignProduct[] = campaigns.map((item: any) => ({
    productId: item.productId,
  }));
  let products = await getProducts(productsResult);
  const result: Product[] = products.map((item: any) => ({
    ...item,
    campaign_id: "269",
  }));
  return result;
};
