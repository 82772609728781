import React, { ReactElement } from "react";
import { IPriceResponse } from "../../../../../Models/Checkout";
import moment from "moment";

interface props {
  price: IPriceResponse;
}

const CancellationPolicyCheckout = ({ price }: props): ReactElement => {
  return (
    <>
      <div className="mb-2">
        <span className="form-titles">Important Information</span>
      </div>
      <div>
        <span className="small-titles">Cancellation Policy</span>
      </div>
      <>
        {price &&
          price.hotel &&
          price?.hotel.rates &&
          price?.hotel.rates.length > 0 &&
          price?.hotel.rates[0].cancellationPolicies &&
          price?.hotel.rates[0].cancellationPolicies.length > 0 &&
          price?.hotel.rates[0].cancellationPolicies[0].rules &&
          price?.hotel.rates[0].cancellationPolicies[0].rules.length > 0 &&
          price?.hotel.rates[0].cancellationPolicies[0].rules.map(
            (item, index) => (
              <li key={index}>
                <small>
                  Cancellation Penalty between{" "}
                  <b>{moment(item.start).format("MM/DD/YYYY")}</b> to{" "}
                  <b>{moment(item.end).format("MM/DD/YYYY")}</b> is{" "}
                  <b>
                    {price.currency}
                    {price.currency === "USD" && "$"}{" "}
                    {item.estimatedValue.toFixed(2)}
                  </b>
                </small>
              </li>
            )
          )}
      </>
    </>
  );
};

export default CancellationPolicyCheckout;
