export const getCreditCardType = (issuer: string) => {
  switch (issuer) {
    case "vi":
      return "visa";
    case "ca":
      return "master";
    case "ds":
      return "discover";
    case "ax":
      return "amex";
    default:
      return issuer;
  }
};
