import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getIp } from "../../helpers/configHelper";

export default class APIClient {
  client: AxiosInstance;

  constructor(
    url: string = process.env.REACT_APP_API_BASE_URL as string,
    apiKey: string = process.env.REACT_APP_DEALPEAK_AUTH_API_KEY as string
  ) {
    this.client = axios.create({
      baseURL: url,
      headers: {
        Authorization: apiKey,
        "customer-ip": localStorage.getItem("ipPublic") ?? "",
      },
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );
  }

  async options<T>(path: string): Promise<T> {
    const response = await this.client.options<T>(path);
    return response.data;
  }

  protected async get<T>(path: string): Promise<AxiosResponse<T>> {
    this.client.defaults.headers.get["customer-ip"] = await getIp();
    return this.client.get<T, AxiosResponse<T>>(path);
  }

  protected async post<T>(
    path: string,
    data?: unknown,
    config?: any
  ): Promise<AxiosResponse<T>> {
    this.client.defaults.headers.post["customer-ip"] = await getIp();
    return this.client.post<T, AxiosResponse<T>>(path, data, config);
  }

  protected async delete<T>(path: string): Promise<AxiosResponse<T>> {
    return this.client.delete<T, AxiosResponse<T>>(path);
  }

  protected async put<T>(
    path: string,
    data?: unknown
  ): Promise<AxiosResponse<T>> {
    this.client.defaults.headers.put["customer-ip"] = await getIp();
    return this.client.put<T, AxiosResponse<T>>(path, data);
  }

  private handleError(error: any): Promise<never> {
    if (error === null || error === undefined) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
}
