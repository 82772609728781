import React, { ReactElement, useContext } from "react";
import { Alert, Divider } from "antd";
import { useSearchParams } from "react-router-dom";
import { IImage, IPolicy } from "../../../../Models/HotelContent";
import { IRate, IRecommendation, IRoom } from "../../../../Models/RoomDetail";
import RoomCard from "./RoomCard";
import { IFakePrice } from "../../../../Models/GooglePrice";
import {
  totalAdults,
  totalChildrens,
} from "../../../../helpers/occupanciesCalc";
import { SearchContext } from "../../../../Context/context";
import { ISearch } from "../../../../Models/Search";

type ContextType = {
  searchContent: ISearch;
  setSearchContent: React.Dispatch<
    React.SetStateAction<ISearch | React.Context<{} | ISearch>>
  >;
};

interface RoomsProps {
  correlationId: string;
  deeplink: string | undefined;
  rooms?: Array<IRoom>;
  rates: IRate[];
  recommendations: IRecommendation[];
  defaultImage: IImage[] | undefined;
  fakePrice: IFakePrice | undefined;
  hotelDetailPolicy?: IPolicy[];
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setcheckoutUrl: React.Dispatch<React.SetStateAction<string>>;
  setSelectedRate: React.Dispatch<React.SetStateAction<IRate | undefined>>;
  showTotalNightly: boolean;
  sFakePrice: IFakePrice | undefined;
  isIpExcempt: boolean;
}

const Rooms = ({
  correlationId,
  deeplink,
  defaultImage,
  fakePrice,
  hotelDetailPolicy,
  rooms,
  rates,
  recommendations,
  setOpenDrawer,
  setcheckoutUrl,
  setSelectedRate,
  showTotalNightly,
  sFakePrice,
  isIpExcempt,
}: RoomsProps): ReactElement => {
  const { searchContent, setSearchContent } = useContext(
    SearchContext
  ) as ContextType;
  const getRatesInfo = (id: string) => {
    const matchedRateIds = new Set<string>();
    const ratesDetail = rates.filter((res) => res.occupancies[0].roomId === id);

    recommendations.forEach((recommendation) => {
      recommendation.rates.forEach((rateId) => {
        ratesDetail.forEach((rate) => {
          if (rate.id === rateId) {
            matchedRateIds.add(rate.id);
          }
        });
      });
    });

    const rateWithRecommID = ratesDetail.filter((rate) =>
      matchedRateIds.has(rate.id)
    );
    return rateWithRecommID;
  };

  const [searchParams] = useSearchParams();

  // const unique = rooms?.filter(
  //   (v, i, a) =>
  //     v.roomId === fakePrice?.roomId ||
  //     a.findIndex((v2) => v2.name === v.name) === i
  // );

  return (
    <div className="my-3 p-2">
      <h6 className="room-option-title">ROOM OPTIONS</h6>
      <span className="occupancies-display">
        {searchContent.occupancies.length} Room,{" "}
        {totalAdults(searchContent.occupancies)} Adults,{" "}
        {totalChildrens(searchContent.occupancies)}{" "}
        {totalChildrens(searchContent.occupancies) === 1 ? "Child" : "Children"}
      </span>
      <Divider />
      {rooms !== undefined && rooms?.length > 0 ? (
        rooms?.map(
          (room, index) =>
            getRatesInfo(room.id).length > 0 && (
              <div key={index}>
                <div
                  id={index + 1 === rooms?.length ? "amenities-section" : ""}
                />
                <RoomCard
                  setSelectedRate={setSelectedRate}
                  setcheckoutUrl={setcheckoutUrl}
                  setOpenDrawer={setOpenDrawer}
                  room={room}
                  rates={getRatesInfo(room.id)}
                  recommendation={recommendations}
                  correlationId={correlationId}
                  hotelDetailPolicy={hotelDetailPolicy}
                  deeplink={deeplink}
                  defaultImage={defaultImage}
                  key={index}
                  numOfAdults={
                    room.MaxAdultAllowed !== 0
                      ? room.MaxAdultAllowed
                      : parseInt(searchParams.get("adults") || "", 10)
                  }
                  numOfChildren={
                    room.MaxChildrenAllowed !== 0
                      ? room.MaxChildrenAllowed
                      : (searchParams.get("children") || "")
                          .split(",")
                          .filter((child: string) => !isNaN(parseInt(child)))
                          .length || 0
                  }
                  fakePrice={fakePrice}
                  provider={getRatesInfo(room.id)[0].providerId || ""}
                  rawRates={rates}
                  showTotalNightly={showTotalNightly}
                  sFakePrice={sFakePrice}
                  isIpExcempt={isIpExcempt}
                />
              </div>
            )
        )
      ) : (
        <Alert
          message={<h3>No rooms available</h3>}
          description={
            <strong>The are not available rooms for this hotel.</strong>
          }
          type="warning"
          showIcon
        />
      )}
    </div>
  );
};
export default Rooms;
