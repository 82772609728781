import React, { ReactElement } from "react";
import { Divider, Image } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IAddress } from "../../../../Models/HotelContent";
import { IBookingCancelReason } from "../../../../Models/BookDetailRequest";

interface props {
  image?: string;
  address?: IAddress;
  name?: string;
  checkin?: string;
  checkout?: string;
  totalrate?: number;
  currency?: string;
  creationDate?: string;
  bookingCancelReason?: IBookingCancelReason | undefined;
}

const SideInformationBook = ({
  image,
  address,
  name,
  checkin,
  checkout,
  totalrate,
  currency,
  creationDate,
  bookingCancelReason,
}: props): ReactElement => (
  <>
    <div className="sidebar_form--div">
      <div className="px-3 pt-4 sidebar_image-grid">
        <Image width={130} src={image} className="sidebar_image" />
        <div>
          <span className="name_city-sidebar">{name}</span>
          <div className="city-string">
            <FontAwesomeIcon icon={faLocationDot as IconProp} />
            <span>
              {address?.line1}, {address?.city.name}, {address?.country.name}
            </span>
          </div>
        </div>
      </div>
      {bookingCancelReason?.reason && (
        <>
          <Divider />
          <div className="px-3 sidebar-containers">
            <p>Cancel Reason</p>
            <p>{bookingCancelReason?.reason}</p>
          </div>
        </>
      )}
      <Divider />
      <div className="px-3 sidebar-containers">
        <p>Creation Date</p>
        <p>{creationDate}</p>
      </div>
      <Divider />
      <div className="px-3 sidebar-containers">
        <p>Trip Date</p>
        <p>
          {checkin?.slice(5, 7)}/{checkin?.slice(8, 10)}/{checkin?.slice(0, 4)}
          <strong> to </strong>
          {checkout?.slice(5, 7)}/{checkout?.slice(8, 10)}/
          {checkout?.slice(0, 4)}
        </p>
      </div>
      <Divider />
    </div>
    <div className="sidebar_form--div mt-3 total-cost_form p-4">
      <p>Total Cost</p>
      <p>
        {currency} {currency === "USD" ? "$" : " "}
        {totalrate?.toFixed(2) || ""}
      </p>
    </div>
  </>
);

export default SideInformationBook;
