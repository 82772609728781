import { CardData } from "../Models/PerksModel";

export const cardData: CardData[] = [
  {
    mainImg:
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/discounted-cruises.png",
    title: "Discounted Cruises",
    icons: [
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Celebrity-Cruises-Logo.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/DLC_Upscaled.webp",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Oceania_cruises_logo.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/lg-princess-cruises-introduces-the-new-princess-promise.jpg.coredownload.jpg",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/RCL_BIG-afc83cfb.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Logo-Carnival-Cruise-Lines.jpg",
    ],
    url: "https://cruisesandvacations.globaltravel.com/Page/Cruises?host=nx30817.cruisesandvacations.globaltravel.com",
  },
  {
    mainImg:
      "https://storage.googleapis.com/imagebucketdealpeak/roller-coaster.png",
    title: "Theme Parks & Attractions",
    icons: [
      "https://storage.googleapis.com/imagebucketdealpeak/1024px-Legoland_logo.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/2560px-Disney_Parks.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/Universal_Pictures_logo.png",
    ],
    url: "https://16306.partner.viator.com/",
  },
  {
    mainImg:
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/car-rental%20(1).png",
    title: "Wholesale Rental Cars",
    icons: [
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Alamo_Rent_a_Car_(logo).svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Avis-Logo.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Enterprise_Rent-A-Car_Logo.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Thrifty-car-rental-logo.jpg",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/national-cart-rental.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/2560px-Hertz_Logo.svg.png",
    ],
    url: "https://rezervco.carhire-solutions.com/",
  },
  {
    mainImg:
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/flight-3.png",
    title: "Flights",
    icons: [
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/2560px-Spirit_Airlines_logo.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/American-Airlines-Logo-2.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/JetBlue_Airways_Logo.svg.png",
      "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/United-Airlines-Logo-2.png",
    ],
    url: "https://secure.rezserver.com/?refid=3131&refclickid=GT20025536",
  },
];

export const bottomCardData = {
  mainImg:
    "https://storage.googleapis.com/imagebucketdealpeak/tropical-resort.jpg",
  title: "Discounted All-Inclusive Resorts",
  icons: [
    "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Image_2_ID_dc187965c845_Logo.jpg",
    "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/zoetry-welness-spa-resorts8103.jpg",
    "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/screenshot_2023_01_31_161429_d27345675628ff93263312829cd161a1c284d3c0.webp",
    "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/clubmed.png",
    "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/dreams-logo-B1D5E18FC3-seeklogo.com.png",
  ],
  url: " https://www.funjet.com/?plcode=10623900#!/",
};

export const bottomCardIcons = [
  "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/Image_2_ID_dc187965c845_Logo.jpg",
  "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/zoetry-welness-spa-resorts8103.jpg",
  "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/screenshot_2023_01_31_161429_d27345675628ff93263312829cd161a1c284d3c0.webp",
  "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/clubmed.png",
  "https://storage.googleapis.com/imagebucketdealpeak/MemberPerksResources/dreams-logo-B1D5E18FC3-seeklogo.com.png",
];
