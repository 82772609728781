import { Col } from "antd";
import moment from "moment";
import React, { ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import { useViewport } from "../../../Context/useViewport";
import DealMakerLogo from "../../../img/dealmaker.png";
import SupplierLabel from "../InformationHotelSection/SupplierLabel";

interface PriceSectionProps {
  rate: number;
  supplier: string;
  discount: number;
  clickNavigate: () => void;
}

const PriceSection = ({
  rate,
  supplier,
  discount,
  clickNavigate,
}: PriceSectionProps): ReactElement => {
  const { width } = useViewport();
  const [searchParams] = useSearchParams();
  const checkIn = searchParams.get("checkin");
  const checkOut = searchParams.get("checkout");

  const check = moment(checkIn);
  const transformCheckOut = moment(checkOut, "YYYY-MM-DD");
  const check2 = moment(transformCheckOut);
  const numberNigths = Number.isNaN(Math.abs(check.diff(check2, "days")))
    ? 1
    : Math.abs(check.diff(check2, "days"));
  const labelNights = numberNigths > 1 ? "nights" : "night";

  return (
    <Col xs={24} md={8} lg={6} className="price-section">
      <div className="price-container">
        <div
          className="price-wrapper"
          style={{
            paddingTop: discount > 0 ? "7rem" : "",
          }}
        >
          <SupplierLabel supplier={supplier} className="hotel-result-mobile" />
          {discount > 0 && <h5 className="discount">Save {discount}%</h5>}

          <div className="first">
            <span className="currency">$</span>
            <h3 className="price">{Math.trunc(rate / numberNigths)} </h3>
          </div>
          <div className="text-center">
            <small className="price-section-label fw-bold">
              {width < 480
                ? `$${Math.trunc(rate)} for ${numberNigths} ${labelNights}`
                : `${
                    numberNigths === 1
                      ? `price per night`
                      : `$${Math.trunc(
                          rate
                        )} for ${numberNigths} ${labelNights}`
                  } `}
            </small>
          </div>
        </div>
        <div className="button-section-price mt-3">
          <button
            className="btn btn-success-dp"
            type="button"
            onClick={clickNavigate}
          >
            Choose your room
          </button>
        </div>
      </div>
    </Col>
  );
};
export default PriceSection;
