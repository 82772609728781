const DataFaqs: any[] = [
  {
    category: "General",
    questions: [
      {
        question: "I’ve been charged. Do I need to do anything?",
        request:
          "No. Your room is allocated and you will receive a confirmation. Please note your room may not be INSTANTLY confirmed with the hotel. Do not be concerned. Hotels confirm their check-in lists in the order of notification from the supplier as well as are prioritized by check-in date.",
        category: "General",
      },
      {
        question: "Where can I see the payment policy for my booking?",
        request: "Payment policy is located on the checkout page.",
        category: "General",
      },
      {
        question:
          "What’s the difference between a Double room and a Twin room?",
        request:
          "Room types are described to give you the level of comfort in each of the rooms available. Typically the room names are related to typical bed types similar to a double, a twin or a king-size bed.",
        category: "General",
      },
      {
        question: "What do “non-refundable” and “free cancellation” mean?",
        request:
          "Non-refundable means there is no refund upon booking of the hotel. Free cancellation means there is a free cancellation associated with the booking as specified and determined by the cancellation policy and check-in date.",
        category: "General",
      },
      {
        question:
          "Can I request an extra bed in my room and will there be extra costs?",
        request:
          "Please check with the hotel directly for information about additional room fees.",
        category: "General",
      },
    ],
  },
  {
    category: "Billing",
    questions: [
      {
        question: "Is breakfast included in the price?",
        request: "Rates typically only included hotel unless otherwise noted.",
        category: "Billing",
      },
      {
        question: "What does the price include?",
        request: "Pricing includes the stay for the room.",
        category: "Billing",
      },
      {
        question: "Are taxes included in the price?",
        request: "At checkout all applicable taxes will be shown.",
        category: "Billing",
      },
      {
        question: "Are there additional taxes or fees I should know about?",
        request:
          "Resort fees may or may not be included in your payment today. In most cases, resort fees are paid to the resort while at the property. Fees for service as well as a booking fee may be included in your booking.",
        category: "Billing",
      },
    ],
  },
  {
    category: "Payment Methods",
    questions: [
      {
        question: "What payment methods are accepted?",
        request:
          "We accept Visa, MasterCard, American Express & Discover Cards.",
        category: "Payment Methods",
      },
      {
        question: "Can I pay with a deposit or prepayment?",
        request:
          "In most cases we only accept prepayment please refer to the hotel payment type upon checking.",
        category: "Payment Methods",
      },
      {
        question: "Why do I need to provide my card details?",
        request:
          "In order to confirm your booking and lock in your price you must provide your payment details.",
        category: "Payment Methods",
      },
      {
        question: "Can I use a debit card to complete my reservation?",
        request: "Yes.",
        category: "Payment Methods",
      },
      {
        question: "Can I make a reservation without a credit card?",
        request: "In most cases all hotel bookings require a credit card.",
        category: "Payment Methods",
      },
      {
        question:
          "Can I make a reservation for myself using someone else’s credit card?",
        request:
          "Only if you are authorized to do so and the person staying in the room is the same name on the card.",
        category: "Payment Methods",
      },
    ],
  },
  {
    category: "Cancellation",
    questions: [
      {
        question: "Can I cancel my booking?",
        request:
          "In most cases you can cancel your booking. Go to your bookings by clicking the button and click cancel my booking.",
        category: "Cancellation",
      },
      {
        question: "If I need to cancel my booking, will I pay a fee?",
        request:
          "You will need to refer to the cancellation policy of the room you have booked located on your confirmation email or in your bookings on the website.",
        category: "Cancellation",
      },
      {
        question:
          "Can I cancel or change my dates for a non-refundable booking?",
        request:
          "In most cases if you have booked a non-refundable room type your room is not refundable – it can be canceled but you will not receive your money back.",
        category: "Cancellation",
      },
      {
        question: "Where can I find my property’s cancellation policy?",
        request:
          "The cancellation policy is located on the room selection screen as well as the checkout page and also your booking confirmation and booking history pages.",
        category: "Cancellation",
      },
    ],
  },
  {
    category: "Booking",
    questions: [
      {
        question:
          "How do I get more information about the room or property’s facilities?",
        request:
          "If you look under the hotel amenities and hotel details section of our website you will find more information. This information is also provided on the room selection section of the page.",
        category: "Booking",
      },
      {
        question: "Is it possible to get an extra bed or cot for a child?",
        request: "Please give us a call.",
        category: "Booking",
      },
      {
        question: "How can I get an invoice?",
        request:
          "You will get a copy of the invoice from the email automatically when you make the hotel booking. Final invoices can be asked for once your hotel is completed at the hotel at the checkout counter.",
        category: "Booking",
      },
      {
        question: "I can’t find my confirmation email. What should I do?",
        request:
          "Go to your bookings tab on our website and click on the hotel booking associated with the hotel and your confirmation will be located there.",
        category: "Booking",
      },
      {
        question: "Will I pay the full price for my children?",
        request:
          "Depending on the location and hotel policy you may or may not be required to pay an additional cost for children. In most cases, occupancy is listed for each hotel room on the booking page and will disclaim the max occupancy of the room.",
        category: "Booking",
      },
    ],
  },
];
export default DataFaqs;
