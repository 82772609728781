import React, { useEffect, useState } from "react";
import HotelCardLanding from "./HotelCard";
import { getByName } from "../../../helpers/requestSetting";

const DealsSection = () => {
  const [hotelIDs, setHotelIDs] = useState<string[]>();
  const [isLoading, setisLoading] = useState(true);

  const init = async (): Promise<void> => {
    setisLoading(true);
    try {
      const hotelIDs = await getByName("LandingPageHotels");
      setHotelIDs(
        hotelIDs.value.split('","').map((value) => value.replace(/"/g, ""))
      );
    } catch (error) {
      console.error(error);
    }
    setisLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className="deal-section-bg">
          <div className="deal-section-container">
            <h1>Member Only Deals</h1>
            <h3 id="save-up-landing">Save up to 70% on hotels</h3>
            <div className="offers-section">
              {hotelIDs &&
                hotelIDs.length > 0 &&
                hotelIDs.map((id: string) => <HotelCardLanding id={id} />)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DealsSection;
