import React, { useEffect } from "react";
import { useScrollLock } from "../../../helpers/scrollLock";

interface IProps {
  handleClick: (id: number) => unknown;
}

const City = ({ handleClick }: IProps) => {
  const { unlockScroll } = useScrollLock();
  useEffect(() => {
    unlockScroll();
  }, []);

  return (
    <div className="container my-4">
      <h2>Discover deals in every city</h2>
      <div className="photo-layout">
        <div className="column-photo-layout">
          <a href="#" onClick={() => handleClick(0)} className="city-image">
            <h4 className="text-overlay">Las Vegas</h4>

            <img
              className="img-fluid featured-img"
              alt="example"
              src="https://storage.googleapis.com/imagebucketdealpeak/las-vegas-g8409198b2_1920.jpg"
            />
          </a>
          <a href="#" onClick={() => handleClick(4)} className="city-image">
            <h4 className="text-overlay">Miami</h4>

            <img
              className="img-fluid featured-img"
              alt="example"
              src="https://storage.googleapis.com/imagebucketdealpeak/miami-gf84e7c61b_1920.jpg"
            />
          </a>
        </div>
        <div className="column-photo-layout">
          <a href="#" onClick={() => handleClick(3)} className="city-image">
            <h4 className="text-overlay">Los Angeles</h4>

            <img
              className="img-fluid featured-img"
              alt="example"
              src="https://storage.googleapis.com/imagebucketdealpeak/highway-gfd0cf7bce_1920.jpg"
            />
          </a>
          <a href="#" onClick={() => handleClick(1)} className="city-image">
            <h4 className="text-overlay">Dubai</h4>

            <img
              className="img-fluid featured-img"
              alt="example"
              src="https://storage.googleapis.com/imagebucketdealpeak/burj-khalifa-g041563f14_1920.jpg"
            />
          </a>
        </div>
        <div className="column-photo-layout">
          <a href="#" onClick={() => handleClick(5)} className="city-image">
            <h4 className="text-overlay">Barcelona</h4>

            <img
              className="img-fluid featured-img"
              alt="example"
              src="https://storage.googleapis.com/imagebucketdealpeak/barcelona-838716_1280.jpg"
            />
          </a>

          <a href="#" onClick={() => handleClick(6)} className="city-image">
            <h4 className="text-overlay">New York</h4>

            <img
              className="img-fluid featured-img"
              alt="example"
              src="https://storage.googleapis.com/imagebucketdealpeak/buildings-gd36a7687e_1920.jpg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default City;
