import React, { useState, useEffect, ReactElement, useContext } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Table,
  Modal,
  Button,
  Input,
  Radio,
  Space,
  DatePicker,
  Pagination,
  Alert,
  Menu,
  Divider,
  Switch,
  Spin,
  Checkbox,
  CheckboxOptionType,
  Dropdown,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faClose,
  faSearch,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import type { ColumnsType } from "antd/es/table";
import {
  cancelBooking,
  cancelBookingReason,
  getBookingDetails,
  getBookings,
  getBookingsAdmin,
  postCancelBooking,
  postCancelReasonBooking,
  sendEmail,
} from "../../../helpers/requestBookings";
import {
  IBookingsList,
  IBookingsListResponse,
  IBookTransform,
} from "../../../Models/BookingsList";
import LoaderComponent from "../../../Components/LoaderComponent";
import { IBookDetailResponse, Rule } from "../../../Models/BookDetailRequest";
import BookingCard from "../../../Components/BookingCard";
import { useViewport } from "../../../Context/useViewport";
import { IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";
import { getWebConfig } from "../../../helpers/requestSetting";
import { EBookingSatus } from "../../../Common/Enums";
import { exportToCSV } from "../../../helpers/csvConverter";
import { formatDateStringWithAMPM } from "../../../helpers/validationsInputs";
import PriceMatchComponent from "../../../Components/ExpandableRowBookList";

const BookListAdmin = (): ReactElement => {
  const { TextArea } = Input;
  interface DataType {
    key: React.Key;
    reason: string;
  }
  const columnsCancel: ColumnsType<DataType> = [
    {
      title: "Why are you cancelling?",
      dataIndex: "reason",
      render: (text) => <span className="mx-2">{text}</span>,
    },
  ];

  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("user");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterObject, setfilterObject] = useState({
    HotelName: "",
    BookingStatus: "",
    BookingId: "",
    ProviderConfirmationNumber: "",
    HotelId: "",
    CheckInDateInit: "",
    CheckInDateEnd: "",
    CheckOutDateInit: "",
    CheckOutDateEnd: "",
    CreationDateInit: "",
    CreationDateEnd: "",
    BoughtMembership: "",
    userId: "",
    sortDirection: "",
    sortField: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [bookId, setBookId] = useState<string>("");
  const [correlationId, setcorrelationId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [bookTransform, setbookTransform] = useState<IBookTransform[]>([]);
  const [isModalVisiblemail, setIsModalVisiblemail] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [bookInformation, setbookInformation] = useState<IBookDetailResponse>();
  const [error, setError] = useState(false);
  const [email, setemail] = useState("");
  const [selectedReason, setSelectedReason] = useState<string>();
  const [otherReason, setOtherReason] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const columns = [
    {
      title: "Booking ID",
      dataIndex: "bookId",
      alwaysShow: true,
      key: "bookId",
      width: 130,
      sorter: () => 0,
      filterDropdown: () => returnSearchBookID(),
      filterIcon: () => filterIconSearch(),
      render: (id: string) => (
        <button
          type="button"
          className="btn btn-primary"
          aria-hidden="true"
          onClick={() => handleNavigate(id)}
        >
          #{id}
        </button>
      ),
      hidden: false,
    },
    {
      title: "Confirmation Number",
      dataIndex: "providerId",
      key: "providerId",
      sorter: () => 0,

      filterDropdown: () => returnSearchConfirmNumber(),
      filterIcon: () => filterIconSearch(),
      width: 140,
      hidden: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      sorter: () => 0,
      className: "statusCell",
      filterDropdown: () => statusFilter(),
      hidden: false,
    },
    {
      title: "Hotel ID",
      dataIndex: "hotelId",
      key: "hotelId",
      sorter: () => 0,
      width: 110,
      filterDropdown: () => returnSearchHotelID(),
      filterIcon: () => filterIconSearch(),
      hidden: false,
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelname",
      key: "hotelname",
      width: 180,
      sorter: () => 0,
      filterDropdown: () => returnSearch(),
      filterIcon: () => filterIconSearch(),
      hidden: false,
    },
    {
      title: "Check In",
      dataIndex: "checkIn",
      key: "checkIn",
      width: 140,
      sorter: () => 0,
      filterDropdown: () => returnSearchCheckIn(),
      filterIcon: () => filterIconSearch(),
      render: (value: string) =>
        value ? new Date(value).toLocaleDateString("en-US") : "",
      hidden: false,
    },
    {
      title: "Check Out",
      dataIndex: "checkOut",
      key: "checkOut",
      width: 140,
      sorter: () => 0,
      filterDropdown: () => returnSearchCheckOut(),
      filterIcon: () => filterIconSearch(),
      render: (value: string) =>
        value ? new Date(value).toLocaleDateString("en-US") : "",
      hidden: false,
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 140,
      sorter: () => 0,
      filterDropdown: () => returnSearchCreationDate(),
      filterIcon: () => filterIconSearch(),
      render: (value: string) => formatDateStringWithAMPM(value),
      hidden: false,
    },
    {
      title: "Is Mobile",
      dataIndex: "isMobile",
      key: "isMobile",
      sorter: () => 0,
      render: (value: boolean) => <Switch checked={value} disabled />,
      width: 80,
      hidden: false,
    },
    {
      title: "Channel Id",
      dataIndex: "channelId",
      key: "channelId",
      width: 80,
      sorter: () => 0,
      hidden: false,
    },
    {
      title: "Total Rate",
      dataIndex: "totalRate",
      key: "totalRate",
      width: 250,
      render: (value: number | null) => (value !== null ? `$ ${value}` : "-"),
      sorter: () => 0,
      align: "right" as "right",
      hidden: false,
    },
    {
      title: "Base Rate",
      dataIndex: "baseRate",
      key: "baseRate",
      width: 250,
      render: (value: number | null) => (value !== null ? `$ ${value}` : "-"),
      sorter: () => 0,
      align: "right" as "right",
      hidden: false,
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      width: 250,
      render: (value: number | null) => (value !== null ? `$ ${value}` : "-"),
      sorter: () => 0,
      align: "right" as "right",
      hidden: false,
    },
    {
      title: "Fee Charged",
      dataIndex: "feeCharged",
      key: "feeCharged",
      width: 100,
      render: (value: number | null) =>
        value !== null && value !== undefined ? `$ ${value}` : "-",
      sorter: () => 0,
      align: "right" as "right",
      hidden: false,
    },
    {
      title: "Fee State",
      dataIndex: "feeState",
      key: "feeState",

      render: (value: string | null) =>
        value !== null && value !== undefined ? ` ${value}` : "-",
      hidden: false,
    },
    {
      title: "Is Member",
      dataIndex: "isMember",
      key: "isMember",
      render: (value: boolean) => <Switch checked={value} disabled />,
      width: 80,
      sorter: () => 0,
      hidden: false,
    },
    {
      title: "Correlation Id",
      dataIndex: "correlationId",
      key: "correlationId",
      width: 80,
      sorter: () => 0,
      hidden: false,
    },
    {
      title: "Provider Name",
      dataIndex: "providerName",
      key: "providerName",
      width: 80,
      sorter: () => 0,
      hidden: false,
    },
    {
      title: "Bought Membership",
      dataIndex: "boughtMembership",
      key: "boughtMembership",
      width: 80,
      render: (value: boolean) => <Switch checked={value} disabled />,
      sorter: () => 0,
      hidden: false,
    },
    {
      title: "Cancel Booking",
      dataIndex: "cancel",
      alwaysShow: true,
      key: "cancel",
      width: 80,
      render: (id: string) => <div>{cancelColumn(id)}</div>,
      hidden: false,
    },
    {
      width: 120,
      title: "Re-send email",
      dataIndex: "bookId",
      alwaysShow: true,
      key: "bookId",
      render: (id?: string) => (
        <span
          className="text-sky-blue"
          role="button"
          aria-hidden="true"
          onClick={() => {
            if (id) reSendEmail(id);
          }}
        >
          Re-send email
        </span>
      ),
      hidden: false,
    },
  ];
  const [options, setOptions] = useState<CheckboxOptionType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [checkedList, setCheckedList] = useState([""]);
  const [loadingCancelModal, setLoadingCancelModal] = useState(false);
  const [dataReason, setDataReason] = useState<DataType[]>();
  const [reasonObject, setReasonObject] = useState<DataType>();
  const [dotwScreen, setDotwScreen] = useState<boolean>(false);
  const [priceClaim, setpriceClaim] = useState<boolean>();
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.key)
  );
  const navigate = useNavigate();

  const { SubMenu } = Menu;

  const cancelSetter = (
    checkin: string,
    checkOut: string,
    status: string,
    bookingId: number
  ) => {
    const checkInDate = moment(checkin);
    const checkOutDate = moment(checkOut);
    const today = moment();
    if (status === EBookingSatus.Cancelled) {
      return "Cancelled";
    }
    if (today.isBetween(checkInDate, checkOutDate)) {
      return "At hotel";
    }
    if (today.isAfter(checkOut)) {
      return "Time Out";
    }
    return bookingId;
  };

  const { width } = useViewport();
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );

  const setInfo = (response: IBookingsList) => {
    const newInfo = response.results.map((item) => {
      const cancelOpt = cancelSetter(
        item.checkIn,
        item.checkOut,
        item.bookingStatus,
        item.bookingId
      );
      const obj = {
        key: item.bookingId,
        bookId: item.bookingId,
        hotelId: item.hotelId,
        hotelname: item.hotelName,
        status: item.bookingStatus,
        providerId: item.providerConfirmationNumber,
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        cancel: cancelOpt,
        creationDate: item.creationDate,
        isMobile: item.isMobile,
        isMember: item.isMember,
        boughtMembership: item.boughtMembership,
        channelId: item.channelId,
        correlationId: item.correlationId,
        providerName: item.providerName,
        totalRate: item.totalRate,
        feeCharged: item.feeCharged,
        feeState: item.feeState,
        baseRate: item.baseRate || "",
        commission: item.commission || "",
      };
      return obj;
    });
    setbookTransform(newInfo);
    setLoading(false);
  };

  const setSingleInfo = (response: IBookingsListResponse[]) => {
    const newInfo = response.map((item) => {
      const obj = {
        key: item.bookingId,
        bookId: item.bookingId,
        hotelId: item.hotelId,
        hotelname: item.hotelName,
        status: item.bookingStatus,
        providerId: item.providerConfirmationNumber,
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        creationDate: item.creationDate,
        cancel:
          item.bookingStatus !== EBookingSatus.Cancelled
            ? item.bookingId
            : "Cancelled",
        userId: item.userId,
      };
      return obj;
    });
    setbookTransform(newInfo);
    setLoading(false);
  };

  const renderBookingsAdmin = async (params?: string, page?: number) => {
    try {
      const response: IBookingsList = await getBookingsAdmin(
        correlationId,
        page || pagination.current,
        pagination.pageSize,
        params
      );
      if (pagination.total !== response.rowCount)
        setPagination({ ...pagination, total: response.rowCount });
      setInfo(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const renderSingleBookings = async (Email: string) => {
    try {
      const response = await getBookings(Email, correlationId);
      setSingleInfo(response);
    } catch (err) {
      console.error(err);
    }
  };

  const handleNavigate = (id: string) =>
    navigate(`/bookings/detail?bookId=${id}`);

  const showError = () => {
    Modal.error({
      title: "There was an error with the cancellation",
    });
  };

  const handleOkModal = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleCloseModal = () => {
    setOtherReason("");
    setSelectedReason("");
    setOpenModal(false);
    setCurrentStep(1);
    setImageFiles([]);
    setpriceClaim(undefined);
  };

  const handleTextAreaChange = (e: { target: { value: any } }) => {
    setOtherReason(e.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedReason(selectedRows[0].reason);
      setReasonObject(selectedRows[0]);
    },
  };

  const handleConfirm = async () => {
    setLoadingCancelModal(true);
    try {
      const response = await cancelBooking(bookId, correlationId);
      setOpenModal(false);
      setCurrentStep(1);
      setLoadingCancelModal(false);
      if (response.bookingStatus !== "Cancelled") {
        showError();
      } else {
        const newReasonObject = {
          cancelReasonId: reasonObject?.key,
          reason: otherReason || "",
        };
        await postCancelReasonBooking(bookId, newReasonObject);
        await postCancelBooking(bookId, response);
        renderBookingsAdmin("&sortDirection=descend&sortField=CreationDate");
        Modal.info({
          title: "Cancel",
          content: "Your booking has been cancelled succesfully",
        });
      }
      setSelectedReason("");
      setOtherReason("");
    } catch (err) {
      setSelectedReason("");
      setOtherReason("");
      setCurrentStep(1);
      console.error(err);
    }
  };

  const getBookingDetail = async (id: string) => {
    setDotwScreen(false);

    const obj = {
      channelId: localStorage.getItem("REACT_APP_CHANNEL_ID") ?? "",
      bookingId: id.toString(),
    };
    try {
      const response = await getBookingDetails(obj, correlationId);
      setbookInformation(response);

      if (
        response &&
        response.hotelBooking &&
        response.hotelBooking.rates &&
        response.hotelBooking.rates.length > 0 &&
        response.hotelBooking.rates[0].cancellationPolicies &&
        response.hotelBooking.rates[0].cancellationPolicies.length > 0 &&
        response.hotelBooking.rates[0].cancellationPolicies[0].rules &&
        response.hotelBooking.rates[0].cancellationPolicies[0].rules.length > 0
      ) {
        const currentDate = new Date();
        const hasValidCancellationPolicy =
          response.hotelBooking.rates[0].cancellationPolicies[0].rules.some(
            (rule: Rule) =>
              (rule.estimatedValue >=
                response.hotelBooking.rates[0].totalRate &&
                currentDate >= new Date(rule.start)) ||
              (rule.value == 100 &&
                rule.valueType == "Percentage" &&
                currentDate >= new Date(rule.start))
          );
        setDotwScreen(hasValidCancellationPolicy);
      }

      setLoadingCancelModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const confirmCancel = (id: string) => {
    setLoadingCancelModal(true);
    getBookingDetail(id);
    setOpenModal(true);
    setBookId(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisiblemail(false);
  };

  const constructFilter = (optionalFilter?: {}) => {
    setLoading(true);
    const URLsearchParams = new URLSearchParams(optionalFilter ?? filterObject);
    setPagination({ ...pagination, current: 1 });
    renderBookingsAdmin(URLsearchParams.toString(), 1);
    setLoading(false);
  };

  const handleFilter = () => {
    constructFilter();
  };

  const statusFilter = () => (
    <div className="p-2 booking-filter-box">
      <Radio.Group
        value={filterObject.BookingStatus}
        onChange={(e) =>
          setfilterObject({ ...filterObject, BookingStatus: e.target.value })
        }
      >
        <Space direction={width < 1200 ? "horizontal" : "vertical"}>
          <Radio value="Confirmed">Confirmed</Radio>
          <Radio value="Cancelled">Cancelled</Radio>
        </Space>
      </Radio.Group>
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, BookingStatus: "" });
            constructFilter({ ...filterObject, BookingStatus: "" });
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          className="ml-3"
          onClick={handleFilter}
        >
          Ok
        </Button>
      </div>
    </div>
  );

  const returnSearchConfirmNumber = () => (
    <div className="p-2 booking-filter-box">
      <Input
        placeholder="Search by Confirm Number"
        autoFocus
        value={filterObject.ProviderConfirmationNumber}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            ProviderConfirmationNumber: e.target.value,
          })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              ProviderConfirmationNumber: "",
            });
            constructFilter({
              ...filterObject,
              ProviderConfirmationNumber: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchHotelID = () => (
    <div className="p-2 booking-filter-box">
      <Input
        placeholder="Search by Hotel ID"
        autoFocus
        value={filterObject.HotelId}
        onChange={(e) =>
          setfilterObject({ ...filterObject, HotelId: e.target.value })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, HotelId: "" });
            constructFilter({ ...filterObject, HotelId: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
  const returnSearchBookID = () => (
    <div className="p-2 booking-filter-box">
      <Input
        placeholder="Search by Book ID"
        autoFocus
        value={filterObject.BookingId}
        onChange={(e) =>
          setfilterObject({ ...filterObject, BookingId: e.target.value })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, BookingId: "" });
            constructFilter({ ...filterObject, BookingId: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchCheckIn = () => (
    <div className="p-2 booking-filter-box">
      <DatePicker.RangePicker
        format="MM/DD/YYYY"
        onChange={(dates, dateStrings) =>
          setfilterObject({
            ...filterObject,
            CheckInDateInit: dateStrings[0],
            CheckInDateEnd: dateStrings[1],
          })
        }
        value={
          filterObject.CheckInDateInit && filterObject.CheckInDateEnd
            ? [
                moment(filterObject.CheckInDateInit, "MM/DD/YYYY"),
                moment(filterObject.CheckInDateEnd, "MM/DD/YYYY"),
              ]
            : null
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              CheckInDateInit: "",
              CheckInDateEnd: "",
            });
            constructFilter({
              ...filterObject,
              CheckInDateInit: "",
              CheckInDateEnd: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchCheckOut = () => (
    <div className="p-2 booking-filter-box">
      <DatePicker.RangePicker
        format="MM/DD/YYYY"
        onChange={(dates, dateStrings) =>
          setfilterObject({
            ...filterObject,
            CheckOutDateInit: dateStrings[0],
            CheckOutDateEnd: dateStrings[1],
          })
        }
        value={
          filterObject.CheckOutDateInit && filterObject.CheckOutDateEnd
            ? [
                moment(filterObject.CheckOutDateInit, "MM/DD/YYYY"),
                moment(filterObject.CheckOutDateEnd, "MM/DD/YYYY"),
              ]
            : null
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              CheckOutDateInit: "",
              CheckOutDateEnd: "",
            });
            constructFilter({
              ...filterObject,
              CheckOutDateInit: "",
              CheckOutDateEnd: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchCreationDate = () => (
    <div className="p-2 booking-filter-box">
      <DatePicker.RangePicker
        format="MM/DD/YYYY"
        onChange={(dates, dateStrings) =>
          setfilterObject({
            ...filterObject,
            CreationDateInit: dateStrings[0],
            CreationDateEnd: dateStrings[1],
          })
        }
        value={
          filterObject.CreationDateInit && filterObject.CreationDateEnd
            ? [
                moment(filterObject.CreationDateInit, "MM/DD/YYYY"),
                moment(filterObject.CreationDateEnd, "MM/DD/YYYY"),
              ]
            : null
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({
              ...filterObject,
              CreationDateInit: "",
              CreationDateEnd: "",
            });
            constructFilter({
              ...filterObject,
              CreationDateInit: "",
              CreationDateEnd: "",
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearch = () => (
    <div className="p-2 booking-filter-box">
      <Input
        placeholder="Search by Hotel Name"
        autoFocus
        value={filterObject.HotelName}
        onChange={(e) =>
          setfilterObject({ ...filterObject, HotelName: e.target.value })
        }
      />
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, HotelName: "" });
            constructFilter({ ...filterObject, HotelName: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const handleSendEmail = async () => {
    setLoadingEmail(true);
    try {
      const channelID =
        webConfigContext.selectedChannel ??
        (await getWebConfig()).REACT_APP_CHANNEL_ID.Organic;
      if (bookId && bookInformation && bookInformation?.hotelBooking.rooms) {
        await sendEmail(email, "NewBooking", channelID, bookId);
      }
      Modal.info({
        title: "Email re-sent!",
        content: "The email has been re-sent!",
      });
      setLoadingEmail(false);
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  const reSendEmail = async (id: string) => {
    setBookId(id);
    setIsModalVisiblemail(true);
    setLoadingEmail(true);
    const channelID =
      webConfigContext.selectedChannel ??
      (await getWebConfig()).REACT_APP_CHANNEL_ID.Organic;
    const obj = {
      channelId: channelID,
      bookingId: id.toString(),
    };
    try {
      const response = await getBookingDetails(obj, correlationId);
      setbookInformation(response);
      setemail(response.hotelBooking.billingContact?.contact.email ?? "");
      setLoadingEmail(false);
    } catch (err) {
      console.error(err);
    }
  };

  const returnSearchUser = () => (
    <div className="p-2 booking-filter-box">
      <Input
        placeholder="Search by User Id"
        autoFocus
        value={filterObject.userId}
        onChange={(e) =>
          setfilterObject({ ...filterObject, userId: e.target.value })
        }
      />
      <div className="book-title mt-2">
        <Button type="primary" size="small" onClick={handleFilter}>
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            setfilterObject({ ...filterObject, userId: "" });
            constructFilter({ ...filterObject, userId: "" });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const filterIconSearch = () => (
    <FontAwesomeIcon icon={faSearch as IconProp} />
  );

  const cancelColumn = (option: string) => {
    switch (option) {
      case "Time Out":
        return <span>Time Out</span>;
      case "Cancelled":
        return <span>Cancelled</span>;
      case "At hotel":
        return <span>At hotel</span>;
      default:
        return (
          <Button
            type="dashed"
            shape="round"
            icon={
              <FontAwesomeIcon icon={faClose as IconProp} className="me-2" />
            }
            onClick={() => confirmCancel(option)}
          >
            Manage bookings
          </Button>
        );
    }
  };

  const getSortField = (sortField: string) => {
    switch (sortField) {
      case "bookId":
        return "BookingId";
      case "providerId":
        return "ProviderConfirmationNumber";
      case "status":
        return "BookingStatus";
      case "hotelId":
        return "HotelId";
      case "hotelname":
        return "HotelName";
      case "checkIn":
        return "CheckIn";
      case "checkOut":
        return "CheckOut";
      case "creationDate":
        return "CreationDate";
      case "bookingDate":
        return "BookingDate";
      case "channelId":
        return "ChannelId";
      case "isMobile":
        return "IsMobile";
      case "correlationId":
        return "CorrelationId";
      case "isMember":
        return "IsMember";
      case "totalRate":
        return "TotalRate";
      case "boughtMembership":
        return "BoughtMembership";
      default:
        return "";
    }
  };

  const handleTableChange = (pag: any, filters: any, sorter: any) => {
    const sortField = getSortField(sorter.field);
    setfilterObject({
      ...filterObject,
      sortDirection: sorter.order,
      sortField,
    });
    constructFilter({
      ...filterObject,
      sortDirection: sorter.order,
      sortField,
    });
  };

  const handlePagination = (page: number) => {
    setPagination({ ...pagination, current: page });
    const URLsearchParams = new URLSearchParams(filterObject);
    renderBookingsAdmin(URLsearchParams.toString(), page);
  };

  const hasAtLeastDifferentLetters = (str: string) => {
    const uniqueLetters = str.match(/[a-zA-Z]/g);

    return uniqueLetters && new Set(uniqueLetters).size >= 5;
  };

  const getCancelReason = async () => {
    const data = await cancelBookingReason();
    const newData = data.map((item: { id: any; reason: any }) => {
      const obj = {
        key: item.id,
        reason: item.reason,
      };
      return obj;
    });
    setDataReason(newData);
  };

  useEffect(() => {
    setLoading(true);
    getCancelReason();
    const defaultCheckedList = columns.map((item) => item.key as string);
    const options = columns.map(({ key, title }) => ({
      label: title,
      value: key,
    }));
    setOptions(options);
    setCheckedList(defaultCheckedList);
    const correlationIdBook = uuidv4();
    setcorrelationId(correlationIdBook);
    if (userEmail) {
      renderSingleBookings(userEmail);
    } else {
      renderBookingsAdmin("&sortDirection=descend&sortField=CreationDate");
    }
  }, []);

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const handleMenuClick = (key: string | undefined) => {
    if (!key) return;
    const index = visibleColumns.indexOf(key);
    if (index === -1) {
      setVisibleColumns([...visibleColumns, key]);
    } else {
      setVisibleColumns(visibleColumns.filter((col) => col !== key));
    }

    const columnIndex = columns.findIndex((col) => col.key === key);
    if (columnIndex !== -1) {
      const newCheckedList = checkedList.includes(key)
        ? checkedList.filter((item) => item !== key)
        : [...checkedList, key];
      setCheckedList(newCheckedList);
    }
  };

  const menu = (
    <Menu>
      {columns
        .filter((p) => !p.alwaysShow)
        .map((item) => (
          <Menu.Item key={item.key}>
            <Checkbox
              onChange={(e) => handleMenuClick(e.target.value)}
              value={item.key}
              checked={checkedList.includes(item.key as string)}
              data-value={item.key}
              data-checked={!checkedList.includes(item.key as string)}
            >
              {item.title}
            </Checkbox>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div>
      {loading && <LoaderComponent title="Loading Bookings..." />}
      {!loading && (
        <>
          <Modal
            title={`We are sorry you are cancelling, ${bookInformation?.hotelBooking.billingContact?.firstName}`}
            open={openModal}
            width={1000}
            onCancel={handleCloseModal}
            footer={
              currentStep === 1
                ? [
                    <Button key="back" onClick={handleCloseModal}>
                      Cancel
                    </Button>,
                    <>
                      {!priceClaim && (
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleOkModal}
                          disabled={
                            !selectedReason
                              ? true
                              : selectedReason === "Other"
                              ? !(
                                  otherReason.length > 0 &&
                                  hasAtLeastDifferentLetters(otherReason)
                                )
                              : false
                          }
                        >
                          Next
                        </Button>
                      )}
                    </>,
                  ]
                : [
                    <Button key="back" onClick={handleCloseModal}>
                      I want to keep my booking
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      className="button-cancel-modal"
                      onClick={handleConfirm}
                    >
                      I understand the cancellation policy and I wish to cancel
                    </Button>,
                  ]
            }
          >
            {!loadingCancelModal && currentStep === 1 ? (
              <>
                {dotwScreen ? (
                  <div className="mt-3">
                    <span className="small-titles">
                      We are very sorry this booking cannot longer be cancelled
                      due to the penalty being equal the total rate
                    </span>
                    <ul>
                      {bookInformation &&
                        bookInformation.hotelBooking &&
                        bookInformation.hotelBooking.rates &&
                        bookInformation.hotelBooking.rates.length > 0 &&
                        bookInformation.hotelBooking.rates[0].providerName &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies.length > 0 &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies[0].rules &&
                        bookInformation.hotelBooking.rates[0]
                          .cancellationPolicies[0].rules.length > 0 &&
                        bookInformation?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                          (item) => (
                            <li>
                              Cancellation Penalty between{" "}
                              {moment(item.start).format("MM/DD/YYYY")} to{" "}
                              {moment(item.end).format("MM/DD/YYYY")} is{" "}
                              {bookInformation.hotelBooking.rates[0].currency}
                              {bookInformation.hotelBooking.rates[0]
                                .currency === "USD" && "$"}{" "}
                              {item.estimatedValue.toFixed(2)}
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                ) : (
                  <>
                    <Table
                      columns={columnsCancel}
                      dataSource={dataReason}
                      pagination={false}
                      rowSelection={{
                        type: "radio",
                        ...rowSelection,
                      }}
                      expandable={{
                        expandedRowRender: (record) => (
                          <PriceMatchComponent
                            bookID={bookId}
                            setpriceClaim={setpriceClaim}
                            priceClaim={priceClaim}
                            setImageFiles={setImageFiles}
                            imageFiles={imageFiles}
                            setOpenModal={setOpenModal}
                          />
                        ),
                        expandedRowKeys:
                          selectedReason == "Found a better price" ? [1] : [0],
                        showExpandColumn: false,
                      }}
                    />
                    {selectedReason === "Other" && (
                      <TextArea
                        rows={4}
                        placeholder="Please state your reason"
                        className="mt-2"
                        onChange={handleTextAreaChange}
                        value={otherReason}
                      />
                    )}
                  </>
                )}
              </>
            ) : !loadingCancelModal && currentStep === 2 ? (
              <div className="mt-3">
                <span className="small-titles">Cancellation Policy</span>
                <ul>
                  {bookInformation?.hotelBooking.rates[0].cancellationPolicies[0].rules.map(
                    (item) => (
                      <li>
                        Cancellation Penalty between{" "}
                        {moment(item.start).format("MM/DD/YYYY")} to{" "}
                        {moment(item.end).format("MM/DD/YYYY")} is{" "}
                        {bookInformation.hotelBooking.rates[0].currency}
                        {bookInformation.hotelBooking.rates[0].currency ===
                          "USD" && "$"}{" "}
                        {item.estimatedValue.toFixed(2)}
                      </li>
                    )
                  )}
                </ul>
                <strong className="text-danger">
                  I acknowledge and agree to the cancellation terms and policies
                  and accept any any all penalities and charges if they apply
                </strong>
              </div>
            ) : (
              <div className="text-center">
                <Spin size="large" />
              </div>
            )}
          </Modal>
          <div className="book-title mt-3">
            <h4>Booking List Admin</h4>
          </div>
          {width < 1200 ? (
            <>
              <Menu mode="inline" className="pb-3 ">
                <SubMenu
                  key="filters"
                  title={
                    <span className="filter-menu-title">
                      <FontAwesomeIcon icon={faSliders as IconProp} />
                      <span>Filters</span>
                    </span>
                  }
                >
                  <Menu.Item className="filter-menu-item" key="filterBookId">
                    {returnSearchBookID()}
                  </Menu.Item>
                  <Menu.Item
                    className="filter-menu-item"
                    key="filterConfNumber"
                  >
                    {returnSearchConfirmNumber()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterStatus">
                    {statusFilter()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterHotelId">
                    {returnSearchHotelID()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterName">
                    {returnSearch()}
                  </Menu.Item>
                  <div>
                    <span className="ps-2">Check In</span>
                    <Menu.Item
                      className="filter-menu-item my-2"
                      key="filterCheckIn"
                    >
                      {returnSearchCheckIn()}
                    </Menu.Item>
                  </div>
                  <div>
                    <span className="ps-2">Check Out</span>
                    <Menu.Item
                      className="filter-menu-item my-2"
                      key="filterCheckOut"
                    >
                      {returnSearchCheckOut()}
                    </Menu.Item>
                  </div>
                  <div>
                    <span className="ps-2">Book Date</span>
                    <Menu.Item
                      className="filter-menu-item my-2"
                      key="filterBookDate"
                    >
                      {returnSearchCreationDate()}
                    </Menu.Item>
                  </div>
                  <Menu.Item className="filter-menu-item" key="filterUser">
                    {returnSearchUser()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterStatus">
                    {statusFilter()}
                  </Menu.Item>
                </SubMenu>
              </Menu>
              <BookingCard
                bookTransform={bookTransform}
                confirmCancel={confirmCancel}
                handleNavigate={handleNavigate}
                admin
              />
            </>
          ) : (
            <>
              <Divider />
              <button
                type="button"
                className="btn btn-primary ms-3"
                aria-hidden="true"
                onClick={exportToCSV}
              >
                Export to CSV
              </button>
              <Divider />
              <div
                className="card rounded-dp shadow-lg"
                style={{ width: "100%" }}
              >
                <div className="card-body">
                  <Dropdown
                    overlay={menu}
                    open={open}
                    onOpenChange={handleOpenChange}
                  >
                    <Button>
                      <Space>
                        Columns
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                  <Table
                    dataSource={bookTransform}
                    onChange={handleTableChange}
                    columns={columns.filter((column) =>
                      visibleColumns.includes(column.key)
                    )}
                    scroll={{ x: 240 }}
                    pagination={false}
                  />
                </div>
              </div>
            </>
          )}
          <Pagination
            className="mt-3 mb-5"
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={(page) => handlePagination(page)}
          />
          <Modal
            title="Cancel Booking"
            open={isModalVisible}
            onOk={handleConfirm}
            okButtonProps={{ loading: loadingCancel }}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to cancel this booking?</p>
          </Modal>
          <Modal
            title="Re-send email"
            open={isModalVisiblemail}
            okButtonProps={{ loading: loadingEmail }}
            onOk={handleSendEmail}
            onCancel={handleCancel}
          >
            {error && (
              <Alert
                type="error"
                message="Error"
                description="Something went wrong please try again later"
                closable
                className="dateAlert"
              />
            )}
            <p>Email</p>
            <Input
              value={email}
              placeholder="Email"
              onChange={(e) => setemail(e.target.value)}
              className="mb-2"
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default BookListAdmin;
