import React, { useState, useEffect, ReactElement, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Result } from "antd";
import MainHeaderDetail from "../MainHeaderDetail";
import { IHotelDetailFullInformation } from "../../../Models/HotelContent";
import { getHotelDetail } from "../../../helpers/requestHotels";
import SkeletonHotelDetail from "../SkeletonHotelDetail";
import { IHotelLittleDetail } from "../../../Models/GooglePrice";
import { useScrollLock } from "../../../helpers/scrollLock";
import ExtDetailInfo from "./ExtDetailInfo";
import { IWebConfigContext } from "../../../Models/SettingsModel";
import { WebConfigContext } from "../../../Context/WebConfigContext";

const HotelDetailSimple = (): ReactElement => {
  const [, setStarRate] = useState<string>("");
  const { unlockScroll } = useScrollLock();
  const [error, setError] = useState(false);
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hotelDetail, setHotelDetail] = useState<
    IHotelDetailFullInformation | undefined
  >();
  const [searchParams] = useSearchParams();

  const [deepLink, setdeepLink] = useState<string>();

  async function getParams(): Promise<IHotelLittleDetail> {
    const correlationId = searchParams.get("correlationId") || uuidv4();
    const hotelId = searchParams.get("hotelId") || "";

    // ToDo: token se generara desde Prices/DeepLinkAndPrices.
    const deeplinkid = uuidv4();
    setdeepLink(deeplinkid);
    return {
      correlationId,
      hotelId,
    };
  }

  useEffect(() => {
    unlockScroll();
    const init = async () => {
      setIsLoading(true);
      const paramObj = await getParams();
      try {
        const result = await getHotelDetail(
          paramObj.correlationId,
          paramObj.hotelId,
          webConfigContext.selectedChannel ?? ""
        );
        setHotelDetail(result.hotels[0]);
        setStarRate(result.hotels[0].starRating);
      } catch (err) {
        setError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    init();
    return () => {
      init();
    };
  }, [searchParams]);

  return (
    <section id="main-header-detail-hotel">
      {isLoading ? (
        <SkeletonHotelDetail />
      ) : (
        <>
          {error && (
            <Result
              status="500"
              title="Hotel Not Found"
              subTitle="Sorry, the hotel you were looking for was not found."
            />
          )}
          {!error && (
            <>
              <MainHeaderDetail
                facilities={hotelDetail?.facilities}
                nameHotel={hotelDetail?.name}
                images={hotelDetail?.images}
              />

              {hotelDetail && (
                <ExtDetailInfo
                  deeplink={deepLink}
                  starRating={hotelDetail?.starRating}
                  nameHotel={hotelDetail?.name}
                  images={hotelDetail?.images}
                  facilities={hotelDetail?.facilityGroups}
                  address={hotelDetail?.contact.address}
                  nearByAttractions={hotelDetail.nearByAttractions}
                  coordenates={{
                    lat: hotelDetail.geoCode.lat.toString(),
                    long: hotelDetail.geoCode.long.toString(),
                  }}
                  settings={webConfigContext.webConfig}
                  descriptionText={hotelDetail?.descriptions}
                  provider={hotelDetail.providerName}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default HotelDetailSimple;
