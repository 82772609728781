import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCogs,
  faFileAlt,
  faUserCog,
  faChartColumn,
  faUser,
  faPlus,
  faListSquares,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, notification } from "antd";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerWithEmailAndPassword } from "../../Auth/firebase";
import LoaderComponent from "../../Components/LoaderComponent";
import { sendEmailUserCreated } from "../../helpers/requestBookings";
import { getWebConfig } from "../../helpers/requestSetting";
import { getRolesFetch, postUser } from "../../helpers/requestUserAndRole";
import { IRole } from "../../Models/UsersModel";
import HotelCenterForm from "../../Components/AccountManagment/HotelCenterForm";
import CreateAccountForm from "../../Components/AccountManagment/CreateAccountForm";

const AccountManagement = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalHotelCenterShow, setModalHotelCenterShow] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [roleSubmit, setRoleSubmit] = useState(0);

  const getRoles = async () => {
    try {
      const response = await getRolesFetch();
      setRoles(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishForm = async (values: any) => {
    setLoading(true);
    setModalCreateVisible(false);
    const webConfigResp = await getWebConfig();
    const app = initializeApp(webConfigResp.firebaseConfig, "secondary");
    const auth = getAuth(app);

    try {
      const userCredential = await registerWithEmailAndPassword(
        values.userName,
        values.password,
        auth
      );
      if (userCredential) {
        const obj = {
          uid: userCredential.user.uid,
          userName: values.userName,
          firstName: values.firstName,
          lastName: values.lastName || "",
          password: values.password,
          roleId: roleSubmit,
        };
        try {
          const response = await postUser(obj);
          if (response !== null)
            sendEmailUserCreated(values.userName, "NewUser", values.password);
          notification.success({
            message: "Succces Registred",
            description: "User registered!",
          });
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      } else setLoading(false);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Error",
      });
      console.error(err);
    }
  };

  useEffect(() => {
    window._CTZ = {
      enabled: false,
      verticals: {
        hotel: {
          active: false,
          search: {
            city: undefined,
            province: undefined,
            countryCode: undefined,
            checkInDate: undefined,
            checkOutDate: undefined,
          },
        },
      },
    };
    setLoading(true);
    if (roles.length === 0) {
      getRoles();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="container mt-5">
      {loading && <LoaderComponent title="Loading Accounts..." />}
      {!loading && (
        <>
          <h3>Accounts Management</h3>
          <span>Select an Option</span>
          <Divider />
          <div className="book-title mt-3 manage-menu">
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("/bookings")}
            >
              <FontAwesomeIcon icon={faFileAlt as IconProp} className="me-2" />
              My Bookings
            </button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => setModalHotelCenterShow(true)}
            >
              <FontAwesomeIcon
                icon={faListSquares as IconProp}
                className="me-2"
              />
              Hotel center list
            </button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => setModalCreateVisible(true)}
            >
              <FontAwesomeIcon icon={faPlus as IconProp} className="me-2" />
              Create New User
            </button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("/settings")}
            >
              <FontAwesomeIcon icon={faCogs as IconProp} className="me-2" />
              Website Settings
            </button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("/rateFilter")}
            >
              <FontAwesomeIcon icon={faCogs as IconProp} className="me-2" />
              Rate Filter
            </button>

            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("/reports")}
            >
              <FontAwesomeIcon
                icon={faChartColumn as IconProp}
                className="me-2"
              />
              Reports
            </button>

            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("roles/")}
            >
              <FontAwesomeIcon icon={faUserCog as IconProp} className="me-2" />
              User Permissions
            </button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("users/")}
            >
              <FontAwesomeIcon icon={faUser as IconProp} className="me-2" />
              Users
            </button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => navigate("bookings/")}
            >
              <FontAwesomeIcon icon={faFileAlt as IconProp} className="me-2" />
              All Bookings
            </button>
          </div>
          <Divider />

          <HotelCenterForm
            modalHotelCenterShow={modalHotelCenterShow}
            setModalHotelCenterShow={setModalHotelCenterShow}
          />
          <CreateAccountForm
            modalCreateVisible={modalCreateVisible}
            setModalCreateVisible={setModalCreateVisible}
            onFinishForm={onFinishForm}
            setRoleSubmit={setRoleSubmit}
            roles={roles}
          />
        </>
      )}
    </div>
  );
};

export default AccountManagement;
