export async function getIp() {
  const publicIp = localStorage.getItem("ipPublic");
  if (publicIp !== null && publicIp !== undefined && publicIp !== "undefined") {
    return publicIp;
  }
  const controller = new AbortController();

  try {
    const response = await fetch("https://api.ipify.org?format=json", {
      signal: controller.signal,
    });
    const data = await response.json();
    localStorage.setItem("ipPublic", data.ip);
    return data.ip;
  } catch (error: any) {
    if (error.name === "AbortError") {
    } else {
      console.error("Error:", error.message); // This will log a timeout error
    }
    return "";
  }
}

export const getConfig = async (correlationId: string) => {
  return {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      correlationId,
    },
  };
};
