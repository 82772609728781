import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { IFacilityGroup, IReview } from "../../Models/HotelContent";

interface props {
  reviews?: Array<IReview>;
  className?: string;
  facilities?: IFacilityGroup[] | undefined;
}

const NavigationBar = ({
  reviews,
  className,
  facilities,
}: props): ReactElement => {
  let reviewContent = false;
  if (reviews) {
    if (reviews.length > 0) {
      reviewContent = true;
    }
  }
  const urlArray = [
    { label: "Overview & Photos", hash: "#main-header" },
    { label: "Rooms", hash: "#gallery-detail-hotel" },
    facilities && facilities?.length > 0
      ? { label: "Amenities", hash: "#amenities-section" }
      : undefined,
    { label: "Map", hash: "#hotel-map" },
    reviewContent ? { label: "Reviews", hash: "#reviews" } : undefined,
  ];
  const pathUrl = useLocation();
  return (
    <nav className={`${className} navbar navbar-light bg-white navigation-bar`}>
      <div className="container">
        <div className="navbar-nav navigation-wrapper">
          {urlArray
            .filter((p) => p)
            .map((itemNav, index) => (
              <div
                key={index}
                className={`${
                  pathUrl.hash === itemNav?.hash ? "item-active" : ""
                } nav-item`}
              >
                <HashLink
                  smooth
                  to={`${pathUrl.pathname}${pathUrl.search}${itemNav?.hash}`}
                >
                  {itemNav?.label}
                </HashLink>
              </div>
            ))}
        </div>
      </div>
    </nav>
  );
};
export default NavigationBar;
