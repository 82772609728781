import React, { ReactElement } from "react";

interface ContentCardReviewProps {
  contentText: string;
}

const ContentCardReview = ({
  contentText,
}: ContentCardReviewProps): ReactElement => (
  <div className="content-review">
    <p>"{contentText}"</p>
  </div>
);
export default ContentCardReview;
