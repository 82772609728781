import { IRate } from "../Models/RoomDetail";

export const sumAmounts = (hotelObject: IRate, numOFNights:number): number => {
// Check if additionalCharges is defined
if (hotelObject.additionalCharges && Array.isArray(hotelObject.additionalCharges)) {
  const totalRateWithAdditionalCharges = hotelObject.totalRate + hotelObject.additionalCharges.reduce((acc, curr) => acc + curr.charge.amount, 0);
  return totalRateWithAdditionalCharges /numOFNights
} else {
  return hotelObject.totalRate/numOFNights
}
};
