import { IOccupancy } from "../Models/CommonType";

export const totalAdults = (totalAdultsArr: IOccupancy[]) =>
  totalAdultsArr.reduce((a, b) => a + b.numOfAdults, 0);

export const totalChildrens = (totalChildrenArr: IOccupancy[]) => {
  let sum = 0;

  totalChildrenArr.forEach((obj) => {
    sum += obj.childAges.length;
  });

  return sum;
};

export const childrenAges = (occupancies: IOccupancy[]) => {
  let combinedAges = occupancies.reduce((result: number[], occupancy) => {
    // Concatenate the childAges array of each occupancy to the result array
    if (occupancy.childAges && occupancy.childAges.length > 0) {
      // Concatenate the childAges array of each occupancy to the result array
      result = result.concat(occupancy.childAges);
    }
    return result;
  }, []);

  return combinedAges.join(",");
};

export const totalRooms = (totalAdultsArr: IOccupancy[]) =>
  totalAdultsArr.length;
