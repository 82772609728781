import React, { ReactElement, useEffect, useState } from "react";
import { Divider } from "antd";

interface ControlBarProps {
  className?: string;
  result: string;
  totalHotels?: number;
  totalFilteredHotels?: number;
}

const ControlBar = ({
  result,
  className,
  totalHotels,
  totalFilteredHotels,
}: ControlBarProps): ReactElement => {
  const [titleText, setTitleText] = useState<string>("");

  useEffect(() => {
    setTitleText(result);
  }, [result]);

  return (
    <>
      <div className={`${className} container`} id="control-bar">
        <div className="row">
          <div className="col-lg-6">
            <span className="h6">
              {totalHotels} hotels in {titleText}{" "}
              {totalFilteredHotels &&
                totalFilteredHotels !== totalHotels &&
                `(${totalFilteredHotels}) are present`}
            </span>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};
export default ControlBar;
