import { Spin } from "antd";
import React, { ReactElement } from "react";

interface props {
  loading: boolean;
  membershipMandatory: boolean;
}

const ButtonComplete = ({
  loading,
  membershipMandatory,
}: props): ReactElement => (
  <div className="button_complete_co">
    <div className="div-info">
      {!membershipMandatory ? (
        <>
          <div className="first">
            <span>
              By clicking complete booking I agree to the{" "}
              <a
                href={`${process.env.REACT_APP_API_BASE_WP_URL}/traveler-services-agreement/`}
                target="_blank"
                rel="noreferrer"
              >
                travel services agreement,&nbsp;
              </a>
              <a
                href={`${process.env.REACT_APP_API_BASE_WP_URL}/privacy-policy/`}
                target="_blank"
                rel="noreferrer"
              >
                privacy policy&nbsp;
              </a>{" "}
              and{" "}
              <a
                href={`${process.env.REACT_APP_API_BASE_WP_URL}/terms-conditions/`}
                target="_blank"
                rel="noreferrer"
              >
                terms and conditions.
              </a>
            </span>
          </div>
        </>
      ) : (
        <div className="first"></div>
      )}

      <div className="button_box">
        <button type="submit" disabled={loading}>
          Complete Booking
          {loading && <Spin className="ms-2" size="small" />}
        </button>
      </div>
    </div>
  </div>
);

export default ButtonComplete;
