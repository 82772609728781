import { IMenuAccess } from "../Models/UsersModel";
import { RoutesPath } from "../Routes";

export const MenuData: IMenuAccess[] = [
  {
    menuLabel: "Book Now",
    path: RoutesPath.root,
    requireLogin: false,
    accessControl: "",
  },
  {
    menuLabel: "My Bookings",
    path: RoutesPath.bookings,
    requireLogin: true,
    accessControl: RoutesPath.bookings,
  },
  {
    menuLabel: "Member Perks",
    path: RoutesPath.memberPerks,
    requireLogin: true,
    accessControl: "",
  },
  {
    menuLabel: "FAQ",
    path: RoutesPath.faqs,
    requireLogin: true,
    accessControl: "",
  },
  {
    menuLabel: "Membership Center",
    path: RoutesPath.membership,
    requireLogin: true,
    accessControl: "",
  },
  {
    menuLabel: "Account Management",
    path: RoutesPath.accounts,
    requireLogin: true,
    accessControl: RoutesPath.accounts,
  },
  {
    menuLabel: "Home",
    path: RoutesPath.home || "/",
    requireLogin: false,
    accessControl: "",
  },
  {
    menuLabel: "About",
    path: `${RoutesPath.home}/about-us/`,
    requireLogin: false,
    accessControl: "",
  },
  {
    menuLabel: "JOIN THE CLUB & SAVE",
    path: `${RoutesPath.home}/member-login/#membership-form`,
    requireLogin: false,
    accessControl: "",
  },
];
