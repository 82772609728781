import APIClient from "../Components/Services/APIClient";
import {
  IPutRole,
  IPostUser,
  IUserDetail,
  IPostRole,
} from "../Models/UsersModel";

export const putRole = async (obj: IPutRole) => {
  const client = new APIClient();
  const contentResponse = await client.client.put(`UserRole/`, obj);
  return contentResponse.data;
};

export const getUserRol = async (username: string) => {
  const client = new APIClient();
  const contentResponse = await client.client.get(
    `User/GetByUserName/${username}`
  );
  const response: IUserDetail = contentResponse.data;
  return response;
};

export const getUsers = async () => {
  const client = new APIClient();
  const contentResponse = await client.client.get("User/");
  return contentResponse.data;
};

export const getAccess = async () => {
  const client = new APIClient();
  const contentResponse = await client.client.get("Access/");
  return contentResponse.data;
};

export const getRolesFetch = async () => {
  const client = new APIClient();
  const contentResponse = await client.client.get("UserRole/GetActive");
  return contentResponse.data;
};

export const getAllRolesFetch = async () => {
  const client = new APIClient();
  const contentResponse = await client.client.get("UserRole/");
  return contentResponse.data;
};

export const postUser = async (obj: IPostUser) => {
  const client = new APIClient();
  const contentResponse = await client.client.post(`User/`, obj);
  return contentResponse.data;
};

export const putUser = async (obj: IPostUser) => {
  const client = new APIClient();
  const contentResponse = await client.client.put(`User/`, obj);
  return contentResponse.data;
};

export const postRole = async (obj: IPostRole) => {
  const client = new APIClient();
  const contentResponse = await client.client.post(`UserRole/`, obj);
  return contentResponse.data;
};

export const deleteUser = async (userName: string) => {
  const client = new APIClient();
  const contentResponse = await client.client.delete(`User/${userName}`);
  return contentResponse.data;
};

export const putAccessRole = async (roleId: number, accessArr: number[]) => {
  const client = new APIClient();
  const contentResponse = await client.client.put(
    `UserRole/${roleId}/OverrideAccess`,
    accessArr
  );
  return contentResponse.data;
};
