import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faEdit,
  faSearch,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Menu,
} from "antd";
import React, { useState, useEffect, ReactElement } from "react";
import RolesCard from "../../../Components/AccountManagment/RolesCard";
import LoaderComponent from "../../../Components/LoaderComponent";
import { useViewport } from "../../../Context/useViewport";
import {
  getAccess,
  getAllRolesFetch,
  postRole,
  putAccessRole,
  putRole,
} from "../../../helpers/requestUserAndRole";
import { IAccess, IRole, IRolesTransform } from "../../../Models/UsersModel";

const AccountsRoles = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [accessList, setAccess] = useState<IAccess[]>([]);
  const [rolesTransform, setRolesTransform] = useState<IRolesTransform[]>([]);
  const [rolesData, setRolesData] = useState<IRolesTransform[]>([]);
  const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [accessSelect, setAccessSelect] = useState<string[]>([]);
  const { width } = useViewport();
  const { SubMenu } = Menu;

  const [formEdit, setformEdit] = useState({
    roleId: 0,
    roleName: "",
    roleDescription: "",
    active: true,
    isDefault: false,
  });

  const setInfoAccess = async () => {
    try {
      const response = await getAccess();
      setAccess(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const setInfo = (response: IRole[]) => {
    const newInfo = response.map((item, index) => {
      const obj = {
        key: item.roleId,
        id: item.roleId,
        roleName: item.roleName,
        roleDescription: item.roleDescription,
        access: item.access,
        edit: index,
        active: item.active,
        isDefault: item.isDefault,
      };
      return obj;
    });
    setRolesTransform(newInfo);
    setRolesData(newInfo);
    if (accessList.length === 0) {
      setInfoAccess();
    } else {
      setLoading(false);
    }
  };

  const renderInfo = async () => {
    try {
      const response = await getAllRolesFetch();
      setInfo(response);
    } catch (err) {
      console.error(err);
    }
  };

  const updateAccessRole = async (roleId: number) => {
    const newArr = accessSelect.map((item) => parseInt(item, 10));
    try {
      await putAccessRole(roleId, newArr);
      setAccessSelect([]);
      renderInfo();
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFormUpdate = async () => {
    setLoading(true);
    setModalVisible(false);
    try {
      await putRole(formEdit);
      updateAccessRole(formEdit.roleId);
      setformEdit({
        roleId: 0,
        roleName: "",
        roleDescription: "",
        active: true,
        isDefault: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const editForm = (id: number) => {
    setformEdit({
      roleId: rolesTransform[id].id,
      roleName: rolesTransform[id].roleName,
      roleDescription: rolesTransform[id].roleDescription,
      active: rolesTransform[id].active,
      isDefault: rolesTransform[id].isDefault,
    });
    setAccessSelect(
      rolesTransform[id].access.map((item) => item.accessId.toString())
    );
    setModalVisible(true);
  };

  const onFinishForm = async (values: any) => {
    setLoading(true);
    setModalCreateVisible(false);
    const obj = {
      ...values,
      isDefault: values.default === undefined ? false : values.default,
    };
    try {
      const response = await postRole(obj);
      if (accessSelect.length > 0) {
        updateAccessRole(response.roleId);
      } else {
        renderInfo();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const filterIconSearch = () => (
    <FontAwesomeIcon icon={faSearch as IconProp} />
  );

  const [filterObject, setfilterObject] = useState({
    roleName: "",
    description: "",
    active: "",
    id: "",
    access: "",
  });

  const returnSearchID = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by ID"
        autoFocus
        value={filterObject.id}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            id: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchName = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Name"
        autoFocus
        value={filterObject.roleName}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            roleName: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchDescription = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Description"
        autoFocus
        value={filterObject.description}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            description: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchActive = () => (
    <div className="p-2 booking-filter-box">
      <Radio.Group
        value={filterObject.active}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            active: e.target.value,
          })
        }
      >
        <Space direction={width < 1200 ? "horizontal" : "vertical"}>
          <Radio value>Active</Radio>
          <Radio value={false}>Inactive</Radio>
        </Space>
      </Radio.Group>
      <div className={`book-title ${width < 1200 ? "" : "mt-2"}`}>
        <Button
          size="small"
          onClick={() => setfilterObject({ ...filterObject, active: "" })}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const returnSearchAccess = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Access"
        autoFocus
        value={filterObject.access}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            access: e.target.value,
          })
        }
      />
    </div>
  );

  const filterAccess = (item: IRolesTransform) => {
    const match = item.access.find((access) =>
      access.path.toLowerCase().includes(filterObject.access.toLowerCase())
    );
    if (match) return true;
    return false;
  };

  useEffect(() => {
    const copyArr = rolesData;
    setRolesTransform(
      copyArr.filter(
        (item) =>
          item.id.toString().includes(filterObject.id.toString()) &&
          item.roleName
            .toLowerCase()
            .includes(filterObject.roleName.toLowerCase()) &&
          item.roleDescription
            .toLowerCase()
            .includes(filterObject.description.toLowerCase()) &&
          item.active.toString().includes(filterObject.active) &&
          filterAccess(item)
      )
    );
  }, [filterObject]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 130,
      filterDropdown: () => returnSearchID(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) => (a.id < b.id ? -1 : 1),
    },
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: 140,
      filterDropdown: () => returnSearchName(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.roleName.toLowerCase() < b.roleName.toLowerCase() ? -1 : 1,
    },
    {
      title: "Role Description",
      dataIndex: "roleDescription",
      key: "roleDescription",
      width: 140,
      filterDropdown: () => returnSearchDescription(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.roleDescription.toLowerCase() < b.roleDescription.toLowerCase()
          ? -1
          : 1,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: 50,
      filterDropdown: () => returnSearchActive(),
      render: (active: boolean) =>
        active && <FontAwesomeIcon icon={faCheck as IconProp} />,
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      width: 140,
      filterDropdown: () => returnSearchAccess(),
      filterIcon: () => filterIconSearch(),
      render: (acces: IAccess[]) => (
        <>
          {acces.map((item) => (
            <Tag color="green" key={item.accessId}>
              {item.path}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Edit Role",
      dataIndex: "edit",
      key: "edit",
      width: 80,
      render: (id: number) => (
        <button
          className="btn btn-success-dp-xs"
          type="button"
          onClick={() => editForm(id)}
        >
          <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
          Edit
        </button>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    renderInfo();
  }, []);
  return (
    <div className="container mt-5">
      {loading && <LoaderComponent title="Loading Roles..." />}
      {!loading && (
        <>
          <div className="book-title my-3">
            <h3 className="mb-3">User Permissions</h3>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => setModalCreateVisible(true)}
            >
              Create Role
            </button>
          </div>
          {width < 1200 ? (
            <>
              <Menu mode="inline" className="pb-3">
                <SubMenu
                  key="filters"
                  title={
                    <span className="filter-menu-title">
                      <FontAwesomeIcon icon={faSliders as IconProp} />
                      <span>Filters</span>
                    </span>
                  }
                >
                  <Menu.Item className="filter-menu-item" key="filterId">
                    {returnSearchID()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterName">
                    {returnSearchName()}
                  </Menu.Item>
                  <Menu.Item
                    className="filter-menu-item"
                    key="filterDescription"
                  >
                    {returnSearchDescription()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterActive">
                    {returnSearchActive()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterAccess">
                    {returnSearchAccess()}
                  </Menu.Item>
                </SubMenu>
              </Menu>
              {rolesTransform.map((role: IRolesTransform, index) => (
                <RolesCard
                  role={role}
                  editForm={editForm}
                  roleIndex={index}
                  key={index}
                />
              ))}
            </>
          ) : (
            <>
              <Divider />

              <div className="card rounded-dp shadow-lg">
                <div className="card-body">
                  <Table
                    columns={columns}
                    scroll={{ x: 240 }}
                    dataSource={rolesTransform}
                  />
                </div>
              </div>
            </>
          )}

          <Modal
            title="Create Role"
            open={modalCreateVisible}
            onCancel={() => setModalCreateVisible(false)}
            footer={null}
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinishForm}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input the Role Name",
                    },
                  ]}
                  id="roleName"
                  label="Role Name"
                  name="roleName"
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      setformEdit({ ...formEdit, roleName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Role Description"
                  name="roleDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Role Description",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      setformEdit({
                        ...formEdit,
                        roleDescription: e.target.value,
                      })
                    }
                    id="roleDescription"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  label="Role is Default"
                  name="isDefault"
                  valuePropName="checked"
                >
                  <Checkbox value={formEdit.isDefault} defaultChecked={false}>
                    Is Default
                  </Checkbox>
                </Form.Item>
                <Form.Item label="Role Access">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Select the access"
                    onChange={(newValue: string[]) => setAccessSelect(newValue)}
                  >
                    {accessList.map((item) => (
                      <Select.Option key={item.accessId}>
                        {item.path}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className="buttons-modal">
                  <button
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Edit Account"
            footer={null}
            open={modalVisible}
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form
                name="basic"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinishFormUpdate}
              >
                <div>
                  <Form.Item id="roleName">
                    <Input
                      type="text"
                      value={formEdit.roleName}
                      onChange={(e) =>
                        setformEdit({ ...formEdit, roleName: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item id="roleDescription">
                    <Input
                      value={formEdit.roleDescription}
                      onChange={(e) =>
                        setformEdit({
                          ...formEdit,
                          roleDescription: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="Role is Default?">
                    <Checkbox
                      checked={formEdit.isDefault}
                      onChange={(e) =>
                        setformEdit({
                          ...formEdit,
                          isDefault: e.target.checked,
                        })
                      }
                    >
                      Is Default
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="Active">
                    <Checkbox
                      checked={formEdit.active}
                      onChange={(e) =>
                        setformEdit({
                          ...formEdit,
                          active: e.target.checked,
                        })
                      }
                    >
                      Active
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="Role Access">
                    <Select
                      mode="multiple"
                      value={accessSelect}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select the access"
                      onChange={(newValue: string[]) =>
                        setAccessSelect(newValue)
                      }
                    >
                      {accessList.map((item) => (
                        <Select.Option key={item.accessId}>
                          {item.path}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="buttons-modal mt-3">
                  <button
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AccountsRoles;
