import React from "react";
import { useNavigate } from "react-router-dom";
import RoutesPath from "../../../Routes";

const BottomSection = () => {
  const navigate = useNavigate();
  return (
    <div className="container text-center">
      <div className="row mt-3">
        <h1 className="logo-header-best-price ">Book with confidence</h1>
      </div>
      <div className="row row-logo-landing d-flex justify-content-center align-items-center">
        <a
          className="col-4 text-center"
          href={`${RoutesPath.home}/best-price-terms-conditions`}
        >
          <img
            src="https://storage.googleapis.com/imagebucketdealpeak/best-price-logo.png"
            className="best-price-logo"
            alt="Best Price Logo"
          />
        </a>
      </div>
    </div>
  );
};

export default BottomSection;
