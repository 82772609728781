import React, { ReactElement } from "react";
import { Col, Divider } from "antd";

interface props {
  titleHotel?: string;
  starRating?: string;
  address?: {
    line1: string;
    line2: string | null;
    city: { code: string | null; name: string };
    state: { code: string | null; name: string };
    country: { code: string | null; name: string };
    postalCode: string;
  };
}

const AboutDetail = ({
  titleHotel,
  starRating,
  address,
}: props): ReactElement => (
  <>
    <Col lg={24} className="my-3 about-detail">
      <h3>{titleHotel}</h3>
      <span className="star-rating">{starRating} Stars Hotel</span>
      <div>
        <span className="address-information">
          {address?.line1}, {address?.city.name}, {address?.country.name}
        </span>
      </div>
    </Col>
    <Divider />
  </>
);
export default AboutDetail;
