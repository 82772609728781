import React, { useState, ReactElement, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Table,
  message,
  Popconfirm,
  Divider,
  Menu,
  Switch,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faLocation,
  faSearch,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import {
  getAll,
  ISetting,
  update,
  del,
  add,
} from "../../../helpers/requestSetting";
import LoaderComponent from "../../../Components/LoaderComponent";
import { useViewport } from "../../../Context/useViewport";
import SettingsCard from "../../../Components/AccountManagment/SettingsCard";
import AdditionalFeeConfig from "../../../Pages/Accounts/Settings/AdditionalFeeConfig/index";
import TwoFactorModal from "./TwoFactorAuthModal";
import SettingsForm from "../../../Components/AccountManagment/SettingsForm";
import SettingsFormAuth from "../../../Components/AccountManagment/TwoFaSettings";
import TwoFaIpListSettingForm from "../../../Components/AccountManagment/TwoFaIpListSettings";

const AccountSettings = (): ReactElement => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [openAdditionalFeeConfig, setOpenAdditionalFeeConfig] =
    useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [twoFactorAuthConfig, setTwoFactorAuthConfig] = useState(false);
  const [isExempt2faConfig, setIsExempt2faConfig] = useState(false);
  const [formEdit, setFormEdit] = useState({ id: 0, name: "", value: "" });
  const [dataSource, setDataSource] = useState<ISetting[]>([]);
  const [settingsData, setSettingsData] = useState<ISetting[]>([]);
  const [openEditForm, setopenEditForm] = useState(false);
  const [twoFactorIsOpen, settwoFactorIsOpen] = useState(false);
  const success = () => message.success("Saved");
  const error = (err: any) => message.error(err);
  const { width } = useViewport();
  const { SubMenu } = Menu;

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const settings = await getAll();
        setDataSource(settings);
        setSettingsData(settings);
      } catch (err) {
        error(err);
      } finally {
        setLoading(false);
      }
    };
    init();
  }, [reload]);

  const handleCancelAdditionalFee = () => {
    setOpenAdditionalFeeConfig(false);
  };
  const handleOkAdditionalFee = async (values: ISetting) => {
    await update(values);
    setOpenAdditionalFeeConfig(false);
    success();
    setReload((p) => !p);
  };

  const handleOkExempt2faConfig = async (values: ISetting) => {
    await update(values);
    setIsExempt2faConfig(!isExempt2faConfig);
    success();
    setReload(!reload);
  };

  const handleOkSettingForm = async (values: ISetting) => {
    try {
      await update(values);
      success();
    } catch (e) {
      error("Error on saving the Setting");
    } finally {
      setopenEditForm(false);
      setReload((p) => !p);
    }
  };

  const handleOkSetting2Fa = async (values: ISetting) => {
    try {
      await update(values);
      success();
    } catch (e) {
      error("Error on saving the Setting");
    } finally {
      setTwoFactorAuthConfig(false);
      setReload((p) => !p);
    }
  };
  const handleTwoFactorAuth = async (values: ISetting) => {
    await update(values);
    settwoFactorIsOpen(false);
    success();
    setReload((p) => !p);
  };

  const handleModal = (editSetting: ISetting) => {
    setFormEdit({
      id: editSetting.id,
      name: editSetting.name,
      value: editSetting.value,
    });

    switch (editSetting.name) {
      case "CreditCardFeeMobile":
      case "CreditCardFeeDesktop":
        setOpenAdditionalFeeConfig(true);
        return;
      case "SMSTwoFactorActive":
        settwoFactorIsOpen(true);
        return;
      case "CommissionPercentages":
        setopenEditForm(true);
        return;
      case "ExemptDevice2fa":
        setTwoFactorAuthConfig(true);
        return;
      case "Exempt2faIpList":
        setIsExempt2faConfig(true);
        return;
      default:
        break;
    }
    setModalVisible(true);
  };

  const onFinishForm = async (values: any) => {
    try {
      await add(values);
      setModalCreateVisible(false);
      success();
      setReload((p) => !p);
    } catch (err) {
      error(err);
    }
  };

  const handleDelete = async (record: ISetting) => {
    try {
      await del(record.id);
      setReload((p) => !p);
    } catch (err) {
      error(err);
    }
  };

  const onFinish = async () => {
    try {
      update(formEdit);
      setModalVisible(false);
      success();
      setReload((p) => !p);
    } catch (err: any) {
      error(err);
    }
  };

  const filterIconSearch = () => (
    <FontAwesomeIcon icon={faSearch as IconProp} />
  );

  const [filterObject, setfilterObject] = useState({
    name: "",
    value: "",
    id: "",
  });

  const returnSearchID = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by ID"
        autoFocus
        value={filterObject.id}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            id: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchName = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Name"
        autoFocus
        value={filterObject.name}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            name: e.target.value,
          })
        }
      />
    </div>
  );

  const returnSearchValue = () => (
    <div className="p-2">
      <Input
        className="filter-input"
        placeholder="Search by Value"
        autoFocus
        value={filterObject.value}
        onChange={(e) =>
          setfilterObject({
            ...filterObject,
            value: e.target.value,
          })
        }
      />
    </div>
  );

  useEffect(() => {
    const copyArr = settingsData;
    setDataSource(
      copyArr.filter(
        (item) =>
          item.id.toString().includes(filterObject.id.toString()) &&
          item.name.toLowerCase().includes(filterObject.name.toLowerCase()) &&
          item.value.toLowerCase().includes(filterObject.value.toLowerCase())
      )
    );
  }, [filterObject]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
      filterDropdown: () => returnSearchID(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) => (a.id < b.id ? -1 : 1),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 20,
      filterDropdown: () => returnSearchName(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value: string) => (
        <div>
          {value.toLowerCase() === "true" || value.toLowerCase() === "false" ? (
            <>
              <Switch checked={value === "true"} />
            </>
          ) : (
            <span>{value}</span>
          )}
        </div>
      ),
      filterDropdown: () => returnSearchValue(),
      filterIcon: () => filterIconSearch(),
      sorter: (a: any, b: any) =>
        a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1,
    },
    {
      title: "Edit Setting",
      dataIndex: "edit",
      key: "edit",
      width: 100,
      render: (_: any, record: ISetting) => (
        <button
          className="btn btn-success-dp-xs"
          type="button"
          aria-hidden="true"
          onClick={() => handleModal(record)}
        >
          <FontAwesomeIcon icon={faEdit as IconProp} className="me-2" />
          Edit
        </button>
      ),
    },
    {
      title: "Delete Setting",
      dataIndex: "delete",
      key: "delete",
      width: 100,
      render: (_: any, record: ISetting) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record)}
        >
          <Button
            htmlType="button"
            type="dashed"
            shape="round"
            aria-hidden="true"
            icon={
              <FontAwesomeIcon icon={faClose as IconProp} className="me-2" />
            }
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      {loading && <LoaderComponent title="Loading Settings..." />}
      {!loading && (
        <>
          <h3 className="mb-3">Settings</h3>
          <div className="book-title mt-3">
            <Button
              type="dashed"
              shape="round"
              htmlType="button"
              onClick={() => navigate("/settings/locations")}
              icon={
                <FontAwesomeIcon
                  icon={faLocation as IconProp}
                  className="me-2"
                />
              }
            >
              Locations Radius
            </Button>
            <button
              type="button"
              className="btn btn-primary-dp"
              onClick={() => setModalCreateVisible(!modalCreateVisible)}
            >
              Create Setting
            </button>
          </div>
          {width < 1200 ? (
            <>
              <Menu mode="inline" className="pb-3">
                <SubMenu
                  key="filters"
                  title={
                    <span className="filter-menu-title">
                      <FontAwesomeIcon icon={faSliders as IconProp} />
                      <span>Filters</span>
                    </span>
                  }
                >
                  <Menu.Item
                    className="filter-menu-item"
                    key="filterSettingsId"
                  >
                    {returnSearchID()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterName">
                    {returnSearchName()}
                  </Menu.Item>
                  <Menu.Item className="filter-menu-item" key="filterValue">
                    {returnSearchValue()}
                  </Menu.Item>
                </SubMenu>
              </Menu>
              {dataSource.map((setting: ISetting, i) => (
                <SettingsCard
                  key={i}
                  setting={setting}
                  handleEdit={handleModal}
                  handleDelete={handleDelete}
                />
              ))}
            </>
          ) : (
            <>
              <Divider />

              <div className="card rounded-dp shadow-lg">
                <div className="card-body">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ x: 240 }}
                    rowKey="id"
                  />
                </div>
              </div>
            </>
          )}

          <Modal
            title="Create Setting"
            open={modalCreateVisible}
            onCancel={() => setModalCreateVisible(false)}
            footer={null}
            cancelText="Cancel"
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form name="basic" onFinish={onFinishForm} layout="vertical">
                <Form.Item
                  name="name"
                  label="Setting Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the setting name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="value"
                  label="Setting Value"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user setting value!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <div className="buttons-modal">
                  <button
                    type="submit"
                    name="create"
                    value="click me"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
          <AdditionalFeeConfig
            setting={formEdit}
            open={openAdditionalFeeConfig}
            onCancel={handleCancelAdditionalFee}
            onOk={handleOkAdditionalFee}
          />
          <TwoFaIpListSettingForm
            open={isExempt2faConfig}
            onCancel={() => setIsExempt2faConfig(!isExempt2faConfig)}
            onOk={handleOkExempt2faConfig}
            setting={formEdit}
          />
          <SettingsForm
            setting={formEdit}
            open={openEditForm}
            onCancel={() => {
              setopenEditForm(false);
            }}
            onOk={handleOkSettingForm}
          />
          <SettingsFormAuth
            setting={formEdit}
            open={twoFactorAuthConfig}
            onCancel={() => {
              setTwoFactorAuthConfig(false);
            }}
            onOk={handleOkSetting2Fa}
          />
          <TwoFactorModal
            open={twoFactorIsOpen}
            onCancel={() => settwoFactorIsOpen(false)}
            onOk={handleTwoFactorAuth}
            setting={formEdit}
          />
          <Modal
            title="Edit Setting"
            footer={null}
            open={modalVisible}
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            destroyOnClose
            closeIcon={<small>Close</small>}
          >
            <div>
              <Form
                name="basic"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
              >
                <div>
                  <Form.Item label="Setting Name">
                    <Input
                      name="name"
                      value={formEdit.name}
                      onChange={(e) =>
                        setFormEdit({ ...formEdit, name: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <Form.Item label="Setting Value">
                    {formEdit.value.toLowerCase() === "true" ||
                    formEdit.value.toLowerCase() === "false" ? (
                      <Switch
                        checked={formEdit.value === "true"}
                        onChange={(e) =>
                          setFormEdit({ ...formEdit, value: e.toString() })
                        }
                      />
                    ) : (
                      <Input
                        name="value"
                        value={formEdit.value}
                        onChange={(e) =>
                          setFormEdit({ ...formEdit, value: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="buttons-modal mt-3">
                  <button
                    type="submit"
                    className="btn btn-success-dp btn-block"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AccountSettings;
